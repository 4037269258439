<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-CompanyExamination" data-bs-toggle="tab"
                                    href="#CompanyExamination" role="tab" aria-controls="CompanyExamination"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px">Quy trình cấp giấy ATTP</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="CompanyExamination" role="tabpanel">
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">
                                            Trường hợp cơ sở đã được thẩm định và xếp loại A và loại B <a style="font-size: 18px; color: #ffffff; text-decoration: underline;" href="assets/Download/QT-03CapGiayChungNhanATTP.pdf" target="_blank">[Tải về Quy trình hướng dẫn]</a>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table class="table table-striped m-0" id="example5">
                                                    <thead>
                                                        <tr>
                                                            <th style="padding: 10px; text-align: center; font-size: 18px;">STT</th>
                                                            <th style="padding: 10px; text-align: center; font-size: 18px;">Trình tự</th>
                                                            <th style="padding: 10px; text-align: center; font-size: 18px;">Trách nhiệm</th>
                                                            <th style="padding: 10px; text-align: center; font-size: 18px;">Thời gian</th>
                                                            <th style="padding: 10px; text-align: center; font-size: 18px;">Biểu mẫu</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style="padding: 10px;">B1</td>
                                                            <td style="padding: 10px;">Nộp hồ sơ: TCCN có nhu cầu nộp đầy
                                                                đủ hồ sơ theo quy định tại mục 5.2
                                                                về Trung tâm Phục vụ hành chính công tỉnh Bến Tre</td>
                                                            <td style="padding: 10px;">TCCN</td>
                                                            <td style="padding: 10px;">Giờ hành chính</td>
                                                            <td style="padding: 10px;">Thành phần hồ sơ theo mục 5.2</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="padding: 10px;">B2</td>
                                                            <td style="padding: 10px;">
                                                                Tiếp nhận hồ sơ: CBCC Bộ phận TN&TKQ Trung tâm Phục vụ
                                                                hành chính công tỉnh tiếp nhận hồ
                                                                sơ có trách nhiệm xem xét, kiểm tra tính chính xác,
                                                                đầy đủ của hồ sơ:
                                                                Tiếp nhận hồ sơ: CBCC Bộ phận TN&TKQ Trung tâm Phục vụ
                                                                hành chính công tỉnh tiếp nhận hồ
                                                                sơ có trách nhiệm xem xét, kiểm tra tính chính xác,
                                                                đầy đủ của hồ sơ:
                                                                a) Trường hợp hồ sơ không đầy đủ, chưa chính xác theo
                                                                quy định, cán bộ, công chức tiếp nhận
                                                                hồ sơ phải hướng dẫn đại diện TCCN bổ sung, hoàn thiện
                                                                hồ sơ theo quy định và nêu rõ lý do
                                                                theo mẫu Phiếu yêu cầu bổ sung, hoàn thiện hồ sơ;
                                                                b) Trường hợp từ chối nhận hồ sơ, cán bộ, công chức tiếp
                                                                nhận hồ sơ phải nêu rõ lý do theo
                                                                mẫu Phiếu từ chối giải quyết hồ sơ thủ tục hành chính;
                                                                c) Trường hợp hồ sơ đầy đủ, chính xác theo quy định, cán
                                                                bộ, công chức tiếp nhận hồ sơ và
                                                                lập Giấy tiếp nhận hồ sơ và hẹn ngày trả kết quả; Lưu
                                                                trữ hồ sơ điện tử, cập nhật vào phần
                                                                mềm hệ thống thông tin một cửa điện tử của tỉnh, chuyển
                                                                hồ sơ đến Chi cục QLCLNLS&TS xử lý

                                                            </td>
                                                            <td style="padding: 10px;">
                                                                CBCC Bộ phận TN&TKQ Trung tâm Phục vụ hành chính công
                                                            </td>
                                                            <td style="padding: 10px;">½ ngày</td>
                                                            <td style="padding: 10px;">Mẫu số 01, 02, 03, 05, 06 (Thông tư 01/2018/TT-VPCP)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="padding: 10px;">B3</td>
                                                            <td style="padding: 10px;">Phân công thụ lý hồ sơ: CBCC tiếp nhận hồ sơ, trình lãnh
                                                                đạo Phòng QLCL, CB &TM phân công
                                                                CBCC thụ lý hồ sơ</td>
                                                            <td style="padding: 10px;">Lãnh đạo Phòng QLCL, CB &TM</td>
                                                            <td style="padding: 10px;">½ ngày</td>
                                                            <td style="padding: 10px;">Mẫu số 05 (Thông tư 01/2018/TT-VPCP)</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="padding: 10px;">B4</td>
                                                            <td style="padding: 10px;">Thẩm định hồ sơ: CBCC được phân công thụ lý thực hiện
                                                                thẩm định, kiểm tra hồ sơ:
                                                                - Nếu hồ sơ cần giải trình, bổ sung thêm: Hướng dẫn TCCN
                                                                bổ sung, hoàn chỉnh hồ sơ theo quy
                                                                định
                                                                - Nếu hồ sơ đạt yêu cầu: tiến hành bước tiếp theo</td>
                                                            <td style="padding: 10px;">
                                                                CBCC chuyên môn
                                                            </td>
                                                            <td style="padding: 10px;">04 ngày</td>
                                                            <td style="padding: 10px;">Mẫu số 02, 05 (Thông tư 01/2018/TT-VPCP)</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="padding: 10px;">B5</td>
                                                            <td style="padding: 10px;">Báo cáo kết quả thẩm định: CBCC tổng hợp, hoàn thiện hồ
                                                                sơ pháp lý, dự thảo tờ trình, dự
                                                                thảo quyết định cấp chứng nhận cở sở đủ điều kiện an
                                                                toàn thực phẩm đối với cơ sở sản xuất,
                                                                kinh doanh thực phẩm nông, lâm, thủy sản trình lãnh đạo
                                                                Chi cục xem xét, phê duyệt
                                                                Ghi chú: Trường hợp hồ sơ bị chậm muộn thì phải kèm theo
                                                                phiếu xin lỗi mẫu số 04</td>
                                                            <td style="padding: 10px;">
                                                                ½ ngày
                                                            </td>
                                                            <td style="padding: 10px;">
                                                                Mẫu số 04, 05 (Thông tư 01/2018/TT-VPCP)
                                                                Dự thảo (quyết định, tờ trình)
                                                            </td>
                                                            <td style="padding: 10px;"></td>
                                                        </tr>
                                                        <tr>
                                                            <td style="padding: 10px;">B6</td>
                                                            <td style="padding: 10px;">Xem xét kết quả thẩm định: Lãnh đạo Chi cục tiếp nhận,
                                                                xem xét hồ sơ, ký phê duyệt quyết
                                                                định cấp chứng nhận cở sở đủ điều kiện an toàn thực phẩm
                                                                đối với cơ sở sản xuất, kinh doanh
                                                                thực phẩm nông, lâm, thủy sản</td>

                                                            <td style="padding: 10px;">Lãnh đạo Chi cục</td>
                                                            <td style="padding: 10px;">½ ngày</td>
                                                            <td style="padding: 10px;">
                                                                Mẫu số 04, 05 (Thông tư 01/2018/TT-VPCP)
                                                                Dự thảo (quyết định, tờ trình)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="padding: 10px;">B7</td>
                                                            <td style="padding: 10px;">Đóng dấu & sao lưu:
                                                                CBCC thụ lý hồ sơ tiếp nhận kết quả, chuyển văn thư vào
                                                                số, đóng dấu, nhân bản và chuyển kết
                                                                quả về bộ phận TN&TKQ
                                                                Lưu trữ hồ sơ theo quy định tại mục 7</td>
                                                            <td style="padding: 10px;">CBCC chuyên môn
                                                                Văn thư</td>
                                                            <td style="padding: 10px;">½ ngày</td>
                                                            <td style="padding: 10px;">
                                                                Mẫu số 04, 05 (Thông tư 01/2018/TT-VPCP)
                                                                Giấy chứng nhận xuất xứ cho lô nguyên liệu nhuyễn thể
                                                                hai mảnh vỏ
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td style="padding: 10px;">B8</td>
                                                            <td style="padding: 10px;">Trả kết quả:
                                                                Xác nhận trên phần mềm Hệ thống thông tin một cửa điện
                                                                tử của tỉnh
                                                                Thông báo và trả kết quả cho TCCN theo quy định</td>
                                                            <td style="padding: 10px;">Bộ phận TN&TKQ</td>
                                                            <td style="padding: 10px;">Theo giấy hẹn</td>
                                                            <td style="padding: 10px;">Mẫu số 04, 05 (Thông tư 01/2018/TT-VPCP)
                                                                Giấy chứng nhận xuất xứ cho lô nguyên liệu nhuyễn thể
                                                                hai mảnh vỏ</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>