<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-ATTPInfoDetail" data-bs-toggle="tab" href="#ATTPInfoDetail" role="tab"
                aria-controls="ATTPInfoDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Đăng ký cấp chứng nhận An toàn thực phẩm</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="ATTPInfoDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <a style="width: 100%;" title="Hoàn thành" (click)="ATTPInfoSave()" class="btn btn-info"><i
                                class="bi bi-sd-card"></i> Hoàn thành</a>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <h4>Bước 01: Thông tin chung</h4>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Tiêu đề</label>
                                <input name="Name" [(ngModel)]="ATTPInfoService.FormData.Name" placeholder="Tiêu đề"
                                    type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Mã hồ sơ</label>
                                <input name="cer_code" [(ngModel)]="ATTPInfoService.FormData.cer_code"
                                    placeholder="Mã hồ sơ" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Ghi chú</label>
                                <input name="Note" [(ngModel)]="ATTPInfoService.FormData.Note" placeholder="Ghi chú"
                                    type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Đơn vị Tổ chức</label>
                                <mat-select *ngIf="ATTPInfoService.FormData.ID==0" class="form-control"
                                    name="ATTPInfoService.FormData.CompanyInfoID"
                                    [(ngModel)]="ATTPInfoService.FormData.CompanyInfoID">
                                    <input placeholder="Tìm..." class="form-control"
                                        (keyup)="CompanyInfoFilter($event.target.value)">
                                    <mat-option *ngFor="let item of CompanyInfoService.ListFilter" [value]="item.ID">
                                        {{item.Name}} - {{item.address}}
                                    </mat-option>
                                </mat-select>
                                <input *ngIf="ATTPInfoService.FormData.ID>0" name="CompanyInfoName"
                                    [(ngModel)]="ATTPInfoService.FormData.CompanyInfoName" disabled
                                    placeholder="Đơn vị Tổ chức" type="text" class="form-control" />
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Loại hồ sơ</label>
                                <select class="form-select" name="DanhMucATTPLoaiHoSoID"
                                    [(ngModel)]="ATTPInfoService.FormData.DanhMucATTPLoaiHoSoID">
                                    <option *ngFor="let item of DanhMucATTPLoaiHoSoService.List;" [value]="item.ID">
                                        {{item.Name}}</option>
                                </select>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Ngày cấp</label>
                                <input [ngModel]="ATTPInfoService.FormData.NgayGhiNhan | date:'yyyy-MM-dd'"
                                    (ngModelChange)="DateNgayGhiNhan($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                                    type="date" class="form-control" autocomplete="off">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <h4>Bước 02: Mặt hàng đăng ký</h4>
                    </div>
                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort #ATTPInfoProductGroupsSort="matSort"
                        [dataSource]="ATTPInfoProductGroupsService.DataSource" class="table table-striped m-0">
                        <ng-container matColumnDef="ProductGroupID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Mặt hàng
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <input class="form-control" type="text" placeholder="Tiêu đề" name="Name{{element.ID}}"
                                    [(ngModel)]="element.Name" />
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Save">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <a class="btn btn-info" (click)="ATTPInfoProductGroupsSave(element)"><i
                                        class="bi bi-sd-card"></i> </a>&nbsp;&nbsp;&nbsp;
                                <a class="btn btn-danger" (click)="ATTPInfoProductGroupsDelete(element)"
                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                </a>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="ATTPInfoProductGroupsService.DisplayColumns001">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ATTPInfoProductGroupsService.DisplayColumns001;">
                        </tr>
                    </table>
                    <mat-paginator *ngIf="!NotificationService.IsMobile" #ATTPInfoProductGroupsPaginator="matPaginator"
                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000" [showFirstLastButtons]></mat-paginator>

                    <table *ngIf="NotificationService.IsMobile" mat-table matSort #ATTPInfoProductGroupsSort="matSort"
                        [dataSource]="ATTPInfoProductGroupsService.DataSource" class="table table-striped m-0">
                        <ng-container matColumnDef="STT">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Danh sách
                            </th>
                            <td mat-cell *matCellDef="let element; index as i;">
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <label>Mặt hàng</label>
                                        <input class="form-control" type="text" placeholder="Tiêu đề"
                                            name="Name{{element.ID}}" [(ngModel)]="element.Name" />
                                    </div>
                                    <div class="row gx-4">
                                        <div class="col-lg-6 col-sm-12 col-12">
                                            <a class="btn btn-info" style="width: 100%;"
                                                (click)="ATTPInfoProductGroupsSave(element)"><i
                                                    class="bi bi-sd-card"></i>
                                            </a>
                                        </div>
                                        <div class="col-lg-6 col-sm-12 col-12">
                                            <a class="btn btn-danger" style="width: 100%;"
                                                (click)="ATTPInfoProductGroupsDelete(element)" *ngIf="element.ID>0"><i
                                                    class="bi bi-trash"></i>
                                            </a>
                                        </div>
                                        <br />
                                        <br />
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="ATTPInfoProductGroupsService.DisplayColumnsMobile">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ATTPInfoProductGroupsService.DisplayColumnsMobile;">
                        </tr>
                    </table>
                    <mat-paginator *ngIf="NotificationService.IsMobile" #ATTPInfoProductGroupsPaginator="matPaginator"
                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <h4>Bước 03: Tải về biểu mẫu</h4>
                    </div>
                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort #ATTPInfoDocumentsSort="matSort"
                        [dataSource]="ATTPInfoDocumentsService.DataSource" class="table table-striped m-0">
                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Hồ sơ
                            </th>
                            <td mat-cell *matCellDef="let element" style="width: 400px;">
                                <a class="link-primary" title="{{element.FileName}}" href="{{element.FileName}}"
                                    target="_blank">{{element.Name}}
                                </a>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Display">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Biểu mẫu
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <a class="link-primary" title="{{element.Description}}" href="{{element.Description}}"
                                    target="_blank">Tải về
                                </a>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="ATTPInfoDocumentsService.DisplayColumns002">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ATTPInfoDocumentsService.DisplayColumns002;">
                        </tr>
                    </table>
                    <mat-paginator *ngIf="!NotificationService.IsMobile" #ATTPInfoDocumentsPaginator="matPaginator"
                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000" [showFirstLastButtons]></mat-paginator>

                    <table *ngIf="NotificationService.IsMobile" mat-table matSort #ATTPInfoDocumentsSort="matSort"
                        [dataSource]="ATTPInfoDocumentsService.DataSource" class="table table-striped m-0">
                        <ng-container matColumnDef="STT">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Danh sách
                            </th>
                            <td mat-cell *matCellDef="let element; index as i;">
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <label>Hồ sơ: </label>
                                        <a class="link-primary form-label" title="{{element.FileName}}"
                                            href="{{element.FileName}}" target="_blank">{{element.Name}}
                                        </a>
                                    </div>
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <label>Biểu mẫu: </label>
                                        <a class="link-primary form-label" title="{{element.Description}}"
                                            href="{{element.Description}}" target="_blank">Tải về
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="ATTPInfoDocumentsService.DisplayColumnsMobile">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ATTPInfoDocumentsService.DisplayColumnsMobile;">
                        </tr>
                    </table>
                    <mat-paginator *ngIf="NotificationService.IsMobile" #ATTPInfoDocumentsPaginator="matPaginator"
                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <h4>Bước 04: Tải lên Đơn đề nghị và Bản Thuyết minh</h4>
                    </div>
                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort #ATTPInfoDocumentsSort="matSort"
                        [dataSource]="ATTPInfoDocumentsService.DataSource" class="table table-striped m-0">
                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Hồ sơ
                            </th>
                            <td mat-cell *matCellDef="let element" style="width: 400px;">
                                <a class="link-primary" title="{{element.FileName}}" href="{{element.FileName}}"
                                    target="_blank">{{element.Name}}
                                </a>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="FileName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải lên
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <input type="file" (change)="ChangeFileName(element, $event.target.files)">
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="ATTPInfoDocumentsService.DisplayColumns003">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ATTPInfoDocumentsService.DisplayColumns003;">
                        </tr>
                    </table>
                    <mat-paginator *ngIf="!NotificationService.IsMobile" #ATTPInfoDocumentsPaginator="matPaginator"
                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000" [showFirstLastButtons]></mat-paginator>

                    <table *ngIf="NotificationService.IsMobile" mat-table matSort #ATTPInfoDocumentsSort="matSort"
                        [dataSource]="ATTPInfoDocumentsService.DataSource" class="table table-striped m-0">
                        <ng-container matColumnDef="STT">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Danh sách
                            </th>
                            <td mat-cell *matCellDef="let element; index as i;">
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <label>Hồ sơ: </label>
                                        <a class="link-primary form-label" title="{{element.FileName}}"
                                            href="{{element.FileName}}" target="_blank">{{element.Name}}
                                        </a>
                                    </div>
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <label>Tải lên: </label>
                                        <br />
                                        <input type="file" (change)="ChangeFileName(element, $event.target.files)">
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="ATTPInfoDocumentsService.DisplayColumnsMobile">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ATTPInfoDocumentsService.DisplayColumnsMobile;">
                        </tr>
                    </table>
                    <mat-paginator *ngIf="NotificationService.IsMobile" #ATTPInfoDocumentsPaginator="matPaginator"
                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ATTPInfoService.IsShowLoading"></app-loading>