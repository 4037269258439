import { Base } from "./Base.model";

export class CompanyInfo extends Base {
    type_id?: number;
    province_id?: number;
    district_id?: number;
    ward_id?: number;
    address?: string;
    fullname?: string;
    identity_card?: string;
    email?: string;
    phone?: string;
    business_number?: string;
    business_number_date?: string;
    business_number_place?: string;
    tax_code?: string;
    latitude?: number;
    longitude?: number;
    agency_approved?: string;
    number_lake?: number;
    hamlet?: string;
    product_des?: string;
    attp_status?: number;
    attp_rank?: number;
    is_tapchat?: boolean;
    tapchat_vipham?: boolean;
    scope_id?: string;
    approved_on?: Date;
    role_name?: string;
    hoso_id?: string;
    hoso_code?: string;
    attp_code?: string;
    last_thamdinh?: Date;
    thamdinh_id?: string;
    attp_next?: Date;
    attp_begin?: Date;
    file_name?: string;
    file_id?: string;
    file_path?: string;
    server_upload?: string;
    provider?: string;
    size_kb?: number;
    document_name?: string;
    document_type?: string;
    mine_type?: string;
    ext?: string;
    se?: number;
    ma?: number;
    mi?: number;
    dat?: number;
    hinhthucnuoi?: number;
    hinhthucnuoi_name?: string;
    acreage_cs?: number;
    acreage_nuoi?: number;
    unit_id?: string;
    unit_name?: string;
    lake_code?: string;
    ProvinceDataID?: number;
    DistrictDataID?: number;
    WardDataID?: number;
    ProvinceDataName?: string;
    DistrictDataName?: string;
    WardDataName?: string;
    DanhMucChuongTrinhQuanLyChatLuongID?: number;
    DanhMucChuongTrinhQuanLyChatLuongName?: string;
    CompanyScopeID?: number;
    CompanyScopeName?: string;
    DanhMucCompanyTinhTrangID?: number;
    DanhMucCompanyTinhTrangName?: string;
    DKKDNgayCap?: Date;
    DKKDSoCap?: string;
    DKKD?: string;
    CongSuatThietKe?: number;
    SanLuong?: number;
    DienTich?: number;
    SoLuongLaoDong?: number;
    MS?: number;
    DanhMucThiTruongID?: number;
    DanhMucThiTruongName?: string;
    MauNen?: string;
    NgayDangKy?: Date;
    NgayHetHan?: Date;
    CompanyGroupID?: number;
    CompanyGroupName?: string;
    CompanyFieldID?: number;
    CompanyFieldName?: string;    
    DanhMucCompanyTrangThaiID?: number;
    DanhMucCompanyTrangThaiName?: string;
    DuyetTaiKhoanThanhVienID?: number;
    DuyetTaiKhoanThanhVienName?: string;
    DuyetTaiKhoanNgayGhiNhan?: Date;
    PlanThamDinhID?: number;
    PlanThamDinhCode?: string;
    DanhMucCompanyInfoID?: number;
    DanhMucCompanyInfoName?: string;
    DanhMucCompanyPhanLoaiName?: string;
    ProductGroupID?: number;
    ProductGroupName?: string;
    DanhMucHinhThucNuoiID?: number;
    DanhMucHinhThucNuoiName?: string;
    CoSoNuoiMa?: string;
    CoSoNuoiDienTichNuoiTrong?: number;
    CoSoNuoiSoLuongAo?: number;
    PlanTypeID?: number;
    Website?: string;
    Facebook?: string;
    Tiktok?: string;
    Youtube?: string;
    Zalo?: string;
    COOP66?: string;
    FBS?: string;
}


