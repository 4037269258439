<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-PlanThamDinhDetail" data-bs-toggle="tab" href="#PlanThamDinhDetail"
                role="tab" aria-controls="PlanThamDinhDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Thẩm định Cơ sở đóng gói</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="PlanThamDinhDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <a *ngIf="NotificationService.IsSave" style="width: 100%;" title="Lưu thay đổi"
                            (click)="PlanThamDinhSave()" class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu thay
                            đổi</a>
                        <a *ngIf="!NotificationService.IsSave" style="width: 100%;" title="Lưu thay đổi"
                            (click)="PlanThamDinhSave()" class="btn btn-danger"><i class="bi bi-sd-card"></i> Lưu thay
                            đổi</a>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <h4>Bước 01: Nhập Thông tin</h4>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Nội dung</label>
                        <input name="Name" [(ngModel)]="PlanThamDinhService.FormData.Name" placeholder="Nội dung"
                            type="text" class="form-control" />
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-4 col-sm-12 col-12">
                        <a style="width: 100%;" class="btn btn-success" title="Thêm mới" (click)="ThanhVienAdd(0)"><i
                                class="bi bi-plus"></i> Thêm thành viên (nếu thiếu)</a>
                    </div>
                    <br />
                    <div class="card mb-4 card-success">
                        <div class="card-header">
                            <h5 class="card-title text-white">Bước 02: Chọn thành viên tham gia Đoàn công tác
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhThanhVienSort="matSort"
                                        [dataSource]="PlanThamDinhThanhVienService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="ThanhVienID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cán bộ đơn vị + Khách
                                                mời
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <select *ngIf="element.ID==0" class="form-select"
                                                    name="element.ThanhVienID" [(ngModel)]="element.ThanhVienID">
                                                    <option *ngFor="let item of ThanhVienService.List;"
                                                        [value]="item.ID">
                                                        {{item.Name}} ({{item.AgencyDepartmentName}} -
                                                        {{item.StateAgencyName}})</option>
                                                </select>
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.ThanhVienName}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="DanhMucChucDanhID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chức danh
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <select *ngIf="element.ID==0" class="form-select"
                                                    name="element.DanhMucChucDanhID"
                                                    [(ngModel)]="element.DanhMucChucDanhID">
                                                    <option *ngFor="let item of DanhMucChucDanhService.List;"
                                                        [value]="item.ID">
                                                        {{item.Name}}</option>
                                                </select>
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.DanhMucChucDanhName}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-info" (click)="PlanThamDinhThanhVienSave(element)"
                                                    *ngIf="element.ID==0"><i class="bi bi-sd-card"></i>
                                                </a>
                                                <a class="btn btn-danger" (click)="PlanThamDinhThanhVienDelete(element)"
                                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhThanhVienService.DisplayColumns001">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhThanhVienService.DisplayColumns001;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #PlanThamDinhThanhVienPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhThanhVienSort="matSort"
                                        [dataSource]="PlanThamDinhThanhVienService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Cán bộ: </label>
                                                        <select *ngIf="element.ID==0" class="form-select"
                                                            name="element.ThanhVienID"
                                                            [(ngModel)]="element.ThanhVienID">
                                                            <option *ngFor="let item of ThanhVienService.List;"
                                                                [value]="item.ID">
                                                                {{item.Name}}</option>
                                                        </select>
                                                        <label class="form-label"
                                                            *ngIf="element.ID>0">{{element.ThanhVienName}}</label>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Chức danh: </label>
                                                        <select *ngIf="element.ID==0" class="form-select"
                                                            name="element.DanhMucChucDanhID"
                                                            [(ngModel)]="element.DanhMucChucDanhID">
                                                            <option *ngFor="let item of DanhMucChucDanhService.List;"
                                                                [value]="item.ID">
                                                                {{item.Name}}</option>
                                                        </select>
                                                        <label class="form-label"
                                                            *ngIf="element.ID>0">{{element.DanhMucChucDanhName}}</label>
                                                    </div>
                                                    <div class="row gx-4">
                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                            <a class="btn btn-info" style="width: 100%"
                                                                (click)="PlanThamDinhThanhVienSave(element)" *ngIf="element.ID==0"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>
                                                            <a class="btn btn-danger" style="width: 100%;"
                                                                (click)="PlanThamDinhThanhVienDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </div>
                                                        <br />
                                                        <br />
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhThanhVienService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhThanhVienService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #PlanThamDinhThanhVienPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-4 col-sm-12 col-12">
                        <a style="width: 100%;" class="btn btn-warning" title="Thêm mới" (click)="CompanyInfoAdd(0)"><i
                                class="bi bi-plus"></i> Thêm Đơn vị Tổ chức (nếu thiếu)</a>
                    </div>
                    <br />
                    <div class="card mb-4 card-warning">
                        <div class="card-header">
                            <h5 class="card-title text-white">Bước 03: Chọn Đơn vị Tổ chức thẩm định
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhCompaniesSort="matSort"
                                        [dataSource]="PlanThamDinhCompaniesService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="CompanyInfoID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Đơn vị Tổ chức
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <mat-select class="form-control" name="element.CompanyInfoID"
                                                    [(ngModel)]="element.CompanyInfoID" *ngIf="element.ID==0">
                                                    <input placeholder="Tìm..." class="form-control"
                                                        (keyup)="CompanyInfoFilter($event.target.value)">
                                                    <mat-option *ngFor="let item of CompanyInfoService.ListFilter"
                                                        [value]="item.ID">
                                                        {{item.Name}} - {{item.address}}
                                                    </mat-option>
                                                </mat-select>
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.CompanyInfoName}} - {{element.Description}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="DanhMucATTPLoaiHoSoID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Hồ sơ
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <select *ngIf="element.ID==0" class="form-select"
                                                    name="element.DanhMucATTPLoaiHoSoID"
                                                    [(ngModel)]="element.DanhMucATTPLoaiHoSoID">
                                                    <option *ngFor="let item of DanhMucATTPLoaiHoSoService.List;"
                                                        [value]="item.ID">
                                                        {{item.Name}}</option>
                                                </select>
                                                <div *ngIf="element.ID>0">
                                                    {{element.DanhMucATTPLoaiHoSoName}}
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="NgayGhiNhan">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ngày thẩm định
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="width: 50px;">
                                                <input *ngIf="element.ID==0" name="NgayGhiNhan{{element.ID}}"
                                                    [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                    (ngModelChange)="DatePlanThamDinhCompaniesNgayGhiNhan(element, $event)"
                                                    [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                    class="form-control" autocomplete="off">
                                                <div *ngIf="element.ID>0">
                                                    {{element.NgayGhiNhan
                                                    | date:'dd/MM/yyyy'}}
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="width: 50px;">
                                                <a class="btn btn-info" (click)="PlanThamDinhCompaniesSave(element)"
                                                    *ngIf="element.ID==0"><i class="bi bi-sd-card"></i>
                                                </a>
                                                <a class="btn btn-danger" (click)="PlanThamDinhCompaniesDelete(element)"
                                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhCompaniesService.DisplayColumns001">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhCompaniesService.DisplayColumns001;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #PlanThamDinhCompaniesPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhCompaniesSort="matSort"
                                        [dataSource]="PlanThamDinhCompaniesService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">                                                       
                                                        <mat-select class="form-control" name="element.CompanyInfoID"
                                                            [(ngModel)]="element.CompanyInfoID" *ngIf="element.ID==0">
                                                            <input placeholder="Tìm..." class="form-control"
                                                                (keyup)="CompanyInfoFilter($event.target.value)">
                                                            <mat-option
                                                                *ngFor="let item of CompanyInfoService.ListFilter"
                                                                [value]="item.ID">
                                                                {{item.Name}}
                                                            </mat-option>
                                                        </mat-select>
                                                        <label class="form-label"
                                                            *ngIf="element.ID>0">{{element.CompanyInfoName}}</label>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Hồ sơ: </label>
                                                        <select *ngIf="element.ID==0" class="form-select"
                                                            name="element.DanhMucATTPLoaiHoSoID"
                                                            [(ngModel)]="element.DanhMucATTPLoaiHoSoID">
                                                            <option
                                                                *ngFor="let item of DanhMucATTPLoaiHoSoService.List;"
                                                                [value]="item.ID">
                                                                {{item.Name}}</option>
                                                        </select>
                                                        <label class="form-label"
                                                            *ngIf="element.ID>0">{{element.DanhMucATTPLoaiHoSoName}}</label>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Ghi nhận: </label>
                                                        <input *ngIf="element.ID==0" name="NgayGhiNhan{{element.ID}}"
                                                            [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                            (ngModelChange)="DatePlanThamDinhCompaniesNgayGhiNhan(element, $event)"
                                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                            class="form-control" autocomplete="off">
                                                        <label class="form-label"
                                                            *ngIf="element.ID>0">{{element.NgayGhiNhan
                                                            | date:'dd/MM/yyyy'}}</label>
                                                    </div>
                                                    <div class="row gx-4">
                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                            <a class="btn btn-info" style="width: 100%"
                                                                (click)="PlanThamDinhCompaniesSave(element)"
                                                                *ngIf="element.ID==0"><i class="bi bi-sd-card"></i>
                                                            </a>
                                                            <a class="btn btn-danger" style="width: 100%;"
                                                                (click)="PlanThamDinhCompaniesDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </div>
                                                        <br />
                                                        <br />
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhCompaniesService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhCompaniesService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #PlanThamDinhCompaniesPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-danger">
                        <div class="card-header">
                            <h5 class="card-title text-white">Bước 03.1: Chọn Hồ sơ (Đã tiếp nhận) Đăng ký cấp chứng mã số đóng gói
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #ATTPInfoSort="matSort" [dataSource]="CompanyInfoDonViDongGoiService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                STT
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i"
                                                style="text-align: center">
                                                <div *ngIf="ATTPInfoPaginator">
                                                    {{ATTPInfoPaginator.pageSize * ATTPInfoPaginator.pageIndex + i + 1}}
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="ID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                ID
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.ID }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="NgayGhiNhan">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ngày đăng ký
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a style="cursor: pointer" title="{{element.cer_code}}"
                                                    class="link-primary"
                                                    (click)="ATTPInfoAddByID(element.ID)"><b>{{element.NgayGhiNhan
                                                        | date:'dd/MM/yyyy'}}</b></a>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="CompanyInfoName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Đơn vị Tổ chức
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <b>{{element.CompanyInfoName}}</b>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="MaHoSo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã hồ sơ
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a style="cursor: pointer" title="{{element.cer_code}}"
                                                    class="link-primary"
                                                    (click)="ATTPInfoAddByID(element.ID)"><b>{{element.MaHoSo}}</b></a>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="DanhMucATTPLoaiHoSoName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại hồ sơ
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.DanhMucATTPLoaiHoSoName}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="DanhMucATTPTinhTrangName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tình trạng
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.DanhMucATTPTinhTrangName}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox"
                                                        (change)="CompanyInfoDonViDongGoiActiveChange(element)"
                                                        name="Active{{element.ID}}" [(ngModel)]="element.Active">
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="CompanyInfoDonViDongGoiService.DisplayColumns004"></tr>
                                        <tr mat-row *matRowDef="let row; columns: CompanyInfoDonViDongGoiService.DisplayColumns004">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #ATTPInfoPaginator="matPaginator" [pageSizeOptions]="[10, 20, 50, 100, 500, 999]"
                                        [pageSize]="6" [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #ATTPInfoSort="matSort" [dataSource]="CompanyInfoDonViDongGoiService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Đăng ký: </label>
                                                        <br />
                                                        <a style="cursor: pointer" title="{{element.cer_code}}"
                                                            class="link-primary form-label"
                                                            (click)="ATTPInfoAddByID(element.ID)"><b>{{element.NgayGhiNhan
                                                                |
                                                                date:'dd/MM/yyyy'}}</b></a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Mã số: </label>
                                                        <br />
                                                        <a style="cursor: pointer" title="{{element.cer_code}}"
                                                            class="link-primary"
                                                            (click)="ATTPInfoAddByID(element.ID)"><b>{{element.cer_code}}</b></a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Loại hồ sơ: </label>
                                                        <label class="form-label">
                                                            {{element.DanhMucATTPLoaiHoSoName}}</label>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Tình trạng: </label>
                                                        <label class="form-label">
                                                            {{element.DanhMucATTPTinhTrangName}}</label>
                                                    </div>
                                                    <div class="row gx-4">
                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    (change)="CompanyInfoDonViDongGoiActiveChange(element)"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <br />
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="CompanyInfoDonViDongGoiService.DisplayColumnsMobile"></tr>
                                        <tr mat-row *matRowDef="let row; columns: CompanyInfoDonViDongGoiService.DisplayColumnsMobile">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #ATTPInfoPaginator="matPaginator" [pageSizeOptions]="[10, 20, 50, 100, 500, 999]"
                                        [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="PlanThamDinhService.IsShowLoading"></app-loading>