<div class="app-body">
  <div class="row">
    <div class="col-xxl-12">
      <div class="card" style="margin-left: 1px">
        <div class="card-body">
          <div class="custom-tabs-container">
            <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="nav-link active" id="tab-GiaoTrinhATTP" data-bs-toggle="tab" href="#GiaoTrinhATTP" role="tab"
                  aria-controls="GiaoTrinhATTP" aria-selected="true">
                  <span class="badge bg-info" style="font-size: 16px">Giáo trình ATTP</span>
                </a>
              </li>
            </ul>
            <div class="tab-content" id="customTabContent">
              <div class="tab-pane fade show active" id="GiaoTrinhATTP" role="tabpanel">
                <div class="row gx-4">
                  <div class="col-lg-3 col-sm-12 col-12">
                    <label *ngIf="!NotificationService.IsMobile" class="form-label">Nhóm
                    </label>
                    <select class="form-select" [(ngModel)]="GiaoTrinhATTPService.BaseParameter.ParentID">
                      <option [value]="0">
                        Tất cả</option>
                      <option *ngFor="let item of CauHoiNhomService.List;" [value]="item.ID">
                        {{item.Name}}</option>
                    </select>
                  </div>
                  <div class="col-lg-7 col-sm-12 col-12">
                    <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                    <input [(ngModel)]="GiaoTrinhATTPService.BaseParameter.SearchString" placeholder="Tìm ..."
                      class="form-control" (keyup.enter)="GiaoTrinhATTPSearch()" />
                  </div>
                  <div class="col-lg-1 col-sm-12 col-12">
                    <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                    <div>
                      <a style="width: 100%" class="btn btn-success" title="Tìm" (click)="GiaoTrinhATTPSearch()"><i
                          class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                    </div>
                  </div>
                </div>
                <div class="card mb-4 card-info">
                  <div class="card-header">
                    <h5 class="card-title text-white">
                      Danh sách
                      <span *ngIf="GiaoTrinhATTPService.List">({{ GiaoTrinhATTPService.List.length }} items)</span>
                    </h5>
                  </div>
                  <div class="card-body">
                    <div class="table-outer">
                      <div class="table-responsive">
                        <table *ngIf="!NotificationService.IsMobile" mat-table matSort #GiaoTrinhATTPSort="matSort"
                          [dataSource]="GiaoTrinhATTPService.DataSource" class="table table-striped m-0">
                          <ng-container matColumnDef="STT">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              STT
                            </th>
                            <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                              <div *ngIf="GiaoTrinhATTPPaginator">
                                {{
                                GiaoTrinhATTPPaginator.pageSize *
                                GiaoTrinhATTPPaginator.pageIndex +
                                i +
                                1
                                }}
                              </div>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="ID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              ID
                            </th>
                            <td mat-cell *matCellDef="let element">
                              {{ element.ID }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="ParentID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Nhóm
                            </th>
                            <td mat-cell *matCellDef="let element">
                              <select class="form-select" [(ngModel)]="element.ParentID">
                                <option *ngFor="let item of CauHoiNhomService.List;" [value]="item.ID">
                                  {{item.Name}}</option>
                              </select>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Tiêu đề
                            </th>
                            <td mat-cell *matCellDef="let element">
                              <input class="form-control" type="text" placeholder="Tiêu đề" name="Name{{ element.ID }}"
                                [(ngModel)]="element.Name" />
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="NgayGhiNhan">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Phát hành
                            </th>
                            <td mat-cell *matCellDef="let element">
                              <input name="NgayGhiNhan{{element.ID}}"
                                [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                (ngModelChange)="DateGiaoTrinhATTPNgayGhiNhan(element, $event)"
                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                autocomplete="off">
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="SortOrder">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Sắp xếp
                            </th>
                            <td mat-cell *matCellDef="let element">
                              <input class="form-control" type="number" placeholder="0" name="SortOrder{{ element.ID }}"
                                [(ngModel)]="element.SortOrder" style="text-align: right" />
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="Active">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Kích hoạt
                            </th>
                            <td mat-cell *matCellDef="let element">
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" name="Active{{ element.ID }}"
                                  [(ngModel)]="element.Active" />
                              </div>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="FileName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Upload
                            </th>
                            <td mat-cell *matCellDef="let element">
                              <input type="file" (change)="ChangeFileName(element, $event.target.files)">
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="Save">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                            <td mat-cell *matCellDef="let element">
                              <a class="btn btn-info" (click)="GiaoTrinhATTPSave(element)"><i class="bi bi-sd-card"></i>
                              </a>&nbsp;&nbsp;&nbsp;
                              <a class="btn btn-danger" (click)="GiaoTrinhATTPDelete(element)" *ngIf="element.ID > 0"><i
                                  class="bi bi-trash"></i>
                              </a>&nbsp;&nbsp;&nbsp;
                              <a *ngIf="element.ID > 0 && element.FileName" class="btn btn-success" title="File công bố"
                                href="{{element.FileName}}" target="_blank"><i class="bi bi-cloud-download"></i>
                              </a>
                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="
                              GiaoTrinhATTPService.DisplayColumns002
                            "></tr>
                          <tr mat-row *matRowDef="
                              let row;
                              columns: GiaoTrinhATTPService.DisplayColumns002
                            "></tr>
                        </table>
                        <mat-paginator *ngIf="!NotificationService.IsMobile" #GiaoTrinhATTPPaginator="matPaginator"
                          [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6" [showFirstLastButtons]></mat-paginator>

                        <table *ngIf="NotificationService.IsMobile" mat-table matSort #GiaoTrinhATTPSort="matSort"
                          [dataSource]="GiaoTrinhATTPService.DataSource" class="table table-striped m-0">
                          <ng-container matColumnDef="STT">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Danh sách
                            </th>
                            <td mat-cell *matCellDef="let element; index as i">
                              <div class="row gx-4">
                                <div class="col-lg-12 col-sm-12 col-12">
                                  <label class="form-label">Nhóm</label>
                                  <select class="form-select" [(ngModel)]="element.ParentID">
                                    <option *ngFor="let item of CauHoiNhomService.List;" [value]="item.ID">
                                      {{item.Name}}</option>
                                  </select>
                                </div>
                                <div class="col-lg-12 col-sm-12 col-12">
                                  <label class="form-label">Tiêu đề</label>
                                  <input class="form-control" type="text" placeholder="Tiêu đề"
                                    name="Name{{ element.ID }}" [(ngModel)]="element.Name" />
                                </div>
                                <div class="col-lg-12 col-sm-12 col-12">
                                  <label class="form-label">Phát hành</label>
                                  <input name="NgayGhiNhan{{element.ID}}"
                                    [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                    (ngModelChange)="DateGiaoTrinhATTPNgayGhiNhan(element, $event)"
                                    [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                    autocomplete="off">
                                </div>
                                <div class="col-lg-12 col-sm-12 col-12">
                                  <label class="form-label">Sắp xếp</label>
                                  <input class="form-control" type="number" placeholder="0"
                                    name="SortOrder{{ element.ID }}" [(ngModel)]="element.SortOrder"
                                    style="text-align: right" />
                                </div>
                                <div class="col-lg-12 col-sm-12 col-12">
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" name="Active{{ element.ID }}"
                                      [(ngModel)]="element.Active" />
                                    <label class="form-label">Kích hoạt</label>
                                  </div>
                                </div>
                                <div class="col-lg-12 col-sm-12 col-12">
                                  <label class="form-label">Upload</label>
                                  <br />
                                  <input type="file" (change)="ChangeFileName(element, $event.target.files)">
                                </div>
                                <div class="row gx-4">
                                  <div class="col-lg-4 col-sm-4 col-4">
                                    <a class="btn btn-info" style="width: 100%" (click)="GiaoTrinhATTPSave(element)"><i
                                        class="bi bi-sd-card"></i>
                                    </a>
                                  </div>
                                  <div class="col-lg-4 col-sm-4 col-4">
                                    <a class="btn btn-danger" style="width: 100%" (click)="GiaoTrinhATTPDelete(element)"
                                      *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                    </a>
                                  </div>
                                  <div class="col-lg-4 col-sm-4 col-4">
                                    <a *ngIf="element.ID > 0 && element.FileName" class="btn btn-success"
                                      title="File công bố" href="{{element.FileName}}" target="_blank"><i
                                        class="bi bi-cloud-download"></i>
                                    </a>
                                  </div>
                                  <br />
                                  <br />
                                </div>
                              </div>
                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="
                              GiaoTrinhATTPService.DisplayColumnsMobile
                            "></tr>
                          <tr mat-row *matRowDef="
                              let row;
                              columns: GiaoTrinhATTPService.DisplayColumnsMobile
                            "></tr>
                        </table>
                        <mat-paginator *ngIf="NotificationService.IsMobile" #GiaoTrinhATTPPaginator="matPaginator"
                          [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                          [showFirstLastButtons]></mat-paginator>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loading *ngIf="GiaoTrinhATTPService.IsShowLoading"></app-loading>