<div class="custom-tabs-container">
    <div class="row gx-4">
        <div class="col-lg-7 col-sm-12 col-12">
            <h3>Đơn vị Tổ chức: <b>{{PlanThamDinhCompaniesService.FormData.CompanyInfoName}}</b></h3>
        </div>
        <div class="col-lg-5 col-sm-12 col-12">
            <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="tab-PlanThamDinhCompaniesDetailChuoiCungUngDetail"
                        data-bs-toggle="tab" href="#PlanThamDinhCompaniesDetailChuoiCungUngDetail" role="tab"
                        aria-controls="PlanThamDinhCompaniesDetailChuoiCungUngDetail" aria-selected="true">
                        <span class="badge bg-info" style="font-size: 16px;">Chuỗi cung ứng An toàn thực phẩm</span>
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                        role="tab" aria-controls="Close" aria-selected="false">
                        <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="PlanThamDinhCompaniesDetailChuoiCungUngDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="PlanThamDinhCompaniesSave()"
                        class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <a style="width: 100%;" title="Thêm Đơn vị Tổ chức (nếu thiếu)" (click)="CompanyInfoAdd(0)"
                        class="btn btn-success"><i class="bi bi-plus"></i> Thêm Đơn vị Tổ chức (nếu thiếu)</a>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <a style="width: 100%;" title="Tải về Mẫu chứng nhận" class="btn btn-success"><i
                            class="bi bi-cloud-download"></i> Tải về Mẫu chứng nhận</a>
                </div>
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
                <h4>Bước 01: Nhập Thông tin</h4>
            </div>
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày cấp</label>
                        <input [ngModel]="PlanThamDinhCompaniesService.FormData.NgayGhiNhan | date:'yyyy-MM-dd'"
                            (ngModelChange)="DateNgayGhiNhan($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                            type="date" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã chứng nhận</label>
                        <input name="MaSo" [(ngModel)]="PlanThamDinhCompaniesService.FormData.MaSo"
                            placeholder="Mã chứng nhận" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đơn vị Tổ chức</label>
                        <mat-select class="form-control" name="PlanThamDinhCompaniesService.FormData.CompanyInfoID"
                            [(ngModel)]="PlanThamDinhCompaniesService.FormData.CompanyInfoID">
                            <input placeholder="Tìm..." class="form-control"
                                (keyup)="CompanyInfoFilter($event.target.value)">
                            <mat-option *ngFor="let item of CompanyInfoService.ListFilter" [value]="item.ID">
                                {{item.Name}} - {{item.phone}} - {{item.DKKD}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Xếp loại</label>
                        <select class="form-select" name="DanhMucATTPXepLoaiID"
                            [(ngModel)]="PlanThamDinhCompaniesService.FormData.DanhMucATTPXepLoaiID">
                            <option *ngFor="let item of DanhMucATTPXepLoaiService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4>Bước 02: Chọn Mặt hàng đăng ký</h4>
                </div>
                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                    #PlanThamDinhCompanyProductGroupSort="matSort"
                    [dataSource]="PlanThamDinhCompanyProductGroupService.DataSource" class="table table-striped m-0">
                    <ng-container matColumnDef="ProductGroupID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mặt hàng
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <label *ngIf="element.ID>0" class="form-label">{{element.Name}}</label>
                            <select *ngIf="element.ID==0" class="form-select" name="element.ProductGroupID"
                                (change)="PlanThamDinhCompanyProductGroupSave(element)"
                                [(ngModel)]="element.ProductGroupID">
                                <option *ngFor="let item of ProductGroupService.List;" [value]="item.ID">
                                    {{item.Name}}</option>
                            </select>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Save">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <a class="btn btn-danger" (click)="PlanThamDinhCompanyProductGroupDelete(element)"
                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                            </a>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="PlanThamDinhCompanyProductGroupService.DisplayColumns001">
                    </tr>
                    <tr mat-row
                        *matRowDef="let row; columns: PlanThamDinhCompanyProductGroupService.DisplayColumns001;">
                    </tr>
                </table>
                <mat-paginator *ngIf="!NotificationService.IsMobile"
                    #PlanThamDinhCompanyProductGroupPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                    [pageSize]="1000" [showFirstLastButtons]></mat-paginator>

                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                    #PlanThamDinhCompanyProductGroupSort="matSort"
                    [dataSource]="PlanThamDinhCompanyProductGroupService.DataSource" class="table table-striped m-0">
                    <ng-container matColumnDef="STT">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Danh sách
                        </th>
                        <td mat-cell *matCellDef="let element; index as i;">
                            <div class="row gx-4">
                                <div class="col-lg-12 col-sm-12 col-12">
                                    <label>Mặt hàng</label>
                                    <label *ngIf="element.ID>0" class="form-label">{{element.Name}}</label>
                                    <select *ngIf="element.ID==0" class="form-select" name="element.ProductGroupID"
                                        (change)="PlanThamDinhCompanyProductGroupSave(element)"
                                        [(ngModel)]="element.ProductGroupID">
                                        <option *ngFor="let item of ProductGroupService.List;" [value]="item.ID">
                                            {{item.Name}}</option>
                                    </select>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <a class="btn btn-danger" style="width: 100%;"
                                            (click)="PlanThamDinhCompanyProductGroupDelete(element)"
                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                        </a>
                                    </div>
                                    <br />
                                    <br />
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="PlanThamDinhCompanyProductGroupService.DisplayColumnsMobile">
                    </tr>
                    <tr mat-row
                        *matRowDef="let row; columns: PlanThamDinhCompanyProductGroupService.DisplayColumnsMobile;">
                    </tr>
                </table>
                <mat-paginator *ngIf="NotificationService.IsMobile"
                    #PlanThamDinhCompanyProductGroupPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                    [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4>Bước 03: Danh sách tập tin đính kèm: Tải hồ sơ lên </h4>
                </div>
                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                    #PlanThamDinhCompanyDocumentSort="matSort"
                    [dataSource]="PlanThamDinhCompanyDocumentService.DataSource" class="table table-striped m-0">
                    <ng-container matColumnDef="LastUpdatedDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Ngày Ghi nhận
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <a style="cursor: pointer" class="link-primary"
                                (click)="PlanThamDinhCompanyDocumentAddByID(element.ID)"><b>{{element.LastUpdatedDate |
                                    date:'dd/MM/yyyy HH:mm:ss'}}</b></a>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <input class="form-control" type="text" placeholder="Tiêu đề" name="Name{{element.ID}}"
                                [(ngModel)]="element.Name" />
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="FileName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải lên
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <input type="file" (change)="ChangeFileName(element, $event.target.files)">
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Save">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <a class="btn btn-info" (click)="PlanThamDinhCompanyDocumentSave(element)"><i
                                    class="bi bi-sd-card"></i> </a>&nbsp;&nbsp;&nbsp;
                            <a class="btn btn-danger" (click)="PlanThamDinhCompanyDocumentDelete(element)"
                                *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                            </a>&nbsp;&nbsp;&nbsp;
                            <a *ngIf="element.ID > 0 && element.FileName" class="btn btn-success" title="Tải về"
                                href="{{element.FileName}}" target="_blank"><i class="bi bi-cloud-download"></i>
                            </a>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="PlanThamDinhCompanyDocumentService.DisplayColumns001">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: PlanThamDinhCompanyDocumentService.DisplayColumns001;">
                    </tr>
                </table>
                <mat-paginator *ngIf="!NotificationService.IsMobile"
                    #PlanThamDinhCompanyDocumentPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                    [pageSize]="1000" [showFirstLastButtons]></mat-paginator>

                <table *ngIf="NotificationService.IsMobile" mat-table matSort #PlanThamDinhCompanyDocumentSort="matSort"
                    [dataSource]="PlanThamDinhCompanyDocumentService.DataSource" class="table table-striped m-0">
                    <ng-container matColumnDef="STT">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Danh sách
                        </th>
                        <td mat-cell *matCellDef="let element; index as i;">
                            <div class="row gx-4">
                                <div class="col-lg-12 col-sm-12 col-12">
                                    <label>Tiêu đề: </label>
                                    <input class="form-control" type="text" placeholder="Tiêu đề"
                                        name="Name{{element.ID}}" [(ngModel)]="element.Name" />
                                </div>
                                <div class="col-lg-12 col-sm-12 col-12">
                                    <label>Tải lên: </label>
                                    <br />
                                    <input type="file" (change)="ChangeFileName(element, $event.target.files)">
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-4 col-4">
                                        <a class="btn btn-info" style="width: 100%"
                                            (click)="PlanThamDinhCompanyDocumentSave(element)"><i
                                                class="bi bi-sd-card"></i>
                                        </a>
                                    </div>
                                    <div class="col-lg-4 col-sm-4 col-4">
                                        <a class="btn btn-danger" style="width: 100%"
                                            (click)="PlanThamDinhCompanyDocumentDelete(element)"
                                            *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                        </a>
                                    </div>
                                    <div class="col-lg-4 col-sm-4 col-4">
                                        <a *ngIf="element.ID > 0 && element.FileName" class="btn btn-success"
                                            title="File công bố" href="{{element.FileName}}" target="_blank"><i
                                                class="bi bi-cloud-download"></i>
                                        </a>
                                    </div>
                                    <br />
                                    <br />
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="PlanThamDinhCompanyDocumentService.DisplayColumnsMobile">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: PlanThamDinhCompanyDocumentService.DisplayColumnsMobile;">
                    </tr>
                </table>
                <mat-paginator *ngIf="NotificationService.IsMobile" #PlanThamDinhCompanyDocumentPaginator="matPaginator"
                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="PlanThamDinhCompaniesService.IsShowLoading"></app-loading>