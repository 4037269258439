<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-DocumentTemplate" data-bs-toggle="tab"
                                    href="#DocumentTemplate" role="tab" aria-controls="DocumentTemplate"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px">Biểu mẫu</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="DocumentTemplate" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-11 col-sm-12 col-12">
                                        <input [(ngModel)]="DocumentTemplateService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="DocumentTemplateSearch()" />
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%" class="btn btn-success" title="Tìm"
                                            (click)="DocumentTemplateSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">
                                            Danh sách
                                            <span *ngIf="DocumentTemplateService.List">({{
                                                DocumentTemplateService.List.length }}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #DocumentTemplateSort="matSort"
                                                    [dataSource]="DocumentTemplateService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i"
                                                            style="text-align: center">
                                                            <div *ngIf="DocumentTemplatePaginator">
                                                                {{
                                                                DocumentTemplatePaginator.pageSize *
                                                                DocumentTemplatePaginator.pageIndex +
                                                                i +
                                                                1
                                                                }}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ParentID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Loại biểu mẫu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select" name="element.ParentID" disabled
                                                                [(ngModel)]="element.ParentID">
                                                                <option *ngFor="let item of PlanTypeService.List;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a *ngIf="element.ID > 0 && element.FileName"
                                                                class="link-primary form-label" title="Tải về"
                                                                href="{{element.FileName}}"
                                                                target="_blank">{{element.Name}}
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row *matHeaderRowDef="
                                DocumentTemplateService.DisplayColumns006
                              "></tr>
                                                    <tr mat-row *matRowDef="
                                let row;
                                columns: DocumentTemplateService.DisplayColumns006
                              "></tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #DocumentTemplatePaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>

                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #DocumentTemplateSort="matSort"
                                                    [dataSource]="DocumentTemplateService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Loại biểu mẫu</label>
                                                                    <select class="form-select" name="element.ParentID"
                                                                        disabled [(ngModel)]="element.ParentID">
                                                                        <option
                                                                            *ngFor="let item of PlanTypeService.List;"
                                                                            [value]="item.ID">
                                                                            {{item.Name}}</option>
                                                                    </select>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Biểu mẫu</label>
                                                                    <a *ngIf="element.ID > 0 && element.FileName"
                                                                        class="link-primary form-label"
                                                                        title="File công bố" href="{{element.FileName}}"
                                                                        target="_blank">{{element.Name}}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row *matHeaderRowDef="
                                DocumentTemplateService.DisplayColumnsMobile
                              "></tr>
                                                    <tr mat-row *matRowDef="
                                let row;
                                columns: DocumentTemplateService.DisplayColumnsMobile
                              "></tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #DocumentTemplatePaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="DocumentTemplateService.IsShowLoading"></app-loading>