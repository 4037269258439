<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-CompanyExaminationDetail" data-bs-toggle="tab"
                href="#CompanyExaminationDetail" role="tab" aria-controls="CompanyExaminationDetail"
                aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Khảo sát ATTP</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="CompanyExaminationDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-5 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <a style="width: 100%;" title="Lưu thay đổi" (click)="CompanyExaminationSave()" name="companyExaminationSave"
                            [ngClass]="{'disabled': isUpdate, 'enabled': !isUpdate}"
                            class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu thay đổi</a>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Nhóm</label>
                        <select class="form-select" name="CauHoiNhomID"
                            [ngClass]="{'disabled': isUpdate, 'enabled': !isUpdate}"
                            [(ngModel)]="CompanyExaminationService.FormData.CauHoiNhomID">
                            <option *ngFor="let item of CauHoiNhomService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu đề</label>
                        <input name="Name" [(ngModel)]="CompanyExaminationService.FormData.Name" placeholder="Tiêu đề"
                            [ngClass]="{'disabled': isUpdate, 'enabled': !isUpdate}"
                            type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-7 col-sm-12 col-12">
                    <div *ngIf="CompanyExaminationService.FormData.ID>0" class="card mb-4 card-info">
                        <div class="card-header">
                            <h5 class="card-title text-white">Bộ câu hỏi
                                <span
                                    *ngIf="CompanyExaminationQuestionsService.List">({{CompanyExaminationQuestionsService.List.length}}
                                    items)</span>
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table mat-table matSort
                                        #CompanyExaminationQuestionsSort="matSort"
                                        [dataSource]="CompanyExaminationQuestionsService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                STT
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;"
                                                style="text-align: center;">
                                                <div *ngIf="CompanyExaminationQuestionsPaginator">
                                                    {{CompanyExaminationQuestionsPaginator.pageSize *
                                                    CompanyExaminationQuestionsPaginator.pageIndex + i + 1}}
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Câu hỏi
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="white-space: normal;">
                                                {{element.Name}}
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyExaminationQuestionsService.DisplayColumns002">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyExaminationQuestionsService.DisplayColumns002;">
                                        </tr>
                                    </table>
                                    <mat-paginator 
                                        #CompanyExaminationQuestionsPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="CompanyExaminationService.IsShowLoading"></app-loading>