import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TruyXuatNguonGocThanhVien } from './ThanhVien.model';
import { BaseService } from './Base.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

@Injectable({
    providedIn: 'root'
})
export class TruyXuatNguonGocThanhVienService extends BaseService {

    DisplayColumns001: string[] = ['STT', 'ID', 'Code', 'TaiKhoan', 'DanhMucUngDungName', 'ParentName', 'Active'];

    List: TruyXuatNguonGocThanhVien[] | undefined;
    ListFilter: TruyXuatNguonGocThanhVien[] | undefined;
    FormData!: TruyXuatNguonGocThanhVien;
    FormDataLogin!: TruyXuatNguonGocThanhVien;

    constructor(

        public httpClient: HttpClient,
        public ThanhVienService: ThanhVienService,

    ) {
        super(httpClient);
        this.Controller = "ThanhVien";
        this.FormDataLogin = {
            Name: environment.InitializationString,
        };
    }

    GetLogin() {
        this.FormDataLogin = {
        }
        this.FormDataLogin.Name = localStorage.getItem(environment.ThanhVienHoTen);
        var LastUpdatedMembershipID = localStorage.getItem(environment.ThanhVienID);
        if (LastUpdatedMembershipID) {
            this.FormDataLogin.ID = Number(LastUpdatedMembershipID);
        }
        var ThanhVienToChucID = localStorage.getItem(environment.ThanhVienCompanyInfoID);
        if (ThanhVienToChucID) {
            this.FormDataLogin.ToChucID = Number(ThanhVienToChucID);
        }
    }
    Sync(Action: number) {
        this.BaseParameter.DanhMucUngDungID = Number(localStorage.getItem(environment.TruyXuatNguonGocDanhMucUngDungID));
        if (this.BaseParameter.DanhMucUngDungID > 0) {
            if (Action == 0) {
                this.ListSync();
            }
            if (Action == 1) {
                this.GetByDanhMucUngDungIDToListAsync().subscribe(
                    res => {
                        this.List = (res as TruyXuatNguonGocThanhVien[]);
                        if (this.List.length == 0) {
                            this.ListSync();
                        }
                    },
                    err => {
                    }
                );
            }
        }
    }
    ListSync() {
        this.ThanhVienService.BaseParameter.DanhMucUngDungID = Number(localStorage.getItem(environment.TruyXuatNguonGocDanhMucUngDungID));
        if (this.ThanhVienService.BaseParameter.DanhMucUngDungID > 0) {
            this.ThanhVienService.GetAllToListAsync().subscribe(
                res => {
                    this.ThanhVienService.List = (res as ThanhVien[]);
                    this.List = [];
                    for (let index = 0; index < this.ThanhVienService.List.length; index++) {
                        let ThanhVien = this.ThanhVienService.List[index];
                        let TruyXuatNguonGocThanhVien: TruyXuatNguonGocThanhVien = {
                            ID: environment.InitializationNumber,
                            Active: ThanhVien.Active,
                            IDToken: ThanhVien.ID,
                            Token: localStorage.getItem(environment.TruyXuatNguonGocToKen),
                            DanhMucUngDungID: this.ThanhVienService.BaseParameter.DanhMucUngDungID,
                            Name: ThanhVien.Name,
                            Code: ThanhVien.Code,
                            TaiKhoan: ThanhVien.TaiKhoan,
                            DienThoai: ThanhVien.DienThoai,
                            Email: ThanhVien.Email,
                        }
                        this.List.push(TruyXuatNguonGocThanhVien);
                    }
                    this.ThanhVienService.List = [];
                    this.SaveListAsync(this.List).subscribe(
                        res => {
                            this.List = [];
                        },
                        err => {
                        }
                    );
                },
                err => {
                }
            );
        }
    }
    AuthenticationAsync() {
        let url = this.APIURL + this.Controller + '/AuthenticationAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.FormData));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    ChangePasswordAsync() {
        let url = this.APIURL + this.Controller + '/ChangePasswordAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.FormData));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
}

