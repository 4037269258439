<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-DanhMucLayMauChiTieuDetail" data-bs-toggle="tab"
                href="#DanhMucLayMauChiTieuDetail" role="tab" aria-controls="DanhMucLayMauChiTieuDetail"
                aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Danh mục Chỉ tiêu</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="DanhMucLayMauChiTieuDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="DanhMucLayMauChiTieuSave()"
                        class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="DanhMucLayMauChiTieuService.FormData.Active" />
                        <label class="form-check-label" for="Active">Kích hoạt</label>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Chỉ tiêu</label>
                        <input name="Name" [(ngModel)]="DanhMucLayMauChiTieuService.FormData.Name"
                            placeholder="Chỉ tiêu" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Parent</label>
                        <select class="form-select" name="ParentID"
                            [(ngModel)]="DanhMucLayMauChiTieuService.FormData.ParentID">
                            <option *ngFor="let item of PlanTypeService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="DanhMucLayMauChiTieuService.IsShowLoading"></app-loading>