import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plan-tham-dinh-ma-so-co-so-nuoi',
  templateUrl: './plan-tham-dinh-ma-so-co-so-nuoi.component.html',
  styleUrls: ['./plan-tham-dinh-ma-so-co-so-nuoi.component.css']
})
export class PlanThamDinhMaSoCoSoNuoiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
