import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-viewoffice-component',
  templateUrl: './viewoffice-component.component.html',
  styleUrls: ['./viewoffice-component.component.css']
})
export class ViewofficeComponentComponent implements OnInit {
  safeUrl: SafeResourceUrl;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { fileUrl: string },
    private dialogRef: MatDialogRef<ViewofficeComponentComponent>,
    private sanitizer: DomSanitizer
  
  ) { }

  ngOnInit(): void {
    this.safeUrl = this.sanitizeUrl(this.data.fileUrl);
  }

  close() {
    this.dialogRef.close(); 
  }

  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);  
  }

  isValidUrl(url: string): boolean {
    try {
      const parsedUrl = new URL(url);
      return ['example.com', 'trusted.com'].includes(parsedUrl.hostname);
    } catch {
      return false;
    }
  }

}
