<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThanhVienLichSuThongBao" data-bs-toggle="tab"
                                    href="#ThanhVienLichSuThongBao" role="tab" aria-controls="ThanhVienLichSuThongBao"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Lịch sử thông báo</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThanhVienLichSuThongBao" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-11 col-sm-12 col-12">
                                        <input [(ngModel)]="ThanhVienLichSuThongBaoService.BaseParameter.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="ThanhVienLichSuThongBaoSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="ThanhVienLichSuThongBaoSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span
                                                *ngIf="ThanhVienLichSuThongBaoService.List">({{ThanhVienLichSuThongBaoService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ThanhVienLichSuThongBaoSort="matSort"
                                                    [dataSource]="ThanhVienLichSuThongBaoService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="ThanhVienLichSuThongBaoPaginator">
                                                                {{ThanhVienLichSuThongBaoPaginator.pageSize *
                                                                ThanhVienLichSuThongBaoPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Code"
                                                                name="Code{{element.ID}}" [(ngModel)]="element.Code">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Tiêu đề" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ParentID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Parent
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number" placeholder="0"
                                                                name="ParentID{{element.ID}}"
                                                                [(ngModel)]="element.ParentID"
                                                                style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SortOrder">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number" placeholder="0"
                                                                name="SortOrder{{element.ID}}"
                                                                [(ngModel)]="element.SortOrder"
                                                                style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ThanhVienLichSuThongBaoSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ThanhVienLichSuThongBaoDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienLichSuThongBaoService.DisplayColumns">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienLichSuThongBaoService.DisplayColumns;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ThanhVienLichSuThongBaoPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ThanhVienLichSuThongBaoSort="matSort"
                                                    [dataSource]="ThanhVienLichSuThongBaoService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Code</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Code" name="Code{{element.ID}}"
                                                                        [(ngModel)]="element.Code">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tiêu đề</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Tiêu đề" name="Name{{element.ID}}"
                                                                        [(ngModel)]="element.Name">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Ghi chú</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                        [(ngModel)]="element.Note">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Parent</label>
                                                                    <input class="form-control" type="number"
                                                                        placeholder="0" name="ParentID{{element.ID}}"
                                                                        [(ngModel)]="element.ParentID"
                                                                        style="text-align: right;">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Sắp xếp</label>
                                                                    <input class="form-control" type="number"
                                                                        placeholder="0" name="SortOrder{{element.ID}}"
                                                                        [(ngModel)]="element.SortOrder"
                                                                        style="text-align: right;">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="Active{{element.ID}}"
                                                                            [(ngModel)]="element.Active">
                                                                        <label class="form-label">Kích hoạt</label>
                                                                    </div>
                                                                </div>
                                                                <div class="row gx-4">
                                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                                        <a class="btn btn-info" style="width: 100%;"
                                                                            (click)="ThanhVienLichSuThongBaoSave(element)"><i
                                                                                class="bi bi-sd-card"></i>
                                                                        </a>
                                                                    </div>
                                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                                        <a class="btn btn-danger" style="width: 100%;"
                                                                            (click)="ThanhVienLichSuThongBaoDelete(element)"
                                                                            *ngIf="element.ID>0"><i
                                                                                class="bi bi-trash"></i>
                                                                        </a>
                                                                    </div>
                                                                    <br />
                                                                    <br />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienLichSuThongBaoService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienLichSuThongBaoService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ThanhVienLichSuThongBaoPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ThanhVienLichSuThongBaoService.IsShowLoading"></app-loading>