<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-LoHangDetail" data-bs-toggle="tab" href="#LoHangDetail"
                role="tab" aria-controls="LoHangDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Thông tin</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="LoHangDetail" role="tabpanel">
            
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="font-weight: bold;">Bước 1: Nhập thông tin đăng ký phát hành mã TXNG</h4>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đơn vị đăng ký phát hành mã TXNG</label>                       
                        <mat-select class="form-control" name="LoHangService.FormData.CompanyInfoID"
                            [(ngModel)]="LoHangService.FormData.CompanyInfoID">
                            <input placeholder="Tìm..." class="form-control"
                                (keyup)="CompanyInfoFilter($event.target.value)">
                            <mat-option *ngFor="let item of CompanyInfoService.ListFilter" [value]="item.ID">
                                {{item.Name}} - {{item.phone}} - {{item.DKKD}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Sản phẩm</label>                       
                        <mat-select class="form-control" name="LoHangService.FormData.ParentID"
                            (selectionChange)="ProductInfoChange()"
                            [(ngModel)]="LoHangService.FormData.ProductInfoID">
                            <input placeholder="Tìm..." class="form-control"
                                (keyup)="ProductInfoFilter($event.target.value)">
                            <mat-option *ngFor="let item of ProductInfoService.ListFilter" [value]="item.ID">
                                {{item.Name}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Loại hình mã TXNG cần phát hành của lô hàng</label>
                        <select class="form-select" name="DanhMucLoHangTruyXuatNguonGocPhanLoaiID"
                            [(ngModel)]="LoHangService.FormData.DanhMucLoHangTruyXuatNguonGocPhanLoaiID">
                            <option *ngFor="let item of DanhMucLoHangTruyXuatNguonGocPhanLoaiService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Phương thức sản xuất tem TXNG</label>
                        <select class="form-select" name="DanhMucLoHangTruyXuatNguonGocPhuongThucSanXuatID"
                            [(ngModel)]="LoHangService.FormData.DanhMucLoHangTruyXuatNguonGocPhuongThucSanXuatID">
                            <option *ngFor="let item of DanhMucLoHangTruyXuatNguonGocPhuongThucSanXuatService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số lượng mã TXNG cần phát hành của lô hàng</label>
                        <input name="SoLuongMaTXNG" [(ngModel)]="LoHangService.FormData.SoLuongMaTXNG" placeholder="0"
                            type="number" class="form-control" (change)="ChangeLoHangSoLuongMaTXNG()">
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tên lô hàng</label>
                        <input name="Name" [(ngModel)]="LoHangService.FormData.Name" placeholder="Tên lô hàng"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã lô hàng</label>
                        <input name="Name" [(ngModel)]="LoHangService.FormData.MaLoHang" placeholder="Mã lô hàng"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số lượng sản phẩm của lô hàng ( dưới 20.000 sản phẩm)</label>
                        <input name="SoLuongSanPham" [(ngModel)]="LoHangService.FormData.SoLuongSanPham" placeholder="0"
                            type="number" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã hồ sơ TXNG của hệ thống</label>
                        <input name="ID" [(ngModel)]="LoHangService.FormData.ID" placeholder="0"
                            type="number" class="form-control" disabled>
                    </div>
                    
                </div>
            </div>
            <div class="row gx-4" *ngIf="!LoHangService.FormData.Active">
                <div class="col-lg-12 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="LoHangSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
            </div>
            <hr/>
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="font-weight: bold;">Bước 2: Nâng cấp thông tin sản phẩm theo TCVN về TXNG</h4>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tên</label>
                        <input name="ProductInfoName" [(ngModel)]="LoHangService.FormData.ProductInfoName" placeholder="Tên sản phẩm"
                            type="text" class="form-control" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã GS1</label>
                        <input name="GS1" [(ngModel)]="LoHangService.FormData.GS1" placeholder="Mã GS1" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã HSCode</label>
                        <input name="HSCode" [(ngModel)]="LoHangService.FormData.HSCode" placeholder="Mã HSCode" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã số chỉ dẫn địa lý</label>
                        <input name="MaSoChiDanDiaLy" [(ngModel)]="LoHangService.FormData.MaSoChiDanDiaLy" placeholder="Mã số chỉ dẫn địa lý" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày sản xuất (đóng gói)</label>
                        <input [ngModel]="LoHangService.FormData.NgaySanXuatDongGoi | date:'yyyy-MM-dd'"
                            (ngModelChange)="DateNgaySanXuatDongGoi($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                            type="date" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày hết hạn sử dụng</label>
                        <input [ngModel]="LoHangService.FormData.NgayHetHanSuDung | date:'yyyy-MM-dd'"
                        (ngModelChange)="DateNgayHetHanSuDung($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                        type="date" class="form-control" autocomplete="off">
                    </div>
                    <!--
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">HSD (tháng)</label>
                        <input name="HanSuDung" [(ngModel)]="LoHangService.FormData.HanSuDung" placeholder="Hạn sử dụng (theo tháng)" type="number"
                            class="form-control">
                    </div>
                    -->
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">HSD sau mở hộp PAO (tháng)</label>
                        <input name="HanSuDungPAO" [(ngModel)]="LoHangService.FormData.HanSuDungPAO" placeholder="HSD sau mở hộp PAO (theo tháng)" type="number"
                            class="form-control">
                    </div>
                    <!--
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu chuẩn, chứng nhận đã đạt (ISO, FDA, ...)</label>
                        <input name="TieuChuan" [(ngModel)]="LoHangService.FormData.TieuChuan" placeholder="Tiêu chuẩn, chứng nhận đã đạt (ISO, FDA, ...)" type="text"
                            class="form-control">
                    </div>
                    -->
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Khối lượng sản phẩm</label>
                        <input name="KhoiLuongSanPham" [(ngModel)]="LoHangService.FormData.KhoiLuongSanPham" placeholder="Khối lượng sản phẩm (Gram)" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Quy cách đóng gói (Theo bao bì)</label>
                        <input name="QuyCachDongGoi" [(ngModel)]="LoHangService.FormData.QuyCachDongGoi" placeholder="Quy cách đóng gói (Theo bao bì)" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Quy cách bảo quản</label>
                        <input name="QuyCachBaoQuan" [(ngModel)]="LoHangService.FormData.QuyCachBaoQuan" placeholder="Quy cách bảo quản" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Loại hình sản phẩm (Thực phẩm, dược phẩm, ...)</label>
                        <input name="LoaiHinhSanPham" [(ngModel)]="LoHangService.FormData.LoaiHinhSanPham" placeholder="Loại hình sản phẩm (Thực phẩm, dược phẩm, ...)" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Giá thấp nhất (Đồng)</label>
                        <input name="price_min" [(ngModel)]="LoHangService.FormData.GiaThapNhat" placeholder="0"
                            type="number" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Giá cao nhất (Đồng)</label>
                        <input name="price_max" [(ngModel)]="LoHangService.FormData.GiaCaoNhat" placeholder="0"
                            type="number" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày công bố (DD/MM/YYYY)</label>
                        <input 
                            [ngModel]="LoHangService.FormData.NgayCongBo | date:'yyyy-MM-dd'"
                            (ngModelChange)="DateNgayCongBo($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                            type="date" class="form-control" autocomplete="off">
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Xuất xứ (Việt Nam, ...)</label>
                        <select class="form-select" name="DanhMucQuocGiaID"
                            [(ngModel)]="LoHangService.FormData.DanhMucQuocGiaID">
                            <option *ngFor="let item of DanhMucQuocGiaService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <!--
                        <label class="form-label">Nhóm sản phẩm</label>
                        <select class="form-select" name="ProductGroupID"
                            [(ngModel)]="LoHangService.FormData.ProductGroupID">
                            <option *ngFor="let item of ProductGroupService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                        -->
                        <label class="form-label">Nhóm sản phẩm</label>
                        <mat-select class="form-control" name="LoHangService.FormData.ProductGroupID"
                            [(ngModel)]="LoHangService.FormData.ProductGroupID">
                            <input placeholder="Tìm..." class="form-control"
                                (keyup)="ProductGroupFilter($event.target.value)">
                            <mat-option *ngFor="let item of ProductGroupService.ListFilter" [value]="item.ID">
                                {{item.Name}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Nhóm ngành nghề</label>
                        <select class="form-select" name="LoHangService.FormData.CompanyGroupID"
                            [(ngModel)]="LoHangService.FormData.CompanyGroupID">
                            <option *ngFor="let item of CompanyGroupService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>                   
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đơn vị tính</label>
                        <select class="form-select" name="ProductUnitID"
                            [(ngModel)]="LoHangService.FormData.ProductUnitID">
                            <option *ngFor="let item of ProductUnitService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Thị trường phân phối (Toàn quốc, xuất khẩu, Tỉnh Bến Tre, ...)</label>
                        <input name="ThiTruongPhanPhoi" [(ngModel)]="LoHangService.FormData.ThiTruongPhanPhoi" placeholder="Thị trường phân phối (Toàn quốc, xuất khẩu, Tỉnh Bến Tre, ...)" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Thông tin khuyến cáo người dùng (Phụ nữ mang thai, các loại dị ứng, ...)</label>
                        <textarea name="ThongTinCanhBaoNguoiDung" [(ngModel)]="LoHangService.FormData.ThongTinCanhBaoNguoiDung" placeholder="Thông tin khuyến cáo người dùng (Phụ nữ mang thai, các loại dị ứng, ...)"
                            type="text" class="form-control" rows="3"></textarea>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mô tả thành phần sản phẩm (chi tiết theo hồ sơ công bố)</label>
                        <textarea name="ThongTinCanhBaoNgMoTaThanhPhanSanPhamuoiDung" [(ngModel)]="LoHangService.FormData.MoTaThanhPhanSanPham" placeholder="Mô tả thành phần sản phẩm (chi tiết theo hồ sơ công bố)"
                            type="text" class="form-control" rows="3"></textarea>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Giới thiệu ngắn về sản phẩm (500 ký tự)</label>
                        <textarea name="GioiThieuSanPham" [(ngModel)]="LoHangService.FormData.GioiThieuSanPham" placeholder="Giới thiệu ngắn về sản phẩm (500 ký tự)"
                            type="text" class="form-control" rows="3" maxlength="500"></textarea>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Giới thiệu ngắn về nhà cung cấp (500 ký tự)</label>
                        <textarea name="GioiThieuNhaCungCap" [(ngModel)]="LoHangService.FormData.GioiThieuNhaCungCap" placeholder="Giới thiệu ngắn về nhà cung cấp (500 ký tự)"
                            type="text" class="form-control" rows="3" maxlength="500"></textarea>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đường dẫn youtube về sản phẩm</label>
                        <input name="YouTube" [(ngModel)]="LoHangService.FormData.YouTube" placeholder="Đường dẫn youtube về sản phẩm" type="text"
                            class="form-control"> 
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số điện thoại chăm sóc khách hàng</label>
                        <input name="SoDienThoaiChamSocKhachHang" [(ngModel)]="LoHangService.FormData.SoDienThoaiChamSocKhachHang" placeholder="Số điện thoại chăm sóc khách hàng" type="text"
                            class="form-control"> 
                    </div>
                    <!--
                        <div class="col-lg-12 col-sm-12 col-12">
                            <label class="form-label">File công bố</label>
                            <br />
                            <a target="_blank" class="link-primary" title="{{LoHangService.FormData.FileName}}" href="{{LoHangService.FormData.FileName}}"><b>{{LoHangService.FormData.FileName}}</b></a>                        
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <label class="form-label">Tài liệu đính kèm file công bố</label>
                            <br />
                            <input type="file" (change)="ChangeFileName($event.target.files)">
                        </div>
                    -->
                </div>

                
            </div>
            <div class="row gx-4" *ngIf="!LoHangService.FormData.Active">
                <div class="col-lg-12 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="LoHangSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
            </div>
            <hr/>
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="font-weight: bold;">Bước 3: Thông tin vùng nguyên liệu và cơ sở đóng gói</h4>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="font-weight: bold;">Bước 3.1: Vùng nguyên liệu</h4>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Chuẩn loại (Giống)</label>
                        <input name="NguyenLieuGiong" [(ngModel)]="LoHangService.FormData.NguyenLieuGiong" placeholder="Chuẩn loại (Giống)"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tên khoa học</label>
                        <input name="NguyenLieuTenKhoaHoc" [(ngModel)]="LoHangService.FormData.NguyenLieuTenKhoaHoc" placeholder="Tên khoa học"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số vụ trong năm</label>
                        <input name="NguyenLieuSoVuTrongNam" [(ngModel)]="LoHangService.FormData.NguyenLieuSoVuTrongNam" placeholder="Số vụ trong năm"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Sản lượng trung bình 1 vụ</label>
                        <input name="NguyenLieuSanLuongTrungBinh" [(ngModel)]="LoHangService.FormData.NguyenLieuSanLuongTrungBinh" placeholder="Sản lượng trung bình 1 vụ"
                            type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Danh sách mã vùng trồng</label>
                        <input name="NguyenLieuDanhSachMaSoVungTrong" [(ngModel)]="LoHangService.FormData.NguyenLieuDanhSachMaSoVungTrong" placeholder="Danh sách mã vùng trồng"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tổng diện tích vùng nguyên liệu</label>
                        <input name="NguyenLieuTongDienTich" [(ngModel)]="LoHangService.FormData.NguyenLieuTongDienTich" placeholder="Tổng diện tích vùng nguyên liệu"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu chuẩn, chứng nhận của vùng nguyên liệu (VietGap, GlobalGap, ...)</label>
                        <input name="NguyenLieuTieuChuan" [(ngModel)]="LoHangService.FormData.NguyenLieuTieuChuan" placeholder="Tiêu chuẩn, chứng nhận của vùng nguyên liệu (VietGap, GlobalGap, ...)" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi chú</label>
                        <input name="NguyenLieuGhiChu" [(ngModel)]="LoHangService.FormData.NguyenLieuGhiChu" placeholder="Ghi chú"
                            type="text" class="form-control">
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="font-weight: bold;">Bước 3.2: Nhà máy sản xuất/ chế biến</h4>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tên</label>
                        <input name="NhaSanXuatCheBienTen" [(ngModel)]="LoHangService.FormData.NhaSanXuatCheBienTen" placeholder="Tên"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã số</label>
                        <input name="NhaSanXuatCheBienMaSo" [(ngModel)]="LoHangService.FormData.NhaSanXuatCheBienMaSo" placeholder="Mã số"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu chuẩn, chứng nhận của nhà máy</label>
                        <input name="NhaSanXuatCheBienTieuChuan" [(ngModel)]="LoHangService.FormData.NhaSanXuatCheBienTieuChuan" placeholder="Tiêu chuẩn, chứng nhận của nhà máy"
                            type="text" class="form-control">
                    </div>
                    
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Địa chỉ</label>
                        <input name="NhaSanXuatCheBienDiaChi" [(ngModel)]="LoHangService.FormData.NhaSanXuatCheBienDiaChi" placeholder="Địa chỉ"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Kinh độ</label>
                        <input name="NhaSanXuatCheBienKinhDo" [(ngModel)]="LoHangService.FormData.NhaSanXuatCheBienKinhDo" placeholder="Kinh độ"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Vĩ độ</label>
                        <input name="NhaSanXuatCheBienViDo" [(ngModel)]="LoHangService.FormData.NhaSanXuatCheBienViDo" placeholder="Vĩ độ"
                            type="text" class="form-control">
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="font-weight: bold;">Bước 3.3: Cơ sở đóng gói</h4>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tên</label>
                        <input name="DonViDongGoiTen" [(ngModel)]="LoHangService.FormData.DonViDongGoiTen" placeholder="Tên"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã số cơ sở đóng gói</label>
                        <input name="DonViDongGoiID" [(ngModel)]="LoHangService.FormData.DonViDongGoiID" placeholder="Mã số cơ sở đóng gói"
                            type="text" class="form-control">
                    </div>
                    
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Địa chỉ</label>
                        <input name="DonViDongGoiDiaChi" [(ngModel)]="LoHangService.FormData.DonViDongGoiDiaChi" placeholder="Địa chỉ"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Kinh độ</label>
                        <input name="DonViDongGoiKinhDo" [(ngModel)]="LoHangService.FormData.DonViDongGoiKinhDo" placeholder="Kinh độ"
                            type="number" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Vĩ độ</label>
                        <input name="DonViDongGoiViDo" [(ngModel)]="LoHangService.FormData.DonViDongGoiViDo" placeholder="Vĩ độ"
                            type="number" class="form-control">
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <h4 style="font-weight: bold;">Bước 3.4: Thêm tuyến đường vận chuyển từ vùng trồng đến nhà máy sản xuất/ chế biến và cơ sở đóng gói</h4>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #LoHangLoTrinhSort="matSort"
                                        [dataSource]="LoHangLoTrinhService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                STT
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                                                <div *ngIf="LoHangLoTrinhPaginator">
                                                    {{
                                                    LoHangLoTrinhPaginator.pageSize *
                                                    LoHangLoTrinhPaginator.pageIndex +
                                                    i +
                                                    1
                                                    }}
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tên đường/ tên điểm
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input type="text" placeholder="Tên đường/ tên điểm" name="Name{{element.ID}}"
                                                    [(ngModel)]="element.Name" class="form-control">
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="KinhDo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Kinh độ
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input type="number" placeholder="Kinh độ" name="KinhDo{{element.ID}}"
                                                    [(ngModel)]="element.KinhDo" class="form-control">
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="ViDo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vĩ độ
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input type="number" placeholder="Vĩ độ" name="ViDo{{element.ID}}"
                                                    [(ngModel)]="element.ViDo" class="form-control">
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Note">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input type="text" placeholder="Ghi chú" name="Note{{element.ID}}"
                                                    [(ngModel)]="element.Note" class="form-control">
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                            <td mat-cell *matCellDef="let element">
                                                <div *ngIf="!LoHangService.FormData.Active">
                                                    <a class="btn btn-info"
                                                    (click)="LoHangLoTrinhSave(element)"><i
                                                        class="bi bi-sd-card"></i>
                                                    </a>&nbsp;&nbsp;&nbsp;
                                                    <a class="btn btn-danger"
                                                        (click)="LoHangLoTrinhDelete(element)"
                                                        *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                    </a>
                                                </div>
                                                
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="LoHangLoTrinhService.DisplayColumns001">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row;columns: LoHangLoTrinhService.DisplayColumns001">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #LoHangLoTrinhPaginator="matPaginator"
                                        [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
    
    
                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #LoHangLoTrinhSort="matSort"
                                        [dataSource]="LoHangLoTrinhService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Tên sản phẩm</label>
                                                        <input name="Name" [(ngModel)]="element.Name"
                                                            placeholder="Tên sản phẩm" type="text" class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Kinh độ</label>
                                                        <input name="KinhDo" [(ngModel)]="element.KinhDo"
                                                            placeholder="Kinh độ" type="text" class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Vĩ độ</label>
                                                        <input name="ViDo" [(ngModel)]="element.ViDo"
                                                            placeholder="Vĩ độ" type="text" class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Ghi chú</label>
                                                        <input name="Note" [(ngModel)]="element.Note" placeholder="Ghi chú"
                                                            type="text" class="form-control">
                                                    </div>
                                                    <div class="row gx-4" *ngIf="!LoHangService.FormData.Active">
                                                        <div class="col-lg-6 col-sm-6 col-6">
                                                            <a class="btn btn-info" style="width: 100%"
                                                                (click)="LoHangLoTrinhSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-6 col-6">
                                                            <a class="btn btn-danger" style="width: 100%"
                                                                (click)="LoHangLoTrinhDelete(element)"
                                                                *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </div>
                                                        <br />
                                                        <br />
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="LoHangLoTrinhService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row;columns: LoHangLoTrinhService.DisplayColumnsMobile">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #LoHangLoTrinhPaginator="matPaginator"
                                        [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gx-4" *ngIf="!LoHangService.FormData.Active">
                <div class="col-lg-12 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="LoHangSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
            </div>
            
            <hr/>
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="font-weight: bold;">Bước 4: Thông tin kho lưu trữ và bảo quản</h4>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="font-weight: bold;">Bước 4.1: Kho lưu trữ</h4>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tên kho</label>
                        <input name="KhoNguyenLieuTen" [(ngModel)]="LoHangService.FormData.KhoNguyenLieuTen" placeholder="Tên kho"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã kho lưu trữ</label>
                        <input name="KhoNguyenLieuID" [(ngModel)]="LoHangService.FormData.KhoNguyenLieuID" placeholder="Mã kho lưu trữ"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Điều kiện lưu trữ</label>
                        <input name="KhoNguyenLieuDieuKienLuuTru" [(ngModel)]="LoHangService.FormData.KhoNguyenLieuDieuKienLuuTru" placeholder="Điều kiện lưu trữ"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Nhiệt độ lưu trữ (°C)</label>
                        <input name="KhoNguyenLieuNhietDo" [(ngModel)]="LoHangService.FormData.KhoNguyenLieuNhietDo" placeholder="Nhiệt độ lưu trữ (°C)"
                            type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Địa chỉ</label>
                        <input name="KhoNguyenLieuDiaChi" [(ngModel)]="LoHangService.FormData.KhoNguyenLieuDiaChi" placeholder="Địa chỉ"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Kinh độ</label>
                        <input name="KhoNguyenLieuKinhDo" [(ngModel)]="LoHangService.FormData.KhoNguyenLieuKinhDo" placeholder="Kinh độ"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Vĩ độ</label>
                        <input name="KhoNguyenLieuViDo" [(ngModel)]="LoHangService.FormData.KhoNguyenLieuViDo" placeholder="Vĩ độ"
                            type="text" class="form-control">
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="font-weight: bold;">Bước 4.2: Kho thương mại</h4>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tên kho</label>
                        <input name="KhoThuongMaiTen" [(ngModel)]="LoHangService.FormData.KhoThuongMaiTen" placeholder="Tên kho"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã kho thương mại</label>
                        <input name="KhoThuongMaiID" [(ngModel)]="LoHangService.FormData.KhoThuongMaiID" placeholder="Mã kho thương mại"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Điều kiện lưu trữ</label>
                        <input name="KhoThuongMaiDieuKienLuuTru" [(ngModel)]="LoHangService.FormData.KhoThuongMaiDieuKienLuuTru" placeholder="Điều kiện lưu trữ"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Nhiệt độ lưu trữ (°C)</label>
                        <input name="KhoThuongMaiNhietDo" [(ngModel)]="LoHangService.FormData.KhoThuongMaiNhietDo" placeholder="Nhiệt độ lưu trữ (°C)"
                            type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Địa chỉ</label>
                        <input name="KhoThuongMaiDiaChi" [(ngModel)]="LoHangService.FormData.KhoThuongMaiDiaChi" placeholder="Địa chỉ"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Kinh độ</label>
                        <input name="KhoThuongMaiKinhDo" [(ngModel)]="LoHangService.FormData.KhoThuongMaiKinhDo" placeholder="Kinh độ"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Vĩ độ</label>
                        <input name="KhoThuongMaiViDo" [(ngModel)]="LoHangService.FormData.KhoThuongMaiViDo" placeholder="Vĩ độ"
                            type="text" class="form-control">
                    </div>
                </div>
            </div>
            <div class="row gx-4" *ngIf="!LoHangService.FormData.Active">
                <div class="col-lg-12 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="LoHangSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
            </div>
            <hr />
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="font-weight: bold;">Bước 5: Thêm nhật ký và điểm bán</h4>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="font-weight: bold;">Bước 5.1: Nhật ký</h4>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                #LoHangNhatKySort="matSort"
                                [dataSource]="LoHangNhatKyService.DataSource"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i" style="text-align: center;width: 50px;">
                                        <div *ngIf="LoHangNhatKyPaginator">
                                            {{
                                            LoHangNhatKyPaginator.pageSize *
                                            LoHangNhatKyPaginator.pageIndex +
                                            i +
                                            1
                                            }}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Thông tin
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="row gx-4">
                                            <div class="col-lg-6 col-sm-12 col-12">
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Loại quy trình</label>
                                                    <select class="form-select" name="element.ParentID"
                                                        [(ngModel)]="element.ParentID">
                                                        <option
                                                            *ngFor="let item of DanhMucLoHangQuyTrinhService.ListFilter;" [value]="item.ID">
                                                            {{item.Name}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Tên hoạt động</label>
                                                    <input type="text" placeholder="Tên hoạt động" name="Name{{element.ID}}"
                                                        [(ngModel)]="element.Name" class="form-control">
                                                </div>
                                                <div *ngIf="element.ID>0" class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Ngày ghi nhận</label>
                                                    <input type="text" placeholder="Ngày ghi nhận" name="NgayGhiNhan{{element.ID}}"
                                                            [(ngModel)]="element.NgayGhiNhan" class="form-control" disabled>
                                                </div>
                                                <div *ngIf="element.ID>0" class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Ngày bắt đầu</label>
                                                    <input [ngModel]="element.NgayBatDau | date:'yyyy-MM-dd'"
                                                        (ngModelChange)="DateLoHangNhatKyNgayBatDau(element, $event)"
                                                        [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                        class="form-control" autocomplete="off">
                                                </div>
                                                <div *ngIf="element.ID>0" class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Ngày kết thúc</label>
                                                    <input [ngModel]="element.NgayKetThuc | date:'yyyy-MM-dd'"
                                                        (ngModelChange)="DateLoHangNhatKyNgayKetThuc(element, $event)"
                                                        [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                        class="form-control" autocomplete="off">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Người phụ trách</label>
                                                    <input type="text" placeholder="Người phụ trách" name="NguoiPhuTrach{{element.ID}}"
                                                        [(ngModel)]="element.NguoiPhuTrach" class="form-control">
                                                </div>
                                               <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Tên cơ sở đóng gói</label>
                                                    <input type="text" placeholder="Tên cơ sở đóng gói" name="CompanyInfoDonViDongGoiName{{element.ID}}"
                                                        [(ngModel)]="element.CompanyInfoDonViDongGoiName" class="form-control">
                                               </div>
                                               <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Mã số cơ sở đóng gói</label>
                                                    <input type="text" placeholder="Mã số cơ sở đóng gói" name="CompanyInfoDonViDongGoiMaHoSo{{element.ID}}"
                                                        [(ngModel)]="element.CompanyInfoDonViDongGoiMaHoSo" class="form-control">
                                               </div>
                                               <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Tên vùng trồng</label>
                                                    <input type="text" placeholder="Tên vùng trồng" name="CompanyInfoVungTrongName{{element.ID}}"
                                                        [(ngModel)]="element.CompanyInfoVungTrongName" class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Mã số vùng trồng</label>
                                                    <input type="text" placeholder="Mã số vùng trồng" name="CompanyInfoVungTrongMaHoSo{{element.ID}}"
                                                        [(ngModel)]="element.CompanyInfoVungTrongMaHoSo" class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Tên vật tư</label>
                                                    <input type="text" placeholder="Tên vật tư" name="VatTuName{{element.ID}}"
                                                        [(ngModel)]="element.VatTuName" class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Đơn vị tính</label>
                                                    <select class="form-select" name="ProductUnitID"
                                                        [(ngModel)]="element.ProductUnitID">
                                                        <option *ngFor="let item of ProductUnitService.List;" [value]="item.ID">
                                                            {{item.Name}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Số lượng/ liều lượng sử dụng</label>
                                                    <input type="number" placeholder="0" name="SoLuong{{element.ID}}"
                                                        [(ngModel)]="element.SoLuong" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-12 col-12">
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Tên nhà vận chuyển</label>
                                                    <input type="text" placeholder="Tên nhà vận chuyển" name="TenNhaVanChuyen{{element.ID}}"
                                                        [(ngModel)]="element.TenNhaVanChuyen" class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Phương thức vận chuyển</label>
                                                    <input type="text" placeholder="Phương thức vận chuyển" name="PhuongThucVanChuyen{{element.ID}}"
                                                        [(ngModel)]="element.PhuongThucVanChuyen" class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Loại hình vận chuyển</label>
                                                    <input type="text" placeholder="Loại hình vận chuyển" name="LoaiHinhVanChuyen{{element.ID}}"
                                                        [(ngModel)]="element.LoaiHinhVanChuyen" class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Biển số xe</label>
                                                    <input type="text" placeholder="Biển số xe" name="BienSoXe{{element.ID}}"
                                                        [(ngModel)]="element.BienSoXe" class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Điểm khởi hành</label>
                                                    <input type="text" placeholder="Điểm khởi hành" name="DiemKhoiHanh{{element.ID}}"
                                                        [(ngModel)]="element.DiemKhoiHanh" class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Điểm đến</label>
                                                    <input type="text" placeholder="Điểm đến" name="DiemDen{{element.ID}}"
                                                        [(ngModel)]="element.DiemDen" class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Mã vận đơn</label>
                                                    <input type="text" placeholder="Mã vận đơn" name="MaVanDon{{element.ID}}"
                                                        [(ngModel)]="element.MaVanDon" class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Mã GTIN</label>
                                                    <input type="text" placeholder="Mã GTIN" name="MaGTIN{{element.ID}}"
                                                        [(ngModel)]="element.MaGTIN" class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Mô tả ngắn hoạt động</label>
                                                    <input type="text" placeholder="Mô tả ngắn hoạt động" name="Note{{element.ID}}"
                                                        [(ngModel)]="element.Note" class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Đã hoàn thành công việc này</label>
                                                    <br />
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input"
                                                            type="checkbox"
                                                            name="IsHoanThanh{{ element.ID }}"
                                                            [(ngModel)]="element.IsHoanThanh"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Tài liệu đính kèm</label>
                                                    <br />
                                                    <input type="file"
                                                        (change)="ChangeFileNameLoHangNhatKy(element, $event.target.files)">
                                                    <br />
                                                    <br />
                                                    <img src="{{element.FileName}}"
                                                        class="img-fluid rounded" style="max-width: 200px;">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Công bố thông tin đến hệ thống TXNG</label>
                                                    <br />
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input"
                                                            type="checkbox"
                                                            name="IsCongBo{{ element.ID }}"
                                                            [(ngModel)]="element.IsCongBo"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                    <td mat-cell *matCellDef="let element" style="width: 100px;">
                                        <div *ngIf="!LoHangService.FormData.Active">
                                            <a class="btn btn-info"
                                                (click)="LoHangNhatKySave(element)"><i
                                                    class="bi bi-sd-card"></i>
                                            </a>&nbsp;&nbsp;&nbsp;
                                            <a class="btn btn-danger"
                                                (click)="LoHangNhatKyDelete(element)"
                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                            </a>&nbsp;&nbsp;&nbsp;
                                            <a class="btn btn-success" (click)="LoHangNhatKyCoppy(element)"
                                                *ngIf="element.ID > 0"><i class="bi bi-subtract"></i>
                                            </a>
                                            <br/>
                                            <br/>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <textarea style="color: blue;
                                            font-size: 14px;
                                            font-style: italic;" rows="8" disabled>Ghi chú: Chỉ cần điền vào những nội dung phù hợp với hoạt động trong nhật ký mà bạn cần tạo, thông tin này sẽ được công bố trong hệ thống TXNG cho người dùng xem khi quét mã TXNG</textarea>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row
                                    *matHeaderRowDef="LoHangNhatKyService.DisplayColumns001">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row;columns: LoHangNhatKyService.DisplayColumns001">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                #LoHangNhatKyPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                [showFirstLastButtons]></mat-paginator>


                            <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                #LoHangNhatKySort="matSort"
                                [dataSource]="LoHangNhatKyService.DataSource"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Loại quy trình</label>
                                                <select class="form-select" name="DanhMucLoHangQuyTrinhID"
                                                    [(ngModel)]="element.ParentID">
                                                    <option *ngFor="let item of DanhMucLoHangQuyTrinhService.List;" [value]="item.ID">
                                                        {{item.Name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Tên hoạt động</label>
                                                <input type="text" placeholder="Tên hoạt động" name="Name{{element.ID}}"
                                                    [(ngModel)]="element.Name" class="form-control">
                                            </div>
                                            <div *ngIf="element.ID>0" class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Ngày ghi nhận</label>
                                                <input type="text" placeholder="Ngày ghi nhận" name="NgayGhiNhan{{element.ID}}"
                                                        [(ngModel)]="element.NgayGhiNhan" class="form-control" disabled>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Người phụ trách</label>
                                                <input type="text" placeholder="Người phụ trách" name="NguoiPhuTrach{{element.ID}}"
                                                    [(ngModel)]="element.NguoiPhuTrach" class="form-control">
                                            </div>
                                           <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Tên cơ sở đóng gói</label>
                                                <input type="text" placeholder="Tên cơ sở đóng gói" name="CompanyInfoDonViDongGoiName{{element.ID}}"
                                                    [(ngModel)]="element.CompanyInfoDonViDongGoiName" class="form-control">
                                           </div>
                                           <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Mã số cơ sở đóng gói</label>
                                                <input type="text" placeholder="Mã số cơ sở đóng gói" name="CompanyInfoDonViDongGoiMaHoSo{{element.ID}}"
                                                    [(ngModel)]="element.CompanyInfoDonViDongGoiMaHoSo" class="form-control">
                                           </div>
                                           <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Tên vùng trồng</label>
                                                <input type="text" placeholder="Tên vùng trồng" name="CompanyInfoVungTrongName{{element.ID}}"
                                                    [(ngModel)]="element.CompanyInfoVungTrongName" class="form-control">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Mã số vùng trồng</label>
                                                <input type="text" placeholder="Mã số vùng trồng" name="CompanyInfoVungTrongMaHoSo{{element.ID}}"
                                                    [(ngModel)]="element.CompanyInfoVungTrongMaHoSo" class="form-control">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Tên vật tư</label>
                                                <input type="text" placeholder="Tên vật tư" name="VatTuName{{element.ID}}"
                                                    [(ngModel)]="element.VatTuName" class="form-control">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Đơn vị tính</label>
                                                <select class="form-select" name="ProductUnitID"
                                                    [(ngModel)]="element.ProductUnitID">
                                                    <option *ngFor="let item of ProductUnitService.List;" [value]="item.ID">
                                                        {{item.Name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Số lượng/ liều lượng</label>
                                                <input type="number" placeholder="0" name="SoLuong{{element.ID}}"
                                                    [(ngModel)]="element.SoLuong" class="form-control">
                                            </div>
                                            
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Mô tả ngắn hoạt động</label>
                                                <input type="text" placeholder="Mô tả ngắn hoạt động" name="Note{{element.ID}}"
                                                    [(ngModel)]="element.Note" class="form-control">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Đã hoàn thành công việc này</label>
                                                <br />
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input"
                                                        type="checkbox"
                                                        name="Active{{ element.ID }}"
                                                        [(ngModel)]="element.Active"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Tài liệu đính kèm</label>
                                                <br />
                                                <input type="file"
                                                    (change)="ChangeFileNameLoHangNhatKy(element, $event.target.files)">
                                                <br />
                                                <br />
                                                <img src="{{element.FileName}}"
                                                    class="img-fluid rounded" style="max-width: 200px;">
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row
                                    *matHeaderRowDef="LoHangNhatKyService.DisplayColumnsMobile">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row;columns: LoHangNhatKyService.DisplayColumnsMobile">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile"
                                #LoHangNhatKyPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="font-weight: bold;">Bước 5.2: Địa điểm bán hàng</h4>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                #LoHangDiemBanSort="matSort"
                                [dataSource]="LoHangDiemBanService.DataSource"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                                        <div *ngIf="LoHangDiemBanPaginator">
                                            {{
                                            LoHangDiemBanPaginator.pageSize *
                                            LoHangDiemBanPaginator.pageIndex +
                                            i +
                                            1
                                            }}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Địa điểm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input type="text" placeholder="Địa điểm" name="Name{{element.ID}}"
                                            [(ngModel)]="element.Name" class="form-control">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DienThoai">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input type="text" placeholder="Điện thoại" name="DienThoai{{element.ID}}"
                                            [(ngModel)]="element.DienThoai" class="form-control">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ProvinceDataID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tỉnh thành
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <select class="form-select" name="element.ProvinceDataID"
                                            [(ngModel)]="element.ProvinceDataID">
                                            <option
                                                *ngFor="let item of ProvinceDataService.ListFilter;"
                                                [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>    
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DistrictDataID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Quận huyện
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <select class="form-select" name="element.DistrictDataID"
                                            [(ngModel)]="element.DistrictDataID">
                                            <option
                                                *ngFor="let item of DistrictDataService.ListFilter;"
                                                [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="WardDataID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Xã phường
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <select class="form-select" name="element.WardDataID"
                                            [(ngModel)]="element.WardDataID">
                                            <option
                                                *ngFor="let item of WardDataService.ListFilter;"
                                                [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DiaChi">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Địa chỉ
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input type="text" placeholder="Địa chỉ" name="DiaChi{{element.ID}}"
                                            [(ngModel)]="element.DiaChi" class="form-control">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="KinhDo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Kinh độ
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input type="number" placeholder="Kinh độ" name="KinhDo{{element.ID}}"
                                            [(ngModel)]="element.KinhDo" class="form-control">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ViDo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Vĩ độ
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input type="number" placeholder="Vĩ độ" name="ViDo{{element.ID}}"
                                            [(ngModel)]="element.ViDo" class="form-control">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Note">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input type="text" placeholder="Ghi chú" name="Note{{element.ID}}"
                                            [(ngModel)]="element.Note" class="form-control">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                    <td mat-cell *matCellDef="let element">
                                        <div *ngIf="!LoHangService.FormData.Active">
                                            <a class="btn btn-info"
                                                (click)="LoHangDiemBanSave(element)"><i
                                                    class="bi bi-sd-card"></i>
                                            </a>&nbsp;&nbsp;&nbsp;
                                            <a class="btn btn-danger"
                                                (click)="LoHangDiemBanDelete(element)"
                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                            </a>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row
                                    *matHeaderRowDef="LoHangDiemBanService.DisplayColumns001">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row;columns: LoHangDiemBanService.DisplayColumns001">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                #LoHangDiemBanPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                [showFirstLastButtons]></mat-paginator>


                            <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                #LoHangDiemBanSort="matSort"
                                [dataSource]="LoHangDiemBanService.DataSource"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Địa điểm</label>
                                                <input name="Name" [(ngModel)]="element.Name"
                                                    placeholder="Địa điểm" type="text" class="form-control">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Điện thoại</label>
                                                <input type="text" placeholder="Điện thoại" name="DienThoai{{element.ID}}"
                                                    [(ngModel)]="element.DienThoai" class="form-control">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Kinh độ</label>
                                                <input name="KinhDo" [(ngModel)]="element.KinhDo"
                                                    placeholder="Kinh độ" type="text" class="form-control">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Vĩ độ</label>
                                                <input name="ViDo" [(ngModel)]="element.ViDo"
                                                    placeholder="Vĩ độ" type="text" class="form-control">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Ghi chú</label>
                                                <input name="Note" [(ngModel)]="element.Note" placeholder="Ghi chú"
                                                    type="text" class="form-control">
                                            </div>
                                            <div class="row gx-4" *ngIf="!LoHangService.FormData.Active">
                                                <div class="col-lg-6 col-sm-6 col-6">
                                                    <a class="btn btn-info" style="width: 100%"
                                                        (click)="LoHangDiemBanSave(element)"><i
                                                            class="bi bi-sd-card"></i>
                                                    </a>
                                                </div>
                                                <div class="col-lg-6 col-sm-6 col-6">
                                                    <a class="btn btn-danger" style="width: 100%"
                                                        (click)="LoHangDiemBanDelete(element)"
                                                        *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                    </a>
                                                </div>
                                                <br />
                                                <br />
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row
                                    *matHeaderRowDef="LoHangDiemBanService.DisplayColumnsMobile">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row;columns: LoHangDiemBanService.DisplayColumnsMobile">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile"
                                #LoHangDiemBanPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="font-weight: bold;">Bước 6: Danh sách file công bố và hình ảnh</h4>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                #TapTinDinhKemSort="matSort"
                                [dataSource]="TapTinDinhKemService.DataSource"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;"
                                        style="text-align: center;">
                                        <div *ngIf="TapTinDinhKemPaginator">
                                            {{TapTinDinhKemPaginator.pageSize *
                                            TapTinDinhKemPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ParentID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân loại
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <select class="form-select" name="element.DanhMucTapTinDinhKemID"
                                            [(ngModel)]="element.DanhMucTapTinDinhKemID">
                                            <option
                                                *ngFor="let item of DanhMucTapTinDinhKemService.ListFilter;"
                                                [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tên hồ sơ
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="text"
                                            placeholder="Tên hồ sơ" name="Name{{element.ID}}"
                                            [(ngModel)]="element.Name">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="MaSo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã số</th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="text" placeholder="Mã số"
                                            name="MaSo{{element.ID}}" [(ngModel)]="element.MaSo">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="NgayCap">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Ngày cấp
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                            <input [ngModel]="element.NgayCap | date:'yyyy-MM-dd'"
                                            (ngModelChange)="DateTapTinDinhKemNgayCap(element, $event)"
                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                            class="form-control" autocomplete="off">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="NgayHetHan">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Ngày hết hạn
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input [ngModel]="element.NgayHetHan | date:'yyyy-MM-dd'"
                                            (ngModelChange)="DateTapTinDinhKemNgayHetHan(element, $event)"
                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                            class="form-control" autocomplete="off">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="NoiCap">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nơi cấp
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="text" placeholder="Nơi cấp"
                                            name="NoiCap{{element.ID}}" [(ngModel)]="element.NoiCap">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Note">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="text" placeholder="Ghi chú"
                                            name="Note{{element.ID}}" [(ngModel)]="element.Note">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="FileName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Tài liệu đính kèm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input type="file"
                                            (change)="ChangeFileNameTapTinDinhKem(element, $event.target.files)">
                                        <br />
                                        <br />
                                        <img src="{{element.FileName}}" class="img-fluid rounded">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div *ngIf="!LoHangService.FormData.Active">
                                            <a class="btn btn-info"
                                                (click)="TapTinDinhKemSave(element)"><i
                                                    class="bi bi-sd-card"></i>
                                            </a>&nbsp;&nbsp;&nbsp;
                                            <a class="btn btn-danger"
                                                (click)="TapTinDinhKemDelete(element)"
                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                            </a>&nbsp;&nbsp;&nbsp;
                                            <a class="btn btn-success"
                                                href="{{element.FileName}}" target="_blank"
                                                *ngIf="element.ID>0"><i class="bi bi bi-download"></i>
                                            </a>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row
                                    *matHeaderRowDef="TapTinDinhKemService.DisplayColumns001">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: TapTinDinhKemService.DisplayColumns001;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                #TapTinDinhKemPaginator="matPaginator"
                                [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                [showFirstLastButtons]></mat-paginator>

                            <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                #TapTinDinhKemSort="matSort"
                                [dataSource]="TapTinDinhKemService.DataSource"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Phân loại</label>
                                                <select class="form-select" name="element.ParentID"
                                                    [(ngModel)]="element.ParentID">
                                                    <option
                                                        *ngFor="let item of TapTinDinhKemService.ListFilter;"
                                                        [value]="item.ID">
                                                        {{item.Name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Tên hồ sơ</label>
                                                <input class="form-control" type="text"
                                                    placeholder="Tên hồ sơ" name="Name{{element.ID}}"
                                                    [(ngModel)]="element.Name">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Mã số</label>
                                                <input class="form-control" type="text"
                                                    placeholder="Mã số" name="MaSo{{element.ID}}"
                                                    [(ngModel)]="element.MaSo">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Ngày cấp</label>
                                                <input class="form-control" type="text"
                                                    placeholder="Ngày cấp" name="NgayCap{{element.ID}}"
                                                    [(ngModel)]="element.NgayCap">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Ngày hết hạn</label>
                                                <input class="form-control" type="text"
                                                    placeholder="Ngày hết hạn" name="NgayHetHan{{element.ID}}" [(ngModel)]="element.NgayHetHan" >
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Nơi cấp</label>
                                                <input class="form-control" type="text"
                                                    placeholder="Nơi cấp" name="NoiCap{{element.ID}}" [(ngModel)]="element.NoiCap" >
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Ghi chú</label>
                                                <input class="form-control" type="text"
                                                    placeholder="Ghi chú" name="Note{{element.ID}}" [(ngModel)]="element.Note" >
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Tài liệu đính kèm</label>
                                                <br />
                                                <input type="file"
                                                    (change)="ChangeFileName(element, $event.target.files)">
                                                <br />
                                                <br />
                                                <img src="{{element.FileName}}"
                                                    class="img-fluid rounded">
                                            </div>
                                            <div class="row gx-4" *ngIf="!LoHangService.FormData.Active">
                                                <div class="col-lg-4 col-sm-4 col-4">
                                                    <a class="btn btn-info" style="width: 100%;"
                                                        (click)="TapTinDinhKemSave(element)"><i
                                                            class="bi bi-sd-card"></i>
                                                    </a>
                                                </div>
                                                <div class="col-lg-4 col-sm-4 col-4">
                                                    <a class="btn btn-danger" style="width: 100%;"
                                                        (click)="TapTinDinhKemDelete(element)"
                                                        *ngIf="element.ID>0"><i
                                                            class="bi bi-trash"></i>
                                                    </a>
                                                </div>
                                                <div class="col-lg-4 col-sm-4 col-4">
                                                    <a class="btn btn-danger" style="width: 100%;"
                                                        href="{{element.FileName}}" target="_blank"
                                                        *ngIf="element.ID>0 && element.FileName"><i
                                                            class="bi bi bi-download"></i>
                                                    </a>
                                                </div>
                                                <br />
                                                <br />
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row
                                    *matHeaderRowDef="TapTinDinhKemService.DisplayColumnsMobile">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: TapTinDinhKemService.DisplayColumnsMobile;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile"
                                #TapTinDinhKemPaginator="matPaginator"
                                [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="font-weight: bold;">Bước 7: Bản đồ tổng hợp</h4>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <div>
                            <a style="width: 100%;" class="btn btn-success" title="Xem bản đồ"
                                (click)="MapLoad()"><i class="bi bi-pin-map"></i> Xem bản đồ</a>
                        </div>
                        <div class="row gx-4" style="position: relative;padding: 10px">
                            <div class="map" id="map" #map style="height: 0px; width: 100%;padding: 0;">
                                <div style="z-index: 1000; position: absolute; top: 0;">
                                    <img src="{{domainURL}}assets/image/vungtrong.png" />
                                </div>
                                <div
                                    style="z-index: 1000; position: absolute; bottom: 0; width: 100%; height: 20px; background-color: #ffffff;">
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row gx-4" *ngIf="!LoHangService.FormData.Active && LoHangService.FormData.ID > 0">
                <div class="col-lg-12 col-sm-12 col-12">
                    <a style="width: 100%;" title="Gửi đăng ký xuất bản dữ liệu truy xuất nguồn gốc" (click)="LoHangSaveDangKy()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Gửi đăng ký xuất bản dữ liệu truy xuất nguồn gốc</a>
                </div>
            </div>
            <br />
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="font-weight: bold;">Bước 8: Nội dung phê duyệt</h4>
                </div>
                <!--
                <div class="row gx-4">
                    <div class="col-lg-6 col-sm-12 col-12">
                        <div class="col-lg-12 col-sm-12 col-12">
                            <div class="form-check form-check-inline">
                                <label class="form-label">&nbsp;&nbsp;&nbsp;Xác nhận phê duyệt</label>
                                <input class="form-check-input" type="checkbox" name="IsPheDuyet{{LoHangService.FormData.ID}}"
                                    [(ngModel)]="LoHangService.FormData.IsPheDuyet"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12 col-12">
                        <div class="col-lg-12 col-sm-12 col-12" *ngIf="LoHangService.FormData.IsPheDuyet!=true">
                            <div class="form-check form-check-inline">
                                <label class="form-label">&nbsp;&nbsp;&nbsp;Xác nhận trả hồ sơ</label>
                                <input class="form-check-input" type="checkbox" name="Active{{LoHangService.FormData.ID}}"
                                (change) ="LoHangService.FormData.Active=true" *ngIf="LoHangService.FormData.Active!=true"
                                />
                                <input class="form-check-input" type="checkbox" name="Active{{LoHangService.FormData.ID}}"
                                    checked="checked" (change) ="LoHangService.FormData.Active=null" *ngIf="LoHangService.FormData.Active ==true"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                -->
                <div  class="col-lg-12 col-sm-12 col-12">
                    <div class="table-responsive">
                        <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                            #TapTinDinhKemSort="matSort"
                            [dataSource]="TapTinDinhKemService.DataSource002"
                            class="table table-striped m-0">
                            <ng-container matColumnDef="STT">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    STT
                                </th>
                                <td mat-cell *matCellDef="let element; index as i;"
                                    style="text-align: center;">
                                    <div *ngIf="TapTinDinhKemPaginator">
                                        {{TapTinDinhKemPaginator.pageSize *
                                        TapTinDinhKemPaginator.pageIndex + i + 1}}
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="ParentID">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân loại
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <select class="form-select" name="element.ParentID"
                                        [(ngModel)]="element.ParentID">
                                        <option
                                            *ngFor="let item of DanhMucTapTinDinhKemService.ListFilter;"
                                            [value]="item.ID">
                                            {{item.Name}}</option>
                                    </select>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Hồ sơ TXNG
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="row gx-4" *ngIf="element.ID==0">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            Thời gian đăng ký: <label class="form-label">{{LoHangService.FormData.CreatedDate |  date:'dd/MM/yyyy HH:mm:ss'}}</label>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            Tình trạng: <label class="form-label">{{LoHangService.FormData.DanhMucATTPTinhTrangName}}</label>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            Tên đơn vị: <label class="form-label">{{LoHangService.FormData.CompanyInfoName}}</label>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            Tên sản phẩm: <label class="form-label">{{LoHangService.FormData.ProductInfoName}}</label>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            Mã lô hàng: <label class="form-label">{{LoHangService.FormData.MaLoHang}}</label>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            Tên lô hàng: <label class="form-label">{{LoHangService.FormData.Name}}</label>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            Loại hình mã TXNG: <label class="form-label">{{LoHangService.FormData.Name}}</label>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            Số lượng mã TXNG cần phát hành: <label class="form-label">{{LoHangService.FormData.Name}}</label>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            Mã số hệ thống: <label class="form-label">{{LoHangService.FormData.ID}}</label>
                                        </div>
                                    </div>
                                    <div class="row gx-4" *ngIf="element.ID>0">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            Lần tiếp nhận: <label class="form-label"> {{element.SoLanTiepNhan}} </label>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            Tình trạng duyệt: <label class="form-label"> {{element.DanhMucATTPTinhTrangName}} </label>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            Người thực hiện: <label class="form-label"> {{element.CreatedMembershipName}} </label>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            Đơn vị công tác: <label class="form-label"> {{element.CreatedMembershipStateAgencyName}} </label>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            Phòng ban: <label class="form-label"> {{element.CreatedMembershipAgencyDepartmentName}} </label>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            Chức vụ: <label class="form-label"> {{element.CreatedMembershipDanhMucChucDanhName}} </label>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            Ngày thực hiện: <label class="form-label"> {{element.CreatedDate |  date:'dd/MM/yyyy HH:mm:ss'}} </label>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Note">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú phê duyệt
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <textarea class="form-control" type="text" placeholder="Ghi chú"
                                        name="Note{{element.ID}}" [(ngModel)]="element.Note" row="2"
                                        [disabled]="!element.ID==0"
                                        ></textarea>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="FileName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Tài liệu đính kèm
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <input type="file" *ngIf="element.ID==0"
                                        (change)="ChangeFileNameTapTinDinhKem(element, $event.target.files)">
                                    <br />
                                    <br />
                                    <img src="{{element.FileName}}" class="img-fluid rounded" width="100" />
                                    <a class="btn btn-success"
                                        href="{{element.FileName}}" target="_blank"
                                        *ngIf="element.ID>0 && element.FileName"><i class="bi bi bi-download"></i>
                                    </a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Display">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Thông tin phê duyệt
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <!--
                                    <div class="row gx-4" *ngIf="element.ID>0">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ngày tạo: <strong>{{element.CreatedDate
                                                |
                                                date:'dd/MM/yyyy HH:mm:ss'}}</strong></label>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Người tạo: <strong>{{element.CreatedMembershipName}}</strong></label>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Thông tin: <strong>{{element.CreatedMembershipStateAgencyName}} - {{element.CreatedMembershipAgencyDepartmentName}} - {{element.CreatedMembershipDanhMucChucDanhName}}</strong></label>
                                        </div>
                                    </div>
                                    -->

                                    <div class="row gx-4" *ngIf="element.ID==0 && LoHangService.FormData.IsPheDuyet!= true">
                                        <div class="col-lg-12 col-sm-12 col-12" *ngIf="LoHangService.FormData.DanhMucATTPTinhTrangID == this.DanhMucATTPTinhTrangIDDoiThamDinh">
                                            <button style="width: 100%;" title="Tiếp nhận hồ sơ" 
                                            [disabled]="!LoHangService.FormData.Active"
                                            (click)="LoHangSaveTiepNhan()" class="btn btn-warning"><i
                                                    class="bi bi-sd-card"></i> Tiếp nhận hồ sơ</button>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12" *ngIf="LoHangService.FormData.DanhMucATTPTinhTrangID == this.DanhMucATTPTinhTrangIDTiepNhan">
                                            <button style="width: 100%;" title="Trả lại hồ sơ truy xuất nguồn gốc"
                                            [disabled]="!LoHangService.FormData.Active"
                                            (click)="LoHangSaveHoanTra()" class="btn btn-danger"><i
                                                    class="bi bi-sd-card"></i> Trả lại hồ sơ truy xuất nguồn gốc</button>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12" *ngIf="LoHangService.FormData.DanhMucATTPTinhTrangID == this.DanhMucATTPTinhTrangIDTiepNhan">
                                            <button style="width: 100%;" title="Phê duyệt hồ sơ truy xuất nguồn gốc"
                                            [disabled]="!LoHangService.FormData.Active"
                                            (click)="LoHangSavePheDuyet()" class="btn btn-info"><i
                                                    class="bi bi-sd-card"></i> Phê duyệt hồ sơ truy xuất nguồn gốc</button>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Save">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <!--
                                    <a class="btn btn-info"
                                        (click)="TapTinDinhKemSave002(element)" *ngIf="element.ID>0">
                                        <i class="bi bi-sd-card"></i>
                                    </a>&nbsp;&nbsp;&nbsp;
                                    <a class="btn btn-success"
                                        href="{{element.FileName}}" target="_blank"
                                        *ngIf="element.ID>0 && element.FileName"><i class="bi bi bi-download"></i>
                                    </a>
                                    <a class="btn btn-danger" (click)="TapTinDinhKemDelete(element)"
                                        [disabled]="!LoHangService.FormData.Active"
                                          *ngIf="element.ID>0"><i
                                            class="bi bi-trash"></i>
                                     </a>
                                     -->
                                </td>
                            </ng-container>
                            <tr mat-header-row
                                *matHeaderRowDef="TapTinDinhKemService.DisplayColumns002">
                            </tr>
                            <tr mat-row
                                *matRowDef="let row; columns: TapTinDinhKemService.DisplayColumns002;">
                            </tr>
                        </table>
                        <mat-paginator *ngIf="!NotificationService.IsMobile"
                            #TapTinDinhKemPaginator="matPaginator"
                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                            [showFirstLastButtons]></mat-paginator>

                        <table *ngIf="NotificationService.IsMobile" mat-table matSort
                            #TapTinDinhKemSort="matSort"
                            [dataSource]="TapTinDinhKemService.DataSource002"
                            class="table table-striped m-0">
                            <ng-container matColumnDef="STT">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Danh sách
                                </th>
                                <td mat-cell *matCellDef="let element; index as i;">
                                    <div class="row gx-4">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Phân loại</label>
                                            <select class="form-select" name="element.ParentID"
                                                [(ngModel)]="element.ParentID">
                                                <option
                                                    *ngFor="let item of TapTinDinhKemService.ListFilter;"
                                                    [value]="item.ID">
                                                    {{item.Name}}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tên hồ sơ</label>
                                            <textarea class="form-control" type="text"
                                                placeholder="Tên hồ sơ" name="Name{{element.ID}}"
                                                [(ngModel)]="element.Name" row="2"></textarea>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Diễn giải lỗi sai</label>
                                            <textarea class="form-control" type="text"
                                                placeholder="Diễn giải lỗi sai" name="Note{{element.ID}}" [(ngModel)]="element.Note" row="2"></textarea>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tài liệu đính kèm</label>
                                            <br />
                                            <input type="file"
                                                (change)="ChangeFileName(element, $event.target.files)">
                                            <br />
                                            <br />
                                            <img src="{{element.FileName}}"
                                                class="img-fluid rounded">
                                        </div>
                                        <!--
                                        <div class="row gx-4">
                                            <div class="col-lg-4 col-sm-4 col-4">
                                                <a class="btn btn-info" style="width: 100%;"
                                                    (click)="TapTinDinhKemSave002(element)"><i
                                                        class="bi bi-sd-card"></i>
                                                </a>
                                            </div>
                                            <div class="col-lg-4 col-sm-4 col-4">
                                                <a class="btn btn-danger" style="width: 100%;"
                                                    (click)="TapTinDinhKemDelete(element)"
                                                    *ngIf="element.ID>0"><i
                                                        class="bi bi-trash"></i>
                                                </a>
                                            </div>
                                            <div class="col-lg-4 col-sm-4 col-4">
                                                <a class="btn btn-danger" style="width: 100%;"
                                                    href="{{element.FileName}}" target="_blank"
                                                    *ngIf="element.ID>0 && element.FileName"><i
                                                        class="bi bi bi-download"></i>
                                                </a>
                                            </div>
                                            <br />
                                            <br />
                                        </div>
                                        -->
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row
                                *matHeaderRowDef="TapTinDinhKemService.DisplayColumnsMobile">
                            </tr>
                            <tr mat-row
                                *matRowDef="let row; columns: TapTinDinhKemService.DisplayColumnsMobile;">
                            </tr>
                        </table>
                        <mat-paginator *ngIf="NotificationService.IsMobile"
                            #TapTinDinhKemPaginator="matPaginator"
                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                            [showFirstLastButtons]></mat-paginator>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="LoHangService.IsShowLoading"></app-loading>