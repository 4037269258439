<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-CompanyInfoDonViDongGoiDetail" data-bs-toggle="tab"
                href="#CompanyInfoDonViDongGoiDetail" role="tab" aria-controls="CompanyInfoDonViDongGoiDetail"
                aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Đăng ký mã số đóng gói
                    [{{CompanyInfoDonViDongGoiService.FormData.ID}}]</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="CompanyInfoDonViDongGoiDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="row gx-4">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <a style="width: 100%;" title="Lưu bước 01" (click)="CompanyInfoDonViDongGoiSave()"
                            class="btn btn-info"><i class="bi bi-sd-card"></i>
                            Lưu bước 01</a>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <h4>Bước 01: Nhập Thông tin</h4>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-12 col-sm-12 col-12">
                            <h4 class="text-center">Thông tin chung</h4>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Loại hồ sơ</label>
                                <select class="form-select" name="DanhMucATTPLoaiHoSoID"
                                    [(ngModel)]="CompanyInfoDonViDongGoiService.FormData.DanhMucATTPLoaiHoSoID">
                                    <option *ngFor="let item of DanhMucATTPLoaiHoSoService.List;" [value]="item.ID">
                                        {{item.Name}}</option>
                                </select>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Tình trạng</label>
                                <select class="form-select" name="DanhMucATTPTinhTrangID"
                                    [(ngModel)]="CompanyInfoDonViDongGoiService.FormData.DanhMucATTPTinhTrangID">
                                    <option *ngFor="let item of DanhMucATTPTinhTrangService.List;" [value]="item.ID">
                                        {{item.Name}}</option>
                                </select>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Xếp loại</label>
                                <select class="form-select" name="DanhMucATTPXepLoaiID"
                                    [(ngModel)]="CompanyInfoDonViDongGoiService.FormData.DanhMucATTPXepLoaiID">
                                    <option *ngFor="let item of DanhMucATTPXepLoaiService.List;" [value]="item.ID">
                                        {{item.Name}}</option>
                                </select>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Ghi nhận</label>
                                <input
                                    [ngModel]="CompanyInfoDonViDongGoiService.FormData.NgayGhiNhan | date:'yyyy-MM-dd'"
                                    (ngModelChange)="DateNgayGhiNhan($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                                    type="date" class="form-control" autocomplete="off">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Mã hồ sơ</label>
                                <input name="MaHoSo" [(ngModel)]="CompanyInfoDonViDongGoiService.FormData.MaHoSo"
                                    placeholder="Mã hồ sơ" type="text" class="form-control">
                            </div>
                            <!-- <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Mã số cơ sở nuôi (nếu có)</label>
                                <input name="Name" [(ngModel)]="CompanyInfoDonViDongGoiService.FormData.Name"
                                    placeholder="Mã số cơ sở nuôi (nếu có)" type="text" class="form-control">
                            </div> -->
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Mã số vùng trồng (nếu có)</label>
                                <input name="MaSoVungTrong"
                                    [(ngModel)]="CompanyInfoDonViDongGoiService.FormData.MaSoVungTrong"
                                    placeholder="Mã số vùng trồng (nếu có)" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Đơn vị Tổ chức</label>
                                <input name="CompanyInfoName"
                                    [(ngModel)]="CompanyInfoDonViDongGoiService.FormData.CompanyInfoName"
                                    placeholder="Đơn vị Tổ chức" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Đại diện cơ sở (nếu có)</label>
                                <input name="DaiDienCoSo"
                                    [(ngModel)]="CompanyInfoDonViDongGoiService.FormData.DaiDienCoSo"
                                    placeholder="Đại diện cơ sở (nếu có)" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Chức danh (nếu có)</label>
                                <input name="DaiDienCoSoChucVu"
                                    [(ngModel)]="CompanyInfoDonViDongGoiService.FormData.DaiDienCoSoChucVu"
                                    placeholder="Chức danh (nếu có)" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Điện thoại (dùng để gửi thông báo)</label>
                                <input name="DaiDienCoSoDienThoai"
                                    [(ngModel)]="CompanyInfoDonViDongGoiService.FormData.DaiDienCoSoDienThoai"
                                    placeholder="Điện thoại (nếu có)" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Email (dùng để gửi thông báo)</label>
                                <input name="DaiDienCoSoEmail"
                                    [(ngModel)]="CompanyInfoDonViDongGoiService.FormData.DaiDienCoSoEmail"
                                    placeholder="Email" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Ghi chú</label>
                                <input name="Note" [(ngModel)]="CompanyInfoDonViDongGoiService.FormData.Note"
                                    placeholder="Ghi chú" type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row gx-4">
                        <div class="col-lg-12 col-sm-12 col-12">
                            <h4 class="text-center">Thông tin địa chỉ</h4>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Địa chỉ đầy đủ</label>
                                <input name="DiaChi" [(ngModel)]="CompanyInfoDonViDongGoiService.FormData.DiaChi"
                                    placeholder="Địa chỉ" type="text" class="form-control">
                            </div>

                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Kinh độ</label>
                                <input name="KinhDo" [(ngModel)]="CompanyInfoDonViDongGoiService.FormData.KinhDo"
                                    placeholder="Kinh độ" type="number" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Vĩ độ</label>
                                <input name="ViDo" [(ngModel)]="CompanyInfoDonViDongGoiService.FormData.ViDo"
                                    placeholder="Vĩ độ" type="number" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Huyện</label>
                                <select class="form-select" name="DistrictDataID"
                                    [(ngModel)]="CompanyInfoDonViDongGoiService.FormData.DistrictDataID"
                                    (change)="WardDataSearch()">
                                    <option *ngFor="let item of DistrictDataService.List;" [value]="item.ID">
                                        {{item.Name}}</option>
                                </select>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Xã</label>
                                <select class="form-select" name="WardDataID"
                                    [(ngModel)]="CompanyInfoDonViDongGoiService.FormData.WardDataID">
                                    <option *ngFor="let item of WardDataService.List;" [value]="item.ID">
                                        {{item.Name}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Thôn ấp</label>
                                <input name="ThonAp" [(ngModel)]="CompanyInfoDonViDongGoiService.FormData.ThonAp"
                                    placeholder="Thôn ấp" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Bản đồ</label>
                                <div>
                                    <a style="width: 100%;" class="btn btn-success" title="Bản đồ"
                                        (click)="MapLoad()"><i class="bi bi-pin-map"></i> Bản đồ</a>
                                </div>
                                <div class="row gx-4" style="position: relative;padding: 10px">
                                    <div class="map" id="map" #map style="height: 0px; width: 100%;padding: 0;">
                                        <div style="z-index: 1000; position: absolute; top: 0;">
                                            <img src="{{domainURL}}assets/image/vungtrong.png" />
                                        </div>
                                        <div
                                            style="z-index: 1000; position: absolute; bottom: 0; width: 100%; height: 20px; background-color: #ffffff;">
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-12 col-sm-12 col-12">
                            <h4 class="text-center">Thông tin sản xuất</h4>
                        </div>
                        <div class="col-lg-4 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Sản phẩm</label>
                                <input name="SanPham" [(ngModel)]="CompanyInfoDonViDongGoiService.FormData.SanPham"
                                    placeholder="Sản phẩm" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-12 col-12">
                            <label class="form-label">Công suất đóng gói (tấn (trái đối với dừa)/ngày)</label>
                            <input name="CongSuatToiDa"
                                [(ngModel)]="CompanyInfoDonViDongGoiService.FormData.CongSuatToiDa"
                                placeholder="Công suất đóng gói (tấn (trái đối với dừa)/ngày)" type="number" class="form-control"
                                style="text-align: right;">
                        </div>
                        <div class="col-lg-4 col-sm-12 col-12">
                            <label class="form-label">Diện tích (m2)</label>
                            <input name="DienTich" [(ngModel)]="CompanyInfoDonViDongGoiService.FormData.DienTich"
                                placeholder="Diện tích(m2)" type="number" class="form-control"
                                style="text-align: right;">
                        </div>
                    </div>
                </div>
                <div class="row gx-4">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <a style="width: 100%;" title="Lưu bước 01" (click)="CompanyInfoDonViDongGoiSave()"
                            class="btn btn-info"><i class="bi bi-sd-card"></i>
                            Lưu bước 01</a>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-danger">
                        <div class="card-header">
                            <h5 class="card-title text-white">Thêm thị trường
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoDonViDongGoiDocumentsSort002="matSort"
                                        [dataSource]="CompanyInfoDonViDongGoiDocumentsService.DataSource002"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="DanhMucThiTruongID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Thị trường
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <select class="form-select" name="element.DanhMucThiTruongID"
                                                    [(ngModel)]="element.DanhMucThiTruongID">
                                                    <option *ngFor="let item of DanhMucThiTruongService.List;"
                                                        [value]="item.ID">
                                                        {{item.Name}}</option>
                                                </select>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Thị trường
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Thị trường"
                                                    name="Name{{element.ID}}" [(ngModel)]="element.Name" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-info"
                                                    (click)="CompanyInfoDonViDongGoiDocumentsSave002(element)"><i
                                                        class="bi bi-sd-card"></i> </a>&nbsp;&nbsp;&nbsp;
                                                <a class="btn btn-danger"
                                                    (click)="CompanyInfoDonViDongGoiDocumentsDelete(element)"
                                                    *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                </a>&nbsp;&nbsp;&nbsp;
                                                <a *ngIf="element.ID > 0 && element.FileName" class="btn btn-success"
                                                    title="Tải về" href="{{element.FileName}}" target="_blank"><i
                                                        class="bi bi-cloud-download"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoDonViDongGoiDocumentsService.DisplayColumns003">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoDonViDongGoiDocumentsService.DisplayColumns003;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #CompanyInfoDonViDongGoiDocumentsPaginator002="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoDonViDongGoiDocumentsSort002="matSort"
                                        [dataSource]="CompanyInfoDonViDongGoiDocumentsService.DataSource002"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoDonViDongGoiDocumentsService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoDonViDongGoiDocumentsService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #CompanyInfoDonViDongGoiDocumentsPaginator002="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-success">
                        <div class="card-header">
                            <h5 class="card-title text-white">Bước 02: Thêm thông tin nhân viên
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoDonViDongGoiNongHoSort="matSort"
                                        [dataSource]="CompanyInfoDonViDongGoiNongHoService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="Active">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                CCCD Trùng
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox"
                                                        name="Active{{ element.ID }}" [(ngModel)]="element.Active" />
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="ThanhVienID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nhân viên
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Nhân viên"
                                                    name="Name{{ element.ID }}" [(ngModel)]="element.Name" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="CCCD">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>CCCD
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="CCCD"
                                                    name="CCCD{{ element.ID }}" [(ngModel)]="element.CCCD" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="DienThoai">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Điện thoại"
                                                    name="DienThoai{{ element.ID }}" [(ngModel)]="element.DienThoai" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Email">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Email"
                                                    name="Email{{ element.ID }}" [(ngModel)]="element.Email" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Note">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Ghi chú"
                                                    name="Note{{ element.ID }}" [(ngModel)]="element.Note" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-info"
                                                    (click)="CompanyInfoDonViDongGoiNongHoSave(element)"><i
                                                        class="bi bi-sd-card"></i>
                                                </a>&nbsp;&nbsp;&nbsp;
                                                <a class="btn btn-danger"
                                                    (click)="CompanyInfoDonViDongGoiNongHoDelete(element)"
                                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoDonViDongGoiNongHoService.DisplayColumns002">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoDonViDongGoiNongHoService.DisplayColumns002;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #CompanyInfoDonViDongGoiNongHoPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoDonViDongGoiNongHoSort="matSort"
                                        [dataSource]="CompanyInfoDonViDongGoiNongHoService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="checkbox"
                                                                name="Active{{ element.ID }}"
                                                                [(ngModel)]="element.Active" />
                                                            <label class="form-label">CCCD Trùng</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Nhân viên: </label>
                                                        <input class="form-control" type="text" placeholder="Nhân viên"
                                                            name="Name{{element.ID}}" [(ngModel)]="element.Name" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>CCCD: </label>
                                                        <input class="form-control" type="text" placeholder="CCCD"
                                                            name="CCCD{{ element.ID }}" [(ngModel)]="element.CCCD" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Điện thoại: </label>
                                                        <input class="form-control" type="text" placeholder="Điện thoại"
                                                            name="DienThoai{{ element.ID }}"
                                                            [(ngModel)]="element.DienThoai" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Email: </label>
                                                        <input class="form-control" type="text" placeholder="Email"
                                                            name="Email{{ element.ID }}" [(ngModel)]="element.Email" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Ghi chú: </label>
                                                        <input class="form-control" type="text" placeholder="Ghi chú"
                                                            name="Note{{ element.ID }}" [(ngModel)]="element.Note" />
                                                    </div>
                                                    <div class="row gx-4">
                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                            <a class="btn btn-info" style="width: 100%"
                                                                (click)="CompanyInfoDonViDongGoiNongHoSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>
                                                            <a class="btn btn-danger" style="width: 100%;"
                                                                (click)="CompanyInfoDonViDongGoiNongHoDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </div>
                                                        <br />
                                                        <br />
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoDonViDongGoiNongHoService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoDonViDongGoiNongHoService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #CompanyInfoDonViDongGoiNongHoPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <a style="width: 100%;" title="Tải tất cả tập tin đính kèm"
                        (click)="CompanyInfoDonViDongGoiDocumentsDownload()" class="btn btn-primary"><i
                            class="bi bi-cloud-download"></i>
                        Tải tất cả tập tin đính kèm</a>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-primary">
                        <div class="card-header">
                            <h5 class="card-title text-white bg-primary">Bước 03: Tải lên biểu mẫu và tập tin đính kèm
                            </h5>
                        </div>
                        <div class="card-body bg-primary">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoDonViDongGoiDocumentsSort="matSort"
                                        [dataSource]="CompanyInfoDonViDongGoiDocumentsService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="Name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Hồ sơ
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="width: 50%;">
                                                <input class="form-control" type="text" placeholder="Hồ sơ"
                                                    name="Name{{ element.ID }}" [(ngModel)]="element.Name" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="FileName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải lên
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input type="file" style="width: 200px;"
                                                    (change)="ChangeFileName(element, $event.target.files)">
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="row gx-4">
                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                        <a class="btn btn-info" style="width: 100%;"
                                                            (click)="CompanyInfoDonViDongGoiDocumentsSave(element)"><i
                                                                class="bi bi-sd-card"></i></a>
                                                    </div>
                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                        <a class="btn btn-danger" style="width: 100%;"
                                                            (click)="CompanyInfoDonViDongGoiDocumentsDelete(element)"
                                                            *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a *ngIf="element.ID > 0 && element.TypeName"
                                                            style="width: 100%;" class="btn btn-success" title="Tải về"
                                                            (click)="CompanyInfoDonViDongGoiDocumentsAdd(element)"
                                                            target="_blank">Xem biểu mẫu trực tuyến
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a *ngIf="element.ID > 0 && element.TypeName"
                                                            style="width: 100%;" class="btn btn-success" title="Tải về"
                                                            href="{{element.TypeName}}" target="_blank">Tải biểu mẫu Cơ
                                                            quan quản lý cung cấp
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a *ngIf="element.ID > 0" style="width: 100%;"
                                                            class="btn btn-success" title="Tải về"
                                                            href="{{element.FileName}}" target="_blank">Tải tài liệu
                                                            người dân cung cấp (nếu có)
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoDonViDongGoiDocumentsService.DisplayColumns001">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoDonViDongGoiDocumentsService.DisplayColumns001;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #CompanyInfoDonViDongGoiDocumentsPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoDonViDongGoiDocumentsSort="matSort"
                                        [dataSource]="CompanyInfoDonViDongGoiDocumentsService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Hồ sơ: </label>
                                                        <input class="form-control" type="text" placeholder="Hồ sơ"
                                                            name="Name{{ element.ID }}" [(ngModel)]="element.Name" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Tải lên: </label>
                                                        <br />
                                                        <input type="file" style="width: 100px;"
                                                            (change)="ChangeFileName(element, $event.target.files)">
                                                    </div>
                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                        <a class="btn btn-info" style="width: 100%"
                                                            (click)="CompanyInfoDonViDongGoiDocumentsSave(element)"><i
                                                                class="bi bi-sd-card"></i> </a>
                                                    </div>
                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                        <a class="btn btn-danger" style="width: 100%"
                                                            (click)="CompanyInfoDonViDongGoiDocumentsDelete(element)"
                                                            *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a *ngIf="element.ID > 0 && element.TypeName"
                                                            style="width: 100%" class="btn btn-success" title="Tải về"
                                                            (click)="CompanyInfoDonViDongGoiDocumentsAdd(element)"
                                                            target="_blank">Xem biểu mẫu trực tuyến
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a *ngIf="element.ID > 0 && element.TypeName"
                                                            style="width: 100%" class="btn btn-success" title="Tải về"
                                                            href="{{element.TypeName}}" target="_blank">Tải biểu mẫu
                                                            CQQL cung cấp
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a *ngIf="element.ID > 0" style="width: 100%"
                                                            class="btn btn-success" title="Tải về"
                                                            href="{{element.FileName}}" target="_blank">Tải tài
                                                            liệu người dân cung cấp (nếu có)
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoDonViDongGoiDocumentsService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoDonViDongGoiDocumentsService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #CompanyInfoDonViDongGoiDocumentsPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-danger">
                        <div class="card-header">
                            <h5 class="card-title text-white">Bước 04: Nhận xét phê duyệt
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoDonViDongGoiDocumentsSort002="matSort"
                                        [dataSource]="CompanyInfoDonViDongGoiDocumentsService.DataSource002"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="DanhMucThiTruongID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Thị trường
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <select class="form-select" name="element.DanhMucThiTruongID"
                                                    [(ngModel)]="element.DanhMucThiTruongID">
                                                    <option *ngFor="let item of DanhMucThiTruongService.List;"
                                                        [value]="item.ID">
                                                        {{item.Name}}</option>
                                                </select>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Thị trường
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Thị trường"
                                                    name="Name{{ element.ID }}" [(ngModel)]="element.Name" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Note">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Diễn Giải Sai Lỗi
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Diễn Giải Sai Lỗi"
                                                    name="Note{{ element.ID }}" [(ngModel)]="element.Note" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="NgayGhiNhan">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input name="NgayGhiNhan{{element.ID}}"
                                                    [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                    (ngModelChange)="CompanyInfoDonViDongGoiDocumentsNgayGhiNhan(element, $event)"
                                                    [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                    class="form-control" autocomplete="off">
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="FileName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải lên
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input type="file" style="width: 100px;"
                                                    (change)="ChangeFileName(element, $event.target.files)">
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-info"
                                                    (click)="CompanyInfoDonViDongGoiDocumentsSave002(element)"><i
                                                        class="bi bi-sd-card"></i> </a>&nbsp;&nbsp;&nbsp;
                                                <a class="btn btn-danger"
                                                    (click)="CompanyInfoDonViDongGoiDocumentsDelete(element)"
                                                    *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                </a>&nbsp;&nbsp;&nbsp;
                                                <a *ngIf="element.ID > 0 && element.FileName" class="btn btn-success"
                                                    title="Tải về" href="{{element.FileName}}" target="_blank"><i
                                                        class="bi bi-cloud-download"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoDonViDongGoiDocumentsService.DisplayColumns004">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoDonViDongGoiDocumentsService.DisplayColumns004;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #CompanyInfoDonViDongGoiDocumentsPaginator002="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoDonViDongGoiDocumentsSort002="matSort"
                                        [dataSource]="CompanyInfoDonViDongGoiDocumentsService.DataSource002"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <!-- <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Hồ sơ: </label>
                                                        <input class="form-control" type="text" placeholder="Hồ sơ"
                                                            name="Name{{ element.ID }}" [(ngModel)]="element.Name" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Diễn Giải Sai Lỗi: </label>
                                                        <input class="form-control" type="text"
                                                            placeholder="Diễn Giải Sai Lỗi" name="Note{{ element.ID }}"
                                                            [(ngModel)]="element.Note" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Tải lên: </label>
                                                        <br />
                                                        <input type="file" style="width: 100px;"
                                                            (change)="ChangeFileName(element, $event.target.files)">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a class="btn btn-info"
                                                            (click)="CompanyInfoDonViDongGoiDocumentsSave002(element)"><i
                                                                class="bi bi-sd-card"></i> </a>&nbsp;&nbsp;&nbsp;
                                                        <a class="btn btn-danger"
                                                            (click)="CompanyInfoDonViDongGoiDocumentsDelete(element)"
                                                            *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                        </a>&nbsp;&nbsp;&nbsp;
                                                        <a *ngIf="element.ID > 0 && element.FileName"
                                                            class="btn btn-success" title="Tải về"
                                                            href="{{element.FileName}}" target="_blank"><i
                                                                class="bi bi-cloud-download"></i>
                                                        </a>
                                                    </div>
                                                </div> -->
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoDonViDongGoiDocumentsService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoDonViDongGoiDocumentsService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #CompanyInfoDonViDongGoiDocumentsPaginator002="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-loading *ngIf="CompanyInfoDonViDongGoiService.IsShowLoading"></app-loading>