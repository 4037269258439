<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-CompanyInfo" data-bs-toggle="tab" href="#CompanyInfo"
                                    role="tab" aria-controls="CompanyInfo" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Đơn vị Tổ chức</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="CompanyInfo" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Trạng
                                            thái</label>
                                        <select class="form-select"
                                            name="CompanyInfoService.BaseParameter.DanhMucCompanyTinhTrangID"
                                            [(ngModel)]="CompanyInfoService.BaseParameter.DanhMucCompanyTinhTrangID">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of DanhMucCompanyTinhTrangService.List;"
                                                [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-5 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input [(ngModel)]="CompanyInfoService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="CompanyInfoSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <div>
                                            <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                                (click)="CompanyInfoSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span *ngIf="CompanyInfoService.List">({{CompanyInfoService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #CompanyInfoSort="matSort"
                                                    [dataSource]="CompanyInfoService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="CompanyInfoPaginator"
                                                                style="background-color: {{element.MauNen}};">
                                                                {{CompanyInfoPaginator.pageSize *
                                                                CompanyInfoPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div style="background-color: {{element.MauNen}};">
                                                                {{element.ID}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucCompanyTinhTrangName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Trạng thái
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div style="background-color: {{element.MauNen}};">
                                                                {{element.DanhMucCompanyTinhTrangName}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DistrictDataName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quận huyện
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div style="background-color: {{element.MauNen}};">
                                                                {{element.DistrictDataName}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="WardDataName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Xã phường
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div style="background-color: {{element.MauNen}};">
                                                                {{element.WardDataName}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div style="background-color: {{element.MauNen}};">
                                                                <a style="cursor: pointer;" title="{{element.Name}}"
                                                                    class="link-primary"
                                                                    (click)="CompanyInfoAdd(element.ID)"><b>{{element.Name}}</b></a>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="fullname">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chủ cơ sở
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div style="background-color: {{element.MauNen}};">
                                                                {{element.fullname}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="phone">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div style="background-color: {{element.MauNen}};">
                                                                {{element.phone}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DKKD">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã số thuế
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div style="background-color: {{element.MauNen}};">
                                                                {{element.DKKD}}
                                                            </div>
                                                        </td>
                                                    </ng-container>

                                                    <tr mat-header-row
                                                        *matHeaderRowDef="CompanyInfoService.DisplayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: CompanyInfoService.DisplayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #CompanyInfoPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>

                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #CompanyInfoSort="matSort"
                                                    [dataSource]="CompanyInfoService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            <div class="row gx-4"
                                                                style="background-color: {{element.MauNen}};">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Tiêu đề: </label>
                                                                    <a class="form-label" style="cursor: pointer;" title="{{element.Name}}"
                                                                        class="link-primary"
                                                                        (click)="CompanyInfoAdd(element.ID)"><b>{{element.Name}}</b></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Chủ cơ sở: </label>
                                                                    <label class="form-label">{{element.fullname}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Điện thoại</label>
                                                                    {{element.phone}}
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Mã số thuế</label>
                                                                    {{element.DKKD}}
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Trạng thái</label>
                                                                    {{element.DanhMucCompanyTinhTrangName}}
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Quận huyện</label>
                                                                    {{element.DistrictDataName}}
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Xã phường</label>
                                                                    {{element.WardDataName}}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="CompanyInfoService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: CompanyInfoService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #CompanyInfoPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="CompanyInfoService.IsShowLoading"></app-loading>