<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-Report" data-bs-toggle="tab" href="#Report"
                                    role="tab" aria-controls="Report" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px">
                                        Phụ lục II: BIỂU MẪU BÁO CÁO THỰC HIỆN THÔNG TƯ SỐ 17/2018/TT-BNNPTNT</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="Report" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Quận
                                            huyện</label>
                                        <select class="form-select" name="ReportService.BaseParameter.DistrictDataID"
                                            [(ngModel)]="ReportService.BaseParameter.DistrictDataID">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of DistrictDataService.List;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Năm</label>
                                        <select class="form-select" [(ngModel)]="ReportService.BaseParameter.Nam">
                                            <option *ngFor="let item of DownloadService.ListNam001;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Tháng</label>
                                        <select class="form-select" [(ngModel)]="ReportService.BaseParameter.Thang">
                                            <option *ngFor="let item of DownloadService.ListThang001;"
                                                [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input [(ngModel)]="ReportService.BaseParameter.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="Report0004Search()" />
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <div class="row gx-4">
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                                    (click)="Report0004Search()"><i class="bi bi-search"></i></a>
                                            </div>
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a style="width: 100%;" class="btn btn-success" title="Tải về"
                                                    (click)="Report0004Download()"><i
                                                        class="bi bi-cloud-download"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">
                                            Danh sách
                                            <span *ngIf="ReportService.List">({{ ReportService.List.length }}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ReportSort="matSort" [dataSource]="ReportService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i"
                                                            style="text-align: center">
                                                            <div *ngIf="ReportPaginator">
                                                                {{ReportPaginator.pageSize * ReportPaginator.pageIndex +
                                                                i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            <div *ngIf="ReportService.BaseParameter.DistrictDataID==0">
                                                                Quận huyện
                                                            </div>
                                                            <div *ngIf="ReportService.BaseParameter.DistrictDataID>0">
                                                                Xã phường
                                                            </div>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <b>{{element.Name}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TyLe001">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tăng/Giảm cùng kỳ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.TyLe001 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe001">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tổng số
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe001 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe005">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Cam kết Trong tháng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe005 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe004">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Lũy kế
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe004 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TyLe002">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tỷ lệ (%)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.TyLe002 |
                                                            number:
                                                            '1.0-0'}}%
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe007">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Kiểm tra Trong tháng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe007 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe006">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Lũy kế
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe006 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TyLe003">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tỷ lệ (%)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.TyLe003 |
                                                            number:
                                                            '1.0-0'}}%
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.DisplayColumns00040005"></tr>
                                                    <tr mat-row
                                                        *matRowDef="let row;columns: ReportService.DisplayColumns00040005">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ReportPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                                    [showFirstLastButtons]></mat-paginator>

                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ReportSort="matSort" [dataSource]="ReportService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.DisplayColumnsMobile"></tr>
                                                    <tr mat-row
                                                        *matRowDef="let row;columns: ReportService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ReportPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ReportService.IsShowLoading"></app-loading>