<div class="app-body">
    <div class="row">
      <div class="col-xxl-12">
        <div class="card" style="margin-left: 1px">
          <div class="card-body">
            <div class="custom-tabs-container">
              <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                <li class="nav-item" role="presentation">
                  <a class="nav-link active" id="tab-LoHang" data-bs-toggle="tab" href="#LoHang" role="tab"
                    aria-controls="LoHang" aria-selected="true">
                    <span class="badge bg-info" style="font-size: 16px">Sản phẩm</span>
                  </a>
                </li>
              </ul>
              <div class="tab-content" id="customTabContent">
                <div class="tab-pane fade show active" id="LoHang" role="tabpanel">
                  <div class="row gx-4">
                    <div class="col-lg-8 col-sm-12 col-12">
                      <input [(ngModel)]="LoHangService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="LoHangSearch()" />
                    </div>
                    <div class="col-lg-4 col-sm-12 col-12">
                        <div class="row gx-4">
                            <div class="col-lg-6 col-sm-6 col-6">
                                <a style="width: 100%" class="btn btn-success" title="Tìm" (click)="LoHangSearch()"><i
                                    class="bi bi-search"></i></a>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-6">
                                <a style="width: 100%;" class="btn btn-success" title="Thêm mới"
                            (click)="LoHangAdd(0)"><i class="bi bi-plus"></i></a>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="card mb-4 card-info">
                    <div class="card-header">
                      <h5 class="card-title text-white">
                        Danh sách
                        <span *ngIf="LoHangService.List">({{LoHangService.List.length}} items)</span>
                      </h5>
                    </div>
                    <div class="card-body">
                      <div class="table-outer">
                        <div class="table-responsive">
                          <table *ngIf="!NotificationService.IsMobile" mat-table matSort #LoHangSort="matSort"
                            [dataSource]="LoHangService.DataSource" class="table table-striped m-0">
                            <ng-container matColumnDef="STT">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                STT
                              </th>
                              <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                                <div *ngIf="LoHangPaginator">
                                  {{
                                  LoHangPaginator.pageSize *
                                  LoHangPaginator.pageIndex +
                                  i +
                                  1
                                  }}
                                </div>
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="ID">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                ID
                              </th>
                              <td mat-cell *matCellDef="let element">
                                {{ element.ID }}
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="CompanyInfoName">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Đơn vị Tổ chức
                              </th>
                              <td mat-cell *matCellDef="let element">
                                <a style="cursor: pointer" title="{{element.CompanyInfoName}}" class="link-primary"
                                  (click)="CompanyInfoAdd(element.CompanyInfoID)"><b>{{ element.CompanyInfoName}}</b></a>
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="Name">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Tên lô hàng
                              </th>
                              <td mat-cell *matCellDef="let element">
                                <a style="cursor: pointer" title="{{ element.Name }}" class="link-primary"
                                  (click)="LoHangAdd(element.ID)"><b>{{ element.Name }}</b></a>
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="NgayGhiNhan">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Ngày công bố
                              </th>
                              <td mat-cell *matCellDef="let element">
                                <a style="cursor: pointer" title="{{ element.NgayGhiNhan }}" class="link-primary"
                                  (click)="LoHangAdd(element.ID)"><b>{{element.NgayGhiNhan | date : "dd/MM/yyyy"}}</b></a>
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="DanhMucATTPTinhTrangName">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Tình trạng
                              </th>
                              <td mat-cell *matCellDef="let element">
                                {{element.DanhMucATTPTinhTrangName}}
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="Save">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                              <td mat-cell *matCellDef="let element">
                                <a class="btn btn-danger" (click)="LoHangDelete(element)" *ngIf="element.ID > 0"><i
                                    class="bi bi-trash"></i>
                                </a>
                              </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="LoHangService.DisplayColumns001"></tr>
                            <tr mat-row *matRowDef="
                                let row;
                                columns: LoHangService.DisplayColumns001
                              "></tr>
                          </table>
                          <mat-paginator *ngIf="!NotificationService.IsMobile" #LoHangPaginator="matPaginator"
                            [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                            [showFirstLastButtons]></mat-paginator>
  
                          <table *ngIf="NotificationService.IsMobile" mat-table matSort #LoHangSort="matSort"
                            [dataSource]="LoHangService.DataSource" class="table table-striped m-0">
                            <ng-container matColumnDef="STT">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Danh sách
                              </th>
                              <td mat-cell *matCellDef="let element; index as i">
                                <div class="row gx-4">
                                  <div class="col-lg-12 col-sm-12 col-12">
                                    <label>Đơn vị tổ chức</label>
                                    <br />
                                    <label class="form-label"><a style="cursor: pointer" title="{{element.CompanyInfoName}}" class="link-primary"
                                      (click)="CompanyInfoAdd(element.CompanyInfoID)"><b>{{ element.CompanyInfoName}}</b></a></label>
                                  </div>
                                  <div class="col-lg-12 col-sm-12 col-12">
                                    <label>Tên lô hàng</label>
                                    <br />
                                    <a style="cursor: pointer" title="{{element.Name}}" class="link-primary"
                                      (click)="LoHangAdd(element.ID)"><b>{{ element.Name }}</b></a>
                                  </div>
                                  <div class="col-lg-12 col-sm-12 col-12">
                                    <label>Công bố</label>
                                    <label class="form-label"><a style="cursor: pointer" title="{{ element.NgayGhiNhan }}" class="link-primary"
                                      (click)="LoHangAdd(element.ID)"><b>{{element.NgayGhiNhan | date : "dd/MM/yyyy"}}</b></a></label>
                                  </div>
                                  <div class="col-lg-12 col-sm-12 col-12">
                                    <label>Tình trạng</label>
                                    <br />
                                    <label class="form-label">{{element.DanhMucATTPTinhTrangName}}</label>
                                  </div>
                                  <div class="col-lg-12 col-sm-12 col-12">
                                    <a class="btn btn-danger" style="width: 100%" (click)="LoHangDelete(element)"
                                      *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                    </a>
                                  </div>
                                  <br />
                                  <br />
                                </div>
                              </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="
                                LoHangService.DisplayColumnsMobile
                              "></tr>
                            <tr mat-row *matRowDef="
                                let row;
                                columns: LoHangService.DisplayColumnsMobile
                              "></tr>
                          </table>
                          <mat-paginator *ngIf="NotificationService.IsMobile" #LoHangPaginator="matPaginator"
                            [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                            [showFirstLastButtons]></mat-paginator>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-loading *ngIf="LoHangService.IsShowLoading"></app-loading>