<div class="app-body">
  <div class="row">
    <div class="col-xxl-12">
      <div class="card" style="margin-left: 1px">
        <div class="card-body">
          <div class="custom-tabs-container">
            <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="nav-link active" id="tab-CamKet17" data-bs-toggle="tab" href="#CamKet17" role="tab"
                  aria-controls="CamKet17" aria-selected="true">
                  <span class="badge bg-info" style="font-size: 16px">Nhập số liệu trực tiếp liên quan đến cam kết thông
                    tư số 17</span>
                </a>
              </li>
            </ul>
            <div class="tab-content" id="customTabContent">
              <div class="tab-pane fade show active" id="CamKet17" role="tabpanel">
                <div class="row gx-4">
                  <div class="col-lg-6 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="CamKet17Save()" class="btn btn-info"><i
                        class="bi bi-sd-card"></i> Lưu thay đổi</a>
                  </div>
                  <div class="col-lg-6 col-sm-12 col-12">
                    <a style="width: 100%;" *ngIf="CamKet17Service.FormData.ID > 0 && CamKet17Service.FormData.FileName"
                      class="btn btn-success" title="Tải về" (click)="OpenWindowByURL()"><i class="bi bi-cloud-download"></i> Tải về biểu mẫu
                    </a>
                  </div>
                  <!-- <div class="col-lg-4 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                      <input type="checkbox" class="form-check-input" name="Active"
                        [(ngModel)]="CamKet17Service.FormData.Active" />
                      <label class="form-check-label" for="Active">Kích hoạt</label>
                    </div>
                  </div> -->
                </div>
                <div class="row gx-4">
                  <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                      <label class="form-label">Năm báo cáo</label>
                      <input name="Nam" [(ngModel)]="CamKet17Service.FormData.Nam" placeholder="0" type="text"
                        class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                      <label class="form-label">Tháng báo cáo</label>
                      <input name="Thang" [(ngModel)]="CamKet17Service.FormData.Thang" placeholder="0" type="text"
                        class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                      <label class="form-label">Ghi chú</label>
                      <input name="Note" [(ngModel)]="CamKet17Service.FormData.Note" placeholder="Ghi chú" type="text"
                        class="form-control">
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                      <label class="form-label">Tổng số cơ sở thuộc đối tượng triển khai Thông tư số 17 (tính đến thời
                        điểm báo cáo)</label>
                      <input name="DonViToChucCount" [(ngModel)]="CamKet17Service.FormData.DonViToChucCount"
                        placeholder="0" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                      <label class="form-label">Số cơ sở ký cam kết trong tháng/ lũy kế đến thời điểm báo cáo</label>
                      <input name="DonViToChucCountThangLuyKe"
                        [(ngModel)]="CamKet17Service.FormData.DonViToChucCountThangLuyKe" placeholder="0" type="text"
                        class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                      <label class="form-label">Số cơ sở được kiểm tra trong tháng/ lũy kế đến thời điểm báo cáo</label>
                      <input name="DonViToChucCountThangLuyKeKiemTra"
                        [(ngModel)]="CamKet17Service.FormData.DonViToChucCountThangLuyKeKiemTra" placeholder="0"
                        type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                      <label class="form-label">Số cơ sở chưa đạt yêu cầu trong tháng/ lũy kế đến thời điểm báo
                        cáo</label>
                      <input name="DonViToChucCountThangLuyKeKiemTraChuaDat"
                        [(ngModel)]="CamKet17Service.FormData.DonViToChucCountThangLuyKeKiemTraChuaDat" placeholder="0"
                        type="text" class="form-control">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loading *ngIf="CamKet17Service.IsShowLoading"></app-loading>