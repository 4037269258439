<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-LoHangDetail" data-bs-toggle="tab" href="#LoHangDetail" role="tab"
                aria-controls="LoHangDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">01. Nhật ký canh tác
                    [{{LoHangService.FormData.ID}}]</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="LoHangDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="text-align: center;"><b>Bước 01: Nhập thông tin vụ sản xuất</b></h4>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đơn vị
                            [{{LoHangService.FormData.ParentID}}]</label>
                        <input name="ParentName" [(ngModel)]="LoHangService.FormData.ParentName" placeholder="Đơn vị"
                            type="text" disabled class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày bắt đầu</label>
                        <input [ngModel]="LoHangService.FormData.DangKy | date:'yyyy-MM-dd'" style="text-align: right;"
                            (ngModelChange)="DateDangKy($event)" [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                            class="form-control" autocomplete="off">
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã vụ</label>
                        <input name="MaHoSo" [(ngModel)]="LoHangService.FormData.MaHoSo" placeholder="Mã vụ" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Vụ sản xuất</label>
                        <input name="Name" [(ngModel)]="LoHangService.FormData.Name" placeholder="Vụ sản xuất"
                            type="text" class="form-control">
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu Bước 01" (click)="LoHangSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu Bước 01</a>
                </div>
            </div>
            <hr />
            <div class="row gx-4">
                <div class="card mb-4 card-success">
                    <div class="card-header">
                        <h5 class="card-title text-white">Bước 02: Nhật ký canh tác
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="table-outer">
                            <div class="table-responsive">
                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                    #LoHangNhatKySort="matSort" [dataSource]="LoHangNhatKyService.DataSource"
                                    class="table table-striped m-0">
                                    <ng-container matColumnDef="Name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thông tin
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <div class="row gx-4">
                                                <div class="col-lg-6 col-sm-12 col-12">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ghi nhận</label>
                                                        <input [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                            style="text-align: right;"
                                                            (ngModelChange)="DateLoHangNhatKyNgayGhiNhan(element,$event)"
                                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                            class="form-control" autocomplete="off">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Quy trình</label>
                                                        <select class="form-select"
                                                            name="DanhMucQuyTrinhID{{element.ID}}"
                                                            [(ngModel)]="element.DanhMucQuyTrinhID">
                                                            <option *ngFor="let item of DanhMucQuyTrinhService.List;"
                                                                [value]="item.ID">
                                                                {{item.Name}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Nhật ký</label>
                                                        <input type="text" placeholder="Nhật ký"
                                                            name="Name{{element.ID}}" [(ngModel)]="element.Name"
                                                            class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Người phụ trách</label>
                                                        <input type="text" placeholder="Người phụ trách"
                                                            name="NguoiPhuTrach{{element.ID}}"
                                                            [(ngModel)]="element.NguoiPhuTrach" class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Cơ sở đóng gói</label>
                                                        <input type="text" placeholder="Cơ sở đóng gói"
                                                            name="CoSoDongGoiName{{element.ID}}"
                                                            [(ngModel)]="element.CoSoDongGoiName" class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Mã số Cơ sở đóng gói</label>
                                                        <input type="text" placeholder="Mã số Cơ sở đóng gói"
                                                            name="CoSoDongGoiMaSo{{element.ID}}"
                                                            [(ngModel)]="element.CoSoDongGoiMaSo" class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Vùng trồng</label>
                                                        <input type="text" placeholder="Vùng trồng"
                                                            name="VungTrongName{{element.ID}}"
                                                            [(ngModel)]="element.VungTrongName" class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Mã số Vùng trồng</label>
                                                        <input type="text" placeholder="Mã số Vùng trồng"
                                                            name="VungTrongMaSo{{element.ID}}"
                                                            [(ngModel)]="element.VungTrongMaSo" class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Vật tư</label>
                                                        <input type="text" placeholder="Vật tư"
                                                            name="VatTuName{{element.ID}}"
                                                            [(ngModel)]="element.VatTuName" class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Đơn vị tính</label>
                                                        <input type="text" placeholder="Đơn vị tính"
                                                            name="VatTuDonViTinh{{element.ID}}"
                                                            [(ngModel)]="element.VatTuDonViTinh" class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Số lượng/liều lượng sử dụng</label>
                                                        <input type="number" placeholder="0"
                                                            name="VatTuSoLuong{{element.ID}}"
                                                            [(ngModel)]="element.VatTuSoLuong" class="form-control">
                                                    </div>
                                                    <div class="row gx-4">
                                                        <div class="col-lg-6 col-sm-12 col-12">
                                                            <div class="form-check form-check-inline mb-6">
                                                                <input type="checkbox" class="form-check-input"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active" />
                                                                <label class="form-check-label" for="Active">Hoàn
                                                                    thành</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-sm-12 col-12">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Đơn vị vận chuyển</label>
                                                        <input type="text" placeholder="Đơn vị vận chuyển"
                                                            name="VanChuyenName{{element.ID}}"
                                                            [(ngModel)]="element.VanChuyenName" class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Phương thức vận chuyển</label>
                                                        <input type="text" placeholder="Phương thức vận chuyển"
                                                            name="VanChuyenPhuongThuc{{element.ID}}"
                                                            [(ngModel)]="element.VanChuyenPhuongThuc"
                                                            class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Loại hình vận chuyển</label>
                                                        <input type="text" placeholder="Loại hình vận chuyển"
                                                            name="VanChuyenLoaiHinh{{element.ID}}"
                                                            [(ngModel)]="element.VanChuyenLoaiHinh"
                                                            class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Biển số xe</label>
                                                        <input type="text" placeholder="Biển số xe"
                                                            name="VanChuyenSoHieu{{element.ID}}"
                                                            [(ngModel)]="element.VanChuyenSoHieu" class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Điểm khởi hành</label>
                                                        <input type="text" placeholder="Điểm khởi hành"
                                                            name="VanChuyenKhoiHanh{{element.ID}}"
                                                            [(ngModel)]="element.VanChuyenKhoiHanh"
                                                            class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Điểm đến</label>
                                                        <input type="text" placeholder="Điểm đến"
                                                            name="VanChuyenDiemDen{{element.ID}}"
                                                            [(ngModel)]="element.VanChuyenDiemDen" class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Mã vận đơn</label>
                                                        <input type="text" placeholder="Mã vận đơn"
                                                            name="VanChuyenMaVanDon{{element.ID}}"
                                                            [(ngModel)]="element.VanChuyenMaVanDon"
                                                            class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Mã GTIN</label>
                                                        <input type="text" placeholder="Mã GTIN"
                                                            name="VanChuyenGTIN{{element.ID}}"
                                                            [(ngModel)]="element.VanChuyenGTIN" class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Mô tả ngắn hoạt động</label>
                                                        <input type="text" placeholder="Mô tả ngắn hoạt động"
                                                            name="Note{{element.ID}}" [(ngModel)]="element.Note"
                                                            class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Tài liệu đính kèm</label>
                                                        <br />
                                                        <input type="file"
                                                            (change)="ChangeFileNameLoHangNhatKy($event.target.files)">
                                                        <br />
                                                        <br />
                                                        <img src="{{element.FileName}}" class="img-fluid rounded"
                                                            style="max-width: 200px;">
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-sm-12 col-12">
                                                    <a style="width: 100%;" class="btn btn-info" title="Lưu"
                                                        (click)="LoHangNhatKySave(element)"><i
                                                            class="bi bi-sd-card"></i>
                                                    </a>
                                                </div>
                                                <div class="col-lg-4 col-sm-12 col-12">
                                                    <a style="width: 100%;" class="btn btn-info" title="Copy"
                                                        (click)="LoHangNhatKyCopy(element)" *ngIf="element.ID>0"><i
                                                            class="bi bi-subtract"></i>
                                                    </a>
                                                </div>
                                                <div class="col-lg-4 col-sm-12 col-12">
                                                    <a style="width: 100%;" class="btn btn-danger" title="Xóa"
                                                        (click)="LoHangNhatKyDelete(element)" *ngIf="element.ID>0"><i
                                                            class="bi bi-trash"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="LoHangNhatKyService.DisplayColumns001">
                                    </tr>
                                    <tr mat-row *matRowDef="let row;columns: LoHangNhatKyService.DisplayColumns001">
                                    </tr>
                                </table>
                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                    #LoHangNhatKyPaginator="matPaginator"
                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                    [showFirstLastButtons]></mat-paginator>

                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                    #LoHangNhatKySort="matSort" [dataSource]="LoHangNhatKyService.DataSource"
                                    class="table table-striped m-0">
                                    <ng-container matColumnDef="STT">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Danh sách
                                        </th>
                                        <td mat-cell *matCellDef="let element; index as i">
                                            <div class="row gx-4">
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Quy trình</label>
                                                    <select class="form-select" name="DanhMucQuyTrinhID"
                                                        [(ngModel)]="element.DanhMucQuyTrinhID">
                                                        <option *ngFor="let item of DanhMucQuyTrinhService.List;"
                                                            [value]="item.ID">
                                                            {{item.Name}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Nhật ký</label>
                                                    <input type="text" placeholder="Nhật ký" name="Name{{element.ID}}"
                                                        [(ngModel)]="element.Name" class="form-control">
                                                </div>
                                                <div *ngIf="element.ID>0" class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Ghi nhận</label>
                                                    <input [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                        style="text-align: right;"
                                                        (ngModelChange)="DateLoHangNhatKyNgayGhiNhan(element,$event)"
                                                        [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                        class="form-control" autocomplete="off">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Người phụ trách</label>
                                                    <input type="text" placeholder="Người phụ trách"
                                                        name="NguoiPhuTrach{{element.ID}}"
                                                        [(ngModel)]="element.NguoiPhuTrach" class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Cơ sở đóng gói</label>
                                                    <input type="text" placeholder="Cơ sở đóng gói"
                                                        name="CoSoDongGoiName{{element.ID}}"
                                                        [(ngModel)]="element.CoSoDongGoiName" class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Mã số Cơ sở đóng gói</label>
                                                    <input type="text" placeholder="Mã số Cơ sở đóng gói"
                                                        name="CoSoDongGoiMaSo{{element.ID}}"
                                                        [(ngModel)]="element.CoSoDongGoiMaSo" class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Vùng trồng</label>
                                                    <input type="text" placeholder="Vùng trồng"
                                                        name="VungTrongName{{element.ID}}"
                                                        [(ngModel)]="element.VungTrongName" class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Mã số Vùng trồng</label>
                                                    <input type="text" placeholder="Mã số Vùng trồng"
                                                        name="VungTrongMaSo{{element.ID}}"
                                                        [(ngModel)]="element.VungTrongMaSo" class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Vật tư</label>
                                                    <input type="text" placeholder="Vật tư"
                                                        name="VatTuName{{element.ID}}" [(ngModel)]="element.VatTuName"
                                                        class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Đơn vị tính</label>
                                                    <input type="text" placeholder="Đơn vị tính"
                                                        name="VatTuDonViTinh{{element.ID}}"
                                                        [(ngModel)]="element.VatTuDonViTinh" class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Số lượng/liều lượng</label>
                                                    <input type="number" placeholder="0"
                                                        name="VatTuSoLuong{{element.ID}}"
                                                        [(ngModel)]="element.VatTuSoLuong" class="form-control">
                                                </div>

                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Mô tả ngắn hoạt động</label>
                                                    <input type="text" placeholder="Mô tả ngắn hoạt động"
                                                        name="Note{{element.ID}}" [(ngModel)]="element.Note"
                                                        class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <div class="form-check form-check-inline mb-6">
                                                        <input type="checkbox" class="form-check-input" name="Active"
                                                            [(ngModel)]="LoHangService.FormData.Active" />
                                                        <label class="form-check-label" for="Active">Hoàn
                                                            thành</label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Tài liệu đính kèm</label>
                                                    <br />
                                                    <input type="file"
                                                        (change)="ChangeFileNameLoHangNhatKy(element, $event.target.files)">
                                                    <br />
                                                    <br />
                                                    <img src="{{element.FileName}}" class="img-fluid rounded"
                                                        style="max-width: 200px;">
                                                </div>
                                                <div class="row gx-4">
                                                    <div class="col-lg-4 col-sm-12 col-12">
                                                        <a class="btn btn-info" style="width: 100%" title="Lưu"
                                                            (click)="LoHangNhatKySave(element)"><i
                                                                class="bi bi-sd-card"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-4 col-sm-12 col-12">
                                                        <a style="width: 100%;" class="btn btn-info" title="Copy"
                                                            (click)="LoHangNhatKyCopy(element)" *ngIf="element.ID>0"><i
                                                                class="bi bi-subtract"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-4 col-sm-12 col-12">
                                                        <a class="btn btn-danger" style="width: 100%" title="Xóa"
                                                            (click)="LoHangNhatKyDelete(element)"
                                                            *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                        </a>
                                                    </div>
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="LoHangNhatKyService.DisplayColumnsMobile">
                                    </tr>
                                    <tr mat-row *matRowDef="let row;columns: LoHangNhatKyService.DisplayColumnsMobile">
                                    </tr>
                                </table>
                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                    #LoHangNhatKyPaginator="matPaginator"
                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                    [showFirstLastButtons]></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-primary">
                        <div class="card-header">
                            <h5 class="card-title text-white bg-primary">Bước 03: Sinh vật gây hại
                            </h5>
                        </div>
                        <div class="card-body bg-primary">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #LoHangTapTinDinhKemSort="matSort"
                                        [dataSource]="LoHangTapTinDinhKemService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="DanhMucTapTinDinhKemPhanLoaiID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân loại
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Tiêu đề</label>
                                                        <input class="form-control" type="text" placeholder="Tiêu đề"
                                                            name="Name{{element.ID}}" [(ngModel)]="element.Name">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ghi chú</label>
                                                        <input class="form-control" type="text" placeholder="Ghi chú"
                                                            name="Note{{element.ID}}" [(ngModel)]="element.Note">
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="NgayCap">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ghi nhận</label>
                                                        <input [ngModel]="element.NgayCap | date:'yyyy-MM-dd'"
                                                            style="text-align: right;"
                                                            (ngModelChange)="DateLoHangTapTinDinhKemNgayCap(element, $event)"
                                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                            class="form-control" autocomplete="off">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Tài liệu đính kèm</label>
                                                        <br />
                                                        <input type="file" style="width: 300px;"
                                                            (change)="ChangeFileNameLoHangTapTinDinhKem($event.target.files)">
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a class="btn btn-info" style="width: 100%;"
                                                            (click)="LoHangTapTinDinhKemSave(element)"><i
                                                                class="bi bi-sd-card"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a class="btn btn-danger" style="width: 100%;"
                                                            (click)="LoHangTapTinDinhKemDelete(element)"
                                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a class="btn btn-success" style="width: 100%;"
                                                            href="{{element.FileName}}" target="_blank"
                                                            *ngIf="element.ID>0 && element.FileName"><i
                                                                class="bi bi bi-download"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="LoHangTapTinDinhKemService.DisplayColumns001">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: LoHangTapTinDinhKemService.DisplayColumns001;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #LoHangTapTinDinhKemPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                        [pageSize]="1000" [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #LoHangTapTinDinhKemSort="matSort"
                                        [dataSource]="LoHangTapTinDinhKemService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Tiêu đề</label>
                                                        <input class="form-control" type="text" placeholder="Tiêu đề"
                                                            name="Name{{element.ID}}" [(ngModel)]="element.Name">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ghi chú</label>
                                                        <input class="form-control" type="text" placeholder="Ghi chú"
                                                            name="Note{{element.ID}}" [(ngModel)]="element.Note">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ghi nhận</label>
                                                        <input [ngModel]="element.NgayCap | date:'yyyy-MM-dd'"
                                                            style="text-align: right;"
                                                            (ngModelChange)="DateLoHangTapTinDinhKemNgayCap(element, $event)"
                                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                            class="form-control" autocomplete="off">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Tài liệu đính kèm</label>
                                                        <br />
                                                        <input type="file" style="width: 100px;"
                                                            (change)="ChangeFileNameLoHangTapTinDinhKem($event.target.files)">
                                                    </div>
                                                    <div class="row gx-4">
                                                        <div class="col-lg-4 col-sm-4 col-4">
                                                            <a class="btn btn-info" style="width: 100%;"
                                                                (click)="LoHangTapTinDinhKemSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>
                                                        </div>
                                                        <div class="col-lg-4 col-sm-4 col-4">
                                                            <a class="btn btn-danger" style="width: 100%;"
                                                                (click)="LoHangTapTinDinhKemDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </div>
                                                        <div class="col-lg-4 col-sm-4 col-4">
                                                            <a class="btn btn-success" style="width: 100%;"
                                                                href="{{element.FileName}}" target="_blank"
                                                                *ngIf="element.ID>0 && element.FileName"><i
                                                                    class="bi bi bi-download"></i>
                                                            </a>
                                                        </div>
                                                        <br />
                                                        <br />
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="LoHangTapTinDinhKemService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: LoHangTapTinDinhKemService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #LoHangTapTinDinhKemPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                        [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="LoHangService.IsShowLoading"></app-loading>