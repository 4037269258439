<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-PlanThamDinhCompaniesQuaHan" data-bs-toggle="tab"
                                    href="#PlanThamDinhCompaniesQuaHan" role="tab"
                                    aria-controls="PlanThamDinhCompaniesQuaHan" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px">Đơn vị thẩm định ATTP</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="PlanThamDinhCompaniesQuaHan" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Quận
                                            huyện</label>
                                        <select class="form-select"
                                            [(ngModel)]="PlanThamDinhCompaniesService.BaseParameter.DistrictDataID">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of DistrictDataService.List;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input [(ngModel)]="PlanThamDinhCompaniesService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="PlanThamDinhCompaniesSearch()" />
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="PlanThamDinhCompaniesSearch()"><i class="bi bi-search"></i></a>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <a style="width: 100%;" class="btn btn-success" title="Tải về"
                                            (click)="ExportThamDinhAnToanThucPhamDownload()"><i class="bi bi-cloud-download"></i></a>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <a style="width: 100%;" class="btn btn-success" title="Lên kế hoạch Thẩm định"
                                            (click)="PlanThamDinhAdd(0)">Lên kế
                                            hoạch Thẩm định</a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">
                                            Danh sách
                                            <span
                                                *ngIf="PlanThamDinhCompaniesService.List001">({{PlanThamDinhCompaniesService.List001.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #PlanThamDinhCompaniesSort="matSort"
                                                    [dataSource]="PlanThamDinhCompaniesService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Chọn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active" />
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="CompanyInfoName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Đơn vị Tổ
                                                            chức
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer" *ngIf="element.SortOrder>=15"
                                                                title="{{element.CompanyInfoName}}" class="link-success"
                                                                (click)="CompanyInfoAdd(element.CompanyInfoID)"><b>{{element.CompanyInfoName}}</b></a>
                                                            <a style="cursor: pointer"
                                                                *ngIf="element.SortOrder>1 && element.SortOrder<15"
                                                                title="{{element.CompanyInfoName}}" class="link-warning"
                                                                (click)="CompanyInfoAdd(element.CompanyInfoID)"><b>{{element.CompanyInfoName}}</b></a>
                                                            <a style="cursor: pointer" *ngIf="element.SortOrder<1"
                                                                title="{{element.CompanyInfoName}}" class="link-danger"
                                                                (click)="CompanyInfoAdd(element.CompanyInfoID)"><b>{{element.CompanyInfoName}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayGhiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Thẩm định
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            <a style="cursor: pointer" *ngIf="element.SortOrder>=15"
                                                                title="{{element.NgayGhiNhan}}" class="link-success"
                                                                (click)="PlanThamDinhCompaniesAdd(element.ID)"><b>{{element.NgayGhiNhan
                                                                    | date:'dd/MM/yyyy'}}</b></a>
                                                            <a style="cursor: pointer"
                                                                *ngIf="element.SortOrder>1 && element.SortOrder<15"
                                                                title="{{element.NgayGhiNhan}}" class="link-warning"
                                                                (click)="PlanThamDinhCompaniesAdd(element.ID)"><b>{{element.NgayGhiNhan
                                                                    | date:'dd/MM/yyyy'}}</b></a>
                                                            <a style="cursor: pointer" *ngIf="element.SortOrder<1"
                                                                title="{{element.NgayGhiNhan}}" class="link-danger"
                                                                (click)="PlanThamDinhCompaniesAdd(element.ID)"><b>{{element.NgayGhiNhan
                                                                    | date:'dd/MM/yyyy'}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHetHan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Hết hạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            <p class="link-success" *ngIf="element.SortOrder>=15">
                                                                {{element.NgayHetHan
                                                                |
                                                                date:'dd/MM/yyyy'}}</p>
                                                            <p class="link-warning"
                                                                *ngIf="element.SortOrder>1 && element.SortOrder<15">
                                                                {{element.NgayHetHan
                                                                |
                                                                date:'dd/MM/yyyy'}}</p>
                                                            <p class="link-danger" *ngIf="element.SortOrder<1">
                                                                {{element.NgayHetHan
                                                                |
                                                                date:'dd/MM/yyyy'}}</p>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SortOrder">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Số ngày đến hạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            <p class="link-success" *ngIf="element.SortOrder>=15">
                                                                {{element.SortOrder}}</p>
                                                            <p class="link-warning"
                                                                *ngIf="element.SortOrder>1 && element.SortOrder<15">
                                                                {{element.SortOrder}}</p>
                                                            <p class="link-danger" *ngIf="element.SortOrder<1">
                                                                {{element.SortOrder}}</p>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucATTPXepLoaiName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Xếp loại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: center;">
                                                            <p class="link-success" *ngIf="element.SortOrder>=15">
                                                                {{element.DanhMucATTPXepLoaiName}}</p>
                                                            <p class="link-warning"
                                                                *ngIf="element.SortOrder>1 && element.SortOrder<15">
                                                                {{element.DanhMucATTPXepLoaiName}}</p>
                                                            <p class="link-danger" *ngIf="element.SortOrder<1">
                                                                {{element.DanhMucATTPXepLoaiName}}</p>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="PlanThamDinhCompaniesService.DisplayColumns013">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: PlanThamDinhCompaniesService.DisplayColumns013">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #PlanThamDinhCompaniesPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>

                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #PlanThamDinhCompaniesSort="matSort"
                                                    [dataSource]="PlanThamDinhCompaniesService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a style="cursor: pointer"
                                                                        *ngIf="element.SortOrder>=15"
                                                                        title="{{element.CompanyInfoName}}"
                                                                        class="link-success"
                                                                        (click)="CompanyInfoAdd(element.CompanyInfoID)"><b>{{element.CompanyInfoName}}</b></a>
                                                                    <a style="cursor: pointer"
                                                                        *ngIf="element.SortOrder>1 && element.SortOrder<15"
                                                                        title="{{element.CompanyInfoName}}"
                                                                        class="link-warning"
                                                                        (click)="CompanyInfoAdd(element.CompanyInfoID)"><b>{{element.CompanyInfoName}}</b></a>
                                                                    <a style="cursor: pointer"
                                                                        *ngIf="element.SortOrder<1"
                                                                        title="{{element.CompanyInfoName}}"
                                                                        class="link-danger"
                                                                        (click)="CompanyInfoAdd(element.CompanyInfoID)"><b>{{element.CompanyInfoName}}</b></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a style="cursor: pointer"
                                                                        *ngIf="element.SortOrder>=15"
                                                                        title="{{element.NgayGhiNhan}}"
                                                                        class="link-success"
                                                                        (click)="PlanThamDinhCompaniesAdd(element.ID)"><b>{{element.NgayGhiNhan
                                                                            | date:'dd/MM/yyyy'}}</b></a>
                                                                    <a style="cursor: pointer"
                                                                        *ngIf="element.SortOrder>1 && element.SortOrder<15"
                                                                        title="{{element.NgayGhiNhan}}"
                                                                        class="link-warning"
                                                                        (click)="PlanThamDinhCompaniesAdd(element.ID)"><b>{{element.NgayGhiNhan
                                                                            | date:'dd/MM/yyyy'}}</b></a>
                                                                    <a style="cursor: pointer"
                                                                        *ngIf="element.SortOrder<1"
                                                                        title="{{element.NgayGhiNhan}}"
                                                                        class="link-danger"
                                                                        (click)="PlanThamDinhCompaniesAdd(element.ID)"><b>{{element.NgayGhiNhan
                                                                            | date:'dd/MM/yyyy'}}</b></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <p class="link-success"
                                                                        *ngIf="element.SortOrder>=15">
                                                                        {{element.NgayHetHan
                                                                        |
                                                                        date:'dd/MM/yyyy'}}</p>
                                                                    <p class="link-warning"
                                                                        *ngIf="element.SortOrder>1 && element.SortOrder<15">
                                                                        {{element.NgayHetHan
                                                                        |
                                                                        date:'dd/MM/yyyy'}}</p>
                                                                    <p class="link-danger" *ngIf="element.SortOrder<1">
                                                                        {{element.NgayHetHan
                                                                        |
                                                                        date:'dd/MM/yyyy'}}</p>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <p class="link-success"
                                                                        *ngIf="element.SortOrder>=15">
                                                                        {{element.SortOrder}}</p>
                                                                    <p class="link-warning"
                                                                        *ngIf="element.SortOrder>1 && element.SortOrder<15">
                                                                        {{element.SortOrder}}</p>
                                                                    <p class="link-danger" *ngIf="element.SortOrder<1">
                                                                        {{element.SortOrder}}</p>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <p class="link-success"
                                                                        *ngIf="element.SortOrder>=15">
                                                                        {{element.DanhMucATTPXepLoaiName}}</p>
                                                                    <p class="link-warning"
                                                                        *ngIf="element.SortOrder>1 && element.SortOrder<15">
                                                                        {{element.DanhMucATTPXepLoaiName}}</p>
                                                                    <p class="link-danger" *ngIf="element.SortOrder<1">
                                                                        {{element.DanhMucATTPXepLoaiName}}</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="PlanThamDinhCompaniesService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: PlanThamDinhCompaniesService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #PlanThamDinhCompaniesPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="PlanThamDinhCompaniesService.IsShowLoading"></app-loading>