<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-CompanyLakeDetail" data-bs-toggle="tab" href="#CompanyLakeDetail"
                role="tab" aria-controls="CompanyLakeDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Trụ sở và Ao hồ</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="CompanyLakeDetail" role="tabpanel">
            <div class="row">
                <div class="col-lg-4 col-sm-12 col-12">                    
                    <a style="width: 100%;" title="Xem bản đồ" (click)="MapLoad()" class="btn btn-success"><i
                            class="bi bi-pin-map"></i> Xem bản đồ</a>
                </div>                
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="map" #map style="height: 600px; width: 100%;">
                        <div style="z-index: 1000; position: absolute; top: 0;">
                            <img src="{{domainURL}}assets/image/vungtrong.png" />
                        </div>
                        <div
                            style="z-index: 1000; position: absolute; bottom: 0; width: 100%; height: 20px; background-color: #ffffff;">
                        </div>
                    </div>
                </div>
            </div>           
        </div>
    </div>
</div>
<app-loading *ngIf="CompanyInfoService.IsShowLoading"></app-loading>