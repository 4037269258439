import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TemPhieu } from './TemPhieu.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class TemPhieuService extends BaseService{

    DisplayColumns001: string[] = ['STT', 'FileName', 'ID', 'MaSo', 'URL', 'SoLanKiemTra'];
        
    List: TemPhieu[] | undefined;
    ListFilter: TemPhieu[] | undefined;
    FormData!: TemPhieu;
    

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "TemPhieu";
    }

    GetByParentID_MaSoAsync() {
        let url = this.APIURL + this.Controller + '/GetByParentID_MaSoAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
}

