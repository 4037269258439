<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-PhanAnhDetail" data-bs-toggle="tab" href="#PhanAnhDetail" role="tab"
                aria-controls="PhanAnhDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Phản ánh</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="PhanAnhDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="PhanAnhSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="PhanAnhService.FormData.Active" />
                        <label class="form-check-label" for="Active">Kích hoạt</label>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu đề [{{PhanAnhService.FormData.ID}}]</label>
                        <input name="Name" [(ngModel)]="PhanAnhService.FormData.Name" placeholder="Tiêu đề" type="text"
                            disabled class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đơn vị Tổ chức</label>
                        <input name="Display" [(ngModel)]="PhanAnhService.FormData.Display" placeholder="Đơn vị Tổ chức"
                            disabled type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi nhận</label>
                        <input [ngModel]="PhanAnhService.FormData.NgayGhiNhan | date:'yyyy-MM-dd HH:mm:ss'" disabled
                            (ngModelChange)="DateNgayGhiNhan($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                            type="datetime" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Câu hỏi</label>
                        <textarea name="HTMLContent" [(ngModel)]="PhanAnhService.FormData.HTMLContent"
                            placeholder="Câu hỏi" disabled type="text" class="form-control" rows="4"></textarea>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">                   
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Trả lời</label>
                        <textarea name="Description" [(ngModel)]="PhanAnhService.FormData.Description"
                            placeholder="Trả lời" type="text" class="form-control" rows="13"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="PhanAnhService.IsShowLoading"></app-loading>