import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';

import { CompanyInfoThuMuaNongSan } from 'src/app/shared/CompanyInfoThuMuaNongSan.model';
import { CompanyInfoThuMuaNongSanService } from 'src/app/shared/CompanyInfoThuMuaNongSan.service';

@Component({
  selector: 'app-company-info-thu-mua-nong-san-detail',
  templateUrl: './company-info-thu-mua-nong-san-detail.component.html',
  styleUrls: ['./company-info-thu-mua-nong-san-detail.component.css']
})
export class CompanyInfoThuMuaNongSanDetailComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CompanyInfoThuMuaNongSanDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,

    public CompanyInfoThuMuaNongSanService: CompanyInfoThuMuaNongSanService,
  ) {
    this.NotificationService.IsSave = false;
  }

  ngOnInit(): void {
  }

  DateNgayGhiNhan(value) {
    this.CompanyInfoThuMuaNongSanService.FormData.NgayGhiNhan = new Date(value);
  }
  
  CompanyInfoThuMuaNongSanSave() {
    this.CompanyInfoThuMuaNongSanService.IsShowLoading = true;
    this.CompanyInfoThuMuaNongSanService.SaveAsync().subscribe(
      res => {
        this.CompanyInfoThuMuaNongSanService.FormData = (res as CompanyInfoThuMuaNongSan);
        this.NotificationService.warn(environment.SaveSuccess);
        this.CompanyInfoThuMuaNongSanService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.CompanyInfoThuMuaNongSanService.IsShowLoading = false;
      }
    );
  } 

  Close() {
    this.dialogRef.close();
  }

}