<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-CompanyExaminationDetail" data-bs-toggle="tab"
                href="#CompanyExaminationDetail" role="tab" aria-controls="CompanyExaminationDetail"
                aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Thông tin</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-CompanyStaffExam" data-bs-toggle="tab" href="#CompanyStaffExam" role="tab"
                aria-controls="CompanyStaffExam" aria-selected="false">
                <span class="badge bg-warning" style="font-size: 16px;">Nhân viên</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-CompanyStaffExamAnswers" data-bs-toggle="tab" href="#CompanyStaffExamAnswers"
                role="tab" aria-controls="CompanyStaffExamAnswers" aria-selected="false">
                <span class="badge bg-primary" style="font-size: 16px;">Trả lời</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="CompanyExaminationDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-5 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <a style="width: 100%; display: none;" title="Lưu thay đổi" (click)="CompanyExaminationSave()"
                            class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu thay đổi</a>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đơn vị Tổ chức</label>
                        <mat-select class="form-control" name="CompanyExaminationService.BaseParameter.ParentID"
                            [(ngModel)]="CompanyExaminationService.FormData.ParentID"
                            (selectionChange)="ThanhVienSearch()">
                            <input placeholder="Tìm..." class="form-control"
                                (keyup)="CompanyInfoFilter($event.target.value)">
                            <mat-option *ngFor="let item of CompanyInfoService.ListFilter" [value]="item.ID">
                                {{item.Name}} - {{item.phone}} - {{item.DKKD}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Nhóm</label>
                        <select class="form-select" name="CauHoiNhomID"
                            [(ngModel)]="CompanyExaminationService.FormData.CauHoiNhomID" (change)="CauHoiATTPSearch()">
                            <option *ngFor="let item of CauHoiNhomService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu đề</label>
                        <input name="Name" [(ngModel)]="CompanyExaminationService.FormData.Name" placeholder="Tiêu đề"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi chú</label>
                        <input name="Note" [(ngModel)]="CompanyExaminationService.FormData.Note" placeholder="Ghi chú"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày ghi nhận</label>
                        <input [ngModel]="CompanyExaminationService.FormData.NgayGhiNhan | date:'yyyy-MM-dd'"
                            (ngModelChange)="DateNgayGhiNhan($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                            type="date" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <div class="form-check form-check-inline mb-6">
                            <input type="checkbox" class="form-check-input" name="Active"
                                [(ngModel)]="CompanyExaminationService.FormData.Active" />
                            <label class="form-check-label" for="Active">Kích hoạt</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 col-sm-12 col-12">
                    <div *ngIf="CompanyExaminationService.FormData.ID>0" class="card mb-4 card-info">
                        <div class="card-header">
                            <h5 class="card-title text-white">Bộ câu hỏi
                                <span
                                    *ngIf="CompanyExaminationQuestionsService.List">({{CompanyExaminationQuestionsService.List.length}}
                                    items)</span>
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #CompanyExaminationQuestionsSort="matSort"
                                        [dataSource]="CompanyExaminationQuestionsService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                STT
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;"
                                                style="text-align: center;">
                                                <div *ngIf="CompanyExaminationQuestionsPaginator">
                                                    {{CompanyExaminationQuestionsPaginator.pageSize *
                                                    CompanyExaminationQuestionsPaginator.pageIndex + i + 1}}
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="CauHoiATTPID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Câu hỏi
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="width: 70%;">
                                                <mat-select class="form-control" name="element.CauHoiATTPID"
                                                    [(ngModel)]="element.CauHoiATTPID">
                                                    <input placeholder="Tìm..." class="form-control"
                                                        (keyup)="CauHoiATTPFilter($event.target.value)">
                                                    <mat-option *ngFor="let item of CauHoiATTPService.ListFilter"
                                                        [value]="item.ID">
                                                        {{item.Name}}
                                                    </mat-option>
                                                </mat-select>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-info" style="display: none;"
                                                    (click)="CompanyExaminationQuestionsSave(element)"><i
                                                        class="bi bi-sd-card"></i>
                                                </a>&nbsp;&nbsp;&nbsp;
                                                <a class="btn btn-danger" style="display: none;"
                                                    (click)="CompanyExaminationQuestionsDelete(element)"
                                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyExaminationQuestionsService.DisplayColumns001">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyExaminationQuestionsService.DisplayColumns001;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #CompanyExaminationQuestionsPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="CompanyExaminationService.FormData.ID>0" class="tab-pane fade" id="CompanyStaffExam"
            role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-11 col-sm-12 col-12">
                    <input [(ngModel)]="CompanyStaffExamService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="CompanyStaffExamSearch()">
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="CompanyStaffExamSearch()"><i
                            class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                </div>
            </div>
            <div class="card mb-4 card-warning">
                <div class="card-header">
                    <h5 class="card-title text-white">Nhân viên
                        <span *ngIf="CompanyStaffExamService.List">({{CompanyStaffExamService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table mat-table matSort #CompanyStaffExamSort="matSort"
                                [dataSource]="CompanyStaffExamService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                        <div *ngIf="CompanyStaffExamPaginator">
                                            {{CompanyStaffExamPaginator.pageSize *
                                            CompanyStaffExamPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ThanhVienID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nhân viên
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <select class="form-select" name="element.ThanhVienID" disabled
                                            [(ngModel)]="element.ThanhVienID">
                                            <option *ngFor="let item of ThanhVienService.List;" [value]="item.ID">
                                                {{item.Name}} - {{item.DienThoai}}</option>
                                        </select>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="point">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Điểm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.point |
                                        number:
                                        '1.0-0'}} / 30
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="display: none;" class="btn btn-info"
                                            (click)="CompanyStaffExamSave(element)"><i class="bi bi-sd-card"></i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a style="display: none;" class="btn btn-danger"
                                            (click)="CompanyStaffExamDelete(element)" *ngIf="element.ID>0"><i
                                                class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="CompanyStaffExamService.DisplayColumns002">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: CompanyStaffExamService.DisplayColumns002;">
                                </tr>
                            </table>
                            <mat-paginator #CompanyStaffExamPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="CompanyExaminationService.FormData.ID>0" class="tab-pane fade" id="CompanyStaffExamAnswers"
            role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <select class="form-select" name="CompanyStaffExamAnswersService.BaseParameter.ThanhVienID"
                        [(ngModel)]="CompanyStaffExamAnswersService.BaseParameter.ThanhVienID">
                        <option *ngFor="let item of ThanhVienService.List;" [value]="item.ID">
                            {{item.Name}} - {{item.DienThoai}}</option>
                    </select>
                </div>
                <div class="col-lg-7 col-sm-12 col-12">
                    <input [(ngModel)]="CompanyStaffExamAnswersService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="CompanyStaffExamAnswersSearch()">
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm"
                        (click)="CompanyStaffExamAnswersSearch()"><i class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                </div>
            </div>
            <div class="card mb-4 card-primary">
                <div class="card-header">
                    <h5 class="card-title text-white bg-primary">Trả lời
                        <span
                            *ngIf="CompanyStaffExamAnswersService.List">({{CompanyStaffExamAnswersService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body bg-primary">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table mat-table matSort #CompanyStaffExamAnswersSort="matSort"
                                [dataSource]="CompanyStaffExamAnswersService.DataSource"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;"
                                        style="text-align: center; width: 10px;">
                                        <div *ngIf="CompanyStaffExamAnswersPaginator">
                                            {{CompanyStaffExamAnswersPaginator.pageSize *
                                            CompanyStaffExamAnswersPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer; white-space: normal;" title="{{element.Name}}"
                                            class="link-primary"
                                            (click)="CauHoiATTPAdd(element.ParentID)"><b>{{element.Name}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Note">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Đáp án</th>
                                    <td mat-cell *matCellDef="let element" style="width: 300px; white-space: normal;">
                                        {{element.Note}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Trả lời</th>
                                    <td mat-cell *matCellDef="let element" style="width: 300px; white-space: normal;">
                                        {{element.Code}}
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="CompanyStaffExamAnswersService.DisplayColumns001">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: CompanyStaffExamAnswersService.DisplayColumns001;">
                                </tr>
                            </table>
                            <mat-paginator #CompanyStaffExamAnswersPaginator="matPaginator"
                                [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="CompanyExaminationService.IsShowLoading"></app-loading>