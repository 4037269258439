import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-co-so-quy-trinh-cap-giay-chung-nhan-attp',
  templateUrl: './co-so-quy-trinh-cap-giay-chung-nhan-attp.component.html',
  styleUrls: ['./co-so-quy-trinh-cap-giay-chung-nhan-attp.component.css']
})
export class CoSoQuyTrinhCapGiayChungNhanATTPComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
