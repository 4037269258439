<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-PlanThamDinhCompanyDocumentDetail" data-bs-toggle="tab"
                href="#PlanThamDinhCompanyDocumentDetail" role="tab" aria-controls="PlanThamDinhCompanyDocumentDetail"
                aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Biểu mẫu (Tập tin đính kèm)</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="PlanThamDinhCompanyDocumentDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h3>{{PlanThamDinhCompanyDocumentService.FormData.Name}}</h3>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="PlanThamDinhCompanyDocumentSave()"
                        class="btn btn-info"><i class="bi bi-sd-card"></i>
                        Lưu thay đổi</a>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <a *ngIf="PlanThamDinhCompanyDocumentService.FormData.ID" style="width: 100%;" title="Copy"
                        (click)="PlanThamDinhCompanyDocumentCopy()" class="btn btn-info"><i class="bi bi-subtract"></i>
                        Copy</a>
                </div>
                <div class="col-lg-2 col-sm-12 col-12">
                    <a style="width: 100%;"
                        *ngIf="PlanThamDinhCompanyDocumentService.FormData.ID > 0 && PlanThamDinhCompanyDocumentService.FormData.FileName"
                        class="btn btn-success" title="In biểu mẫu" (click)="OpenWindowByURL()"><i
                            class="bi bi-printer"></i> In
                    </a>
                </div>
                <div class="col-lg-2 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="IsLamMoi"
                            [(ngModel)]="PlanThamDinhCompanyDocumentService.FormData.IsLamMoi" />
                        <label class="form-check-label" for="IsLamMoi">Bản gốc</label>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="PlanThamDinhCompanyDocumentService.FormData.Active" />
                        <label class="form-check-label" for="Active">Ký thay</label>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu đề</label>
                        <input name="Name" [(ngModel)]="PlanThamDinhCompanyDocumentService.FormData.Name"
                            placeholder="Tiêu đề" type="text" class="form-control">
                    </div>                    
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số văn bản (nếu có)</label>
                        <input name="Description" [(ngModel)]="PlanThamDinhCompanyDocumentService.FormData.Description"
                            placeholder="Số văn bản" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đại diện Cơ sở (nếu có)</label>
                        <input name="Display" [(ngModel)]="PlanThamDinhCompanyDocumentService.FormData.Display"
                            placeholder="Đại diện Cơ sở (nếu có)" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày Ghi nhận</label>
                        <input [ngModel]="PlanThamDinhCompanyDocumentService.FormData.NgayGhiNhan | date:'yyyy-MM-dd'"
                            (ngModelChange)="DateNgayGhiNhan($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                            type="date" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Người ký 01 (Đối với Biểu mẫu có 01 người ký)</label>
                        <select class="form-select" name="PlanThamDinhCompanyDocumentService.FormData.ThanhVienID"
                            [(ngModel)]="PlanThamDinhCompanyDocumentService.FormData.ThanhVienID">
                            <option *ngFor="let item of ThanhVienService.List;" [value]="item.ID">
                                {{item.Name}} - {{item.DanhMucChucDanhName}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Người ký 02 (Đối với Biểu mẫu có 02 người ký)</label>
                        <select class="form-select" name="PlanThamDinhCompanyDocumentService.FormData.ThanhVienID001"
                            [(ngModel)]="PlanThamDinhCompanyDocumentService.FormData.ThanhVienID001">
                            <option *ngFor="let item of ThanhVienService.List;" [value]="item.ID">
                                {{item.Name}} - {{item.DanhMucChucDanhName}}</option>
                        </select>
                    </div>                    
                </div>
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
                <ck-editor [config]="{'height': 500, 'width': 1400, 'versionCheck': false}" name="HTMLContent"
                    [(ngModel)]="PlanThamDinhCompanyDocumentService.FormData.HTMLContent" skin="moono-lisa"
                    language="en" [fullPage]="true"></ck-editor>

            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="PlanThamDinhCompanyDocumentService.IsShowLoading"></app-loading>