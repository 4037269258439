<div class="app-body">
  <div class="row">
    <div class="col-xxl-12">
      <div class="card" style="margin-left: 1px">
        <div class="card-body">
          <div class="custom-tabs-container">
            <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="nav-link active" id="tab-DocumentTemplate" data-bs-toggle="tab" href="#DocumentTemplate"
                  role="tab" aria-controls="DocumentTemplate" aria-selected="true">
                  <span class="badge bg-info" style="font-size: 16px">Biểu mẫu</span>
                </a>
              </li>
            </ul>
            <div class="tab-content" id="customTabContent">
              <div class="tab-pane fade show active" id="DocumentTemplate" role="tabpanel">
                <div class="row gx-4">
                  <div class="col-lg-5 col-sm-12 col-12">
                    <select class="form-select" [(ngModel)]="DocumentTemplateService.BaseParameter.ParentID">
                      <option [value]="0">
                        Tất cả</option>
                      <option *ngFor="let item of PlanTypeService.List;" [value]="item.ID">
                        {{item.Name}}</option>
                    </select>
                  </div>
                  <div class="col-lg-5 col-sm-12 col-12">
                    <input [(ngModel)]="DocumentTemplateService.BaseParameter.SearchString" placeholder="Tìm ..."
                      class="form-control" (keyup.enter)="DocumentTemplateSearch()" />
                  </div>
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="row gx-4">
                      <div class="col-lg-6 col-sm-6 col-6">
                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                          (click)="DocumentTemplateSearch()"><i class="bi bi-search"></i></a>
                      </div>
                      <div class="col-lg-6 col-sm-6 col-6">
                        <a style="width: 100%;" class="btn btn-success" title="Thêm mới"
                          (click)="DocumentTemplateAdd(0)"><i class="bi bi-plus"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-4 card-info">
                  <div class="card-header">
                    <h5 class="card-title text-white">
                      Danh sách
                      <span *ngIf="DocumentTemplateService.List">({{ DocumentTemplateService.List.length }}
                        items)</span>
                    </h5>
                  </div>
                  <div class="card-body">
                    <div class="table-outer">
                      <div class="table-responsive">
                        <table *ngIf="!NotificationService.IsMobile" mat-table matSort #DocumentTemplateSort="matSort"
                          [dataSource]="DocumentTemplateService.DataSource" class="table table-striped m-0">
                          <ng-container matColumnDef="STT">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              STT
                            </th>
                            <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                              <div *ngIf="DocumentTemplatePaginator">
                                {{
                                DocumentTemplatePaginator.pageSize *
                                DocumentTemplatePaginator.pageIndex +
                                i +
                                1
                                }}
                              </div>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="ID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              ID
                            </th>
                            <td mat-cell *matCellDef="let element">
                              {{element.ID}}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="SortOrder">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Sắp xếp
                            </th>
                            <td mat-cell *matCellDef="let element">
                              <input name="SortOrder{{element.ID}}" [(ngModel)]="element.SortOrder"
                                placeholder="Sắp xếp" type="number" class="form-control" style="text-align: right;">
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="ParentID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Loại biểu mẫu
                            </th>
                            <td mat-cell *matCellDef="let element">
                              <div *ngFor="let item of PlanTypeService.List;">
                                <div *ngIf="item.ID==element.ParentID">
                                  {{item.Name}}
                                </div>
                              </div>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Tiêu đề
                            </th>
                            <td mat-cell *matCellDef="let element">
                              <a style="cursor: pointer;" title="{{element.Name}}" class="link-primary"
                                (click)="DocumentTemplateAdd(element.ID)"><b>{{element.Name}}</b></a>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="Save">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                            <td mat-cell *matCellDef="let element">
                              <!-- <a class="btn btn-danger" (click)="DocumentTemplateDelete(element)"
                                *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                              </a>
                              &nbsp;&nbsp;&nbsp; -->
                              <a *ngIf="element.ID > 0" class="btn btn-info" (click)="DocumentTemplateSave(element)"><i
                                  class="bi bi-sd-card" title="Lưu thay đổi"></i></a>
                              &nbsp;&nbsp;&nbsp;
                              <a *ngIf="element.ID > 0" class="btn btn-success"
                                (click)="DocumentTemplateCopy(element)" title="Copy"><i class="bi bi-subtract"></i></a>
                              &nbsp;&nbsp;&nbsp;
                              <a *ngIf="element.ID > 0 && element.FileName" class="btn btn-success" title="Tải về"
                                href="{{element.FileName}}" target="_blank"><i class="bi bi-cloud-download"></i>
                              </a>
                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="
                              DocumentTemplateService.DisplayColumns007
                            "></tr>
                          <tr mat-row *matRowDef="
                              let row;
                              columns: DocumentTemplateService.DisplayColumns007
                            "></tr>
                        </table>
                        <mat-paginator *ngIf="!NotificationService.IsMobile" #DocumentTemplatePaginator="matPaginator"
                          [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                          [showFirstLastButtons]></mat-paginator>

                        <table *ngIf="NotificationService.IsMobile" mat-table matSort #DocumentTemplateSort="matSort"
                          [dataSource]="DocumentTemplateService.DataSource" class="table table-striped m-0">
                          <ng-container matColumnDef="STT">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Danh sách
                            </th>
                            <td mat-cell *matCellDef="let element; index as i">
                              <div class="row gx-4">
                                <div class="col-lg-12 col-sm-12 col-12">
                                  <label>Tiêu đề</label>
                                  <br />
                                  <a style="cursor: pointer;" title="{{element.Name}}" class="link-primary"
                                    (click)="DocumentTemplateAdd(element.ID)"><b>{{element.Name}}</b></a>
                                </div>
                                <div class="row gx-4">
                                  <div class="col-lg-4 col-sm-4 col-4">
                                    <a class="btn btn-danger" style="width: 100%"
                                      (click)="DocumentTemplateDelete(element)" *ngIf="element.ID > 0"><i
                                        class="bi bi-trash"></i>
                                    </a>
                                  </div>
                                  <div class="col-lg-4 col-sm-4 col-4">
                                    <a *ngIf="element.ID > 0 && element.FileName" class="btn btn-success" title="Tải về"
                                      href="{{element.FileName}}" target="_blank"><i class="bi bi-cloud-download"></i>
                                    </a>
                                  </div>
                                  <br />
                                  <br />
                                </div>
                              </div>
                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="
                              DocumentTemplateService.DisplayColumnsMobile
                            "></tr>
                          <tr mat-row *matRowDef="
                              let row;
                              columns: DocumentTemplateService.DisplayColumnsMobile
                            "></tr>
                        </table>
                        <mat-paginator *ngIf="NotificationService.IsMobile" #DocumentTemplatePaginator="matPaginator"
                          [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                          [showFirstLastButtons]></mat-paginator>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loading *ngIf="DocumentTemplateService.IsShowLoading"></app-loading>