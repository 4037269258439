import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { CompanyInfo } from 'src/app/shared/CompanyInfo.model';
import { CompanyInfoService } from 'src/app/shared/CompanyInfo.service';


import { LoHang } from 'src/app/shared/LoHang.model';
import { LoHangService } from 'src/app/shared/LoHang.service';
import { CompanyInfoDetailComponent } from '../company-info-detail/company-info-detail.component';
import { LoHangDetailComponent } from '../lo-hang-detail/lo-hang-detail.component';

@Component({
  selector: 'app-lo-hang',
  templateUrl: './lo-hang.component.html',
  styleUrls: ['./lo-hang.component.css']
})
export class LoHangComponent implements OnInit {

  @ViewChild('LoHangSort') LoHangSort: MatSort;
  @ViewChild('LoHangPaginator') LoHangPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,

    public CompanyInfoService: CompanyInfoService,

    public LoHangService: LoHangService,
  ) {

  }

  ngOnInit(): void {
    this.LoHangSearch();
  }


  
  

  LoHangSearch() {
    this.LoHangService.SearchAllNotEmpty(this.LoHangSort, this.LoHangPaginator);
  }
  LoHangDelete(element: LoHang) {
    this.LoHangService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.LoHangService.ComponentDeleteAllNotEmpty(this.LoHangSort, this.LoHangPaginator));
  }

  LoHangAdd(ID: number) {
    this.LoHangService.BaseParameter.ID = ID;
    this.LoHangService.GetByIDAsync().subscribe(
      res => {
        this.LoHangService.FormData = res as LoHang;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(LoHangDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.LoHangSearch();
        });
      },
      err => {
      }
    );
  }
  CompanyInfoAdd(ID: number) {
    this.LoHangService.IsShowLoading = true;
    this.CompanyInfoService.BaseParameter.ID = ID;
    this.CompanyInfoService.GetByIDAsync().subscribe(
      res => {
        this.CompanyInfoService.FormData = res as CompanyInfo;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(CompanyInfoDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
}
