export class Base {
    ID?: number;
    ParentID?: number;
    CreatedDate?: Date;
    CreatedMembershipID?: number;
    LastUpdatedDate?: Date;
    LastUpdatedMembershipID?: number;
    RowVersion?: number;
    SortOrder?: number;
    Active?: boolean;
    TypeName?: string;
    Name?: string;
    Code?: string;
    Note?: string;
    Display?: string;
    FileName?: string;
    Description?: string;
    HTMLContent?: string;
    DanhMucNgonNguID?: number;
    status_id?: number;
    uid?: string;
    agency_id?: string;
}

