<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-ProductGroupDetail" data-bs-toggle="tab" href="#ProductGroupDetail"
                role="tab" aria-controls="ProductGroupDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Mặt hàng</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="ProductGroupDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="ProductGroupSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="ProductGroupService.FormData.Active" />
                        <label class="form-check-label" for="Active">Kích hoạt</label>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu đề</label>
                        <input name="Name" [(ngModel)]="ProductGroupService.FormData.Name" placeholder="Tiêu đề"
                            type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Phân loại</label>
                        <select class="form-select" name="ParentID" [(ngModel)]="ProductGroupService.FormData.ParentID">
                            <option *ngFor="let item of DanhMucProductGroupService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ProductGroupService.IsShowLoading"></app-loading>