import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

import { LoHang } from 'src/app/shared/TruyXuatNguonGoc/LoHang.model';
import { LoHangService } from 'src/app/shared/TruyXuatNguonGoc/LoHang.service';
import { TruyXuatNguonGocToChuc } from 'src/app/shared/TruyXuatNguonGoc/ToChuc.model';
import { TruyXuatNguonGocToChucService } from 'src/app/shared/TruyXuatNguonGoc/ToChuc.service';
import { DanhMucUngDung } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.model';
import { DanhMucUngDungService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.service';

import { CompanyInfo } from 'src/app/shared/CompanyInfo.model';
import { CompanyInfoService } from 'src/app/shared/CompanyInfo.service';

import { TruyXuatNguonGocLoHangDetailComponent } from '../truy-xuat-nguon-goc-lo-hang-detail/truy-xuat-nguon-goc-lo-hang-detail.component';
import { CompanyInfoDetailComponent } from '../company-info-detail/company-info-detail.component';
import { CoSoTruyXuatNguonGocLoHangDetailComponent } from '../co-so-truy-xuat-nguon-goc-lo-hang-detail/co-so-truy-xuat-nguon-goc-lo-hang-detail.component';

@Component({
  selector: 'app-co-so-truy-xuat-nguon-goc-lo-hang',
  templateUrl: './co-so-truy-xuat-nguon-goc-lo-hang.component.html',
  styleUrls: ['./co-so-truy-xuat-nguon-goc-lo-hang.component.css']
})
export class CoSoTruyXuatNguonGocLoHangComponent implements OnInit {

  @ViewChild('LoHangSort') LoHangSort: MatSort;
  @ViewChild('LoHangPaginator') LoHangPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public ThanhVienService: ThanhVienService,

    public LoHangService: LoHangService,
    public TruyXuatNguonGocToChucService: TruyXuatNguonGocToChucService,
    public DanhMucUngDungService: DanhMucUngDungService,
    public CompanyInfoService: CompanyInfoService,
  ) { }

  ngOnInit(): void {
    this.ThanhVienService.GetLogin();
    this.DanhMucUngDungService.GetLogin();
    this.LoHangSearch();
  }
  DateBatDau(value) {
    this.LoHangService.BaseParameter.BatDau = new Date(value);
  }
  DateKetThuc(value) {
    this.LoHangService.BaseParameter.KetThuc = new Date(value);
  }
  LoHangSearch() {
    this.LoHangService.IsShowLoading = true;
    this.TruyXuatNguonGocToChucService.BaseParameter.DanhMucUngDungID = Number(localStorage.getItem(environment.TruyXuatNguonGocDanhMucUngDungID));
    this.TruyXuatNguonGocToChucService.BaseParameter.IDToken = this.ThanhVienService.FormDataLogin.CompanyInfoID;    
    this.TruyXuatNguonGocToChucService.GetByDanhMucUngDungIDAndIDTokenAsync().subscribe(
      res => {
        this.TruyXuatNguonGocToChucService.FormData = (res as TruyXuatNguonGocToChuc);

        
            this.LoHangService.IsShowLoading = true;
            this.LoHangService.BaseParameter.ParentID = this.TruyXuatNguonGocToChucService.FormData.ID;
            this.LoHangService.BaseParameter.ParentName = this.TruyXuatNguonGocToChucService.FormData.Name;
            this.LoHangService.BaseParameter.DanhMucUngDungID = Number(localStorage.getItem(environment.TruyXuatNguonGocDanhMucUngDungID));
            this.LoHangService.BaseParameter.Active = true;
            this.LoHangService.GetBySearchString_BatDau_KetThuc_Active_ParentIDToListAsync().subscribe(
              res => {
                this.LoHangService.List = (res as LoHang[]).sort((a, b) => (a.SortOrder < b.SortOrder ? 1 : -1));
                this.LoHangService.DataSource = new MatTableDataSource(this.LoHangService.List);
                this.LoHangService.DataSource.sort = this.LoHangSort;
                this.LoHangService.DataSource.paginator = this.LoHangPaginator;
                this.LoHangService.IsShowLoading = false;
              },
              err => {
                this.LoHangService.IsShowLoading = false;
              }
            );
        
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangCopy(element: LoHang) {
    this.LoHangService.IsShowLoading = true;
    this.LoHangService.FormData = element;
    this.LoHangService.CopyAsync().subscribe(
      res => {
        this.LoHangSearch();
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangDelete(element: LoHang) {
    if (confirm(environment.DeleteConfirm)) {
      this.LoHangService.IsShowLoading = true;
      this.LoHangService.BaseParameter.ID = element.ID;
      this.LoHangService.RemoveAsync().subscribe(
        res => {
          this.LoHangSearch();
          this.LoHangService.IsShowLoading = false;
        },
        err => {
          this.LoHangService.IsShowLoading = false;
        }
      );
    }
  }
  LoHangAdd(ID: number) {
    this.LoHangService.IsShowLoading = true;
    this.LoHangService.BaseParameter.ID = ID;
    this.LoHangService.GetByIDAsync().subscribe(
      res => {
        this.LoHangService.FormData = res as LoHang;
        this.LoHangService.FormData.Active = true;
        this.LoHangService.FormData.ParentID = this.LoHangService.BaseParameter.ParentID;
        this.LoHangService.FormData.ParentName = this.LoHangService.BaseParameter.ParentName;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(CoSoTruyXuatNguonGocLoHangDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.LoHangSearch();
        });
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  ToChucAdd(ID: number) {
  }
}