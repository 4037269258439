<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-PlanThamDinhCompaniesLichSu" data-bs-toggle="tab"
                                    href="#PlanThamDinhCompaniesLichSu" role="tab"
                                    aria-controls="PlanThamDinhCompaniesLichSu" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px">An toàn thực
                                        phẩm</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="PlanThamDinhCompaniesLichSu" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Đơn vị Tổ
                                            chức</label>
                                        <mat-select class="form-control"
                                            name="PlanThamDinhCompaniesService.BaseParameter.CompanyInfoID"
                                            [(ngModel)]="PlanThamDinhCompaniesService.BaseParameter.CompanyInfoID">
                                            <input placeholder="Tìm..." class="form-control"
                                                (keyup)="CompanyInfoFilter($event.target.value)">
                                            <mat-option *ngFor="let item of CompanyInfoService.ListFilter"
                                                [value]="item.ID">
                                                {{item.Name}} - {{item.phone}} - {{item.DKKD}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-5 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input [(ngModel)]="PlanThamDinhCompaniesService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="PlanThamDinhCompaniesSearch()" />
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="PlanThamDinhCompaniesSearch()"><i class="bi bi-search"></i></a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">
                                            Danh sách
                                            <span
                                                *ngIf="PlanThamDinhCompaniesService.ListQuaHan">({{PlanThamDinhCompaniesService.ListQuaHan.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #PlanThamDinhCompaniesSort="matSort"
                                                    [dataSource]="PlanThamDinhCompaniesService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i"
                                                            style="text-align: center">
                                                            <div *ngIf="PlanThamDinhCompaniesPaginator">
                                                                {{
                                                                PlanThamDinhCompaniesPaginator.pageSize *
                                                                PlanThamDinhCompaniesPaginator.pageIndex +
                                                                i +
                                                                1
                                                                }}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="CompanyInfoName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Đơn vị Tổ
                                                            chức
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer"
                                                                title="{{element.CompanyInfoName}}" class="link-primary"
                                                                (click)="CompanyInfoAdd(element.ID)"><b>{{element.CompanyInfoName}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucATTPXepLoaiName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Xếp loại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element" style="text-align: center;">
                                                            {{element.DanhMucATTPXepLoaiName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayGhiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Thẩm định
                                                        </th>
                                                        <td mat-cell *matCellDef="let element" style="text-align: right;">
                                                            <a style="cursor: pointer" title="{{element.NgayGhiNhan}}"
                                                                class="link-primary"
                                                                (click)="PlanThamDinhCompaniesAdd(element.ID)"><b>{{element.NgayGhiNhan
                                                                    | date:'dd/MM/yyyy'}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHetHan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Hết hạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element" style="text-align: right;">
                                                            {{element.NgayHetHan | date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="PlanThamDinhCompaniesService.DisplayColumns004">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: PlanThamDinhCompaniesService.DisplayColumns004">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #PlanThamDinhCompaniesPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>

                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #PlanThamDinhCompaniesSort="matSort"
                                                    [dataSource]="PlanThamDinhCompaniesService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="PlanThamDinhCompaniesService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: PlanThamDinhCompaniesService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #PlanThamDinhCompaniesPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="PlanThamDinhCompaniesService.IsShowLoading"></app-loading>