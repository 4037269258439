<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-Report" data-bs-toggle="tab" href="#Report"
                                    role="tab" aria-controls="Report" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px">
                                        Báo cáo Tổng hợp</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="Report" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input [(ngModel)]="ReportService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="Report0009Search()" />
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <div class="row gx-4">
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                                    (click)="Report0009Search()"><i class="bi bi-search"></i></a>
                                            </div>
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a style="width: 100%;" class="btn btn-success" title="Tải về"
                                                    (click)="Report0009Download()"><i
                                                        class="bi bi-cloud-download"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">
                                            Danh sách
                                            <span *ngIf="ReportService.List">({{ ReportService.List.length }}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ReportSort="matSort" [dataSource]="ReportService.DataSource"
                                                    class="table table-striped m-0">                                                   
                                                    <ng-container matColumnDef="Nam">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-col" style="background-color: black;">
                                                            <span style="color: #ffffff;">Năm</span>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element" class="sticky-col">
                                                            <b>{{element.Nam}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe001">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tổng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            <p class="link-success"><b>{{element.ThongKe001 |
                                                                number:
                                                                '1.0-0'}}</b></p>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe002">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tổng - A
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe002 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe003">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tổng - B
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe003 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe004">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tổng - C
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe004 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe005">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Cấp mới - Tổng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe005 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe006">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Cấp mới - A
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe006 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe007">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Cấp mới - B
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe004 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe008">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Cấp mới - C
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe005 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe009">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Định kỳ - Tổng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe006 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe010">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Định kỳ - A
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe010 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe011">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Định kỳ - B
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe011 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe012">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Định kỳ - C
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe012 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe101">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Nông sản - Tổng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            <p class="link-success"><b>{{element.ThongKe101 |
                                                                number:
                                                                '1.0-0'}}</b></p>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe102">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tổng - A
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe102 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe103">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tổng - B
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe103 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe104">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tổng - C
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe104 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe105">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Cấp mới - Tổng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe105 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe106">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Cấp mới - A
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe106 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe107">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Cấp mới - B
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe107 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe108">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Cấp mới - C
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe108 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe109">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Định kỳ - Tổng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe109 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe110">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Định kỳ - A
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe110 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe111">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Định kỳ - B
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe111 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe112">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Định kỳ - C
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe112 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe201">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Thủy sản - Tổng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            <p class="link-success"><b>{{element.ThongKe201 |
                                                                number:
                                                                '1.0-0'}}</b></p>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe202">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tổng - A
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe202 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe203">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tổng - B
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe203 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe204">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tổng - C
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe204 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe205">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Cấp mới - Tổng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe205 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe206">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Cấp mới - A
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe206 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe207">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Cấp mới - B
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe207 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe208">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Cấp mới - C
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe208 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe209">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Định kỳ - Tổng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe209 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe210">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Định kỳ - A
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe210 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe211">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Định kỳ - B
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe211 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe212">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Định kỳ - C
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe212 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.DisplayColumns0009"></tr>
                                                    <tr mat-row
                                                        *matRowDef="let row;columns: ReportService.DisplayColumns0009">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ReportPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                                    [showFirstLastButtons]></mat-paginator>

                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ReportSort="matSort" [dataSource]="ReportService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tiêu đề</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Tiêu đề"
                                                                        name="Name{{ element.ID }}"
                                                                        [(ngModel)]="element.Name" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.DisplayColumnsMobile"></tr>
                                                    <tr mat-row
                                                        *matRowDef="let row;columns: ReportService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ReportPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ReportService.IsShowLoading"></app-loading>