import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoHangLoTrinh } from 'src/app/shared/LoHangLoTrinh.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class LoHangLoTrinhService extends BaseService{

    List: LoHangLoTrinh[] | undefined;
    ListFilter: LoHangLoTrinh[] | undefined;
    FormData!: LoHangLoTrinh;

    DisplayColumns001: string[] = ['STT', 'Name', 'KinhDo', 'ViDo', 'Note', 'Save'];

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "LoHangLoTrinh";
    }
}

