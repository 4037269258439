import { Base } from "./Base.model";

export class CompanyStaffExamAnswers extends Base{

question_id?: string;
answer_id?: number;
CompanyExaminationQuestionsID?: number;
CauHoiATTPID?: number;
CauHoiATTPQuestionsID?: number;
NgayGhiNhan?: Date;
}


