<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-PlanThamDinhCompanyDocumentDetailDetail" data-bs-toggle="tab"
                href="#PlanThamDinhCompanyDocumentDetailDetail" role="tab"
                aria-controls="PlanThamDinhCompanyDocumentDetailDetail" aria-selected="true">
                <span class="badge bg-success" style="font-size: 16px;">Biểu mẫu</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-PlanThamDinhCompaniesDetail" data-bs-toggle="tab"
                href="#PlanThamDinhCompaniesDetail" role="tab" aria-controls="PlanThamDinhCompaniesDetail"
                aria-selected="false">
                <span class="badge bg-info" style="font-size: 16px;">Danh sách</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade" id="PlanThamDinhCompaniesDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-11 col-sm-12 col-12">
                    <input [(ngModel)]="PlanThamDinhCompaniesService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="PlanThamDinhCompaniesSearch()">
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm"
                        (click)="PlanThamDinhCompaniesSearch()"><i class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                </div>
            </div>
            <div class="card mb-4 card-info">
                <div class="card-header">
                    <h5 class="card-title text-white">Danh sách
                        <span *ngIf="PlanThamDinhCompaniesService.List">({{PlanThamDinhCompaniesService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                #PlanThamDinhCompaniesSort="matSort"
                                [dataSource]="PlanThamDinhCompaniesService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <a style="width: 100%;" class="btn btn-success"
                                                    (click)="PlanThamDinhCompaniesAdd(element.ID)"
                                                    *ngIf="element.ID > 0"><i class="bi bi-pencil-square"></i> Chi tiết</a>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <a style="width: 100%;" class="btn btn-success"
                                                    (click)="MailAdd(element.ID)" *ngIf="element.ID > 0"><i
                                                        class="bi bi-envelope-check"></i> Kết quả
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                        <div *ngIf="PlanThamDinhCompaniesPaginator">
                                            {{PlanThamDinhCompaniesPaginator.pageSize *
                                            PlanThamDinhCompaniesPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="CompanyInfoName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Thẩm định Đơn vị Tổ chức
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="row gx-4" *ngIf="element.CompanyInfo">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <a style="cursor: pointer;" title="{{element.CompanyInfoName}}"
                                                    class="link-primary"
                                                    (click)="CompanyInfoAdd(element.CompanyInfoID)"><b>{{element.CompanyInfoName}}</b></a>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Điện thoại: </label>
                                                <label class="form-label">{{element.CompanyInfo.phone}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Bản đồ: </label>
                                                <label class="form-label"><a style="cursor: pointer;" title="Xem bản đồ"
                                                        class="link-primary"
                                                        (click)="CoSoMapDetailAdd(element.CompanyInfoID)"><b>Xem</b></a></label>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="NgayGhiNhan">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Ngày thẩm định
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Ngày ghi nhận: </label>
                                                <label class="form-label">{{element.NgayGhiNhan |
                                                    date:'dd/MM/yyyy'}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Loại Thẩm định: </label>
                                                <label class="form-label">{{element.DanhMucATTPLoaiHoSoName}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Xếp loại: </label>
                                                <label class="form-label">{{element.DanhMucATTPXepLoaiName}}</label>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Địa chỉ
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="row gx-4" *ngIf="element.CompanyInfo">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Quận huyện: </label>
                                                <label
                                                    class="form-label">{{element.CompanyInfo.DistrictDataName}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Xã phường: </label>
                                                <label class="form-label">{{element.CompanyInfo.WardDataName}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Địa chỉ: </label>
                                                <label class="form-label">{{element.CompanyInfo.address}}</label>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="PlanThamDinhCompaniesService.DisplayColumns014">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: PlanThamDinhCompaniesService.DisplayColumns014;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                #PlanThamDinhCompaniesPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="1000" [showFirstLastButtons]></mat-paginator>
                            <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                #PlanThamDinhCompaniesSort="matSort"
                                [dataSource]="PlanThamDinhCompaniesService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Ngày thẩm định</label>
                                                <label class="form-label">{{element.NgayGhiNhan | date:'dd/MM/yyyy'}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <a style="cursor: pointer;" title="{{element.CompanyInfoName}}"
                                                    class="link-primary"
                                                    (click)="CompanyInfoAdd(element.CompanyInfoID)"><b>{{element.CompanyInfoName}}</b></a>
                                            </div>
                                            <div class="row gx-4">
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <a style="width: 100%;" class="btn btn-success"
                                                        (click)="PlanThamDinhCompaniesAdd(element.ID)"
                                                        *ngIf="element.ID>0"><i class="bi bi-pencil-square"></i> Chi tiết
                                                    </a>
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <a style="width: 100%;" class="btn btn-success"
                                                        (click)="MailAdd(element.ID)" *ngIf="element.ID > 0"><i
                                                            class="bi bi-envelope-check"></i> Kết quả
                                                    </a>
                                                </div>                                               
                                                <br />
                                                <br />
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="PlanThamDinhCompaniesService.DisplayColumnsMobile">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: PlanThamDinhCompaniesService.DisplayColumnsMobile;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile"
                                #PlanThamDinhCompaniesPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade show active" id="PlanThamDinhCompanyDocumentDetailDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-11 col-sm-12 col-12">
                    <input [(ngModel)]="PlanThamDinhCompanyDocumentService.BaseParameter.SearchString"
                        placeholder="Tìm ..." class="form-control" (keyup.enter)="PlanThamDinhCompanyDocumentSearch()">
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm"
                        (click)="PlanThamDinhCompanyDocumentSearch()"><i class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                </div>
            </div>
            <div class="card mb-4 card-success">
                <div class="card-header">
                    <h5 class="card-title text-white">
                        Danh sách
                        <span
                            *ngIf="PlanThamDinhCompanyDocumentService.ListPlanThamDinh">({{PlanThamDinhCompanyDocumentService.ListPlanThamDinh.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                #PlanThamDinhCompanyDocumentSort="matSort"
                                [dataSource]="PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinh"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                                        <div *ngIf="PlanThamDinhCompanyDocumentPaginator">
                                            {{PlanThamDinhCompanyDocumentPaginator.pageSize *
                                            PlanThamDinhCompanyDocumentPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.ID }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Biểu mẫu
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer" title="{{element.Name}}" class="link-primary"
                                            (click)="PlanThamDinhCompanyDocumentAdd(element.ID)"><b>{{element.Name}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="NgayGhiNhan">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Ngày ghi nhận
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.NgayGhiNhan | date:'dd/MM/yyyy'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ThanhVienName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Người ký
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ThanhVienName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                    <td mat-cell *matCellDef="let element">
                                        <a *ngIf="element.ID > 0 && element.FileName" class="btn btn-success"
                                            title="Tải về" href="{{element.FileName}}" target="_blank"><i
                                                class="bi bi-cloud-download"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row
                                    *matHeaderRowDef="PlanThamDinhCompanyDocumentService.DisplayColumns006"></tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: PlanThamDinhCompanyDocumentService.DisplayColumns006">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                #PlanThamDinhCompanyDocumentPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                [showFirstLastButtons]></mat-paginator>

                            <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                #PlanThamDinhCompanyDocumentSort="matSort"
                                [dataSource]="PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinh"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <a style="cursor: pointer" title="{{element.Name}}" class="link-primary"
                                                    (click)="PlanThamDinhCompanyDocumentAdd(element.ID)"><b>{{element.Name}}</b></a>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Ghi nhận: </label>
                                                <label class="form-label">{{element.NgayGhiNhan |
                                                    date:'dd/MM/yyyy'}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Người ký: </label>
                                                <label class="form-label">{{element.ThanhVienName}}</label>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row
                                    *matHeaderRowDef="PlanThamDinhCompanyDocumentService.DisplayColumnsMobile"></tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: PlanThamDinhCompanyDocumentService.DisplayColumnsMobile">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile"
                                #PlanThamDinhCompanyDocumentPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="PlanThamDinhCompaniesService.IsShowLoading"></app-loading>