<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-ProductInfoDetail" data-bs-toggle="tab" href="#ProductInfoDetail"
                role="tab" aria-controls="ProductInfoDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Tự công bố sản phẩm</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="ProductInfoDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="ProductInfoSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <h4>Bước 01: Nhập Thông tin</h4>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Sản phẩm</label>
                                <input name="Name" [(ngModel)]="ProductInfoService.FormData.Name" placeholder="Sản phẩm"
                                    type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Ngày công bố</label>
                                <input [ngModel]="ProductInfoService.FormData.NgayGhiNhan | date:'yyyy-MM-dd'"
                                    (ngModelChange)="DateNgayGhiNhan($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                                    type="date" class="form-control" autocomplete="off">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Tải lên Tập tin công bố</label>
                                <br />
                                <input type="file" multiple (change)="ChangeFileName($event.target.files)">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Mã hồ sơ</label>
                                <input disabled name="Code" [(ngModel)]="ProductInfoService.FormData.Code"
                                    placeholder="Mã hồ sơ" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Xếp loại</label>
                                <input disabled name="DanhMucATTPXepLoaiName"
                                    [(ngModel)]="ProductInfoService.FormData.DanhMucATTPXepLoaiName"
                                    placeholder="Xếp loại" type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-primary">
                        <div class="card-header">
                            <h5 class="card-title text-white bg-primary">Bước 02: Tập tin đính kèm
                            </h5>
                        </div>
                        <div class="card-body bg-primary">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #ProductInfoDocumentsSort="matSort"
                                        [dataSource]="ProductInfoDocumentsService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="LastUpdatedDate">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Ngày Ghi nhận
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <b>{{element.LastUpdatedDate | date:'dd/MM/yyyy HH:mm:ss'}}</b>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="width: 600px;">
                                                <input class="form-control" type="text" placeholder="Tiêu đề"
                                                    name="Name{{element.ID}}" [(ngModel)]="element.Name" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="FileName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải về hoặc
                                                tải lên
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a *ngIf="element.ID > 0 && element.TypeName" class="link-primary"
                                                    href="{{element.TypeName}}"><b>Tải về</b></a>
                                                <input *ngIf="element.ID == 0" type="file"
                                                    (change)="ChangeFileName($event.target.files)">
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-info" (click)="ProductInfoDocumentsSave(element)"><i
                                                        class="bi bi-sd-card"></i> </a>&nbsp;&nbsp;&nbsp;
                                                <a class="btn btn-danger" (click)="ProductInfoDocumentsDelete(element)"
                                                    *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                </a>&nbsp;&nbsp;&nbsp;
                                                <a *ngIf="element.ID > 0 && element.FileName" class="btn btn-success"
                                                    title="Tải về" href="{{element.FileName}}" target="_blank"><i
                                                        class="bi bi-cloud-download"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="ProductInfoDocumentsService.DisplayColumns001">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: ProductInfoDocumentsService.DisplayColumns001;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #ProductInfoDocumentsPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                        [pageSize]="1000" [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #ProductInfoDocumentsSort="matSort"
                                        [dataSource]="ProductInfoDocumentsService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Tiêu đề</label>
                                                        <input class="form-control" type="text" placeholder="Tiêu đề"
                                                            name="Name{{element.ID}}" [(ngModel)]="element.Name" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Tải về hoặc
                                                            tải lên</label>
                                                        <br />
                                                        <a *ngIf="element.ID > 0 && element.TypeName"
                                                            class="link-primary" href="{{element.TypeName}}"><b>Tải về
                                                                biểu mẫu</b></a>
                                                        <input *ngIf="element.ID == 0" type="file"
                                                            (change)="ChangeFileName($event.target.files)">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a class="btn btn-info" style="width: 100%"
                                                            (click)="ProductInfoDocumentsSave(element)"><i
                                                                class="bi bi-sd-card"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a class="btn btn-danger" style="width: 100%"
                                                            (click)="ProductInfoDocumentsDelete(element)"
                                                            *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a *ngIf="element.ID > 0 && element.FileName"
                                                            class="btn btn-success" style="width: 100%" title="Tải về"
                                                            href="{{element.FileName}}" target="_blank"><i
                                                                class="bi bi-cloud-download"></i>
                                                        </a>
                                                    </div>
                                                    <br />
                                                    <br />
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="ProductInfoDocumentsService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: ProductInfoDocumentsService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #ProductInfoDocumentsPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                        [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ProductInfoService.IsShowLoading"></app-loading>