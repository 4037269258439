<div class="app-body">
    <div class="mx-n4 p-4 bg-light mb-4">
        <div class="row gx-4">
            <div class="col-sm-3 col-12">
                <div class="card my-2 card-danger">
                    <div class="card-header">
                        <h5 class="card-title text-white">Huyện</h5>
                    </div>
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class=" text-white">
                                <h2 class="display-6 mb-2 fw-semibold">{{ReportService.FormData.ThongKe001 |
                                    number:'1.0-0'}}</h2>
                                <h5 class="mb-3 fw-light opacity-75">Huyện có mã số cơ sở nuôi.</h5>
                                <div class="progress small">
                                    <div class="progress-bar bg-white" role="progressbar" style="width: 75%"
                                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div class="ms-3 graph-mini" id="option1"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-12">
                <div class="card my-2 card-success">
                    <div class="card-header">
                        <h5 class="card-title text-white">Cơ sở nuôi</h5>
                    </div>
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class=" text-white">
                                <h2 class="display-6 mb-2 fw-semibold">{{ReportService.FormData.ThongKe002 |
                                    number:'1.0-0'}}</h2>
                                <h5 class="mb-3 fw-light opacity-75">Mã số cơ sở nuôi lũy kế.</h5>
                                <div class="progress small">
                                    <div class="progress-bar bg-white" role="progressbar" style="width: 75%"
                                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div class="ms-3 graph-mini" id="option2"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-12">
                <div class="card my-2 card-warning">
                    <div class="card-header">
                        <h5 class="card-title text-white">Tổng Diện tích</h5>
                    </div>
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class="text-white">
                                <h2 class="display-6 mb-2 fw-semibold">{{ReportService.FormData.ThongKe003 |
                                    number:'1.0-0'}}</h2>
                                <h5 class="mb-3 fw-light opacity-75">Diện tích đăng ký (ha).</h5>
                                <div class="progress small">
                                    <div class="progress-bar bg-white" role="progressbar" style="width: 75%"
                                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div class="ms-3 graph-mini" id="option3"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-12">
                <div class="card my-2 card-primary">
                    <div class="card-header">
                        <h5 class="card-title text-white bg-primary">Diện tích nuôi</h5>
                    </div>
                    <div class="card-body bg-primary">
                        <div class="d-flex justify-content-between">
                            <div class="text-white">
                                <h2 class="display-6 mb-2 fw-semibold">{{ReportService.FormData.ThongKe004 |
                                    number:'1.0-0'}}</h2>
                                <h5 class="mb-3 fw-light opacity-75">Diện tích nuôi trồng (ha).</h5>
                                <div class="progress small">
                                    <div class="progress-bar bg-white" role="progressbar" style="width: 75%"
                                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div class="ms-3 graph-mini" id="option3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12" style="padding: 10px;">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <a style="cursor: pointer;" class="link-primary" (click)="ChartReport0016Click()">
                    <h4 class="text-center" style="text-transform: uppercase;">Tổng hợp số liệu mã số cơ sở nuôi</h4>
                </a>
                <canvas id="ChartReport0016" style="font-size: 30px;" baseChart
                    [datasets]="ChartDataReport0016" [labels]="ChartLabelsReport0016"
                    [chartType]="ChartTypeReport0016" [colors]="ChartColorsReport0016"
                    [options]="ChartOptionsReport0016">
                </canvas>
            </div>
        </div>
    </div>
    <div class="row gx-4">
        <div class="col-lg-6 col-sm-12 col-12" style="padding: 10px;">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <a style="cursor: pointer;" class="link-primary" (click)="ChartReport0016_001Click()">
                    <h4 class="text-center" style="text-transform: uppercase;">Diện tích (ha)</h4>
                </a>
                <canvas id="ChartReport0016_001" style="font-size: 30px;" baseChart
                    [datasets]="ChartDataReport0016_001" [labels]="ChartLabelsReport0016_001"
                    [chartType]="ChartTypeReport0016_001" [colors]="ChartColorsReport0016_001"
                    [options]="ChartOptionsReport0016_001">
                </canvas>
            </div>
        </div>
        <div class="col-lg-6 col-sm-12 col-12" style="padding: 10px;">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <a style="cursor: pointer;" class="link-primary" (click)="ChartReport0016_002Click()">
                    <h4 class="text-center" style="text-transform: uppercase;">Diện tích nuôi (ha)</h4>
                </a>
                <canvas id="ChartReport0016_002" style="font-size: 30px;" baseChart
                    [datasets]="ChartDataReport0016_002" [labels]="ChartLabelsReport0016_002"
                    [chartType]="ChartTypeReport0016_002" [colors]="ChartColorsReport0016_002"
                    [options]="ChartOptionsReport0016_002">
                </canvas>
            </div>
        </div>
    </div>   
    <div class="row gx-4">
        <div class="col-lg-6 col-sm-12 col-12" style="padding: 10px;">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <a style="cursor: pointer;" class="link-primary" (click)="ChartReport0016_003Click()">
                    <h4 class="text-center" style="text-transform: uppercase;">Cơ sở nuôi</h4>
                </a>
                <canvas id="ChartReport0016_003" style="font-size: 30px;" baseChart
                    [datasets]="ChartDataReport0016_003" [labels]="ChartLabelsReport0016_003"
                    [chartType]="ChartTypeReport0016_003" [colors]="ChartColorsReport0016_003"
                    [options]="ChartOptionsReport0016_003">
                </canvas>
            </div>
        </div>       
    </div>   
</div>
<app-loading *ngIf="ReportService.IsShowLoading"></app-loading>