import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets, Chart, ChartConfiguration, ChartData } from 'chart.js';
import { Color, Label, SingleDataSet, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Report } from 'src/app/shared/Report.model';
import { ReportService } from 'src/app/shared/Report.service';
import { NamThang } from 'src/app/shared/NamThang.model';

import { LoHang } from 'src/app/shared/TruyXuatNguonGoc/LoHang.model';
import { LoHangService } from 'src/app/shared/TruyXuatNguonGoc/LoHang.service';
import { TemPhieuKhoiTao } from 'src/app/shared/TruyXuatNguonGoc/TemPhieuKhoiTao.model';
import { TemPhieuKhoiTaoService } from 'src/app/shared/TruyXuatNguonGoc/TemPhieuKhoiTao.service';
import { DanhMucUngDung } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.model';
import { DanhMucUngDungService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.service';

import { CompanyInfoVungTrong } from 'src/app/shared/CompanyInfoVungTrong.model';
import { CompanyInfoVungTrongService } from 'src/app/shared/CompanyInfoVungTrong.service';

import { CompanyInfoDonViDongGoi } from 'src/app/shared/CompanyInfoDonViDongGoi.model';
import { CompanyInfoDonViDongGoiService } from 'src/app/shared/CompanyInfoDonViDongGoi.service';

@Component({
  selector: 'app-dashboard003',
  templateUrl: './dashboard003.component.html',
  styleUrls: ['./dashboard003.component.css']
})
export class Dashboard003Component implements OnInit {

  @Output() onDraw = new EventEmitter();

  constructor(
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    public ReportService: ReportService,

    public LoHangService: LoHangService,
    public TemPhieuKhoiTaoService: TemPhieuKhoiTaoService,
    public DanhMucUngDungService: DanhMucUngDungService,

    public CompanyInfoVungTrongService: CompanyInfoVungTrongService,
    public CompanyInfoDonViDongGoiService: CompanyInfoDonViDongGoiService,


  ) { }


  ngOnInit(): void {
    this.DanhMucUngDungService.GetLogin();
    this.GetData();
  }

  GetData() {
    this.CompanyInfoVungTrongService.GetAllToListAsync().subscribe(
      res => {
        this.ReportService.FormData.ThongKe001 = (res as CompanyInfoVungTrong[]).length;
      },
      err => {
      }
    );
    this.CompanyInfoDonViDongGoiService.GetAllToListAsync().subscribe(
      res => {
        this.ReportService.FormData.ThongKe002 = (res as CompanyInfoDonViDongGoi[]).length;
      },
      err => {
      }
    );
    this.LoHangService.BaseParameter.DanhMucUngDungID = Number(localStorage.getItem(environment.TruyXuatNguonGocDanhMucUngDungID));
    this.LoHangService.GetByDanhMucUngDungIDToListAsync().subscribe(
      res => {
        this.ReportService.FormData.ThongKe003 = (res as LoHang[]).length;
      },
      err => {
      }
    );
    this.TemPhieuKhoiTaoService.BaseParameter.DanhMucUngDungID = Number(localStorage.getItem(environment.TruyXuatNguonGocDanhMucUngDungID));
    this.TemPhieuKhoiTaoService.GetByDanhMucUngDungIDToListAsync().subscribe(
      res => {
        this.ReportService.FormData.ThongKe004 = (res as TemPhieuKhoiTao[]).length;
      },
      err => {
      }
    );
    this.Report0032();
  }
  Report0032() {
    this.ReportService.IsShowLoading = true;    
    this.ReportService.Report0032ToListAsync().subscribe(
      res => {
        this.ReportService.List = (res as Report[]);

        let labelArray001 = [];
        let dataArray001 = [];
        let dataArray002 = [];
        

        for (let i = 0; i < this.ReportService.List.length; i++) {
          labelArray001.push(this.ReportService.List[i].DistrictDataName);
          dataArray001.push(this.ReportService.List[i].ThongKe001);
          dataArray002.push(this.ReportService.List[i].ThongKe002);          
        }
        let label001: string = 'Vùng trồng';
        let label002: string = 'Cơ sở đóng gói';        
        this.ChartLabelsReport0032 = labelArray001;
        this.ChartDataReport0032 = [
          { data: dataArray001, label: label001, stack: 'b', fill: false, yAxisID: 'B', },
          { data: dataArray002, label: label002, stack: 'a', fill: false, yAxisID: 'A', },        
        ];     

        this.ReportService.IsShowLoading = false;
      },
      err => {
        this.ReportService.IsShowLoading = false;
      }
    );
  }

  ChartReport0032Click() {
    let canvas = <HTMLCanvasElement>document.getElementById('ChartReport0032');
    var MIME_TYPE = "image/png";
    let imgURL = canvas.toDataURL(MIME_TYPE);
    var dlLink = document.createElement('a');
    dlLink.download = "Tổng hợp số liệu Vùng trồng và Cơ sở đóng gói";
    dlLink.href = imgURL;
    dlLink.dataset.downloadurl = [MIME_TYPE, dlLink.download, dlLink.href].join(':');
    document.body.appendChild(dlLink);
    dlLink.click();
    document.body.removeChild(dlLink);
  }

  public ChartOptionsReport0032: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'bottom'
    },
    animation: {
      duration: 1,
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.fillStyle = "rgba(0, 0, 0, 1)";
        ctx.textBaseline = 'bottom';
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);

          });
        });
      }
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return Number(tooltipItem.yLabel).toFixed(0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "." + c : c;
          });
        }
      }
    },
    scales: {
      yAxes: [
        {
          id: 'A',
          position: 'left',
        }, {
          id: 'B',
          position: 'right',
        }
      ]
    },
  };
  public ChartColorsReport0032: Color[] = [
  ]
  public ChartLabelsReport0032: Label[] = [];
  public ChartTypeReport0032: ChartType = 'bar';
  public ChartLegendReport0032 = true;
  public ChartPluginsReport0032 = [];

  public ChartDataReport0032: ChartDataSets[] = [
  ];

  public ChartOptionsReport0032_001: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'right'
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index];
          var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return label + '';
        }
      }
    }
  };

}
