<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThanhVien" data-bs-toggle="tab" href="#ThanhVien"
                                    role="tab" aria-controls="ThanhVien" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Lãnh đạo và chuyên viên</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThanhVien" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <select class="form-select"
                                            [(ngModel)]="ThanhVienService.BaseParameter.StateAgencyID">                                           
                                            <option *ngFor="let item of StateAgencyService.List;"
                                                [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <input [(ngModel)]="ThanhVienService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ThanhVienSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-6 col-12">
                                        <div class="row gx-4">
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                                    (click)="ThanhVienSearch()"><i class="bi bi-search"></i></a>
                                            </div>
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a style="width: 100%;" class="btn btn-success" title="Thêm mới"
                                                    (click)="ThanhVienAdd(0)"><i class="bi bi-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span *ngIf="ThanhVienService.List">({{ThanhVienService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ThanhVienSort="matSort" [dataSource]="ThanhVienService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="ThanhVienPaginator">
                                                                {{ThanhVienPaginator.pageSize *
                                                                ThanhVienPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TaiKhoan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tài khoản
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.TaiKhoan}}"
                                                                class="link-primary"
                                                                (click)="ThanhVienAdd(element.ID)"><b>{{element.TaiKhoan}}</b></a>
                                                        </td>
                                                    </ng-container>   
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Họ tên
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Name}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="StateAgencyName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Cơ quan
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.StateAgencyName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="AgencyDepartmentName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phòng ban
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.AgencyDepartmentName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucChucDanhName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chức danh
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucChucDanhName}}
                                                        </td>
                                                    </ng-container>                                                                                         
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienService.DisplayColumns003">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienService.DisplayColumns003;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ThanhVienPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ThanhVienSort="matSort" [dataSource]="ThanhVienService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    Họ tên: <label
                                                                        class="form-label">{{element.Name}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    Tài khoản:
                                                                    <a style="cursor: pointer;"
                                                                        title="{{element.TaiKhoan}}"
                                                                        class="link-primary"
                                                                        (click)="ThanhVienAdd(element.ID)"><b>{{element.TaiKhoan}}</b></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    Điện thoại: <label
                                                                        class="form-label">{{element.DienThoai}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    Email: <label
                                                                        class="form-label">{{element.Email}}</label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ThanhVienPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ThanhVienService.IsShowLoading"></app-loading>
