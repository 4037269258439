import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoHangDiemBan } from 'src/app/shared/LoHangDiemBan.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class LoHangDiemBanService extends BaseService{

    List: LoHangDiemBan[] | undefined;
    ListFilter: LoHangDiemBan[] | undefined;
    FormData!: LoHangDiemBan;

    DisplayColumns001: string[] = ['STT', 'Name', 'DienThoai' , 'DiaChi' , 'KinhDo', 'ViDo', 'Note', 'Save'];

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "LoHangDiemBan";
    }
}

