<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-PhanAnh" data-bs-toggle="tab" href="#PhanAnh"
                                    role="tab" aria-controls="PhanAnh" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Phản ánh</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="PhanAnh" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-11 col-sm-12 col-12">
                                        <input [(ngModel)]="PhanAnhService.BaseParameter.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="PhanAnhSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <div>
                                            <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                                (click)="PhanAnhSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Phản ánh
                                            <span *ngIf="PhanAnhService.List">({{PhanAnhService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #PhanAnhSort="matSort" [dataSource]="PhanAnhService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{PhanAnhPaginator.pageSize *
                                                            PhanAnhPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayGhiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.NgayGhiNhan | date:'dd/MM/yyyy HH:mm:ss'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input *ngIf="element.ID == 0" class="form-control"
                                                                type="text" placeholder="Tiêu đề"
                                                                name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name" />
                                                            <div *ngIf="element.ID > 0">
                                                                {{element.Name}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="HTMLContent">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Câu hỏi
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input *ngIf="element.ID == 0" class="form-control"
                                                                type="text" placeholder="Câu hỏi"
                                                                name="HTMLContent{{element.ID}}"
                                                                [(ngModel)]="element.HTMLContent" />
                                                            <div *ngIf="element.ID > 0">
                                                                {{element.HTMLContent}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Description">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Trả lời
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Description}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info" (click)="PhanAnhSave(element)"><i
                                                                    class="bi bi-sd-card"></i> </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger" (click)="PhanAnhDelete(element)"
                                                                *ngIf="element.ID > 0 && !element.Description"><i
                                                                    class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="PhanAnhService.DisplayColumns002">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: PhanAnhService.DisplayColumns002;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #PhanAnhPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                                    [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #PhanAnhSort="matSort" [dataSource]="PhanAnhService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Ghi nhận</label>
                                                                    <label class="form-label">{{element.NgayGhiNhan |
                                                                        date:'dd/MM/yyyy HH:mm:ss'}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Tiêu đề</label>
                                                                    <input *ngIf="element.ID == 0" class="form-control"
                                                                        type="text" placeholder="Tiêu đề"
                                                                        name="Name{{ element.ID }}"
                                                                        [(ngModel)]="element.Name" />
                                                                    <label class="form-label" *ngIf="element.ID > 0">{{
                                                                        element.Name
                                                                        }}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Câu hỏi</label>
                                                                    <input *ngIf="element.ID == 0" class="form-control"
                                                                        type="text" placeholder="Tiêu đề"
                                                                        name="HTMLContent{{ element.ID }}"
                                                                        [(ngModel)]="element.HTMLContent" />
                                                                    <label class="form-label" *ngIf="element.ID > 0">{{
                                                                        element.HTMLContent
                                                                        }}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Trả lời</label>
                                                                    <label class="form-label">{{ element.Description
                                                                        }}</label>
                                                                </div>
                                                                <div class="row gx-4">
                                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                                        <a class="btn btn-info" style="width: 100%"
                                                                            (click)="PhanAnhSave(element)"><i
                                                                                class="bi bi-sd-card"></i>
                                                                        </a>
                                                                    </div>
                                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                                        <a class="btn btn-danger" style="width: 100%"
                                                                            (click)="PhanAnhDelete(element)"
                                                                            *ngIf="element.ID > 0 && !element.Description"><i
                                                                                class="bi bi-trash"></i>
                                                                        </a>
                                                                    </div>
                                                                    <br />
                                                                    <br />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="PhanAnhService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: PhanAnhService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #PhanAnhPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                                    [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="PhanAnhService.IsShowLoading"></app-loading>