<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-KienThucATTPDetail" data-bs-toggle="tab" href="#KienThucATTPDetail"
                role="tab" aria-controls="KienThucATTPDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Bài viết truyền thông</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="KienThucATTPDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-5 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <a style="width: 100%;" title="Lưu thay đổi" (click)="KienThucATTPSave()"
                            class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu thay đổi</a>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Nhóm</label>
                        <select class="form-select" [(ngModel)]="KienThucATTPService.FormData.ParentID">
                            <option *ngFor="let item of CauHoiNhomService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu đề</label>
                        <input name="Name" [(ngModel)]="KienThucATTPService.FormData.Name" placeholder="Tiêu đề"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mô tả</label>
                        <input name="Description" [(ngModel)]="KienThucATTPService.FormData.Description"
                            placeholder="Mô tả" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Slug</label>
                        <input name="Code" [(ngModel)]="KienThucATTPService.FormData.Code" placeholder="Slug"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày ghi nhận</label>
                        <input name="KienThucATTPService.FormData.NgayGhiNhan"
                            [ngModel]="KienThucATTPService.FormData.NgayGhiNhan | date:'yyyy-MM-dd'"
                            (ngModelChange)="DateNgayGhiNhan($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                            type="date" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <div class="form-check form-check-inline mb-6">
                            <input type="checkbox" class="form-check-input" name="Active"
                                [(ngModel)]="KienThucATTPService.FormData.Active" />
                            <label class="form-check-label" for="Active">Kích hoạt</label>
                        </div>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Hình đại diện: </label>
                        <br />
                        <input type="file" (change)="ChangeFileName($event.target.files)">
                        <br />
                        <br />
                        <img src="{{KienThucATTPService.FormData.FileName}}" class="img-fluid rounded">
                    </div>
                </div>
                <div class="col-lg-5 col-sm-12 col-12">
                    <ck-editor [config]="{'height': 500, 'width': 800, 'versionCheck': false}" name="HTMLContent"
                        [(ngModel)]="KienThucATTPService.FormData.HTMLContent" skin="moono-lisa" language="en"
                        [fullPage]="true"></ck-editor>
                </div>
            </div>
            <div *ngIf="KienThucATTPService.FormData.ID>0" class="row gx-4">
                <div class="card mb-4 card-info">
                    <div class="card-header">
                        <h5 class="card-title text-white">
                            Tập tin đính kèm
                            <span *ngIf="KienThucATTPTapTinDinhKemService.List">({{
                                KienThucATTPTapTinDinhKemService.List.length }} items)</span>
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="table-outer">
                            <div class="table-responsive">
                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                    #KienThucATTPTapTinDinhKemSort="matSort"
                                    [dataSource]="KienThucATTPTapTinDinhKemService.DataSource"
                                    class="table table-striped m-0">
                                    <ng-container matColumnDef="FileName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Tập tin đính kèm
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <input *ngIf="element.ID==0" type="file" multiple
                                                (change)="KienThucATTPTapTinDinhKemChangeFileName($event.target.files)">
                                            <input *ngIf="element.ID>0" class="form-control" type="text"
                                                placeholder="FileName" name="FileName{{element.ID}}"
                                                [(ngModel)]="element.FileName">
                                            <br />
                                            <img *ngIf="element.ID>0" width="300" height="200"
                                                src="{{element.FileName}}" class="img-fluid rounded">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Tiêu đề
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <input class="form-control" type="text" placeholder="Tiêu đề"
                                                name="Name{{element.ID}}" [(ngModel)]="element.Name">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Save">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                        <td mat-cell *matCellDef="let element">
                                            <a class="btn btn-info" (click)="KienThucATTPTapTinDinhKemSave(element)"><i
                                                    class="bi bi-sd-card"></i>
                                            </a>&nbsp;&nbsp;&nbsp;
                                            <a class="btn btn-danger" (click)="KienThucATTPTapTinDinhKemDelete(element)"
                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                            </a>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row
                                        *matHeaderRowDef="KienThucATTPTapTinDinhKemService.DisplayColumns001"></tr>
                                    <tr mat-row
                                        *matRowDef="let row; columns: KienThucATTPTapTinDinhKemService.DisplayColumns001">
                                    </tr>
                                </table>
                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                    #KienThucATTPTapTinDinhKemPaginator="matPaginator"
                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                    [showFirstLastButtons]></mat-paginator>

                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                    #KienThucATTPTapTinDinhKemSort="matSort"
                                    [dataSource]="KienThucATTPTapTinDinhKemService.DataSource"
                                    class="table table-striped m-0">
                                    <ng-container matColumnDef="STT">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Danh sách
                                        </th>
                                        <td mat-cell *matCellDef="let element; index as i">
                                            <div class="row gx-4">
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label>Tiêu đề: </label>
                                                    <input class="form-control" type="text" placeholder="Tiêu đề"
                                                        name="Name{{element.ID}}" [(ngModel)]="element.Name">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label>Hình đại diện: </label>
                                                    <input class="form-control" type="text" placeholder="FileName"
                                                        name="FileName{{element.ID}}" [(ngModel)]="element.FileName">
                                                    <br />
                                                    <img src="{{element.FileName}}" class="img-fluid rounded">
                                                </div>
                                                <div class="row gx-4">
                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                        <a class="btn btn-info" style="width: 100%;"
                                                            (click)="KienThucATTPTapTinDinhKemSave(element)"><i
                                                                class="bi bi-sd-card"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                        <a class="btn btn-danger" style="width: 100%;"
                                                            (click)="KienThucATTPTapTinDinhKemDelete(element)"
                                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                        </a>
                                                    </div>
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="
                                    KienThucATTPTapTinDinhKemService.DisplayColumnsMobile
                              "></tr>
                                    <tr mat-row *matRowDef="
                                let row;
                                columns: KienThucATTPTapTinDinhKemService.DisplayColumnsMobile
                              "></tr>
                                </table>
                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                    #KienThucATTPTapTinDinhKemPaginator="matPaginator"
                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                    [showFirstLastButtons]></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="KienThucATTPService.IsShowLoading"></app-loading>