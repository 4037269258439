<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-PlanThamDinhGiamSatDuLuong" data-bs-toggle="tab"
                                    href="#PlanThamDinhGiamSatDuLuong" role="tab"
                                    aria-controls="PlanThamDinhGiamSatDuLuong" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px">Giám sát dư lượng</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="PlanThamDinhGiamSatDuLuong" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">Năm</label>
                                        <input type="number" [(ngModel)]="PlanThamDinhService.BaseParameter.Nam"
                                            class="form-control" style="text-align: right;">
                                    </div>
                                    <div class="col-lg-8 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input [(ngModel)]="PlanThamDinhService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="PlanThamDinhSearch()" />
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <div class="row gx-4">
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                                    (click)="PlanThamDinhSearch()"><i class="bi bi-search"></i></a>
                                            </div>
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a style="width: 100%;" class="btn btn-success" title="Thêm mới"
                                                    (click)="PlanThamDinhAdd(0)"><i class="bi bi-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">
                                            Danh sách
                                            <span *ngIf="PlanThamDinhService.List">({{PlanThamDinhService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #PlanThamDinhSort="matSort"
                                                    [dataSource]="PlanThamDinhService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a style="width: 100%;" class="btn btn-success"
                                                                        (click)="PlanThamDinhAdd(element.ID)"
                                                                        *ngIf="element.ID > 0"><i
                                                                            class="bi bi-people"></i> Hồ sơ
                                                                    </a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a style="width: 100%;" class="btn btn-success"
                                                                        (click)="PlanThamDinhCopy(element)"
                                                                        *ngIf="element.ID > 0"><i
                                                                            class="bi bi-subtract"></i> Copy
                                                                    </a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a style="width: 100%;" class="btn btn-danger"
                                                                        (click)="PlanThamDinhDelete(element)"
                                                                        *ngIf="element.ID > 0"><i
                                                                            class="bi bi-trash"></i> Xóa
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i"
                                                            style="text-align: center">
                                                            <div *ngIf="PlanThamDinhPaginator">
                                                                {{PlanThamDinhPaginator.pageSize *
                                                                PlanThamDinhPaginator.pageIndex + i + 1}}
                                                            </div>
                                                            <div>
                                                                [{{element.ID}}]
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Nam">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Thời gian
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a style="cursor: pointer" title="{{element.Name}}"
                                                                        class="link-primary"
                                                                        (click)="PlanThamDinhAdd(element.ID)"><b>{{element.Name}}</b></a>
                                                                </div>
                                                            </div>
                                                            <div class="row gx-4">
                                                                <div class="col-lg-6 col-sm-12 col-12">
                                                                    Thời gian
                                                                </div>
                                                                <div class="col-lg-6 col-sm-12 col-12">
                                                                    <b>{{element.DanhMucThoiGianLayMauName}}</b>
                                                                </div>
                                                                <div class="col-lg-6 col-sm-12 col-12">
                                                                    Năm
                                                                </div>
                                                                <div class="col-lg-6 col-sm-12 col-12">
                                                                    <b>{{element.Nam}}</b>
                                                                </div>
                                                                <div class="col-lg-6 col-sm-12 col-12">
                                                                    Tháng
                                                                </div>
                                                                <div class="col-lg-6 col-sm-12 col-12">
                                                                    <b>{{element.Thang}}</b>
                                                                </div>
                                                                <div class="col-lg-6 col-sm-12 col-12">
                                                                    Bắt đầu
                                                                </div>
                                                                <div class="col-lg-6 col-sm-12 col-12">
                                                                    <b>{{element.NgayBatDau
                                                                        | date:'dd/MM/yyyy'}}</b>
                                                                </div>
                                                                <div class="col-lg-6 col-sm-12 col-12">
                                                                    Kết thúc
                                                                </div>
                                                                <div class="col-lg-6 col-sm-12 col-12">
                                                                    <b>{{element.NgayKetThuc
                                                                        | date:'dd/MM/yyyy'}}</b>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Kế hoạch + Nội dung thực hiện
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12"
                                                                    *ngFor="let itemPlanThamDinhDanhMucLayMau of element.ListPlanThamDinhDanhMucLayMau;">
                                                                    <b>{{itemPlanThamDinhDanhMucLayMau.DistrictDataName}}</b>
                                                                    -
                                                                    {{itemPlanThamDinhDanhMucLayMau.DanhMucLayMauName}}
                                                                    -
                                                                    {{itemPlanThamDinhDanhMucLayMau.DanhMucLayMauChiTieuName}}
                                                                    -
                                                                    {{itemPlanThamDinhDanhMucLayMau.TypeName}}
                                                                    -
                                                                    <b>{{itemPlanThamDinhDanhMucLayMau.ThanhVienName}}</b>
                                                                    -
                                                                    {{itemPlanThamDinhDanhMucLayMau.CompanyInfoName}}
                                                                    -
                                                                    {{itemPlanThamDinhDanhMucLayMau.CompanyLakeName}}                                                                                                                                 
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="PlanThamDinhService.DisplayColumns008"></tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: PlanThamDinhService.DisplayColumns008">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #PlanThamDinhPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="100"
                                                    [showFirstLastButtons]></mat-paginator>

                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #PlanThamDinhSort="matSort"
                                                    [dataSource]="PlanThamDinhService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="PlanThamDinhService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: PlanThamDinhService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #PlanThamDinhPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="PlanThamDinhService.IsShowLoading"></app-loading>