<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-Report" data-bs-toggle="tab" href="#Report"
                                    role="tab" aria-controls="Report" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px">Thống kê sản lượng</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="Report" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Năm</label>
                                        <select class="form-select" [(ngModel)]="ReportService.BaseParameter.Nam">
                                            <option *ngFor="let item of DownloadService.ListNam001;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Tháng</label>
                                        <select class="form-select" [(ngModel)]="ReportService.BaseParameter.Thang">
                                            <option *ngFor="let item of DownloadService.ListThang001;"
                                                [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-7 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input [(ngModel)]="ReportService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="Report0007Search()" />
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-6 col-6">
                                                <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                                    (click)="Report0007Search()"><i class="bi bi-search"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">
                                            Danh sách
                                            <span *ngIf="ReportService.List">({{ ReportService.List.length }}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ReportSort="matSort" [dataSource]="ReportService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i"
                                                            style="text-align: center">
                                                            <div *ngIf="ReportPaginator">
                                                                {{ReportPaginator.pageSize * ReportPaginator.pageIndex +
                                                                i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="CompanyInfoName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Đơn vị Tổ chức
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer" title="{{element.NgayBatDau}}"
                                                                class="link-primary"
                                                                (click)="RegisterHarvestAdd(element.ID)"><b>{{element.CompanyInfoName}}</b></a>

                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SpeciesName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            NT2MV
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <b>{{element.SpeciesName}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayBatDau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Bắt đầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.NgayBatDau
                                                            | date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayKetThuc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Kết thúc
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.NgayKetThuc
                                                            | date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Giấy chứng nhận xuất xứ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <b>{{element.Code}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayGhiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Ngày cấp
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.NgayGhiNhan
                                                            | date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe001">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Thu hoạch dự kiến (tấn)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe001 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThongKe002">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Thu hoạch theo Giấy chứng nhận (tấn)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThongKe002 |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.DisplayColumns0007"></tr>
                                                    <tr mat-row
                                                        *matRowDef="let row;columns: ReportService.DisplayColumns0007">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ReportPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                                    [showFirstLastButtons]></mat-paginator>

                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ReportSort="matSort" [dataSource]="ReportService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.DisplayColumnsMobile"></tr>
                                                    <tr mat-row
                                                        *matRowDef="let row;columns: ReportService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ReportPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ReportService.IsShowLoading"></app-loading>