import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TruyXuatNguonGocToChuc } from './ToChuc.model';
import { BaseService } from './Base.service';

import { CompanyInfo } from 'src/app/shared/CompanyInfo.model';
import { CompanyInfoService } from 'src/app/shared/CompanyInfo.service';

@Injectable({
    providedIn: 'root'
})
export class TruyXuatNguonGocToChucService extends BaseService {

    DisplayColumns001: string[] = ['STT', 'ID', 'Code', 'Name', 'DienThoai', 'DanhMucUngDungName', 'DanhMucQuocGiaName', 'DanhMucTinhThanhName', 'Active'];
    DisplayColumns002: string[] = ['STT', 'ID', 'Name', 'TemPhieuSoLuong', 'TemPhieuSuDung', 'TemPhieuConLai'];

    List: TruyXuatNguonGocToChuc[] | undefined;
    ListFilter: TruyXuatNguonGocToChuc[] | undefined;
    FormData!: TruyXuatNguonGocToChuc;

    List001: TruyXuatNguonGocToChuc[] | undefined;
    ListFilter001: TruyXuatNguonGocToChuc[] | undefined;

    constructor(

        public httpClient: HttpClient,
        public CompanyInfoService: CompanyInfoService,

    ) {
        super(httpClient);
        this.Controller = "ToChuc";
    }
    Sync(Action: number) {
        this.BaseParameter.DanhMucUngDungID = Number(localStorage.getItem(environment.TruyXuatNguonGocDanhMucUngDungID));
        if (this.BaseParameter.DanhMucUngDungID > 0) {
            if (Action == 0) {
                this.ListSync();
            }
            if (Action == 1) {
                this.GetByDanhMucUngDungIDToListAsync().subscribe(
                    res => {
                        this.List = (res as TruyXuatNguonGocToChuc[]);
                        if (this.List.length == 0) {
                            this.ListSync();
                        }
                    },
                    err => {
                    }
                );
            }
        }
    }
    ListSync() {
        this.CompanyInfoService.BaseParameter.DanhMucUngDungID = Number(localStorage.getItem(environment.TruyXuatNguonGocDanhMucUngDungID));
        if (this.CompanyInfoService.BaseParameter.DanhMucUngDungID > 0) {
            this.CompanyInfoService.GetAllToListAsync().subscribe(
                res => {
                    this.CompanyInfoService.List = (res as CompanyInfo[]);
                    this.List = [];
                    for (let index = 0; index < this.CompanyInfoService.List.length; index++) {
                        let CompanyInfo = this.CompanyInfoService.List[index];
                        let TruyXuatNguonGocToChuc: TruyXuatNguonGocToChuc = {
                            ID: environment.InitializationNumber,
                            Active: CompanyInfo.Active,
                            IDToken: CompanyInfo.ID,
                            Token: localStorage.getItem(environment.TruyXuatNguonGocToKen),
                            DanhMucUngDungID: this.CompanyInfoService.BaseParameter.DanhMucUngDungID,
                            Name: CompanyInfo.Name,
                            Code: CompanyInfo.Code,
                            MaSoThue: CompanyInfo.DKKD,
                            DienThoai: CompanyInfo.phone,
                            Email: CompanyInfo.email,
                            DanhMucTinhThanhName: CompanyInfo.ProvinceDataName,
                            DanhMucQuanHuyenName: CompanyInfo.DistrictDataName,
                            DanhMucXaPhuongName: CompanyInfo.WardDataName,
                        }
                        this.List.push(TruyXuatNguonGocToChuc);
                    }
                    this.CompanyInfoService.List = [];
                    this.SaveListAsync(this.List).subscribe(
                        res => {
                            this.List = [];
                        },
                        err => {
                        }
                    );
                },
                err => {
                }
            );
        }
    }
    GetByDanhMucUngDungIDAndIDTokenAsync() {
        let url = this.APIURL + this.Controller + '/GetByDanhMucUngDungIDAndIDTokenAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
}

