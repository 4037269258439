import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';

import { ProductGroup } from 'src/app/shared/ProductGroup.model';
import { ProductGroupService } from 'src/app/shared/ProductGroup.service';
import { ProductUnit } from 'src/app/shared/ProductUnit.model';
import { ProductUnitService } from 'src/app/shared/ProductUnit.service';


import { DanhMucQuocGia } from 'src/app/shared/DanhMucQuocGia.model';
import { DanhMucQuocGiaService } from 'src/app/shared/DanhMucQuocGia.service';

import { LoHang } from 'src/app/shared/LoHang.model';
import { LoHangService } from 'src/app/shared/LoHang.service';

import { eventNames } from 'process';

import { CompanyGroup } from 'src/app/shared/CompanyGroup.model';
import { CompanyGroupService } from 'src/app/shared/CompanyGroup.service';

import { TapTinDinhKem } from 'src/app/shared/TapTinDinhKem.model';
import { TapTinDinhKemService } from 'src/app/shared/TapTinDinhKem.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

import { LoHangLoTrinh } from 'src/app/shared/LoHangLoTrinh.model';
import { LoHangLoTrinhService } from 'src/app/shared/LoHangLoTrinh.service';

import { LoHangDiemBan } from 'src/app/shared/LoHangDiemBan.model';
import { LoHangDiemBanService } from 'src/app/shared/LoHangDiemBan.service';

import { LoHangNhatKy } from 'src/app/shared/LoHangNhatKy.model';
import { LoHangNhatKyService } from 'src/app/shared/LoHangNhatKy.service';

import { CompanyInfo } from 'src/app/shared/CompanyInfo.model';
import { CompanyInfoService } from 'src/app/shared/CompanyInfo.service';

import { ProductInfo } from 'src/app/shared/ProductInfo.model';
import { ProductInfoService } from 'src/app/shared/ProductInfo.service'

import { DanhMucTapTinDinhKem } from 'src/app/shared/DanhMucTapTinDinhKem.model';
import { DanhMucTapTinDinhKemService } from 'src/app/shared/DanhMucTapTinDinhKem.service';

import { DanhMucLoHangQuyTrinh } from 'src/app/shared/DanhMucLoHangQuyTrinh.model';
import { DanhMucLoHangQuyTrinhService } from 'src/app/shared/DanhMucLoHangQuyTrinh.service';

import { DanhMucLoHangTruyXuatNguonGocPhanLoai } from 'src/app/shared/DanhMucLoHangTruyXuatNguonGocPhanLoai.model';
import { DanhMucLoHangTruyXuatNguonGocPhanLoaiService } from 'src/app/shared/DanhMucLoHangTruyXuatNguonGocPhanLoai.service';

import { DanhMucLoHangTruyXuatNguonGocPhuongThucSanXuat } from 'src/app/shared/DanhMucLoHangTruyXuatNguonGocPhuongThucSanXuat.model';
import { DanhMucLoHangTruyXuatNguonGocPhuongThucSanXuatService } from 'src/app/shared/DanhMucLoHangTruyXuatNguonGocPhuongThucSanXuat.service';

import * as maplibregl from 'maplibre-gl';

@Component({
  selector: 'app-lo-hang-detail',
  templateUrl: './lo-hang-detail.component.html',
  styleUrls: ['./lo-hang-detail.component.css']
})
export class LoHangDetailComponent implements OnInit {

  domainURL: string = environment.DomainURL;

  DanhMucATTPTinhTrangIDDoiThamDinh: number = environment.DanhMucATTPTinhTrangIDDoiThamDinh;
  DanhMucATTPTinhTrangIDTiepNhan: number = environment.DanhMucATTPTinhTrangID;
  DanhMucATTPTinhTrangIDDaPheDuyet: number = environment.DanhMucATTPTinhTrangIDDaPheDuyet;
  DanhMucATTPTinhTrangIDTraHuyHoSo: number = environment.DanhMucATTPTinhTrangIDTraHuyHoSo;

  @ViewChild('TapTinDinhKemSort') TapTinDinhKemSort: MatSort;
  @ViewChild('TapTinDinhKemPaginator') TapTinDinhKemPaginator: MatPaginator;

  @ViewChild('TapTinDinhKemSort002') TapTinDinhKemSort002: MatSort;
  @ViewChild('TapTinDinhKemPaginator002') TapTinDinhKemPaginator002: MatPaginator;


  @ViewChild('LoHangLoTrinhSort') LoHangLoTrinhSort: MatSort;
  @ViewChild('LoHangLoTrinhPaginator') LoHangLoTrinhPaginator: MatPaginator;

  @ViewChild('LoHangDiemBanSort') LoHangDiemBanSort: MatSort;
  @ViewChild('LoHangDiemBanPaginator') LoHangDiemBanPaginator: MatPaginator;
  
  @ViewChild('LoHangNhatKySort') LoHangNhatKySort: MatSort;
  @ViewChild('LoHangNhatKyPaginator') LoHangNhatKyPaginator: MatPaginator;

  
  constructor(
    public dialogRef: MatDialogRef<LoHangDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,

    public ProductGroupService: ProductGroupService,
    public ProductUnitService: ProductUnitService,

    public DanhMucQuocGiaService: DanhMucQuocGiaService,

    public LoHangService: LoHangService,
    public LoHangLoTrinhService: LoHangLoTrinhService,
    public LoHangDiemBanService: LoHangDiemBanService,
    public LoHangNhatKyService: LoHangNhatKyService,

    public CompanyGroupService: CompanyGroupService,

    public TapTinDinhKemService: TapTinDinhKemService,
    public DanhMucTapTinDinhKemService: DanhMucTapTinDinhKemService,
    public DanhMucLoHangQuyTrinhService: DanhMucLoHangQuyTrinhService,
    public DanhMucLoHangTruyXuatNguonGocPhanLoaiService: DanhMucLoHangTruyXuatNguonGocPhanLoaiService,
    public DanhMucLoHangTruyXuatNguonGocPhuongThucSanXuatService: DanhMucLoHangTruyXuatNguonGocPhuongThucSanXuatService,

    public ThanhVienService: ThanhVienService,

    public CompanyInfoService: CompanyInfoService,
    public ProductInfoService: ProductInfoService,

  ) {

  }

  ngOnInit(): void {
    this.ProductGroupSearch();
    this.ProductUnitSearch();
    this.DanhMucQuocGiaSearch();
    this.LoHangService.FormData.DanhMucQuocGiaID = environment.DanhMucQuocGiaIDVietNam;
    this.CompanyGroupSearch();
    this.TapTinDinhKemSearch();
    this.ThanhVienGetLogin();
    this.LoHangLoTrinhSearch();
    this.LoHangNhatKySearch();
    this.LoHangDiemBanSearch();
    this.CompanyInfoSearch();
    this.ProductInfoSearch();
    this.DanhMucTapTinDinhKemSearch();
    this.DanhMucLoHangQuyTrinhSearch();
    this.DanhMucLoHangTruyXuatNguonGocPhanLoaiSearch();
    this.DanhMucLoHangTruyXuatNguonGocPhuongThucSanXuatSearch();
  }

  DateNgaySanXuatDongGoi(value) {
    this.LoHangService.FormData.NgaySanXuatDongGoi = new Date(value);
  }
  DateNgayHetHanSuDung(value) {
    this.LoHangService.FormData.NgayHetHanSuDung = new Date(value);
  }

  ChangeLoHangSoLuongMaTXNG(){
    if(this.LoHangService.FormData.SoLuongMaTXNG > this.LoHangService.FormData.SoLuongSanPham){
      this.LoHangService.FormData.SoLuongMaTXNG = this.LoHangService.FormData.SoLuongSanPham;
    }
  }

  DateLoHangNhatKyNgayBatDau(element: LoHangNhatKy, value) {
    element.NgayBatDau = new Date(value);
  }
  DateLoHangNhatKyNgayKetThuc(element: LoHangNhatKy, value) {
    element.NgayKetThuc = new Date(value);
  }

  DateTapTinDinhKemNgayCap(element: TapTinDinhKem, value) {
    element.NgayCap = new Date(value);
  }
  DateTapTinDinhKemNgayHetHan(element: TapTinDinhKem, value) {
    element.NgayHetHan = new Date(value);
  }
  
  DanhMucLoHangTruyXuatNguonGocPhanLoaiSearch() {
    this.DanhMucLoHangTruyXuatNguonGocPhanLoaiService.ComponentGetAllToListAsync();
  }

  DanhMucLoHangTruyXuatNguonGocPhuongThucSanXuatSearch() {
    this.DanhMucLoHangTruyXuatNguonGocPhuongThucSanXuatService.ComponentGetAllToListAsync();
  }

  DanhMucLoHangQuyTrinhSearch() {
    this.DanhMucLoHangQuyTrinhService.ComponentGetAllToListAsync();
  }
  DanhMucTapTinDinhKemSearch() {
    this.DanhMucTapTinDinhKemService.ComponentGetAllToListAsync();
  }

  ProductInfoChange(){
    this.LoHangService.IsShowLoading = true;
    this.ProductInfoService.BaseParameter.ID = this.LoHangService.FormData.ProductInfoID;
    this.ProductInfoService.GetByIDAsync().subscribe(
      res => {
        this.ProductInfoService.FormData = res as ProductInfo;
        this.LoHangService.FormData.ProductInfoName = this.ProductInfoService.FormData.Name;

        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  ProductInfoSearch() {
    this.ProductInfoService.BaseParameter.ParentID = this.LoHangService.FormData.CompanyInfoID;
    this.ProductInfoService.ComponentGet000ToListAsync();
  }
  ProductInfoFilter(searchString: string) {
    this.ProductInfoService.Filter000(this.LoHangService.FormData.CompanyInfoID,searchString);
  }

  CompanyInfoChange(){
    this.ProductInfoService.FormData = null;
    this.ProductInfoSearch();
  }

  CompanyInfoSearch() {
    this.CompanyInfoService.BaseParameter.ID = this.LoHangService.FormData.CompanyInfoID;
    this.CompanyInfoService.ComponentGet000ToListAsync();
    this.ProductInfoSearch();
  }
  CompanyInfoFilter(searchString: string) {
    this.CompanyInfoService.Filter000(searchString);
  }


  LoHangDiemBanSearch() {
    this.LoHangService.IsShowLoading = true;
    this.LoHangDiemBanService.BaseParameter.SearchString = this.LoHangService.FormData.Code;
    this.LoHangDiemBanService.GetBySearchStringAndEmptyToListAsync().subscribe(
      res => {
        this.LoHangDiemBanService.List = (res as LoHangDiemBan[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.LoHangDiemBanService.DataSource = new MatTableDataSource(this.LoHangDiemBanService.List);
        this.LoHangDiemBanService.DataSource.sort = this.LoHangDiemBanSort;
        this.LoHangDiemBanService.DataSource.paginator = this.LoHangDiemBanPaginator;
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }

  
  LoHangDiemBanSave(element: LoHangDiemBan) {
    this.LoHangService.IsShowLoading = true;
    element.ParentID = this.LoHangService.FormData.ID;
    element.Code = this.LoHangService.FormData.Code;
    this.LoHangDiemBanService.FormData = element;
    this.LoHangDiemBanService.SaveAsync().subscribe(
      res => {
        this.LoHangDiemBanSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangDiemBanDelete(element: LoHangDiemBan) {
    this.LoHangService.IsShowLoading = true;
    this.LoHangDiemBanService.BaseParameter.ID = element.ID;
    this.LoHangDiemBanService.RemoveAsync().subscribe(
      res => {
        this.LoHangDiemBanSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangLoTrinhSearch() {
    this.LoHangService.IsShowLoading = true;
    this.LoHangLoTrinhService.BaseParameter.SearchString = this.LoHangService.FormData.Code;
    this.LoHangLoTrinhService.GetBySearchStringAndEmptyToListAsync().subscribe(
      res => {
        this.LoHangLoTrinhService.List = (res as LoHangLoTrinh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.LoHangLoTrinhService.DataSource = new MatTableDataSource(this.LoHangLoTrinhService.List);
        this.LoHangLoTrinhService.DataSource.sort = this.LoHangLoTrinhSort;
        this.LoHangLoTrinhService.DataSource.paginator = this.LoHangLoTrinhPaginator;
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangLoTrinhSave(element: LoHangLoTrinh) {
    this.LoHangService.IsShowLoading = true;
    element.ParentID = this.LoHangService.FormData.ID;
    element.Code = this.LoHangService.FormData.Code;
    this.LoHangLoTrinhService.FormData = element;
    this.LoHangLoTrinhService.SaveAsync().subscribe(
      res => {
        this.LoHangLoTrinhSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangLoTrinhDelete(element: LoHangLoTrinh) {
    this.LoHangService.IsShowLoading = true;
    this.LoHangLoTrinhService.BaseParameter.ID = element.ID;
    this.LoHangLoTrinhService.RemoveAsync().subscribe(
      res => {
        this.LoHangLoTrinhSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangNhatKySearch() {
    this.LoHangService.IsShowLoading = true;
    this.LoHangNhatKyService.BaseParameter.SearchString = this.LoHangService.FormData.Code;
    this.LoHangNhatKyService.GetBySearchStringAndEmptyToListAsync().subscribe(
      res => {
        this.LoHangNhatKyService.List = (res as LoHangNhatKy[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.LoHangNhatKyService.DataSource = new MatTableDataSource(this.LoHangNhatKyService.List);
        this.LoHangNhatKyService.DataSource.sort = this.LoHangNhatKySort;
        this.LoHangNhatKyService.DataSource.paginator = this.LoHangNhatKyPaginator;
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangNhatKySave(element: LoHangNhatKy) {
    this.LoHangService.IsShowLoading = true;
    element.ParentID = this.LoHangService.FormData.ID;
    element.Code = this.LoHangService.FormData.Code;
    this.LoHangNhatKyService.FormData = element;
    this.LoHangNhatKyService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.LoHangNhatKySearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangNhatKyCoppy(element: LoHangNhatKy) {
    this.LoHangService.IsShowLoading = true;
    element.ID = 0;
    element.ParentID = this.LoHangService.FormData.ID;
    element.Code = this.LoHangService.FormData.Code;
    this.LoHangNhatKyService.FormData = element;
    this.LoHangNhatKyService.SaveAsync().subscribe(
      res => {
        this.LoHangNhatKySearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangNhatKyDelete(element: LoHangNhatKy) {
    this.LoHangService.IsShowLoading = true;
    this.LoHangNhatKyService.BaseParameter.ID = element.ID;
    this.LoHangNhatKyService.RemoveAsync().subscribe(
      res => {
        this.LoHangNhatKyService.FileToUpload = null;   
        this.LoHangNhatKySearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangService.IsShowLoading = false;
      }
    );
  }

  ChangeFileNameLoHangNhatKy(element: LoHangNhatKy, files: FileList) {
    if (files) {
      this.LoHangNhatKyService.FileToUpload = files;
    }
  }


  ThanhVienGetLogin() {
    this.ThanhVienService.GetLogin();
  }
  TapTinDinhKemSearch() {
    this.TapTinDinhKemService.IsShowLoading = true;
    this.TapTinDinhKemService.BaseParameter.SearchString = this.LoHangService.FormData.Code;
    this.TapTinDinhKemService.GetBySearchStringAndEmptyToListAsync().subscribe(
        res => {
          this.TapTinDinhKemService.List = (res as TapTinDinhKem[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.TapTinDinhKemService.ListFilter = this.TapTinDinhKemService.List.filter(item => item.ParentID == 0);

          this.TapTinDinhKemService.DataSource = new MatTableDataSource(
            this.TapTinDinhKemService.List.filter(x => x.IsPheDuyet != true)
          );
          this.TapTinDinhKemService.DataSource.sort = this.TapTinDinhKemSort;
          this.TapTinDinhKemService.DataSource.paginator = this.TapTinDinhKemPaginator;
  
          this.TapTinDinhKemService.DataSource002 = new MatTableDataSource(
            this.TapTinDinhKemService.List.filter(x => x.IsPheDuyet == true || x.ID == 0)
          );
          this.TapTinDinhKemService.DataSource002.sort = this.TapTinDinhKemSort002;
          this.TapTinDinhKemService.DataSource002.paginator = this.TapTinDinhKemPaginator002;

          this.TapTinDinhKemService.IsShowLoading = false;
        },
        err => {
          this.TapTinDinhKemService.IsShowLoading = false;
        }
    );
  }
  TapTinDinhKemSave(element: TapTinDinhKem) {
    this.TapTinDinhKemService.IsShowLoading = true;
    element.ParentID = this.LoHangService.FormData.ID;
    element.Code = this.LoHangService.FormData.Code;
    this.TapTinDinhKemService.FormData = element;
    this.TapTinDinhKemService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.TapTinDinhKemService.FileToUpload = null;        
        this.TapTinDinhKemSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.TapTinDinhKemService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.TapTinDinhKemService.IsShowLoading = false;
      }
    );
  }

  TapTinDinhKemSave002(element: TapTinDinhKem) {
    this.TapTinDinhKemService.IsShowLoading = true;
    element.ParentID = this.LoHangService.FormData.ID;
    element.Code = this.LoHangService.FormData.Code;
    element.IsPheDuyet = true;
    this.TapTinDinhKemService.FormData = element;
    this.TapTinDinhKemService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.TapTinDinhKemService.FileToUpload = null;        
        this.TapTinDinhKemSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.TapTinDinhKemService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.TapTinDinhKemService.IsShowLoading = false;
      }
    );
  }

  TapTinDinhKemDelete(element: TapTinDinhKem) {
    this.TapTinDinhKemService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.TapTinDinhKemService.ComponentDeleteAll(this.TapTinDinhKemSort, this.TapTinDinhKemPaginator));
    this.TapTinDinhKemSearch();
  }
  
  ChangeFileNameTapTinDinhKem(element: TapTinDinhKem, files: FileList) {
    if (files) {
      this.TapTinDinhKemService.FileToUpload = files;
    }
  }

  CompanyGroupSearch() {
    this.CompanyGroupService.ComponentGetAllToListAsync();
  }

  DateNgayCongBo(value) {
    this.LoHangService.FormData.NgayCongBo = new Date(value);
  }


  ProductGroupSearch() {
    this.ProductGroupService.BaseParameter.ID = this.LoHangService.FormData.ProductGroupID;
    this.ProductGroupService.ComponentGet000ToListAsync();
  }
  ProductGroupFilter(searchString: string) {
    this.ProductGroupService.Filter000(searchString);
  }

  ProductUnitSearch() {
    this.ProductUnitService.ComponentGetAllToListAsync();
  }

  DanhMucQuocGiaSearch() {
    this.DanhMucQuocGiaService.ComponentGetAllToListAsync();
  }
  DanhMucQuocGiaFilter(searchString: string) {
    this.DanhMucQuocGiaService.Filter(searchString);
  }

  LoHangSave() {
    this.LoHangService.FormData.ParentID = this.ThanhVienService.FormDataLogin.CompanyInfoID;
    this.LoHangService.IsShowLoading = true;
    this.LoHangService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.LoHangService.FormData = res as LoHang;
        this.NotificationService.warn(environment.SaveSuccess);
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangService.IsShowLoading = false;
      }
    );
  }

  LoHangSaveDangKy() {
    if (confirm(environment.XacNhanDangKy)) {
      this.LoHangService.FormData.ParentID = this.ThanhVienService.FormDataLogin.CompanyInfoID;
      this.LoHangService.FormData.DanhMucATTPTinhTrangID = this.DanhMucATTPTinhTrangIDDoiThamDinh;
      this.LoHangService.FormData.Active = true;
      this.LoHangService.IsShowLoading = true;
      this.LoHangService.SaveAndUploadFileAsync().subscribe(
        res => {
          this.LoHangService.FormData = res as LoHang;
          this.NotificationService.warn(environment.SaveSuccess);
          this.LoHangService.IsShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.LoHangService.IsShowLoading = false;
        }
      );
    }
  }
  LoHangSaveTiepNhan() {
    if (confirm(environment.XacNhanTiepNhan)) {
      this.LoHangService.FormData.ParentID = this.ThanhVienService.FormDataLogin.CompanyInfoID;
      this.LoHangService.FormData.DanhMucATTPTinhTrangID = this.DanhMucATTPTinhTrangIDTiepNhan;
      this.LoHangService.IsShowLoading = true;
      this.LoHangService.SaveAndUploadFileAsync().subscribe(
        res => {
          this.LoHangService.FormData = res as LoHang;
          this.NotificationService.warn(environment.SaveSuccess);
          this.TapTinDinhKemSearch();
          this.LoHangService.IsShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.LoHangService.IsShowLoading = false;
        }
      );
    }
    
  }
  LoHangSaveHoanTra() {
    if (confirm(environment.XacNhanTraHoSo)) {
      this.LoHangService.IsShowLoading = true;
      this.LoHangService.FormData.ParentID = this.ThanhVienService.FormDataLogin.CompanyInfoID;
      this.LoHangService.FormData.DanhMucATTPTinhTrangID = this.DanhMucATTPTinhTrangIDTraHuyHoSo;
      this.LoHangService.SaveAndUploadFileAsync().subscribe(
        res => {
          this.LoHangService.FormData = res as LoHang;
          this.NotificationService.warn(environment.SaveSuccess);
          this.TapTinDinhKemSearch();
          this.LoHangService.IsShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.LoHangService.IsShowLoading = false;
        }
      );
    }
  }
  LoHangSavePheDuyet() {
    if (confirm(environment.XacNhanPheDuyetHoSo)) {
      this.LoHangService.FormData.ParentID = this.ThanhVienService.FormDataLogin.CompanyInfoID;
      this.LoHangService.FormData.DanhMucATTPTinhTrangID = this.DanhMucATTPTinhTrangIDDaPheDuyet;
      this.LoHangService.IsShowLoading = true;
      this.LoHangService.FormData.IsPheDuyet = true;
      this.LoHangService.SaveAndUploadFileAsync().subscribe(
        res => {
          this.LoHangService.FormData = res as LoHang;
          this.NotificationService.warn(environment.SaveSuccess);
          this.TapTinDinhKemSearch();
          this.LoHangService.IsShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.LoHangService.IsShowLoading = false;
        }
      );
    }
  }
  
  ChangeFileName(files: FileList) {
    if (files) {
      this.LoHangService.FileToUpload = files;
    }
  }

  Close() {
    this.dialogRef.close();
  }
  map: maplibregl.Map | undefined;

  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;

  ngAfterViewInit() {
    //this.MapLoad();
  }


  ngOnDestroy() {
    this.map?.remove();
  }

  MapInitialization(longitude, latitude) {
    let zoom = 10;
    if ((latitude > 90) || (latitude == 0)) {
      latitude = environment.Latitude;
      longitude = environment.Longitude;
    }
    this.map = new maplibregl.Map({
      container: this.mapContainer.nativeElement,
      style: 'https://api.maptiler.com/maps/hybrid/style.json?key=' + environment.MaptilerAPIKey,
      center: [longitude, latitude],
      zoom: zoom,
      pitch: 45,
    });

    this.map.addControl(
      new maplibregl.NavigationControl({
        visualizePitch: true,
        showZoom: true,
        showCompass: true
      })
    );
    this.map.addControl(
      new maplibregl.FullscreenControl({
      })
    );
    this.map.on('load', () => {

      this.map.addSource("HoangSa", {
        "type": "image",
        "url": environment.DomainURL + "assets/image/HoangSa01.png",
        "coordinates": [
          [111.09665858054495, 17.432475898867523],
          [113.11720985517763, 17.38420482529338],
          [112.79285037220984, 15.643938718432054],
          [110.88537855035554, 15.672592116966598],
        ]
      });
      this.map.addLayer({
        "id": "HoangSa",
        "source": "HoangSa",
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

      this.map.addSource("TruongSa", {
        "type": "image",
        "url": environment.DomainURL + "assets/image/TruongSa01.png",
        "coordinates": [
          [112.32373278444106, 12.236103169381323],
          [117.4620551483049, 11.606334626304161],
          [115.59654957671216, 7.357025445897818],
          [110.62186805246108, 7.811210355974268],


        ]
      });
      this.map.addLayer({
        "id": "TruongSa",
        "source": "TruongSa",
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

    });
  }

  MapLoad() {

    document.getElementById("map").style.height = "500px";

    if (this.LoHangService.FormData) 
    {
      let latitude = environment.Latitude;
      let longitude = environment.Longitude;
      /*
      if (this.CompanyInfoDonViDongGoiService.FormData.KinhDo > 0) {
        if (this.CompanyInfoDonViDongGoiService.FormData.ViDo > 0) {
          latitude = Number(this.CompanyInfoDonViDongGoiService.FormData.ViDo);
          longitude = Number(this.CompanyInfoDonViDongGoiService.FormData.KinhDo);
        }
      }
      */
      this.MapInitialization(longitude, latitude);
      if (latitude <= 90) {
        let popupContent = "<div style='opacity: 0.8; background-color: transparent;'>";
        /*
        popupContent = popupContent + "<a style='text-align: center;' class='link-primary form-label' href='#'><h1>" + this.CompanyInfoDonViDongGoiService.FormData.Name + " [" + this.CompanyInfoDonViDongGoiService.FormData.ID + "]</h1></a>";
        popupContent = popupContent + "<div>Chủ cơ sở: <b>" + this.CompanyInfoDonViDongGoiService.FormData.Name + "</b></div>";
        popupContent = popupContent + "<div>Điện thoại: <b>" + this.CompanyInfoDonViDongGoiService.FormData.DaiDienCoSoDienThoai + "</b></div>";
        popupContent = popupContent + "<div>Địa chỉ: <b>" + this.CompanyInfoDonViDongGoiService.FormData.DiaChi + "</b></div>";
        popupContent = popupContent + "<div>Ấp thôn: <b>" + this.CompanyInfoDonViDongGoiService.FormData.ThonAp + "</b></div>";
        popupContent = popupContent + "<div>Xã phường: <b>" + this.CompanyInfoDonViDongGoiService.FormData.WardDataName + "</b></div>";
        popupContent = popupContent + "<div>Quận huyện: <b>" + this.CompanyInfoDonViDongGoiService.FormData.DistrictDataName + "</b></div>";
        popupContent = popupContent + "</div>";
        */
        let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
          .setMaxWidth("600px");

        var el = document.createElement('div');
        el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/logo_30.png)";
        el.style.width = '30px';
        el.style.height = '30px';

        let marker = new maplibregl.Marker({ element: el })
          .setLngLat([longitude, latitude])
          .setPopup(popup)
          .addTo(this.map);
      }
    }
  }
}

