<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-CompanyInfoThuMuaNongSan" data-bs-toggle="tab"
                                    href="#CompanyInfoThuMuaNongSan" role="tab" aria-controls="CompanyInfoThuMuaNongSan"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Bán ra nông sản</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="CompanyInfoThuMuaNongSan" role="tabpanel">
                                <div class="row gx-4">                                    
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input [(ngModel)]="CompanyInfoThuMuaNongSanService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="CompanyInfoThuMuaNongSanSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-6 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <div class="row gx-4">
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                                    (click)="CompanyInfoThuMuaNongSanSearch()"><i
                                                        class="bi bi-search"></i></a>
                                            </div>
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a style="width: 100%;" class="btn btn-success" title="Thêm mới"
                                                    (click)="CompanyInfoThuMuaNongSanAdd(0)"><i
                                                        class="bi bi-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span
                                                *ngIf="CompanyInfoThuMuaNongSanService.List">({{CompanyInfoThuMuaNongSanService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #CompanyInfoThuMuaNongSanSort="matSort"
                                                    [dataSource]="CompanyInfoThuMuaNongSanService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="CompanyInfoThuMuaNongSanPaginator">
                                                                {{CompanyInfoThuMuaNongSanPaginator.pageSize *
                                                                CompanyInfoThuMuaNongSanPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{ element.ID }}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayGhiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer" title="{{element.cer_code}}"
                                                                class="link-primary"
                                                                (click)="CompanyInfoThuMuaNongSanAdd(element.ID)"><b>{{element.NgayGhiNhan
                                                                    | date:'dd/MM/yyyy HH:mm:ss'}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ParentID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Bên mua
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <b>{{element.DonViName}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="BenBanID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Bên bán
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <b>{{element.BenBanName}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SanPham">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sản phẩm
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer" title="{{element.SanPham}}"
                                                                class="link-primary"
                                                                (click)="CompanyInfoThuMuaNongSanAdd(element.ID)"><b>{{element.SanPham}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThanhTien">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thành tiền                                                            
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.ThanhTien | number:'1.0-0'}} (đồng)
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsBenMua">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Bên mua xác nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsBenMua{{ element.ID }}"
                                                                    [(ngModel)]="element.IsBenMua" />
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsBenBan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Bên bán xác nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsBenBan{{ element.ID }}"
                                                                    [(ngModel)]="element.IsBenBan" />
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-danger"
                                                                (click)="CompanyInfoThuMuaNongSanDelete(element)"
                                                                *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="CompanyInfoThuMuaNongSanService.DisplayColumns002">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: CompanyInfoThuMuaNongSanService.DisplayColumns002;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #CompanyInfoThuMuaNongSanPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>

                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #CompanyInfoThuMuaNongSanSort="matSort"
                                                    [dataSource]="CompanyInfoThuMuaNongSanService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Ghi nhận: </label>
                                                                    <br />
                                                                    <a style="cursor: pointer"
                                                                        title="{{element.cer_code}}"
                                                                        class="link-primary form-label"
                                                                        (click)="CompanyInfoThuMuaNongSanAdd(element.ID)"><b>{{element.NgayGhiNhan
                                                                            |
                                                                            date:'dd/MM/yyyy HH:mm:ss'}}</b></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Bên mua: </label>
                                                                    <label class="form-label">
                                                                        {{element.DonViName}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Bên bán: </label>
                                                                    <label class="form-label">
                                                                        {{element.BenBanName}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Sản phẩm: </label>
                                                                    <label class="form-label">
                                                                        {{element.SanPham}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Thành tiền (đồng): </label>
                                                                    <label class="form-label">
                                                                        {{element.ThanhTien | number:'1.0-0'}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsBenMua{{ element.ID }}"
                                                                            [(ngModel)]="element.IsBenMua" />
                                                                        <label class="form-label">Bên mua xác
                                                                            nhận</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsBenBan{{ element.ID }}"
                                                                            [(ngModel)]="element.IsBenBan" />
                                                                        <label class="form-label">Bên bán xác
                                                                            nhận</label>
                                                                    </div>
                                                                </div>
                                                                <div class="row gx-4">
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <a class="btn btn-danger" style="width: 100%"
                                                                            (click)="CompanyInfoThuMuaNongSanDelete(element)"
                                                                            *ngIf="element.ID > 0"><i
                                                                                class="bi bi-trash"></i>
                                                                        </a>
                                                                    </div>
                                                                    <br />
                                                                    <br />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="CompanyInfoThuMuaNongSanService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: CompanyInfoThuMuaNongSanService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #CompanyInfoThuMuaNongSanPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="CompanyInfoThuMuaNongSanService.IsShowLoading"></app-loading>