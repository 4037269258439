<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-PlanThamDinhCompaniesCamKet17" data-bs-toggle="tab"
                                    href="#PlanThamDinhCompaniesCamKet17" role="tab"
                                    aria-controls="PlanThamDinhCompaniesCamKet17" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px">Cam kết 17</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="PlanThamDinhCompaniesCamKet17" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Quận
                                            huyện</label>
                                        <select class="form-select"
                                            [(ngModel)]="PlanThamDinhCompaniesService.BaseParameter.DistrictDataID"
                                            (change)="WardDataSearch()">
                                            <option [value]="0">Tất cả</option>
                                            <option *ngFor="let item of DistrictDataService.List;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Phường
                                            xã</label>
                                        <select class="form-select"
                                            [(ngModel)]="PlanThamDinhCompaniesService.BaseParameter.WardDataID">
                                            <option [value]="0">Tất cả</option>
                                            <option *ngFor="let item of WardDataService.List;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input [(ngModel)]="PlanThamDinhCompaniesService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="PlanThamDinhCompaniesSearch()" />
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <div class="row gx-4">
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                                    (click)="PlanThamDinhCompaniesSearch()"><i
                                                        class="bi bi-search"></i></a>
                                            </div>
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a style="width: 100%;" class="btn btn-success" title="Tải về"
                                                    (click)="PlanThamDinhCompaniesDownload()"><i
                                                        class="bi bi-cloud-download"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">
                                            Danh sách
                                            <span
                                                *ngIf="PlanThamDinhCompaniesService.List">({{PlanThamDinhCompaniesService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #PlanThamDinhCompaniesSort="matSort"
                                                    [dataSource]="PlanThamDinhCompaniesService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i"
                                                            style="text-align: center">
                                                            <div *ngIf="PlanThamDinhCompaniesPaginator">
                                                                {{PlanThamDinhCompaniesPaginator.pageSize *
                                                                PlanThamDinhCompaniesPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="CompanyInfoName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Đơn vị Tổ chức
                                                        </th>
                                                        <td mat-cell *matCellDef="let element" style="width: 500px;">
                                                            <mat-select class="form-control"
                                                                *ngIf="element.ID==0"
                                                                name="element.CompanyInfoID"
                                                                [(ngModel)]="element.CompanyInfoID">
                                                                <input placeholder="Tìm..." class="form-control"
                                                                    (keyup)="CompanyInfoFilter($event.target.value)">
                                                                <mat-option
                                                                    *ngFor="let item of CompanyInfoService.ListFilter"
                                                                    [value]="item.ID">
                                                                    {{item.Name}} - {{item.address}}
                                                                </mat-option>
                                                            </mat-select>
                                                            <a *ngIf="element.ID>0" title="{{element.CompanyInfoName}}"
                                                                style="cursor: pointer;" class="link-primary"
                                                                (click)="CompanyInfoAdd(element.CompanyInfoID)"><b>{{element.CompanyInfoName}}</b>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="HTMLContent">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Mặt hàng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Mặt hàng" name="HTMLContent{{element.ID}}"
                                                                [(ngModel)]="element.HTMLContent" />
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayGhiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ngày ký
                                                        </th>
                                                        <td mat-cell *matCellDef="let element" style="width: 50px;">
                                                            <input name="NgayGhiNhan{{element.ID}}"
                                                                [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                                (ngModelChange)="DatePlanThamDinhCompaniesNgayGhiNhan(element, $event)"
                                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                                class="form-control" autocomplete="off">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHetHan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ngày kiểm tra
                                                        </th>
                                                        <td mat-cell *matCellDef="let element" style="width: 50px;">
                                                            <input name="NgayHetHan{{element.ID}}"
                                                                [ngModel]="element.NgayHetHan | date:'yyyy-MM-dd'"
                                                                (ngModelChange)="DatePlanThamDinhCompaniesNgayHetHan(element, $event)"
                                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                                class="form-control" autocomplete="off">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucATTPXepLoaiName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Xếp loại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucATTPXepLoaiID"
                                                                [(ngModel)]="element.DanhMucATTPXepLoaiID">
                                                                <option
                                                                    *ngFor="let item of DanhMucATTPXepLoaiService.List;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>                                                   
                                                    <ng-container matColumnDef="FileName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tải lên
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input type="file" class="form-control"
                                                                (change)="ChangeFileName(element, $event.target.files)">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div>
                                                                <a class="btn btn-info"
                                                                    (click)="PlanThamDinhCompaniesSave(element)"><i
                                                                        class="bi bi-sd-card"></i>
                                                                </a>&nbsp;&nbsp;&nbsp;
                                                                <a class="btn btn-danger"
                                                                    (click)="PlanThamDinhCompaniesDelete(element)"
                                                                    *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                                </a>&nbsp;&nbsp;&nbsp;
                                                                <a *ngIf="element.ID > 0 && element.FileName"
                                                                    class="btn btn-success" title="Tải về"
                                                                    href="{{element.FileName}}" target="_blank"><i
                                                                        class="bi bi-cloud-download"></i>
                                                                </a>
                                                            </div>                                                           
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="PlanThamDinhCompaniesService.DisplayColumns020">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: PlanThamDinhCompaniesService.DisplayColumns020">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #PlanThamDinhCompaniesPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>

                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #PlanThamDinhCompaniesSort="matSort"
                                                    [dataSource]="PlanThamDinhCompaniesService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row *matHeaderRowDef="
                                PlanThamDinhCompaniesService.DisplayColumnsMobile
                              "></tr>
                                                    <tr mat-row *matRowDef="
                                let row;
                                columns: PlanThamDinhCompaniesService.DisplayColumnsMobile
                              "></tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #PlanThamDinhCompaniesPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="PlanThamDinhCompaniesService.IsShowLoading"></app-loading>