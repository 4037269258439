import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CompanyInfoThuMuaNongSan } from 'src/app/shared/CompanyInfoThuMuaNongSan.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class CompanyInfoThuMuaNongSanService extends BaseService {

    List: CompanyInfoThuMuaNongSan[] | undefined;
    ListFilter: CompanyInfoThuMuaNongSan[] | undefined;
    FormData!: CompanyInfoThuMuaNongSan;

    DisplayColumns001: string[] = ['STT', 'ID', 'NgayGhiNhan', 'ParentID', 'SanPham', 'ThanhTien', 'Active', 'Save'];
    DisplayColumns002: string[] = ['STT', 'ID', 'NgayGhiNhan', 'ParentID', 'BenBanID', 'SanPham', 'ThanhTien', 'IsBenMua', 'IsBenBan', 'Save'];

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "CompanyInfoThuMuaNongSan";
    }


    GetByThanhVienIDToListAsync() {
        let url = this.APIURL + this.Controller + '/GetByThanhVienIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    GetByBatDauAndKetThuc_SearchStringToListAsync() {
        let url = this.APIURL + this.Controller + '/GetByBatDauAndKetThuc_SearchStringToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
}

