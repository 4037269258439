<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-bank2 fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Hướng dẫn sử dụng
            </h2>
            <h6 class="m-0 text-dark fw-light">
                Giới thiệu
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThongBaoDetail" data-bs-toggle="tab"
                                    href="#ThongBaoDetail" role="tab" aria-controls="ThongBaoDetail"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Hướng dẫn sử dụng</span>
                                </a>
                            </li>

                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThongBaoDetail" role="tabpanel">
                                <div class="row gx-4">  
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <label class="form-label">Kết nối máy tính</label>
                                        <br/>
                                       <a class="link-primary" href="https://www.ultraviewer.net/vi/download.html" target="_blank"><h4><b>Tải về ULTRA VIEWER</b></h4></a>
                                    </div>                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>