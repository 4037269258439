import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ProductGroup } from 'src/app/shared/ProductGroup.model';
import { BaseService } from './Base.service';
@Injectable({
  providedIn: 'root'
})
export class ProductGroupService extends BaseService {


  List: ProductGroup[] | undefined;
  ListFilter: ProductGroup[] | undefined;
  FormData!: ProductGroup;

  DisplayColumns001: string[] = ['STT', 'ID', 'ParentID', 'Name', 'SortOrder', 'Active', 'Save'];
  constructor(public httpClient: HttpClient) {
    super(httpClient);
    this.Controller = "ProductGroup";
  }


  ComponentGet000ToListAsync() {
    this.IsShowLoading = true;    
    this.BaseParameter.Page = 1;
    this.BaseParameter.PageSize = 10;
    this.GetByPageAndPageSizeToListAsync().subscribe(
      res => {
        this.List = (res as any[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ListFilter = this.List;
        this.IsShowLoading = false;
      },
      err => {
        this.IsShowLoading = false;
      }
    );
  }
  Filter000(searchString: string) {
    if (searchString.length > 0) {
      searchString = searchString.trim();
      searchString = searchString.toLocaleLowerCase();
      this.IsShowLoading = true;      
      this.BaseParameter.SearchString = searchString;
      this.GetBySearchStringToListAsync().subscribe(
        res => {
          this.List = (res as any[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.ListFilter = this.List;
          this.IsShowLoading = false;
        },
        err => {
          this.IsShowLoading = false;
        }
      );
    }
    else {
      this.ListFilter = this.List;
    }
  }
}

