<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-PlanThamDinhDetail" data-bs-toggle="tab" href="#PlanThamDinhDetail"
                role="tab" aria-controls="PlanThamDinhDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Kiểm tra tạp chất</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-PlanThamDinhCompanyDocumentDetailDetail" data-bs-toggle="tab"
                href="#PlanThamDinhCompanyDocumentDetailDetail" role="tab"
                aria-controls="PlanThamDinhCompanyDocumentDetailDetail" aria-selected="false">
                <span class="badge bg-success" style="font-size: 16px;">Biểu mẫu</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="PlanThamDinhDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="PlanThamDinhSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay
                        đổi</a>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <h4>Bước 01: Nhập Thông tin</h4>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Nội dung</label>
                                <input name="Name" [(ngModel)]="PlanThamDinhService.FormData.Name"
                                    placeholder="Nội dung" type="text" class="form-control" />
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Số quyết định</label>
                                <input name="Display" [(ngModel)]="PlanThamDinhService.FormData.Display"
                                    placeholder="Số quyết định" type="text" class="form-control" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Ngày bắt đầu</label>
                                <input [ngModel]="PlanThamDinhService.FormData.NgayBatDau | date:'yyyy-MM-dd'"
                                    (ngModelChange)="DateNgayBatDau($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                                    type="date" class="form-control" autocomplete="off">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Ngày kết thúc</label>
                                <input [ngModel]="PlanThamDinhService.FormData.NgayKetThuc | date:'yyyy-MM-dd'"
                                    (ngModelChange)="DateNgayKetThuc($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                                    type="date" class="form-control" autocomplete="off">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-4 col-sm-12 col-12">
                        <a style="width: 100%;" class="btn btn-success" title="Thêm mới" (click)="ThanhVienAdd(0)"><i
                                class="bi bi-plus"></i> Thêm thành viên (nếu thiếu)</a>
                    </div>
                    <br />
                    <div class="card mb-4 card-success">
                        <div class="card-header">
                            <h5 class="card-title text-white">Bước 02: Chọn thành viên tham gia Đoàn công tác
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhThanhVienSort="matSort"
                                        [dataSource]="PlanThamDinhThanhVienService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="ThanhVienID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cán bộ đơn vị + Khách
                                                mời
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <select *ngIf="element.ID==0" class="form-select"
                                                    name="element.ThanhVienID" [(ngModel)]="element.ThanhVienID">
                                                    <option *ngFor="let item of ThanhVienService.List;"
                                                        [value]="item.ID">
                                                        {{item.Name}} ({{item.AgencyDepartmentName}} -
                                                        {{item.StateAgencyName}})</option>
                                                </select>
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.ThanhVienName}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="DanhMucChucDanhID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chức danh
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <select *ngIf="element.ID==0" class="form-select"
                                                    name="element.DanhMucChucDanhID"
                                                    [(ngModel)]="element.DanhMucChucDanhID">
                                                    <option *ngFor="let item of DanhMucChucDanhService.List;"
                                                        [value]="item.ID">
                                                        {{item.Name}}</option>
                                                </select>
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.DanhMucChucDanhName}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-info" (click)="PlanThamDinhThanhVienSave(element)"
                                                    *ngIf="element.ID==0"><i class="bi bi-sd-card"></i>
                                                </a>
                                                <a class="btn btn-danger" (click)="PlanThamDinhThanhVienDelete(element)"
                                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhThanhVienService.DisplayColumns001">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhThanhVienService.DisplayColumns001;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #PlanThamDinhThanhVienPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhThanhVienSort="matSort"
                                        [dataSource]="PlanThamDinhThanhVienService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhThanhVienService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhThanhVienService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #PlanThamDinhThanhVienPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-danger">
                        <div class="card-header">
                            <h5 class="card-title text-white">Bước 03: Chọn Khu vực
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhDistrictDataSort="matSort"
                                        [dataSource]="PlanThamDinhDistrictDataService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="DistrictDataID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Khu vực
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <select *ngIf="element.ID==0" class="form-select"
                                                    name="element.DistrictDataID" [(ngModel)]="element.DistrictDataID">
                                                    <option *ngFor="let item of DistrictDataService.List;"
                                                        [value]="item.ID">
                                                        {{item.Name}}</option>
                                                </select>
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.DistrictDataName}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="NgayGhiNhan">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ngày ghi nhận
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input *ngIf="element.ID==0" name="NgayGhiNhan{{element.ID}}"
                                                    [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                    (ngModelChange)="DatePlanThamDinhDistrictDataNgayGhiNhan(element, $event)"
                                                    [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                    class="form-control" autocomplete="off">
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.NgayGhiNhan | date:'dd/MM/yyyy'}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-info" (click)="PlanThamDinhDistrictDataSave(element)"
                                                    *ngIf="element.ID==0"><i class="bi bi-sd-card"></i>
                                                </a>
                                                <a class="btn btn-danger"
                                                    (click)="PlanThamDinhDistrictDataDelete(element)"
                                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhDistrictDataService.DisplayColumns002"></tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhDistrictDataService.DisplayColumns002">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #PlanThamDinhDistrictDataPaginator="matPaginator"
                                        [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhDistrictDataSort="matSort"
                                        [dataSource]="PlanThamDinhDistrictDataService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i">
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhDistrictDataService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhDistrictDataService.DisplayColumnsMobile">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #PlanThamDinhDistrictDataPaginator="matPaginator"
                                        [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-4 col-sm-12 col-12">
                        <a style="width: 100%;" class="btn btn-warning" title="Thêm mới" (click)="CompanyInfoAdd(0)"><i
                                class="bi bi-plus"></i> Thêm Đơn vị Tổ chức (nếu thiếu)</a>
                    </div>
                    <br />
                    <div class="card mb-4 card-warning">
                        <div class="card-header">
                            <h5 class="card-title text-white">Bước 04: Chọn Đơn vị Tổ chức
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhCompaniesSort="matSort"
                                        [dataSource]="PlanThamDinhCompaniesService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="CompanyInfoID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Đơn vị Tổ chức
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="width: 60%;">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <mat-select class="form-control" name="element.CompanyInfoID"
                                                            [(ngModel)]="element.CompanyInfoID" *ngIf="element.ID==0">
                                                            <input placeholder="Tìm..." class="form-control"
                                                                (keyup)="CompanyInfoFilter($event.target.value)">
                                                            <mat-option
                                                                *ngFor="let item of CompanyInfoService.ListFilter"
                                                                [value]="item.ID">
                                                                {{item.Name}} - {{item.address}}
                                                            </mat-option>
                                                        </mat-select>
                                                        <div *ngIf="element.ID>0">
                                                            <b>{{element.CompanyInfoName}}</b>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Đại diện</label>
                                                        <input class="form-control" type="text" placeholder="Đại diện"
                                                            name="TypeName{{element.ID}}"
                                                            [(ngModel)]="element.TypeName" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Điện thoại</label>
                                                        <input class="form-control" type="text" placeholder="Điện thoại"
                                                            name="Display{{element.ID}}"
                                                            [(ngModel)]="element.Display" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Địa chỉ</label>
                                                        <input class="form-control" type="text" placeholder="Địa chỉ"
                                                            name="Description{{element.ID}}"
                                                            [(ngModel)]="element.Description" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Loại hình</label>
                                                        <input class="form-control" type="text" placeholder="Loại hình"
                                                            name="HTMLContent{{element.ID}}"
                                                            [(ngModel)]="element.HTMLContent" />
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Note">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nội dung
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ghi chú</label>
                                                        <textarea class="form-control" type="text" placeholder="Ghi chú"
                                                            rows="6" name="Note{{element.ID}}"
                                                            [(ngModel)]="element.Note"></textarea>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ghi nhận</label>
                                                        <input name="NgayGhiNhan{{element.ID}}"
                                                            [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                            (ngModelChange)="DatePlanThamDinhCompaniesNgayGhiNhan(element, $event)"
                                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                            class="form-control" autocomplete="off">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <div class="form-check form-check-inline mb-6">
                                                            <input type="checkbox" class="form-check-input"
                                                                name="Active{{element.ID}}"
                                                                [(ngModel)]="element.Active" />
                                                            <label class="form-check-label" for="Active">Đạt</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a style="width: 100%;" class="btn btn-info"
                                                            (click)="PlanThamDinhCompaniesSave(element)"><i
                                                                class="bi bi-sd-card"></i> Lưu thay đổi
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a style="width: 100%;" class="btn btn-success"
                                                            *ngIf="element.ID>0"
                                                            (click)="PlanThamDinhCompanyDocumentAdd001(element, 79)">Biên
                                                            bản làm
                                                            việc
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a style="width: 100%;" class="btn btn-success"
                                                            *ngIf="element.ID>0"
                                                            (click)="PlanThamDinhCompanyDocumentAdd001(element, 81)">Biên
                                                            bản kỹ
                                                            thuật
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a style="width: 100%;" class="btn btn-danger"
                                                            *ngIf="element.ID>0"
                                                            (click)="PlanThamDinhCompaniesDelete(element)"><i
                                                                class="bi bi-trash"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhCompaniesService.DisplayColumns017">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhCompaniesService.DisplayColumns017;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #PlanThamDinhCompaniesPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhCompaniesSort="matSort"
                                        [dataSource]="PlanThamDinhCompaniesService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">

                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhCompaniesService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhCompaniesService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #PlanThamDinhCompaniesPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-primary">
                        <div class="card-header">
                            <h5 class="card-title text-white bg-primary">Bước 05: Tải về
                                biểu mẫu và Tải lên biểu mẫu đã ký
                            </h5>
                        </div>
                        <div class="card-body bg-primary">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhCompanyDocumentSort="matSort"
                                        [dataSource]="PlanThamDinhCompanyDocumentService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="LastUpdatedDate">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Ngày Ghi nhận
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <b>{{element.LastUpdatedDate | date:'dd/MM/yyyy HH:mm:ss'}}</b>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="width: 600px;">
                                                <input class="form-control" type="text" placeholder="Tiêu đề"
                                                    name="Name{{element.ID}}" [(ngModel)]="element.Name" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="FileName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải về hoặc
                                                tải lên
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a *ngIf="element.ID > 0 && element.TypeName" class="link-primary"
                                                    href="{{element.TypeName}}"><b>Tải về biểu mẫu</b></a>
                                                <input *ngIf="element.ID == 0" type="file"
                                                    (change)="ChangeFileName($event.target.files)">
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-info"
                                                    (click)="PlanThamDinhCompanyDocumentSave(element)"><i
                                                        class="bi bi-sd-card"></i> </a>&nbsp;&nbsp;&nbsp;
                                                <a class="btn btn-danger"
                                                    (click)="PlanThamDinhCompanyDocumentDelete(element)"
                                                    *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                </a>&nbsp;&nbsp;&nbsp;
                                                <a *ngIf="element.ID > 0 && element.DocumentTemplateID > 0"
                                                    class="btn btn-success" title="Tải về"
                                                    (click)="PlanThamDinhCompanyDocumentAdd(element.ID)"><i
                                                        class="bi bi-cloud-download"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhCompanyDocumentService.DisplayColumns001">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhCompanyDocumentService.DisplayColumns001;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #PlanThamDinhCompanyDocumentPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhCompanyDocumentSort="matSort"
                                        [dataSource]="PlanThamDinhCompanyDocumentService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhCompanyDocumentService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhCompanyDocumentService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #PlanThamDinhCompanyDocumentPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="PlanThamDinhCompanyDocumentDetailDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-11 col-sm-12 col-12">
                    <input [(ngModel)]="PlanThamDinhCompanyDocumentService.BaseParameter.SearchString"
                        placeholder="Tìm ..." class="form-control" (keyup.enter)="PlanThamDinhCompanyDocumentSearch()">
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm"
                        (click)="PlanThamDinhCompanyDocumentSearch()"><i class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                </div>
            </div>
            <div class="card mb-4 card-success">
                <div class="card-header">
                    <h5 class="card-title text-white">
                        Danh sách
                        <span
                            *ngIf="PlanThamDinhCompanyDocumentService.ListPlanThamDinh">({{PlanThamDinhCompanyDocumentService.ListPlanThamDinh.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                #PlanThamDinhCompanyDocumentSort="matSort"
                                [dataSource]="PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinh"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                                        <div *ngIf="PlanThamDinhCompanyDocumentPaginator">
                                            {{PlanThamDinhCompanyDocumentPaginator.pageSize *
                                            PlanThamDinhCompanyDocumentPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.ID }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Biểu mẫu
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer" title="{{element.Name}}" class="link-primary"
                                            (click)="PlanThamDinhCompanyDocumentAdd(element.ID)"><b>{{element.Name}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="NgayGhiNhan">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Ngày ghi nhận
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.NgayGhiNhan | date:'dd/MM/yyyy'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ThanhVienName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Người ký
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ThanhVienName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                    <td mat-cell *matCellDef="let element">
                                        <a *ngIf="element.ID > 0 && element.FileName" class="btn btn-success"
                                            title="Tải về" href="{{element.FileName}}" target="_blank"><i
                                                class="bi bi-cloud-download"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row
                                    *matHeaderRowDef="PlanThamDinhCompanyDocumentService.DisplayColumns006"></tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: PlanThamDinhCompanyDocumentService.DisplayColumns006">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                #PlanThamDinhCompanyDocumentPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                [showFirstLastButtons]></mat-paginator>

                            <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                #PlanThamDinhCompanyDocumentSort="matSort"
                                [dataSource]="PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinh"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <a style="cursor: pointer" title="{{element.Name}}" class="link-primary"
                                                    (click)="PlanThamDinhCompanyDocumentAdd(element.ID)"><b>{{element.Name}}</b></a>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Ghi nhận: </label>
                                                <label class="form-label">{{element.NgayGhiNhan |
                                                    date:'dd/MM/yyyy'}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Người ký: </label>
                                                <label class="form-label">{{element.ThanhVienName}}</label>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row
                                    *matHeaderRowDef="PlanThamDinhCompanyDocumentService.DisplayColumnsMobile"></tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: PlanThamDinhCompanyDocumentService.DisplayColumnsMobile">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile"
                                #PlanThamDinhCompanyDocumentPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="PlanThamDinhService.IsShowLoading"></app-loading>