<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-CoSoMapDetail" data-bs-toggle="tab" href="#CoSoMapDetail" role="tab"
                aria-controls="CoSoMapDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Bản đồ</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="CoSoMapDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Bản đồ" (click)="MapLoad()"><i
                            class="bi bi-pin-map"></i> Bản đồ</a>
                </div>
            </div>
            <div class="card mb-4 card-info">
                <div class="map" #map style="height: 600px; width: 100%;">
                    <div style="z-index: 1000; position: absolute; top: 0;">
                        <img src="{{domainURL}}assets/image/vungtrong.png" />
                    </div>
                    <div
                        style="z-index: 1000; position: absolute; bottom: 0; width: 100%; height: 20px; background-color: #ffffff;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="CompanyInfoService.IsShowLoading"></app-loading>