<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-CauHoiATTP" data-bs-toggle="tab" href="#CauHoiATTP"
                                    role="tab" aria-controls="CauHoiATTP" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px">Kiến thức ATTP</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="CauHoiATTP" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-11 col-sm-12 col-12">
                                        <input [(ngModel)]="CauHoiATTPService.BaseParameter.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="CauHoiATTPSearch()" />
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%" class="btn btn-success" title="Tìm"
                                            (click)="CauHoiATTPSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">
                                            Danh sách
                                            <span *ngIf="CauHoiATTPService.List">({{
                                                CauHoiATTPService.List.length }}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #CauHoiATTPSort="matSort"
                                                    [dataSource]="CauHoiATTPService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i"
                                                            style="text-align: center">
                                                            <div *ngIf="CauHoiATTPPaginator">
                                                                {{CauHoiATTPPaginator.pageSize * CauHoiATTPPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Description">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Nhóm
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Description}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Câu hỏi
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="white-space: normal;">
                                                            {{element.Name}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Đáp án
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="white-space: normal;">
                                                            <ul>
                                                                <li
                                                                    *ngFor="let itemCauHoiATTPQuestions of element.ListCauHoiATTPQuestions;">
                                                                    <div *ngIf="itemCauHoiATTPQuestions.is_ans"><b>{{itemCauHoiATTPQuestions.Name}} [Đáp án đúng]</b></div>
                                                                    <div *ngIf="!itemCauHoiATTPQuestions.is_ans">{{itemCauHoiATTPQuestions.Name}}</div>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row *matHeaderRowDef="CauHoiATTPService.DisplayColumns002"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: CauHoiATTPService.DisplayColumns002"></tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #CauHoiATTPPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                                    [showFirstLastButtons]></mat-paginator>

                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #CauHoiATTPSort="matSort"
                                                    [dataSource]="CauHoiATTPService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4" style="white-space: normal;">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Nhóm: </label>
                                                                    <label class="form-label">{{element.Description}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Câu hỏi: </label>
                                                                    <label class="form-label">{{element.Name}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Đáp án: </label>
                                                                    <ul>
                                                                        <li
                                                                            *ngFor="let itemCauHoiATTPQuestions of element.ListCauHoiATTPQuestions;">
                                                                            <div *ngIf="itemCauHoiATTPQuestions.is_ans">
                                                                                <b>{{itemCauHoiATTPQuestions.Name}}
                                                                                    [Đáp án đúng]</b></div>
                                                                            <div
                                                                                *ngIf="!itemCauHoiATTPQuestions.is_ans">
                                                                                {{itemCauHoiATTPQuestions.Name}}</div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row *matHeaderRowDef="CauHoiATTPService.DisplayColumnsMobile"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: CauHoiATTPService.DisplayColumnsMobile"></tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #CauHoiATTPPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="CauHoiATTPService.IsShowLoading"></app-loading>