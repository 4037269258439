<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-NguonVonDetail" data-bs-toggle="tab" href="#NguonVonDetail" role="tab"
                aria-controls="NguonVonDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Nguồn vốn</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="NguonVonDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="NguonVonSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="NguonVonService.FormData.Active" />
                        <label class="form-check-label" for="Active">Kích hoạt</label>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu đề [{{NguonVonService.FormData.ID}}]</label>
                        <input name="Name" [(ngModel)]="NguonVonService.FormData.Name" placeholder="Tiêu đề" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Phân loại nguồn vốn</label>
                        <input name="Description" [(ngModel)]="NguonVonService.FormData.Description"
                            placeholder="Phân loại nguồn vốn" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Cơ quan cấp vốn</label>
                        <input name="StateAgencyName001" [(ngModel)]="NguonVonService.FormData.StateAgencyName001"
                            placeholder="Cơ quan cấp vốn" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Cơ quan nhận vốn</label>
                        <input name="StateAgencyName002" [(ngModel)]="NguonVonService.FormData.StateAgencyName002"
                            placeholder="Cơ quan nhận vốn" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Phòng ban</label>
                        <input name="AgencyDepartmentName" [(ngModel)]="NguonVonService.FormData.AgencyDepartmentName"
                            placeholder="Phòng ban" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Người chịu trách nhiệm</label>
                        <input name="ThanhVienName" [(ngModel)]="NguonVonService.FormData.ThanhVienName"
                            placeholder="Người chịu trách nhiệm" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Năm tài chính</label>
                        <input name="Nam" [(ngModel)]="NguonVonService.FormData.Nam" placeholder="0" type="number"
                            class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày bắt đầu</label>
                        <input [ngModel]="NguonVonService.FormData.NgayBatDau | date:'yyyy-MM-dd'"
                            (ngModelChange)="DateNgayBatDau($event)" [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                            class="form-control" autocomplete="off" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày kết thúc</label>
                        <input [ngModel]="NguonVonService.FormData.NgayKetThuc | date:'yyyy-MM-dd'"
                            (ngModelChange)="DateNgayKetThuc($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                            type="date" class="form-control" autocomplete="off" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tổng vốn: {{NguonVonService.FormData.TongCong | number:'1.0-0'}}
                            đồng</label>
                        <input name="TongCong" [(ngModel)]="NguonVonService.FormData.TongCong" placeholder="0"
                            type="number" class="form-control" style="text-align: right; font-weight: bold;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label" style="color: red;">Đã chi: {{NguonVonService.FormData.DaChi |
                            number:'1.0-0'}}
                            đồng</label>
                        <input name="DaChi" [(ngModel)]="NguonVonService.FormData.DaChi" placeholder="0" type="number"
                            disabled class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label" style="color: green;">Còn lại: {{NguonVonService.FormData.ConLai |
                            number:'1.0-0'}}
                            đồng</label>
                        <input name="ConLai" [(ngModel)]="NguonVonService.FormData.ConLai" placeholder="0" type="number"
                            disabled class="form-control" style="text-align: right;">
                    </div>
                </div>
            </div>
            <div class="row gx-4" *ngIf="NguonVonService.FormData.ID>0">              
                <div class="card mb-4 card-danger">
                    <div class="card-header">
                        <h5 class="card-title text-white">
                            Danh sách chi
                            <span *ngIf="NguonVonChiTietService.List">({{NguonVonChiTietService.List.length}}
                                items)</span>
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="table-outer">
                            <div class="table-responsive">
                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort #NguonVonSort="matSort"
                                    [dataSource]="NguonVonChiTietService.DataSource" class="table table-striped m-0">
                                    <ng-container matColumnDef="NgayBatDau">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Thời gian
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="width: 300px;">
                                            <div class="row gx-4">
                                                <div class="col-lg-4 col-sm-12 col-12">
                                                    Bắt đầu
                                                </div>
                                                <div class="col-lg-8 col-sm-12 col-12">
                                                    <input name="NgayBatDau{{element.ID}}"
                                                        [ngModel]="element.NgayBatDau | date:'yyyy-MM-dd'"
                                                        (ngModelChange)="DateNguonVonNgayBatDau(element, $event)"
                                                        [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                        class="form-control" autocomplete="off" style="text-align: right;">
                                                </div>
                                            </div>
                                            <div class="row gx-4">
                                                <div class="col-lg-4 col-sm-12 col-12">
                                                    Kết thúc
                                                </div>
                                                <div class="col-lg-8 col-sm-12 col-12">
                                                    <input name="NgayKetThuc{{element.ID}}"
                                                        [ngModel]="element.NgayKetThuc | date:'yyyy-MM-dd'"
                                                        (ngModelChange)="DateNguonVonNgayKetThuc(element, $event)"
                                                        [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                        class="form-control" autocomplete="off" style="text-align: right;">
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Nội dung
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <div class="row gx-4">
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <input class="form-control" type="text" placeholder="Tiêu đề"
                                                        name="Name{{element.ID}}" [(ngModel)]="element.Name" />
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <input class="form-control" type="text"
                                                        placeholder="Người chịu trách nhiệm"
                                                        name="Description{{element.ID}}"
                                                        [(ngModel)]="element.Description" />
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <input class="form-control" type="text" placeholder="Ghi chú"
                                                        name="Note{{element.ID}}" [(ngModel)]="element.Note" />
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="DaChi">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Đã chi
                                        </th>
                                        <td mat-cell *matCellDef="let element"
                                            style="text-align: right; color: red; font-weight: bold;">
                                            <div class="row gx-4">
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <input class="form-control" type="number" placeholder="0"
                                                        name="DaChi{{element.ID}}" [(ngModel)]="element.DaChi"
                                                        style="text-align: right; color: red; font-weight: bold;" />
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <b>{{element.DaChi |
                                                        number:
                                                        '1.0-0'}} đồng</b>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Save">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                        <td mat-cell *matCellDef="let element">
                                            <a class="btn btn-info" (click)="NguonVonChiTietSave(element)"><i
                                                    class="bi bi-sd-card"></i>
                                            </a>&nbsp;&nbsp;&nbsp;
                                            <a class="btn btn-danger" (click)="NguonVonChiTietDelete(element)"
                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                            </a>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="NguonVonChiTietService.DisplayColumns001"></tr>
                                    <tr mat-row *matRowDef="let row; columns: NguonVonChiTietService.DisplayColumns001">
                                    </tr>
                                </table>
                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                    #NguonVonChiTietPaginator="matPaginator"
                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                    [showFirstLastButtons]></mat-paginator>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="NguonVonService.IsShowLoading"></app-loading>