<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-PlanThamDinh" data-bs-toggle="tab"
                                    href="#PlanThamDinh" role="tab" aria-controls="PlanThamDinh" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px">Kế hoạch tổng hợp - Thành viên</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="PlanThamDinh" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-5 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Thành viên</label>
                                        <select class="form-select"
                                            [(ngModel)]="PlanThamDinhService.BaseParameter.ThanhVienID">                                          
                                            <option *ngFor="let item of ThanhVienService.List;" [value]="item.ID">
                                                {{item.Name}}-{{item.StateAgencyName}}-{{item.DanhMucChucDanhName}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Năm</label>
                                        <select class="form-select" [(ngModel)]="PlanThamDinhService.BaseParameter.Nam">
                                            <option *ngFor="let item of DownloadService.ListNam001;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Tháng</label>
                                        <select class="form-select"
                                            [(ngModel)]="PlanThamDinhService.BaseParameter.Thang">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of DownloadService.ListThang001;"
                                                [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input [(ngModel)]="PlanThamDinhService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="PlanThamDinhSearch()" />
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <div class="row gx-4">
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                                    (click)="PlanThamDinhSearch()"><i class="bi bi-search"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">
                                            Danh sách
                                            <span *ngIf="PlanThamDinhService.List">({{PlanThamDinhService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #PlanThamDinhSort="matSort"
                                                    [dataSource]="PlanThamDinhService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a style="width: 100%;" class="btn btn-success"
                                                                        (click)="PlanThamDinhAdd(element.ID)"
                                                                        *ngIf="element.ID > 0"><i
                                                                            class="bi bi-people"></i> Hồ sơ
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i"
                                                            style="text-align: center">
                                                            <div *ngIf="PlanThamDinhPaginator">
                                                                {{
                                                                PlanThamDinhPaginator.pageSize *
                                                                PlanThamDinhPaginator.pageIndex +
                                                                i +
                                                                1
                                                                }}
                                                                <br />
                                                                [{{element.ID}}]
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Nội dung
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a style="cursor: pointer" title="{{element.Name}}"
                                                                        class="link-primary"
                                                                        (click)="PlanThamDinhAdd(element.ID)"><b>{{element.Name}}</b></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    Bắt đầu: <a style="cursor: pointer"
                                                                        title="{{element.NgayBatDau}}"
                                                                        class="link-primary"
                                                                        (click)="PlanThamDinhAdd(element.ID)"><b>{{element.NgayBatDau
                                                                            | date:'dd/MM/yyyy'}}</b></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    Kết thúc: <a style="cursor: pointer"
                                                                        title="{{element.NgayKetThuc}}"
                                                                        class="link-primary"
                                                                        (click)="PlanThamDinhAdd(element.ID)"><b>{{element.NgayKetThuc
                                                                            | date:'dd/MM/yyyy'}}</b></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Description">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Cán bộ tham gia
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12"
                                                                    *ngFor="let itemPlanThamDinhThanhVien of element.ListPlanThamDinhThanhVien;">
                                                                    <a style="cursor: pointer;"
                                                                        title="{{itemPlanThamDinhThanhVien.ThanhVienName}}"
                                                                        class="link-primary"
                                                                        (click)="ThanhVienAdd(itemPlanThamDinhThanhVien.ID)"><b>{{itemPlanThamDinhThanhVien.ThanhVienName}}</b></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12"
                                                                    *ngFor="let itemPlanThamDinhDanhMucLayMau of element.ListPlanThamDinhDanhMucLayMau;">
                                                                    <a style="cursor: pointer;"
                                                                        title="{{itemPlanThamDinhDanhMucLayMau.ThanhVienName}}"
                                                                        class="link-primary"
                                                                        (click)="ThanhVienAdd(itemPlanThamDinhDanhMucLayMau.ID)"><b>{{itemPlanThamDinhDanhMucLayMau.ThanhVienName}}</b></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="PlanThamDinhService.DisplayColumns012"></tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: PlanThamDinhService.DisplayColumns012">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #PlanThamDinhPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>

                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #PlanThamDinhSort="matSort"
                                                    [dataSource]="PlanThamDinhService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">

                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="PlanThamDinhService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row;columns: PlanThamDinhService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #PlanThamDinhPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="PlanThamDinhService.IsShowLoading"></app-loading>