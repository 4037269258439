<div class="app-body">
  <div class="row">
    <div class="col-xxl-12">
      <div class="card" style="margin-left: 1px">
        <div class="card-body">
          <div class="custom-tabs-container">
            <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="nav-link active" id="tab-ATTPInfo" data-bs-toggle="tab" href="#ATTPInfo" role="tab"
                  aria-controls="ATTPInfo" aria-selected="true">
                  <span class="badge bg-info" style="font-size: 16px">Hồ sơ Đăng ký cấp chứng nhận đủ điều kiện an toàn
                    thực phẩm</span>
                </a>
              </li>
            </ul>
            <div class="tab-content" id="customTabContent">
              <div class="tab-pane fade show active" id="ATTPInfo" role="tabpanel">
                <div class="row gx-4">                
                  <div class="col-lg-10 col-sm-12 col-12">
                    <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                    <input [(ngModel)]="ATTPInfoService.BaseParameter.SearchString" placeholder="Tìm ..."
                      class="form-control" (keyup.enter)="ATTPInfoSearch()" />
                  </div>
                  <div class="col-lg-2 col-sm-6 col-12">
                    <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                    <div class="row gx-4">
                      <div class="col-lg-6 col-sm-6 col-6">
                        <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="ATTPInfoSearch()"><i
                            class="bi bi-search"></i></a>
                      </div>
                      <div class="col-lg-6 col-sm-6 col-6">
                        <a style="width: 100%;" class="btn btn-success" title="Thêm mới" (click)="ATTPInfoAdd(0)"><i
                            class="bi bi-plus"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-4 card-info">
                  <div class="card-header">
                    <h5 class="card-title text-white">
                      Danh sách
                      <span *ngIf="ATTPInfoService.List">({{ ATTPInfoService.List.length }} items)</span>
                    </h5>
                  </div>
                  <div class="card-body">
                    <div class="table-outer">
                      <div class="table-responsive">
                        <table *ngIf="!NotificationService.IsMobile" mat-table matSort #ATTPInfoSort="matSort"
                          [dataSource]="ATTPInfoService.DataSource" class="table table-striped m-0">
                          <ng-container matColumnDef="STT">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              STT
                            </th>
                            <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                              <div *ngIf="ATTPInfoPaginator">
                                {{
                                ATTPInfoPaginator.pageSize *
                                ATTPInfoPaginator.pageIndex +
                                i +
                                1
                                }}
                              </div>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="ID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              ID
                            </th>
                            <td mat-cell *matCellDef="let element">
                              {{ element.ID }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="NgayGhiNhan">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ngày đăng ký
                            </th>
                            <td mat-cell *matCellDef="let element">
                              <a style="cursor: pointer" title="{{element.NgayGhiNhan}}" class="link-primary"
                                (click)="ATTPInfoAddByID(element.ID)"><b>{{element.NgayGhiNhan
                                  | date:'dd/MM/yyyy HH:mm:ss'}}</b></a>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="CompanyInfoName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Đơn vị Tổ chức
                            </th>
                            <td mat-cell *matCellDef="let element">                              
                              <a style="cursor: pointer" title="{{element.CompanyInfoName}}" class="link-primary"
                              (click)="CompanyInfoAdd(element.ParentID)"><b>{{element.CompanyInfoName}}</b></a>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                            </th>
                            <td mat-cell *matCellDef="let element">
                              <a style="cursor: pointer" title="{{element.Name}}" class="link-primary"
                                ><b>{{element.Name}}</b></a>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="cer_code">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã hồ sơ
                            </th>
                            <td mat-cell *matCellDef="let element">
                              <a style="cursor: pointer" title="{{element.cer_code}}" class="link-primary"
                                (click)="ATTPInfoAddByID(element.ID)"><b>{{element.cer_code}}</b></a>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="DanhMucATTPLoaiHoSoName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại hồ sơ
                            </th>
                            <td mat-cell *matCellDef="let element">
                              {{element.DanhMucATTPLoaiHoSoName}}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="DanhMucATTPTinhTrangName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tình Trạng Thẩm Định
                            </th>
                            <td mat-cell *matCellDef="let element">
                              {{element.DanhMucATTPTinhTrangName}}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="Save">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                            <td mat-cell *matCellDef="let element">
                              <a class="btn btn-danger" (click)="ATTPInfoDelete(element)" *ngIf="element.ID > 0"><i
                                  class="bi bi-trash"></i>
                              </a>
                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="ATTPInfoService.DisplayColumns005"></tr>
                          <tr mat-row *matRowDef="
                              let row;
                              columns: ATTPInfoService.DisplayColumns005
                            "></tr>
                        </table>
                        <mat-paginator *ngIf="!NotificationService.IsMobile" #ATTPInfoPaginator="matPaginator"
                          [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                          [showFirstLastButtons]></mat-paginator>

                        <table *ngIf="NotificationService.IsMobile" mat-table matSort #ATTPInfoSort="matSort"
                          [dataSource]="ATTPInfoService.DataSource" class="table table-striped m-0">
                          <ng-container matColumnDef="STT">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Danh sách
                            </th>
                            <td mat-cell *matCellDef="let element; index as i">
                              <div class="row gx-4">
                                <div class="col-lg-12 col-sm-12 col-12">
                                  <label>Ngày đăng ký: </label>
                                  <br />
                                  <a style="cursor: pointer" title="{{element.cer_code}}"
                                    class="link-primary form-label"
                                    (click)="ATTPInfoAddByID(element.ID)"><b>{{element.NgayGhiNhan
                                      |
                                      date:'dd/MM/yyyy HH:mm:ss'}}</b></a>
                                </div>
                                <div class="col-lg-12 col-sm-12 col-12">
                                  <label>Mã số: </label>
                                  <br />
                                  <a style="cursor: pointer" title="{{element.cer_code}}" class="link-primary"
                                    (click)="ATTPInfoAddByID(element.ID)"><b>{{element.cer_code}}</b></a>
                                </div>
                                <div class="col-lg-12 col-sm-12 col-12">
                                  <label>Loại hồ sơ: </label>
                                  <label class="form-label">
                                    {{element.DanhMucATTPLoaiHoSoName}}</label>
                                </div>
                                <div class="col-lg-12 col-sm-12 col-12">
                                  <label>Tình trạng: </label>
                                  <label class="form-label">
                                    {{element.DanhMucATTPTinhTrangName}}</label>
                                </div>
                                <!-- <div class="col-lg-12 col-sm-12 col-12">
                                  <label>Xếp loại: </label>
                                  <label class="form-label">
                                    {{element.DanhMucATTPXepLoaiName}}</label>
                                </div> -->
                                <div class="row gx-4">
                                  <div class="col-lg-12 col-sm-12 col-12">
                                    <a class="btn btn-danger" style="width: 100%" (click)="ATTPInfoDelete(element)"
                                      *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                    </a>
                                  </div>
                                  <br />
                                  <br />
                                </div>
                              </div>
                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="
                              ATTPInfoService.DisplayColumnsMobile
                            "></tr>
                          <tr mat-row *matRowDef="
                              let row;
                              columns: ATTPInfoService.DisplayColumnsMobile
                            "></tr>
                        </table>
                        <mat-paginator *ngIf="NotificationService.IsMobile" #ATTPInfoPaginator="matPaginator"
                          [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                          [showFirstLastButtons]></mat-paginator>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loading *ngIf="ATTPInfoService.IsShowLoading"></app-loading>