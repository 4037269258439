import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';

import { Report } from 'src/app/shared/Report.model';
import { ReportService } from 'src/app/shared/Report.service';

import { CompanyInfo } from 'src/app/shared/CompanyInfo.model';
import { CompanyInfoService } from 'src/app/shared/CompanyInfo.service';

import { PlanThamDinh } from 'src/app/shared/PlanThamDinh.model';
import { PlanThamDinhService } from 'src/app/shared/PlanThamDinh.service';
import { PlanThamDinhCompanies } from 'src/app/shared/PlanThamDinhCompanies.model';
import { PlanThamDinhCompaniesService } from 'src/app/shared/PlanThamDinhCompanies.service';


import { CompanyInfoDetailComponent } from '../company-info-detail/company-info-detail.component';
import { PlanThamDinhDetailComponent } from '../plan-tham-dinh-detail/plan-tham-dinh-detail.component';
import { PlanThamDinhCompaniesDetailComponent } from '../plan-tham-dinh-companies-detail/plan-tham-dinh-companies-detail.component';
import { PlanThamDinhDanhMucLayMauDetailGiamSatDuLuongComponent } from '../plan-tham-dinh-danh-muc-lay-mau-detail-giam-sat-du-luong/plan-tham-dinh-danh-muc-lay-mau-detail-giam-sat-du-luong.component';
import { PlanThamDinhDetailGiamSatDuLuong001Component } from '../plan-tham-dinh-detail-giam-sat-du-luong001/plan-tham-dinh-detail-giam-sat-du-luong001.component';
import { PlanThamDinhDetailNhuyenTheHaiManhVo001Component } from '../plan-tham-dinh-detail-nhuyen-the-hai-manh-vo001/plan-tham-dinh-detail-nhuyen-the-hai-manh-vo001.component';
import { PlanThamDinhDanhMucLayMauDetailNhuyenTheHaiManhVoComponent } from '../plan-tham-dinh-danh-muc-lay-mau-detail-nhuyen-the-hai-manh-vo/plan-tham-dinh-danh-muc-lay-mau-detail-nhuyen-the-hai-manh-vo.component';
import { PlanThamDinhDetailAnToanThucPhamSauThuHoach001Component } from '../plan-tham-dinh-detail-an-toan-thuc-pham-sau-thu-hoach001/plan-tham-dinh-detail-an-toan-thuc-pham-sau-thu-hoach001.component';
import { PlanThamDinhDanhMucLayMauDetailAnToanThucPhamSauThuHoachComponent } from '../plan-tham-dinh-danh-muc-lay-mau-detail-an-toan-thuc-pham-sau-thu-hoach/plan-tham-dinh-danh-muc-lay-mau-detail-an-toan-thuc-pham-sau-thu-hoach.component';
import { PlanThamDinhDetailChuoiCungUngAnToanThucPhamComponent } from '../plan-tham-dinh-detail-chuoi-cung-ung-an-toan-thuc-pham/plan-tham-dinh-detail-chuoi-cung-ung-an-toan-thuc-pham.component';
import { PlanThamDinhDetailChuoiCungUngAnToanThucPhamKeHoachLayMauComponent } from '../plan-tham-dinh-detail-chuoi-cung-ung-an-toan-thuc-pham-ke-hoach-lay-mau/plan-tham-dinh-detail-chuoi-cung-ung-an-toan-thuc-pham-ke-hoach-lay-mau.component';



@Component({
  selector: 'app-company-info-thong-ke-v1',
  templateUrl: './company-info-thong-ke-v1.component.html',
  styleUrls: ['./company-info-thong-ke-v1.component.css']
})
export class CompanyInfoThongKeV1Component implements OnInit {

  @ViewChild('ReportSort') ReportSort: MatSort;
  @ViewChild('ReportPaginator') ReportPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,

    public ReportService: ReportService,

    public CompanyInfoService: CompanyInfoService,

    public PlanThamDinhService: PlanThamDinhService,    
    public PlanThamDinhCompaniesService: PlanThamDinhCompaniesService,

  ) {
    
   
  }

  ngOnInit(): void {
    this.CompanyInfoSearch();
  }
 

  CompanyInfoSearch() {
    this.CompanyInfoService.ComponentGet000ToListAsync();
  }
  CompanyInfoFilter(searchString: string) {
    this.CompanyInfoService.Filter000(searchString);
  }

  ReportSearch() {
    this.ReportService.IsShowLoading = true;
    this.ReportService.BaseParameter.ID = environment.InitializationNumber;
    this.ReportService.ReportCompanyInfoV1ToListAsync().subscribe(
      res => {
        this.ReportService.List = (res as Report[]);        
        this.ReportService.DataSource = new MatTableDataSource(this.ReportService.List);
        this.ReportService.DataSource.sort = this.ReportSort;
        this.ReportService.DataSource.paginator = this.ReportPaginator;
        this.ReportService.IsShowLoading = false;
      },
      err => {
        this.ReportService.IsShowLoading = false;
      }
    );
  }
  CompanyInfoAdd(ID: number) {
    this.CompanyInfoService.IsShowLoading = true;
    this.CompanyInfoService.BaseParameter.ID = ID;
    this.CompanyInfoService.GetByIDAsync().subscribe(
      res => {
        this.CompanyInfoService.FormData = res as CompanyInfo;        
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(CompanyInfoDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {          
        });
        this.CompanyInfoService.IsShowLoading = false;
      },
      err => {
        this.CompanyInfoService.IsShowLoading = false;
      }
    );
  }

  PlanThamDinhAdd(ID: number) {
    this.CompanyInfoService.IsShowLoading = true;
    this.PlanThamDinhService.BaseParameter.ID = ID;
    this.PlanThamDinhService.GetByIDAsync().subscribe(
      res => {
        this.PlanThamDinhService.FormData = res as PlanThamDinh;        
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(PlanThamDinhDetailChuoiCungUngAnToanThucPhamComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => { 
          this.CompanyInfoSearch();         
        });
        this.CompanyInfoService.IsShowLoading = false;
      },
      err => {
        this.CompanyInfoService.IsShowLoading = false;
      }
    );
  }

  PlanThamDinhDetailAdd(ID: number) {
    // this.CompanyInfoService.IsShowLoading = true;
    // this.PlanThamDinhCompaniesService.BaseParameter.ID = ID;
    // this.PlanThamDinhCompaniesService.GetByIDAsync().subscribe(
    //   res => {
    //     this.PlanThamDinhCompaniesService.FormData = res as PlanThamDinhCompanies;        
    //     const dialogConfig = new MatDialogConfig();
    //     dialogConfig.disableClose = true;
    //     dialogConfig.autoFocus = true;
    //     dialogConfig.width = environment.DialogConfigWidth;
    //     dialogConfig.data = { ID: ID };
    //     const dialog = this.dialog.open(PlanThamDinhDetailChuoiCungUngAnToanThucPhamKeHoachLayMauComponent, dialogConfig);
    //     dialog.afterClosed().subscribe(() => {   
    //       this.CompanyInfoSearch();       
    //     });
    //     this.CompanyInfoService.IsShowLoading = false;
    //   },
    //   err => {
    //     this.CompanyInfoService.IsShowLoading = false;
    //   }
    // );
  }
}