import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoHang } from 'src/app/shared/LoHang.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class LoHangService extends BaseService{
    List: LoHang[] | undefined;
    ListFilter: LoHang[] | undefined;
    FormData!: LoHang;


    DisplayColumns001: string[] = ['STT', 'ID', 'CompanyInfoName', 'Name', 'NgayGhiNhan', 'DanhMucATTPTinhTrangName', 'Save'];

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "LoHang";
    }
}

