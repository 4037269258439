import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/TruyXuatNguonGoc/Download.service';

import { DistrictData } from 'src/app/shared/DistrictData.model';
import { DistrictDataService } from 'src/app/shared/DistrictData.service';

import { TruyXuatNguonGocToChuc } from 'src/app/shared/TruyXuatNguonGoc/ToChuc.model';
import { TruyXuatNguonGocToChucService } from 'src/app/shared/TruyXuatNguonGoc/ToChuc.service';

import { DanhMucUngDung } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.model';
import { DanhMucUngDungService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.service';

import { CompanyInfo } from 'src/app/shared/CompanyInfo.model';
import { CompanyInfoService } from 'src/app/shared/CompanyInfo.service';
import { CompanyInfoDetailComponent } from '../company-info-detail/company-info-detail.component';

@Component({
  selector: 'app-truy-xuat-nguon-goc-kho-tem001',
  templateUrl: './truy-xuat-nguon-goc-kho-tem001.component.html',
  styleUrls: ['./truy-xuat-nguon-goc-kho-tem001.component.css']
})
export class TruyXuatNguonGocKhoTem001Component implements OnInit {

  @ViewChild('DistrictDataSort') DistrictDataSort: MatSort;
  @ViewChild('DistrictDataPaginator') DistrictDataPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DistrictDataService: DistrictDataService,

    public TruyXuatNguonGocToChucService: TruyXuatNguonGocToChucService,

    public DanhMucUngDungService: DanhMucUngDungService,

    public CompanyInfoService: CompanyInfoService,
  ) { }

  ngOnInit(): void {
    this.DanhMucUngDungService.GetLogin();

  }

  DistrictDataSearch() {
    this.DistrictDataService.IsShowLoading = true;
    this.DistrictDataService.BaseParameter.ParentID = environment.ProvinceDataIDBenTre;
    this.DistrictDataService.BaseParameter.Active = true;
    this.DistrictDataService.GetByParentIDAndActiveToListAsync().subscribe(
      res => {
        this.DistrictDataService.List = (res as DistrictData[]).sort((a, b) => (a.TemPhieuSoLuong < b.TemPhieuSoLuong ? 1 : -1));
        for (let i = 0; i < this.DistrictDataService.List.length; i++) {
          this.DistrictDataService.List[i].TemPhieuSoLuong = environment.InitializationNumber;
          this.DistrictDataService.List[i].TemPhieuSuDung = environment.InitializationNumber;
          this.DistrictDataService.List[i].TemPhieuConLai = environment.InitializationNumber;
        }
        this.TruyXuatNguonGocToChucService.BaseParameter.SearchString = environment.InitializationString;
        this.TruyXuatNguonGocToChucService.BaseParameter.DanhMucUngDungID = Number(localStorage.getItem(environment.TruyXuatNguonGocDanhMucUngDungID));
        this.TruyXuatNguonGocToChucService.GetByDanhMucUngDungID_SearchStringToListAsync().subscribe(
          res => {
            this.TruyXuatNguonGocToChucService.ListFilter001 = (res as TruyXuatNguonGocToChuc[]).sort((a, b) => (a.TemPhieuSoLuong < b.TemPhieuSoLuong ? 1 : -1));
            this.TruyXuatNguonGocToChucService.List001 = [];
            this.CompanyInfoService.BaseParameter.ListID = [];
            for (let i = 0; i < this.TruyXuatNguonGocToChucService.ListFilter001.length; i++) {
              let TruyXuatNguonGocToChuc = this.TruyXuatNguonGocToChucService.ListFilter001[i];
              if (TruyXuatNguonGocToChuc.TemPhieuSoLuong > 0) {
                this.TruyXuatNguonGocToChucService.List001.push(TruyXuatNguonGocToChuc);
                this.CompanyInfoService.BaseParameter.ListID.push(TruyXuatNguonGocToChuc.IDToken);
              }
            }

            this.CompanyInfoService.GetByListIDToListAsync().subscribe(
              res => {
                this.CompanyInfoService.List = res as CompanyInfo[];
                for (let i = 0; i < this.CompanyInfoService.List.length; i++) {
                  let CompanyInfo = this.CompanyInfoService.List[i];
                  for (let j = 0; j < this.TruyXuatNguonGocToChucService.List001.length; j++) {
                    let TruyXuatNguonGocToChuc = this.TruyXuatNguonGocToChucService.List001[j];
                    if (CompanyInfo.ID == TruyXuatNguonGocToChuc.IDToken) {
                      for (let k = 0; k < this.DistrictDataService.List.length; k++) {
                        if (CompanyInfo.DistrictDataID == this.DistrictDataService.List[k].ID) {
                          this.DistrictDataService.List[k].TemPhieuSoLuong = this.DistrictDataService.List[k].TemPhieuSoLuong + TruyXuatNguonGocToChuc.TemPhieuSoLuong;
                          this.DistrictDataService.List[k].TemPhieuSuDung = this.DistrictDataService.List[k].TemPhieuSuDung + TruyXuatNguonGocToChuc.TemPhieuSuDung;
                          this.DistrictDataService.List[k].TemPhieuConLai = this.DistrictDataService.List[k].TemPhieuConLai + TruyXuatNguonGocToChuc.TemPhieuConLai;
                        }
                      }
                    }
                  }
                }
              },
              err => {
              },
              () => {
                this.DistrictDataService.DataSource = new MatTableDataSource(this.DistrictDataService.List);
                this.DistrictDataService.DataSource.sort = this.DistrictDataSort;
                this.DistrictDataService.DataSource.paginator = this.DistrictDataPaginator;
                this.DistrictDataService.IsShowLoading = false;
              }
            );
          },
          err => {
          }
        );        
      },
      err => {
        this.DistrictDataService.IsShowLoading = false;
      }
    );
  }
}