<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-CompanyInfoVungTrongDetail" data-bs-toggle="tab"
                href="#CompanyInfoVungTrongDetail" role="tab" aria-controls="CompanyInfoVungTrongDetail"
                aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Đăng ký mã số vùng trồng</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="CompanyInfoVungTrongDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="row gx-4">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <a style="width: 100%;" title="Hoàn thành" (click)="CompanyInfoVungTrongSave()"
                            class="btn btn-info"><i class="bi bi-sd-card"></i>
                            Lưu bước 01</a>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <h4>Bước 01: Nhập Thông tin</h4>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-12 col-sm-12 col-12">
                            <h4 class="text-center">Thông tin chung</h4>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Loại hồ sơ</label>
                                <select class="form-select" name="DanhMucATTPLoaiHoSoID"
                                    [(ngModel)]="CompanyInfoVungTrongService.FormData.DanhMucATTPLoaiHoSoID">
                                    <option *ngFor="let item of DanhMucATTPLoaiHoSoService.List;" [value]="item.ID">
                                        {{item.Name}}</option>
                                </select>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Tình trạng</label>
                                <input name="DanhMucATTPTinhTrangName" [(ngModel)]="CompanyInfoVungTrongService.FormData.DanhMucATTPTinhTrangName"
                                    placeholder="Tình trạng" type="text" class="form-control" disabled>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Xếp loại</label>
                                <input name="DanhMucATTPLoaiHoSoName" [(ngModel)]="CompanyInfoVungTrongService.FormData.DanhMucATTPLoaiHoSoName"
                                    placeholder="Xếp loại" type="text" class="form-control" disabled>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Ghi nhận</label>
                                <input [ngModel]="CompanyInfoVungTrongService.FormData.NgayGhiNhan | date:'yyyy-MM-dd'"
                                    (ngModelChange)="DateNgayGhiNhan($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                                    type="date" class="form-control" autocomplete="off">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Mã hồ sơ</label>
                                <input name="MaHoSo" [(ngModel)]="CompanyInfoVungTrongService.FormData.MaHoSo"
                                    placeholder="Mã hồ sơ" type="text" class="form-control" disabled>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Mã số vùng trồng (nếu có)</label>
                                <input name="Name" [(ngModel)]="CompanyInfoVungTrongService.FormData.Name"
                                    placeholder="Mã số vùng trồng" type="text" class="form-control" disabled>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Đơn vị Tổ chức</label>
                                <input name="CompanyInfoName"
                                    [(ngModel)]="CompanyInfoVungTrongService.FormData.CompanyInfoName"
                                    placeholder="Đơn vị Tổ chức" type="text" class="form-control">
                                <!-- <mat-select class="form-control" name="CompanyInfoVungTrongService.FormData.ParentID"
                                    *ngIf="CompanyInfoVungTrongService.FormData.ID==0"
                                    (selectionChange)="CompanyInfoChange()"
                                    [(ngModel)]="CompanyInfoVungTrongService.FormData.ParentID">
                                    <input placeholder="Tìm..." class="form-control"
                                        (keyup)="CompanyInfoFilter($event.target.value)">
                                    <mat-option *ngFor="let item of CompanyInfoService.ListFilter" [value]="item.ID">
                                        {{item.Name}} - {{item.phone}} - {{item.DKKD}}
                                    </mat-option>
                                </mat-select> -->
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Đại diện cơ sở (nếu có)</label>
                                <input name="DaiDienCoSo" [(ngModel)]="CompanyInfoVungTrongService.FormData.DaiDienCoSo"
                                    placeholder="Đại diện cơ sở" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Chức danh (nếu có)</label>
                                <input name="DaiDienCoSoChucVu"
                                    [(ngModel)]="CompanyInfoVungTrongService.FormData.DaiDienCoSoChucVu"
                                    placeholder="Chức danh" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Điện thoại (dùng để gửi thông báo)</label>
                                <input name="DaiDienCoSoDienThoai"
                                    [(ngModel)]="CompanyInfoVungTrongService.FormData.DaiDienCoSoDienThoai"
                                    placeholder="Điện thoại" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Email (dùng để gửi thông báo)</label>
                                <input name="DaiDienCoSoEmail"
                                    [(ngModel)]="CompanyInfoVungTrongService.FormData.DaiDienCoSoEmail"
                                    placeholder="Email" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Ghi chú</label>
                                <input name="Note" [(ngModel)]="CompanyInfoVungTrongService.FormData.Note"
                                    placeholder="Ghi chú" type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row gx-4">
                        <div class="col-lg-12 col-sm-12 col-12">
                            <h4 class="text-center">Thông tin địa chỉ</h4>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Địa chỉ đầy đủ</label>
                                <input name="DiaChi" [(ngModel)]="CompanyInfoVungTrongService.FormData.DiaChi"
                                    placeholder="Địa chỉ" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Kinh độ</label>
                                <input name="KinhDo" [(ngModel)]="CompanyInfoVungTrongService.FormData.KinhDo"
                                    placeholder="Kinh độ" type="number" class="form-control" style="text-align: right;">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Vĩ độ</label>
                                <input name="ViDo" [(ngModel)]="CompanyInfoVungTrongService.FormData.ViDo"
                                    placeholder="Vĩ độ" type="number" class="form-control" style="text-align: right;">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Huyện</label>
                                <select class="form-select" name="DistrictDataID"
                                    [(ngModel)]="CompanyInfoVungTrongService.FormData.DistrictDataID"
                                    (change)="WardDataSearch()">
                                    <option *ngFor="let item of DistrictDataService.List;" [value]="item.ID">
                                        {{item.Name}}</option>
                                </select>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Xã</label>
                                <select class="form-select" name="WardDataID"
                                    [(ngModel)]="CompanyInfoVungTrongService.FormData.WardDataID">
                                    <option *ngFor="let item of WardDataService.List;" [value]="item.ID">
                                        {{item.Name}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Thôn ấp</label>
                                <input name="ThonAp" [(ngModel)]="CompanyInfoVungTrongService.FormData.ThonAp"
                                    placeholder="Thôn ấp" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Bản đồ</label>
                                <div>
                                    <a style="width: 100%;" class="btn btn-success" title="Bản đồ"
                                        (click)="MapLoad()"><i class="bi bi-pin-map"></i> Bản đồ</a>
                                </div>
                                <div class="row gx-4" style="position: relative;padding: 10px">
                                    <div class="map" id="map" #map style="height: 0px; width: 100%;padding: 0;">
                                        <div style="z-index: 1000; position: absolute; top: 0;">
                                            <img src="{{domainURL}}assets/image/vungtrong.png" />
                                        </div>
                                        <div
                                            style="z-index: 1000; position: absolute; bottom: 0; width: 100%; height: 20px; background-color: #ffffff;">
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-12 col-sm-12 col-12">
                            <h4 class="text-center">Thông tin sản xuất</h4>
                        </div>
                        <div class="col-lg-4 col-sm-12 col-12">
                            <label class="form-label">Sản phẩm</label>
                            <input name="SanPham" [(ngModel)]="CompanyInfoVungTrongService.FormData.SanPham"
                                placeholder="Sản phẩm" type="text" class="form-control">
                        </div>
                        <div class="col-lg-4 col-sm-12 col-12">
                            <label class="form-label">Thị trường</label>
                            <input name="ThiTruong" [(ngModel)]="CompanyInfoVungTrongService.FormData.ThiTruong"
                                placeholder="Thị trường" type="text" class="form-control">
                        </div>
                        <div class="col-lg-4 col-sm-12 col-12">
                            <label class="form-label">Diện tích (m2)</label>
                            <input name="DienTich" [(ngModel)]="CompanyInfoVungTrongService.FormData.DienTich"
                                placeholder="Diện tích (m2)" type="number" class="form-control"
                                style="text-align: right;">
                        </div>
                    </div>
                </div>
                <div class="row gx-4">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <a style="width: 100%;" title="Hoàn thành" (click)="CompanyInfoVungTrongSave()"
                            class="btn btn-info"><i class="bi bi-sd-card"></i>
                            Lưu bước 01</a>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-success">
                        <div class="card-header">
                            <h5 class="card-title text-white">Bước 02: Thêm vị trí vùng trồng
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoVungTrongToaDoSort="matSort"
                                        [dataSource]="CompanyInfoVungTrongToaDoService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="Name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vị trí
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Vị trí"
                                                    name="Name{{ element.ID }}" [(ngModel)]="element.Name" disabled />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="KinhDo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Kinh độ
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="number" placeholder="Kinh độ"
                                                    style="text-align: right;" name="KinhDo{{ element.ID }}"
                                                    [(ngModel)]="element.KinhDo" />
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="ViDo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vĩ độ
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="number" placeholder="Vĩ độ"
                                                    style="text-align: right;" name="ViDo{{ element.ID }}"
                                                    [(ngModel)]="element.ViDo" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="IsTrungTam">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Trung tâm
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox"
                                                        name="IsTrungTam{{ element.ID }}"
                                                        [(ngModel)]="element.IsTrungTam" disabled />
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-info"
                                                    (click)="CompanyInfoVungTrongToaDoSave(element)"><i
                                                        class="bi bi-sd-card"></i></a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoVungTrongToaDoService.DisplayColumns001">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoVungTrongToaDoService.DisplayColumns001;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #CompanyInfoVungTrongToaDoPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoVungTrongToaDoSort="matSort"
                                        [dataSource]="CompanyInfoVungTrongToaDoService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Tên: </label>
                                                        <input class="form-control" type="number" placeholder="Tên"
                                                            name="Name{{ element.ID }}" [(ngModel)]="element.Name"
                                                            disabled />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Kinh độ: </label>
                                                        <input class="form-control" type="number" placeholder="Kinh độ"
                                                            style="text-align: right;" name="KinhDo{{ element.ID }}"
                                                            [(ngModel)]="element.KinhDo" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Vĩ độ: </label>
                                                        <input class="form-control" type="number" placeholder="Vĩ độ"
                                                            style="text-align: right;" name="ViDo{{ element.ID }}"
                                                            [(ngModel)]="element.ViDo" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Trung tâm </label>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="checkbox"
                                                                name="IsTrungTam{{ element.ID }}"
                                                                [(ngModel)]="element.IsTrungTam" disabled />
                                                        </div>
                                                    </div>
                                                    <div class="row gx-4">
                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                            <a class="btn btn-info" style="width: 100%"
                                                                (click)="CompanyInfoVungTrongToaDoSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>
                                                            <a class="btn btn-danger" style="width: 100%;"
                                                                (click)="CompanyInfoVungTrongToaDoDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </div>
                                                        <br />
                                                        <br />
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoVungTrongToaDoService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoVungTrongToaDoService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #CompanyInfoVungTrongToaDoPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-warning">
                        <div class="card-header">
                            <h5 class="card-title text-white">Bước 03: Thêm thông tin nông hộ
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoVungTrongNongHoSort="matSort"
                                        [dataSource]="CompanyInfoVungTrongNongHoService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="ThanhVienID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nông hộ
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Nông hộ"
                                                    name="Name{{ element.ID }}" [(ngModel)]="element.Name" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="CCCD">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>CCCD
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="CCCD"
                                                    name="CCCD{{ element.ID }}" [(ngModel)]="element.CCCD" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="DienThoai">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Điện thoại"
                                                    name="DienThoai{{ element.ID }}" [(ngModel)]="element.DienThoai" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Email">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Email"
                                                    name="Email{{ element.ID }}" [(ngModel)]="element.Email" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="NamSinh">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Năm sinh
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="number" placeholder="Năm sinh"
                                                    style="text-align: right;" name="NamSinh{{ element.ID }}"
                                                    [(ngModel)]="element.NamSinh" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="DiaChi">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Địa chỉ
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Địa chỉ"
                                                    name="DiaChi{{ element.ID }}" [(ngModel)]="element.DiaChi" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="KinhDo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Kinh độ
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="number" placeholder="Kinh độ"
                                                    style="text-align: right;" name="KinhDo{{ element.ID }}"
                                                    [(ngModel)]="element.KinhDo" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="ViDo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vĩ độ
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="number" placeholder="Vĩ độ"
                                                    style="text-align: right;" name="ViDo{{ element.ID }}"
                                                    [(ngModel)]="element.ViDo" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Giong">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Giống
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Giống"
                                                    name="Giong{{ element.ID }}" [(ngModel)]="element.Giong" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="NamTrong">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Năm trồng
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="number" placeholder="Năm trồng"
                                                    style="text-align: right;" name="NamTrong{{ element.ID }}"
                                                    [(ngModel)]="element.NamTrong" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="ChungNhanVietGap">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng nhận VietGap
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Chứng nhận VietGap"
                                                    name="ChungNhanVietGap{{ element.ID }}"
                                                    [(ngModel)]="element.ChungNhanVietGap" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-info"
                                                    (click)="CompanyInfoVungTrongNongHoSave(element)"><i
                                                        class="bi bi-sd-card"></i>
                                                </a>&nbsp;&nbsp;&nbsp;
                                                <a class="btn btn-danger"
                                                    (click)="CompanyInfoVungTrongNongHoDelete(element)"
                                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoVungTrongNongHoService.DisplayColumns001">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoVungTrongNongHoService.DisplayColumns001;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #CompanyInfoVungTrongNongHoPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoVungTrongNongHoSort="matSort"
                                        [dataSource]="CompanyInfoVungTrongNongHoService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Nông hộ: </label>
                                                        <input class="form-control" type="text" placeholder="Nông hộ"
                                                            name="Name{{element.ID}}" [(ngModel)]="element.Name" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>CCCD: </label>
                                                        <input class="form-control" type="text" placeholder="CCCD"
                                                            name="CCCD{{element.ID}}" [(ngModel)]="element.CCCD" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Điện thoại: </label>
                                                        <input class="form-control" type="text" placeholder="Điện thoại"
                                                            name="DienThoai{{element.ID}}"
                                                            [(ngModel)]="element.DienThoai" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Email: </label>
                                                        <input class="form-control" type="text" placeholder="Email"
                                                            name="Email{{element.ID}}" [(ngModel)]="element.Email"
                                                            disabled />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Năm sinh: </label>
                                                        <input class="form-control" type="number" placeholder="NamSinh"
                                                            name="NamSinh{{element.ID}}"
                                                            [(ngModel)]="element.NamSinh" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>DiaChi: </label>
                                                        <input class="form-control" type="text" placeholder="DiaChi"
                                                            name="DiaChi{{element.ID}}" [(ngModel)]="element.DiaChi" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Kinh độ: </label>
                                                        <input class="form-control" type="number" placeholder="KinhDo"
                                                            name="KinhDo{{element.ID}}" [(ngModel)]="element.KinhDo" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Vĩ độ: </label>
                                                        <input class="form-control" type="number" placeholder="ViDo"
                                                            name="ViDo{{element.ID}}" [(ngModel)]="element.ViDo" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Giống: </label>
                                                        <input class="form-control" type="text" placeholder="Giong"
                                                            name="Giong{{element.ID}}" [(ngModel)]="element.Giong" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Năm trồng: </label>
                                                        <input class="form-control" type="number" placeholder="NamTrong"
                                                            name="NamTrong{{element.ID}}"
                                                            [(ngModel)]="element.NamTrong" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Chứng nhận VietGap: </label>
                                                        <input class="form-control" type="text"
                                                            placeholder="Chứng nhận VietGap"
                                                            name="ChungNhanVietGap{{element.ID}}"
                                                            [(ngModel)]="element.ChungNhanVietGap" />
                                                    </div>
                                                    <div class="row gx-4">
                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                            <a class="btn btn-info" style="width: 100%"
                                                                (click)="CompanyInfoVungTrongNongHoSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>
                                                            <a class="btn btn-danger" style="width: 100%;"
                                                                (click)="CompanyInfoVungTrongNongHoDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </div>
                                                        <br />
                                                        <br />
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoVungTrongNongHoService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoVungTrongNongHoService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #CompanyInfoVungTrongNongHoPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-primary">
                        <div class="card-header">
                            <h5 class="card-title text-white bg-primary">Bước 04: Tải lên biểu mẫu và tập tin đính kèm
                            </h5>
                        </div>
                        <div class="card-body bg-primary">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoVungTrongDocumentsSort="matSort"
                                        [dataSource]="CompanyInfoVungTrongDocumentsService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="Name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Hồ sơ
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="width: 400px;">
                                                <input class="form-control" type="text" placeholder="Hồ sơ"
                                                    name="Name{{ element.ID }}" [(ngModel)]="element.Name" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="FileName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải lên
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input type="file"
                                                    (change)="ChangeFileName(element, $event.target.files)">
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="row gx-4">
                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                        <a class="btn btn-info" style="width: 100%;"
                                                            (click)="CompanyInfoVungTrongDocumentsSave(element)"><i
                                                                class="bi bi-sd-card"></i></a>
                                                    </div>
                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                        <a class="btn btn-danger" style="width: 100%;"
                                                            (click)="CompanyInfoVungTrongDocumentsDelete(element)"
                                                            *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a *ngIf="element.ID > 0 && element.TypeName"
                                                            style="width: 100%;" class="btn btn-success" title="Tải về"
                                                            (click)="CompanyInfoVungTrongDocumentsAdd(element)"
                                                            target="_blank">Xem biểu mẫu trực tuyến
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a *ngIf="element.ID > 0 && element.TypeName"
                                                            style="width: 100%;" class="btn btn-success" title="Tải về"
                                                            href="{{element.TypeName}}" target="_blank">Tải biểu mẫu Cơ
                                                            quan quản lý cung cấp
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a *ngIf="element.ID > 0"
                                                            style="width: 100%;" class="btn btn-success" title="Tải về"
                                                            href="{{element.FileName}}" target="_blank">Tải tài liệu
                                                            người dân cung cấp (nếu có)
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoVungTrongDocumentsService.DisplayColumns001">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoVungTrongDocumentsService.DisplayColumns001;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #CompanyInfoVungTrongDocumentsPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoVungTrongDocumentsSort="matSort"
                                        [dataSource]="CompanyInfoVungTrongDocumentsService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Hồ sơ: </label>
                                                        <input class="form-control" type="text" placeholder="Name"
                                                            name="Name{{ element.ID }}" [(ngModel)]="element.Name" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Tải lên: </label>
                                                        <br />
                                                        <input type="file"
                                                            (change)="ChangeFileName(element, $event.target.files)">
                                                    </div>
                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                        <a class="btn btn-info" style="width: 100%"
                                                            (click)="CompanyInfoVungTrongDocumentsSave(element)"><i
                                                                class="bi bi-sd-card"></i> </a>
                                                    </div>
                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                        <a class="btn btn-danger" style="width: 100%"
                                                            (click)="CompanyInfoVungTrongDocumentsDelete(element)"
                                                            *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a *ngIf="element.ID > 0 && element.TypeName"
                                                            style="width: 100%" class="btn btn-success" title="Tải về"
                                                            (click)="CompanyInfoVungTrongDocumentsAdd(element)"
                                                            target="_blank">Xem biểu mẫu trực tuyến
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a *ngIf="element.ID > 0 && element.TypeName"
                                                            style="width: 100%" class="btn btn-success" title="Tải về"
                                                            href="{{element.TypeName}}" target="_blank">Tải biểu mẫu
                                                            CQQL cung cấp
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a *ngIf="element.ID > 0"
                                                            style="width: 100%" class="btn btn-success" title="Tải về"
                                                            href="{{element.FileName}}" target="_blank">Tải tài
                                                            liệu người dân cung cấp
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoVungTrongDocumentsService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoVungTrongDocumentsService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #CompanyInfoVungTrongDocumentsPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-danger">
                        <div class="card-header">
                            <h5 class="card-title text-white">Bước 05: Nhận xét phê duyệt
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoVungTrongDocumentsSort002="matSort"
                                        [dataSource]="CompanyInfoVungTrongDocumentsService.DataSource002"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="Name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Hồ sơ
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="width: 400px;">
                                                <input class="form-control" type="text" placeholder="Hồ sơ"
                                                    name="Name{{ element.ID }}" [(ngModel)]="element.Name" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Note">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Diễn Giải Sai Lỗi
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="width: 400px;">
                                                <input class="form-control" type="text" placeholder="Diễn Giải Sai Lỗi"
                                                    name="Note{{ element.ID }}" [(ngModel)]="element.Note" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="FileName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải lên
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a *ngIf="element.ID > 0 && element.FileName" class="btn btn-success"
                                                    title="Tải về" href="{{element.FileName}}" target="_blank"><i
                                                        class="bi bi-cloud-download"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoVungTrongDocumentsService.DisplayColumns002">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoVungTrongDocumentsService.DisplayColumns002;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #CompanyInfoVungTrongDocumentsPaginator002="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoVungTrongDocumentsSort002="matSort"
                                        [dataSource]="CompanyInfoVungTrongDocumentsService.DataSource002"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Hồ sơ: </label>
                                                        <input class="form-control" type="text" placeholder="Hồ sơ"
                                                            name="Name{{ element.ID }}" [(ngModel)]="element.Name" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Diễn Giải Sai Lỗi: </label>
                                                        <input class="form-control" type="text"
                                                            placeholder="Diễn Giải Sai Lỗi" name="Note{{ element.ID }}"
                                                            [(ngModel)]="element.Note" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a *ngIf="element.ID > 0 && element.FileName"
                                                            class="btn btn-success" title="Tải về"
                                                            href="{{element.FileName}}" target="_blank"><i
                                                                class="bi bi-cloud-download"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoVungTrongDocumentsService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoVungTrongDocumentsService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #CompanyInfoVungTrongDocumentsPaginator002="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loading *ngIf="CompanyInfoVungTrongService.IsShowLoading"></app-loading>