import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { LoHang } from 'src/app/shared/TruyXuatNguonGoc/LoHang.model';
import { LoHangService } from 'src/app/shared/TruyXuatNguonGoc/LoHang.service';
import { TruyXuatNguonGocToChuc } from 'src/app/shared/TruyXuatNguonGoc/ToChuc.model';
import { TruyXuatNguonGocToChucService } from 'src/app/shared/TruyXuatNguonGoc/ToChuc.service';
import { DanhMucUngDung } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.model';
import { DanhMucUngDungService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.service';

import { CompanyInfo } from 'src/app/shared/CompanyInfo.model';
import { CompanyInfoService } from 'src/app/shared/CompanyInfo.service';



import { TruyXuatNguonGocLoHangDetailComponent } from '../truy-xuat-nguon-goc-lo-hang-detail/truy-xuat-nguon-goc-lo-hang-detail.component';
import { CompanyInfoDetailComponent } from '../company-info-detail/company-info-detail.component';

@Component({
  selector: 'app-company-info-thong-ke-xv',
  templateUrl: './company-info-thong-ke-xv.component.html',
  styleUrls: ['./company-info-thong-ke-xv.component.css']
})
export class CompanyInfoThongKeXVComponent implements OnInit {

  @ViewChild('LoHangSort') LoHangSort: MatSort;
  @ViewChild('LoHangPaginator') LoHangPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public LoHangService: LoHangService,
    public TruyXuatNguonGocToChucService: TruyXuatNguonGocToChucService,
    public DanhMucUngDungService: DanhMucUngDungService,
    public CompanyInfoService: CompanyInfoService,
  ) { }

  ngOnInit(): void {
    this.DanhMucUngDungService.GetLogin();
    this.ToChucSearch();
  }
  ToChucSearch() {
    this.TruyXuatNguonGocToChucService.ComponentGetByDanhMucUngDungIDAndActiveAndPageAndPageSizeToListAsync(this.LoHangService);
  }
  ToChucFilter(searchString: string) {
    this.TruyXuatNguonGocToChucService.FilterDanhMucUngDungIDAndActive(searchString, this.LoHangService);
  }
  LoHangSearch() {
    this.LoHangService.IsShowLoading = true;    
    this.LoHangService.GetByParentIDToListAsync().subscribe(
      res => {
        this.LoHangService.List = (res as LoHang[]).sort((a, b) => (a.DangKy < b.DangKy ? 1 : -1));
        this.LoHangService.DataSource = new MatTableDataSource(this.LoHangService.List);
        this.LoHangService.DataSource.sort = this.LoHangSort;
        this.LoHangService.DataSource.paginator = this.LoHangPaginator;
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );   
  } 
  LoHangAdd(ID: number) {
    this.LoHangService.IsShowLoading = true;
    this.LoHangService.BaseParameter.ID = ID;
    this.LoHangService.GetByIDAsync().subscribe(
      res => {
        this.LoHangService.FormData = res as LoHang;             
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(TruyXuatNguonGocLoHangDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.LoHangSearch();
        });
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  ToChucAdd(ID: number) {
    this.LoHangService.IsShowLoading = true;
    this.TruyXuatNguonGocToChucService.BaseParameter.ID = ID;
    this.TruyXuatNguonGocToChucService.GetByIDAsync().subscribe(
      res => {
        this.TruyXuatNguonGocToChucService.FormData = res as TruyXuatNguonGocToChuc;


        this.CompanyInfoService.BaseParameter.ID = this.TruyXuatNguonGocToChucService.FormData.IDToken;
        this.CompanyInfoService.GetByIDAsync().subscribe(
          res => {
            this.CompanyInfoService.FormData = res as CompanyInfo;
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.width = environment.DialogConfigWidth;
            dialogConfig.data = { ID: ID };
            const dialog = this.dialog.open(CompanyInfoDetailComponent, dialogConfig);
            dialog.afterClosed().subscribe(() => {
            });
          },
          err => {
          }
        );


        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
}