import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';

import { DistrictData } from 'src/app/shared/DistrictData.model';
import { DistrictDataService } from 'src/app/shared/DistrictData.service';
import { WardData } from 'src/app/shared/WardData.model';
import { WardDataService } from 'src/app/shared/WardData.service';
import { DanhMucChuongTrinhQuanLyChatLuong } from 'src/app/shared/DanhMucChuongTrinhQuanLyChatLuong.model';
import { DanhMucChuongTrinhQuanLyChatLuongService } from 'src/app/shared/DanhMucChuongTrinhQuanLyChatLuong.service';
import { DanhMucCompanyTinhTrang } from 'src/app/shared/DanhMucCompanyTinhTrang.model';
import { DanhMucCompanyTinhTrangService } from 'src/app/shared/DanhMucCompanyTinhTrang.service';
import { DanhMucThiTruong } from 'src/app/shared/DanhMucThiTruong.model';
import { DanhMucThiTruongService } from 'src/app/shared/DanhMucThiTruong.service';
import { DanhMucDangKyCapGiay } from 'src/app/shared/DanhMucDangKyCapGiay.model';
import { DanhMucDangKyCapGiayService } from 'src/app/shared/DanhMucDangKyCapGiay.service';
import { DanhMucXepLoai } from 'src/app/shared/DanhMucXepLoai.model';
import { DanhMucXepLoaiService } from 'src/app/shared/DanhMucXepLoai.service';
import { DanhMucCompanyTrangThai } from 'src/app/shared/DanhMucCompanyTrangThai.model';
import { DanhMucCompanyTrangThaiService } from 'src/app/shared/DanhMucCompanyTrangThai.service';
import { DanhMucHinhThucNuoi } from 'src/app/shared/DanhMucHinhThucNuoi.model';
import { DanhMucHinhThucNuoiService } from 'src/app/shared/DanhMucHinhThucNuoi.service';

import { CompanyScope } from 'src/app/shared/CompanyScope.model';
import { CompanyScopeService } from 'src/app/shared/CompanyScope.service';
import { CompanyGroup } from 'src/app/shared/CompanyGroup.model';
import { CompanyGroupService } from 'src/app/shared/CompanyGroup.service';
import { Species } from 'src/app/shared/Species.model';
import { SpeciesService } from 'src/app/shared/Species.service';
import { CompanyFields } from 'src/app/shared/CompanyFields.model';
import { CompanyFieldsService } from 'src/app/shared/CompanyFields.service';

import { CompanyInfo } from 'src/app/shared/CompanyInfo.model';
import { CompanyInfoService } from 'src/app/shared/CompanyInfo.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

@Component({
  selector: 'app-co-so-company-info',
  templateUrl: './co-so-company-info.component.html',
  styleUrls: ['./co-so-company-info.component.css']
})
export class CoSoCompanyInfoComponent implements OnInit {

  constructor(
    private dialog: MatDialog,        

    public NotificationService: NotificationService,

    public DistrictDataService: DistrictDataService,
    public WardDataService: WardDataService,
    public DanhMucChuongTrinhQuanLyChatLuongService: DanhMucChuongTrinhQuanLyChatLuongService,
    public DanhMucCompanyTinhTrangService: DanhMucCompanyTinhTrangService,
    public DanhMucThiTruongService: DanhMucThiTruongService,
    public DanhMucDangKyCapGiayService: DanhMucDangKyCapGiayService,
    public DanhMucXepLoaiService: DanhMucXepLoaiService,
    public DanhMucCompanyTrangThaiService: DanhMucCompanyTrangThaiService,
    public DanhMucHinhThucNuoiService: DanhMucHinhThucNuoiService,

    public CompanyScopeService: CompanyScopeService,
    public CompanyGroupService: CompanyGroupService,
    public SpeciesService: SpeciesService,
    public CompanyFieldsService: CompanyFieldsService,

    public CompanyInfoService: CompanyInfoService,

    public ThanhVienService: ThanhVienService,
  ) { }

  ngOnInit(): void {
    this.ThanhVienGetLogin();
    this.CompanyInfoGetData();
  }
  
  ngOnDestroy(): void {
    if (this.CompanyInfoService.FormData) {
      this.CompanyInfoService.FormData = {};
    }
    if (this.DistrictDataService) {
      this.DistrictDataService = null;
    }
  }

  ThanhVienGetLogin() {
    this.ThanhVienService.GetLogin();
  }

  DateDuyetTaiKhoanNgayGhiNhan(value) {
    this.CompanyInfoService.FormData.DuyetTaiKhoanNgayGhiNhan = new Date(value);
  }
 
  DateDKKDNgayCap(value) {
    this.CompanyInfoService.FormData.DKKDNgayCap = new Date(value);
  }

  DateNgayDangKy(value) {
    this.CompanyInfoService.FormData.NgayDangKy = new Date(value);
  }

  DateNgayHetHan(value) {
    this.CompanyInfoService.FormData.NgayHetHan = new Date(value);
  }

  DistrictDataSearch(companyInfo: CompanyInfo) {
    this.CompanyInfoService.IsShowLoading = true;
    this.DistrictDataService.BaseParameter.ParentID = environment.ProvinceDataIDBenTre;

    this.DistrictDataService.GetByParentIDToListAsync().subscribe(
      async res => {
        this.DistrictDataService.List = (res as DistrictData[]).sort((a, b) => a.SortOrder - b.SortOrder);

        // Đợi tất cả các hàm tìm kiếm hoàn tất
        const promises = [
          this.WardDataSearch(companyInfo.DistrictDataID),
          this.DanhMucHinhThucNuoiSearch(),
          this.DanhMucCompanyTrangThaiSearch(),
          this.CompanyScopeSearch(),
          this.CompanyGroupSearch(),
          this.SpeciesSearch(),
          this.CompanyFieldsSearch(),
          this.DanhMucChuongTrinhQuanLyChatLuongSearch(),
          this.DanhMucCompanyTinhTrangSearch(),
          this.DanhMucThiTruongSearch(),
          this.DanhMucDangKyCapGiaySearch(),
          this.DanhMucXepLoaiSearch()
        ];

        try {
          await Promise.all(promises); // Chờ tất cả các Promise hoàn tất
          this.CompanyInfoService.FormData = companyInfo; // Gán dữ liệu vào FormData
        } catch (error) {
          console.error('Error during data fetching:', error);
        } finally {
          this.CompanyInfoService.IsShowLoading = false; // Tắt loading
        }
      },
      err => {
        this.CompanyInfoService.IsShowLoading = false; // Tắt loading nếu có lỗi
        console.error('Error fetching district data:', err);
      }
    );
  }

  // Đảm bảo rằng các hàm tìm kiếm đều trả về Promise
  WardDataSearch(districtDataID: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.WardDataService.BaseParameter.ParentID = districtDataID;
      this.WardDataService.GetByParentIDToListAsync().subscribe(
        res => {
          this.WardDataService.List = (res as WardData[]).sort((a, b) => a.SortOrder - b.SortOrder);
          resolve();
        },
        err => {
          reject(err);
        }
      );
    });
  }

  DanhMucHinhThucNuoiSearch(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.DanhMucHinhThucNuoiService.ComponentGetAllToListAsyncUpdate().subscribe(
        res => {
          resolve();
        },
        err => {
          reject(err);
        }
      );
    });
  }

  async DanhMucCompanyTrangThaiSearch(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.DanhMucCompanyTrangThaiService.ComponentGetAllToListAsyncUpdate().subscribe(
        res => {
          resolve();
        },
        err => {
          reject(err);
        }
      );
    });
  }

  async DanhMucDangKyCapGiaySearch(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.DanhMucDangKyCapGiayService.ComponentGetAllToListAsyncUpdate().subscribe(
        res => {
          resolve();
        },
        err => {
          reject(err);
        }
      );
    });
  }

  async DanhMucXepLoaiSearch(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.DanhMucXepLoaiService.ComponentGetAllToListAsyncUpdate().subscribe(
        res => {
          resolve();
        },
        err => {
          reject(err);
        }
      );
    });
  }

  async DanhMucChuongTrinhQuanLyChatLuongSearch(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.DanhMucChuongTrinhQuanLyChatLuongService.ComponentGetAllToListAsyncUpdate().subscribe(
        res => {
          resolve();
        },
        err => {
          reject(err);
        }
      );
    });
  }

  async DanhMucCompanyTinhTrangSearch(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.DanhMucCompanyTinhTrangService.ComponentGetAllToListAsyncUpdate().subscribe(
        res => {
          resolve();
        },
        err => {
          reject(err);
        }
      );
    });
  }

  async DanhMucThiTruongSearch(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.DanhMucThiTruongService.ComponentGetAllToListAsyncUpdate().subscribe(
        res => {
          resolve();
        },
        err => {
          reject(err);
        }
      );
    });
  }

  async CompanyScopeSearch(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.CompanyScopeService.ComponentGetAllToListAsyncUpdate().subscribe(
        res => {
          resolve();
        },
        err => {
          reject(err);
        }
      );
    });
  }

  async CompanyGroupSearch(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.CompanyGroupService.ComponentGetAllToListAsyncUpdate().subscribe(
        res => {
          resolve();
        },
        err => {
          reject(err);
        }
      );
    });
  }

  async SpeciesSearch(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.SpeciesService.ComponentGetAllToListAsyncUpdate().subscribe(
        res => {
          resolve();
        },
        err => {
          reject(err);
        }
      );
    });
  }

  async CompanyFieldsSearch(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.CompanyFieldsService.ComponentGetAllToListAsyncUpdate().subscribe(
        res => {
          resolve();
        },
        err => {
          reject(err);
        }
      );
    });
  }

  CompanyInfoGetData() {
    this.CompanyInfoService.IsShowLoading = true;
    this.CompanyInfoService.BaseParameter.ID = this.ThanhVienService.FormDataLogin.CompanyInfoID;
    this.CompanyInfoService.GetByIDAsync().subscribe(
      res => {
        this.DistrictDataSearch(res);
      },
      err => {
        this.CompanyInfoService.IsShowLoading = false;
      }
    );
  } 

  CompanyInfoSave() {
    this.NotificationService.warn(this.CompanyInfoService.ComponentSaveForm());
  }  
}
