<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-DocumentTemplateDetail" data-bs-toggle="tab"
                href="#DocumentTemplateDetail" role="tab" aria-controls="DocumentTemplateDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Biểu mẫu</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="DocumentTemplateDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-3 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="DocumentTemplateSave()"
                        class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <a *ngIf="DocumentTemplateService.FormData.ID > 0" style="width: 100%;" title="Copy"
                        (click)="DocumentTemplateCopy()" class="btn btn-info"><i class="bi bi-subtract"></i> Copy</a>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <a style="width: 100%;"
                        *ngIf="DocumentTemplateService.FormData.ID > 0 && DocumentTemplateService.FormData.Code"
                        class="btn btn-success" title="In biểu mẫu" (click)="OpenWindowByURL()"><i
                            class="bi bi-printer"></i> In
                    </a>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <a style="width: 100%;"
                        *ngIf="DocumentTemplateService.FormData.ID > 0 && DocumentTemplateService.FormData.FileName"
                        class="btn btn-success" title="Tải về biểu mẫu"
                        href="{{DocumentTemplateService.FormData.FileName}}" target="_blank"><i
                            class="bi bi-cloud-download"></i> Tải về
                    </a>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu đề [{{DocumentTemplateService.FormData.ID}}]</label>
                        <input name="Name" [(ngModel)]="DocumentTemplateService.FormData.Name" placeholder="Tiêu đề"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Sắp xếp</label>
                        <input name="SortOrder" [(ngModel)]="DocumentTemplateService.FormData.SortOrder" placeholder="0"
                            type="number" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <div class="form-check form-check-inline mb-6">
                            <input type="checkbox" class="form-check-input" name="Active"
                                [(ngModel)]="DocumentTemplateService.FormData.Active" />
                            <label class="form-check-label" for="Active">Bản gốc</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Danh mục</label>
                        <select class="form-select" name="ParentID"
                            [(ngModel)]="DocumentTemplateService.FormData.ParentID">
                            <option *ngFor="let item of PlanTypeService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Phân loại</label>
                        <select class="form-select" name="DanhMucProductGroupID"
                            [(ngModel)]="DocumentTemplateService.FormData.DanhMucProductGroupID">
                            <option *ngFor="let item of DanhMucProductGroupService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Biểu mẫu thay thế: </label>
                        <br />
                        <input type="file" (change)="ChangeFileName($event.target.files)">
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
                <ck-editor [config]="{'height': 500, 'width': 1400, 'versionCheck': false}" name="HTMLContent"
                    [(ngModel)]="DocumentTemplateService.FormData.HTMLContent" skin="moono-lisa" language="en"
                    [fullPage]="true"></ck-editor>

            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="DocumentTemplateService.IsShowLoading"></app-loading>