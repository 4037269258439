<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-CompanyLake" data-bs-toggle="tab" href="#CompanyLake"
                                    role="tab" aria-controls="CompanyLake" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Ao hồ</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="CompanyLake" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-5 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Đơn vị Tổ chức
                                        </label>
                                        <mat-select class="form-control"
                                            name="CompanyLakeService.BaseParameter.ParentID"
                                            [(ngModel)]="CompanyLakeService.BaseParameter.ParentID">
                                            <input placeholder="Tìm..." class="form-control"
                                                (keyup)="CompanyInfoFilter($event.target.value)">
                                            <mat-option *ngFor="let item of CompanyInfoService.ListFilter"
                                                [value]="item.ID">
                                                {{item.Name}} - {{item.phone}} - {{item.DKKD}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input [(ngModel)]="CompanyLakeService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="CompanyLakeSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <div>
                                            <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                                (click)="CompanyLakeSearch()"><i class="bi bi-search"></i></a>
                                        </div>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <div>
                                            <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                                (click)="CompanyLakeAdd(0)"><i class="bi bi-plus"></i></a>
                                        </div>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <div>
                                            <a style="width: 100%;" class="btn btn-success" title="Bản đồ"
                                                (click)="CompanyLakeMap()"><i class="bi bi-pin-map"></i> Bản đồ</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span *ngIf="CompanyLakeService.List">({{CompanyLakeService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #CompanyLakeSort="matSort"
                                                    [dataSource]="CompanyLakeService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="CompanyLakePaginator">
                                                                {{CompanyLakePaginator.pageSize *
                                                                CompanyLakePaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="species_name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vật nuôi
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.species_name}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã ao</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.Code}}"
                                                                class="link-primary"
                                                                (click)="CompanyLakeAdd(element.ID)"><b>{{element.Code}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.Name}}"
                                                                class="link-primary"
                                                                (click)="CompanyLakeAdd(element.ID)"><b>{{element.Name}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="acreage">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Diện tích
                                                            (ha)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.acreage | number: "1.0-0"}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="latitude">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vĩ độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.latitude}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="longitude">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kinh độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.longitude}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-danger"
                                                                (click)="CompanyLakeDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="CompanyLakeService.DisplayColumns003">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: CompanyLakeService.DisplayColumns003;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #CompanyLakePaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>

                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #CompanyLakeSort="matSort"
                                                    [dataSource]="CompanyLakeService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Vật nuôi: </label>
                                                                    <label
                                                                        class="form-label">{{element.species_name}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Mã ao: </label>
                                                                    <label class="form-label">{{element.Code}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Tiêu đề: </label>
                                                                    <label class="form-label">{{element.Name}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Diện tích (ha): </label>
                                                                    <label class="form-label">{{element.acreage |
                                                                        number: "1.0-0"}}</label>
                                                                </div>
                                                                <div class="row gx-4">
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <a class="btn btn-danger" style="width: 100%;"
                                                                            (click)="CompanyLakeDelete(element)"
                                                                            *ngIf="element.ID>0"><i
                                                                                class="bi bi-trash"></i>
                                                                        </a>
                                                                    </div>
                                                                    <br />
                                                                    <br />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="CompanyLakeService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: CompanyLakeService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #CompanyLakePaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="CompanyLakeService.IsShowLoading"></app-loading>