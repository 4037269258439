import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/TruyXuatNguonGoc/Download.service';

import { LoHang } from 'src/app/shared/TruyXuatNguonGoc/LoHang.model';
import { LoHangService } from 'src/app/shared/TruyXuatNguonGoc/LoHang.service';
import { LoHangNhatKy } from 'src/app/shared/TruyXuatNguonGoc/LoHangNhatKy.model';
import { LoHangNhatKyService } from 'src/app/shared/TruyXuatNguonGoc/LoHangNhatKy.service';
import { LoHangTapTinDinhKem } from 'src/app/shared/TruyXuatNguonGoc/LoHangTapTinDinhKem.model';
import { LoHangTapTinDinhKemService } from 'src/app/shared/TruyXuatNguonGoc/LoHangTapTinDinhKem.service';


import { TruyXuatNguonGocToChuc } from 'src/app/shared/TruyXuatNguonGoc/ToChuc.model';
import { TruyXuatNguonGocToChucService } from 'src/app/shared/TruyXuatNguonGoc/ToChuc.service';

import { DanhMucQuyTrinh } from 'src/app/shared/TruyXuatNguonGoc/DanhMucQuyTrinh.model';
import { DanhMucQuyTrinhService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucQuyTrinh.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

@Component({
  selector: 'app-co-so-truy-xuat-nguon-goc-lo-hang-detail-ma-so-vung-trong',
  templateUrl: './co-so-truy-xuat-nguon-goc-lo-hang-detail-ma-so-vung-trong.component.html',
  styleUrls: ['./co-so-truy-xuat-nguon-goc-lo-hang-detail-ma-so-vung-trong.component.css']
})
export class CoSoTruyXuatNguonGocLoHangDetailMaSoVungTrongComponent implements OnInit {

  @ViewChild('LoHangNhatKySort') LoHangNhatKySort: MatSort;
  @ViewChild('LoHangNhatKyPaginator') LoHangNhatKyPaginator: MatPaginator;

  @ViewChild('LoHangTapTinDinhKemSort') LoHangTapTinDinhKemSort: MatSort;
  @ViewChild('LoHangTapTinDinhKemPaginator') LoHangTapTinDinhKemPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CoSoTruyXuatNguonGocLoHangDetailMaSoVungTrongComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public LoHangService: LoHangService,
    public LoHangNhatKyService: LoHangNhatKyService,
    public LoHangTapTinDinhKemService: LoHangTapTinDinhKemService,

    public TruyXuatNguonGocToChucService: TruyXuatNguonGocToChucService,

    public DanhMucQuyTrinhService: DanhMucQuyTrinhService,

    public ThanhVienService: ThanhVienService,

  ) { }

  ngOnInit(): void {
    this.ThanhVienGetLogin();
    this.LoHangSearch();
  }

  ThanhVienGetLogin() {
    this.ThanhVienService.GetLogin();
  }

  ChangeFileNameLoHangNhatKy(files: FileList) {
    if (files) {
      this.LoHangNhatKyService.FileToUpload = files;
    }
  }
  ChangeFileNameLoHangTapTinDinhKem(files: FileList) {
    if (files) {
      this.LoHangTapTinDinhKemService.FileToUpload = files;
    }
  }
  DateLoHangNhatKyNgayGhiNhan(element: LoHangNhatKy, value) {
    element.NgayGhiNhan = new Date(value);
  }
  DateLoHangTapTinDinhKemNgayCap(element: LoHangTapTinDinhKem, value) {
    element.NgayCap = new Date(value);
  }
  DateLoHangTapTinDinhKemHetHan(element: LoHangTapTinDinhKem, value) {
    element.HetHan = new Date(value);
  }


  DateDangKy(value) {
    this.LoHangService.FormData.DangKy = new Date(value);
  }
  DateTiepNhan(value) {
    this.LoHangService.FormData.TiepNhan = new Date(value);
  }
  DateKiemTra(value) {
    this.LoHangService.FormData.KiemTra = new Date(value);
  }
  DatePheDuyet(value) {
    this.LoHangService.FormData.PheDuyet = new Date(value);
  }
  DateThuHoi(value) {
    this.LoHangService.FormData.ThuHoi = new Date(value);
  }
  DateNgaySanXuatDongGoi(value) {
    this.LoHangService.FormData.NgaySanXuatDongGoi = new Date(value);
  }
  DateNgayHetHanSuDung(value) {
    this.LoHangService.FormData.NgayHetHanSuDung = new Date(value);
  }
  DateCongBo(value) {
    this.LoHangService.FormData.CongBo = new Date(value);
  }

  DanhMucQuyTrinhSearch() {
    this.DanhMucQuyTrinhService.ComponentGetAllToListAsync(this.LoHangService);
  }
  ToChucSearch() {
    this.TruyXuatNguonGocToChucService.ComponentGetByDanhMucUngDungIDAndActiveAndPageAndPageSizeToListAsync(this.LoHangService);
  }
  ToChucFilter(searchString: string) {
    this.TruyXuatNguonGocToChucService.FilterDanhMucUngDungIDAndActive(searchString, this.LoHangService);
  }

  LoHangSearch() {
    this.LoHangService.BaseParameter.ID = this.LoHangService.FormData.ID;
    this.LoHangService.GetByIDAsync().subscribe(
      res => {
        this.LoHangService.FormData = res as LoHang;

        this.TruyXuatNguonGocToChucService.BaseParameter.DanhMucUngDungID = Number(localStorage.getItem(environment.TruyXuatNguonGocDanhMucUngDungID));
        this.TruyXuatNguonGocToChucService.BaseParameter.IDToken = this.ThanhVienService.FormDataLogin.CompanyInfoID;
        this.TruyXuatNguonGocToChucService.GetByDanhMucUngDungIDAndIDTokenAsync().subscribe(
          res => {
            this.TruyXuatNguonGocToChucService.FormData = (res as TruyXuatNguonGocToChuc);

            this.LoHangService.FormData.ParentID = this.TruyXuatNguonGocToChucService.FormData.ID;
            this.LoHangService.FormData.ParentName = this.TruyXuatNguonGocToChucService.FormData.Name;

          },
          err => {

          }
        );


        this.DanhMucQuyTrinhSearch();
        this.ToChucSearch();
        this.LoHangNhatKySearch();
        this.LoHangTapTinDinhKemSearch();
      },
      err => {
      }
    );
  }
  LoHangSave() {
    this.LoHangService.IsShowLoading = true;
    this.LoHangService.FormData.Active = false;
    this.LoHangService.SaveAsync().subscribe(
      res => {
        this.LoHangService.FormData = res as LoHang;
        this.NotificationService.warn(environment.SaveSuccess);
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangService.IsShowLoading = false;
      }
    );
  }

  LoHangNhatKySearch() {
    this.LoHangNhatKyService.BaseParameter.Code = this.LoHangService.FormData.Code;
    this.LoHangNhatKyService.SearchByCode(this.LoHangNhatKySort, this.LoHangNhatKyPaginator);
  }
  LoHangNhatKySave(element: LoHangNhatKy) {
    element.ParentID = this.LoHangService.FormData.ID;
    element.Code = this.LoHangService.FormData.Code;
    this.LoHangNhatKyService.FormData = element;
    this.LoHangService.IsShowLoading = true;
    this.LoHangNhatKyService.SaveAndUploadFileAsync().subscribe(
      res => {
        element = res as LoHangNhatKy;
        this.LoHangNhatKySearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangNhatKyCopy(element: LoHangNhatKy) {
    this.LoHangService.IsShowLoading = true;
    this.LoHangNhatKyService.FormData = element;
    this.LoHangNhatKyService.CopyAsync().subscribe(
      res => {
        this.LoHangNhatKySearch();
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }

  LoHangNhatKyDelete(element: LoHangNhatKy) {
    this.LoHangNhatKyService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.LoHangNhatKyService.ComponentDeleteByCode(this.LoHangNhatKySort, this.LoHangNhatKyPaginator));
  }
  LoHangTapTinDinhKemSearch() {
    this.LoHangTapTinDinhKemService.BaseParameter.Code = this.LoHangService.FormData.Code;
    this.LoHangTapTinDinhKemService.SearchByCode(this.LoHangTapTinDinhKemSort, this.LoHangTapTinDinhKemPaginator);
  }
  LoHangTapTinDinhKemSave(element: LoHangTapTinDinhKem) {
    element.ParentID = this.LoHangService.FormData.ID;
    element.Code = this.LoHangService.FormData.Code;
    this.LoHangTapTinDinhKemService.FormData = element;
    this.LoHangService.IsShowLoading = true;
    this.LoHangTapTinDinhKemService.SaveAndUploadFileAsync().subscribe(
      res => {
        element = res as LoHangTapTinDinhKem;
        this.LoHangTapTinDinhKemSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangTapTinDinhKemDelete(element: LoHangTapTinDinhKem) {
    this.LoHangTapTinDinhKemService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.LoHangTapTinDinhKemService.ComponentDeleteByCode(this.LoHangTapTinDinhKemSort, this.LoHangTapTinDinhKemPaginator));
  }

  Close() {
    this.dialogRef.close();
  }
}