<div class="app-body">
    <div class="mx-n4 p-4 bg-light mb-4">
        <div class="row gx-4">
            <div class="col-sm-3 col-12">
                <div class="card my-2 card-danger">
                    <div class="card-header">
                        <h5 class="card-title text-white">Vùng trồng</h5>
                    </div>
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class=" text-white">
                                <h2 class="display-6 mb-2 fw-semibold">{{ReportService.FormData.ThongKe001 |
                                    number:'1.0-0'}}</h2>
                                <h5 class="mb-3 fw-light opacity-75">Tổng số mã số vùng trồng đã cấp.</h5>
                                <div class="progress small">
                                    <div class="progress-bar bg-white" role="progressbar" style="width: 75%"
                                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div class="ms-3 graph-mini" id="option1"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-12">
                <div class="card my-2 card-success">
                    <div class="card-header">
                        <h5 class="card-title text-white">Đóng gói</h5>
                    </div>
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class=" text-white">
                                <h2 class="display-6 mb-2 fw-semibold">{{ReportService.FormData.ThongKe002 |
                                    number:'1.0-0'}}</h2>
                                <h5 class="mb-3 fw-light opacity-75">Tổng số mã số đóng gói đã cấp.</h5>
                                <div class="progress small">
                                    <div class="progress-bar bg-white" role="progressbar" style="width: 75%"
                                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div class="ms-3 graph-mini" id="option2"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-12">
                <div class="card my-2 card-warning">
                    <div class="card-header">
                        <h5 class="card-title text-white">Truy xuất</h5>
                    </div>
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class="text-white">
                                <h2 class="display-6 mb-2 fw-semibold">{{ReportService.FormData.ThongKe003 |
                                    number:'1.0-0'}}</h2>
                                <h5 class="mb-3 fw-light opacity-75">Tổng số lô hàng đăng ký.</h5>
                                <div class="progress small">
                                    <div class="progress-bar bg-white" role="progressbar" style="width: 75%"
                                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div class="ms-3 graph-mini" id="option3"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-12">
                <div class="card my-2 card-primary">
                    <div class="card-header">
                        <h5 class="card-title text-white bg-primary">Lô tem</h5>
                    </div>
                    <div class="card-body bg-primary">
                        <div class="d-flex justify-content-between">
                            <div class="text-white">
                                <h2 class="display-6 mb-2 fw-semibold">{{ReportService.FormData.ThongKe004 |
                                    number:'1.0-0'}}</h2>
                                <h5 class="mb-3 fw-light opacity-75">Tổng số lô tem đã cấp.</h5>
                                <div class="progress small">
                                    <div class="progress-bar bg-white" role="progressbar" style="width: 75%"
                                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div class="ms-3 graph-mini" id="option3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12" style="padding: 10px;">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <a style="cursor: pointer;" class="link-primary" (click)="ChartReport0032Click()">
                    <h4 class="text-center" style="text-transform: uppercase;">Tổng hợp số liệu Vùng trồng và Cơ sở đóng gói</h4>
                </a>
                <canvas id="ChartReport0032" style="font-size: 30px;" baseChart
                    [datasets]="ChartDataReport0032" [labels]="ChartLabelsReport0032"
                    [chartType]="ChartTypeReport0032" [colors]="ChartColorsReport0032"
                    [options]="ChartOptionsReport0032">
                </canvas>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ReportService.IsShowLoading"></app-loading>