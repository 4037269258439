<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-BienBanATTPDetail" data-bs-toggle="tab" href="#BienBanATTPDetail"
                role="tab" aria-controls="BienBanATTPDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Biên bản Thẩm định ATTP</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="BienBanATTPDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-3 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <a style="width: 100%;" title="Lưu thay đổi" (click)="BienBanATTPSave()" class="btn btn-info"><i
                                class="bi bi-sd-card"></i> Lưu thay đổi</a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <a style="width: 100%;" title="Copy" (click)="BienBanATTPCopy()" class="btn btn-warning"><i
                                class="bi bi-subtract"></i> Copy</a>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="BienBanATTPService.FormData.Active" />
                        <label class="form-check-label" for="Active">Kích hoạt</label>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Phân loại</label>
                        <select class="form-select" [(ngModel)]="BienBanATTPService.FormData.ParentID">
                            <option *ngFor="let item of DanhMucProductGroupService.List;" [value]="item.ID">
                              {{item.Name}}</option>
                          </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ðiều Khoản Tham Chiếu</label>
                        <textarea name="Name" [(ngModel)]="BienBanATTPService.FormData.Name" placeholder="Ðiều Khoản Tham Chiếu"
                            type="text" class="form-control" rows="8"></textarea>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi chú</label>
                        <input name="Note" [(ngModel)]="BienBanATTPService.FormData.Note" placeholder="Ghi chú"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Sắp xếp</label>
                        <input name="SortOrder" [(ngModel)]="BienBanATTPService.FormData.SortOrder" placeholder="0"
                            type="number" class="form-control">
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">	Nhóm Chỉ Tiêu Đánh Giá</label>
                        <textarea name="Description" [(ngModel)]="BienBanATTPService.FormData.Description"
                            placeholder="Nhóm Chỉ Tiêu Đánh Giá" type="text" class="form-control" rows="18"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="BienBanATTPService.IsShowLoading"></app-loading>