<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-DanhMucLayMauDetail" data-bs-toggle="tab" href="#DanhMucLayMauDetail"
                role="tab" aria-controls="DanhMucLayMauDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Danh mục Mẫu xét nghiệm</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="DanhMucLayMauDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="DanhMucLayMauSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="DanhMucLayMauService.FormData.Active" />
                        <label class="form-check-label" for="Active">Kích hoạt</label>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mẫu xét nghiệm</label>
                        <input name="Name" [(ngModel)]="DanhMucLayMauService.FormData.Name" placeholder="Mẫu xét nghiệm"
                            type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Parent</label>
                        <select class="form-select" name="ParentID" [(ngModel)]="DanhMucLayMauService.FormData.ParentID">
                            <option *ngFor="let item of PlanTypeService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Phân loại</label>
                        <select class="form-select" name="DanhMucLayMauPhanLoaiID"
                            [(ngModel)]="DanhMucLayMauService.FormData.DanhMucLayMauPhanLoaiID">
                            <option *ngFor="let item of DanhMucLayMauPhanLoaiService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="DanhMucLayMauService.IsShowLoading"></app-loading>