<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-CompanyInfoGroups" data-bs-toggle="tab" href="#CompanyInfoGroups"
                                    role="tab" aria-controls="CompanyInfoGroups" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Nhóm</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="CompanyInfoGroups" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-11 col-sm-12 col-12">
                                        <input [(ngModel)]="CompanyInfoGroupsService.BaseParameter.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="CompanyInfoGroupsSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="CompanyInfoGroupsSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span *ngIf="CompanyInfoGroupsService.List">({{CompanyInfoGroupsService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #CompanyInfoGroupsSort="matSort" [dataSource]="CompanyInfoGroupsService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="CompanyInfoGroupsPaginator">
                                                                {{CompanyInfoGroupsPaginator.pageSize *
                                                                CompanyInfoGroupsPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="CompanyGroupID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nhóm
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select" name="CompanyGroupID"
                                                                [(ngModel)]="element.CompanyGroupID">
                                                                <option
                                                                    *ngFor="let item of CompanyGroupService.List;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>                                                                                                                               
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info" (click)="CompanyInfoGroupsSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger" (click)="CompanyInfoGroupsDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="CompanyInfoGroupsService.DisplayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: CompanyInfoGroupsService.DisplayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #CompanyInfoGroupsPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>


                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #CompanyInfoGroupsSort="matSort" [dataSource]="CompanyInfoGroupsService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Nhóm</label>
                                                                    <select class="form-select" name="CompanyGroupID"
                                                                        [(ngModel)]="element.CompanyGroupID">
                                                                        <option
                                                                            *ngFor="let item of CompanyGroupService.List;"
                                                                            [value]="item.ID">
                                                                            {{item.Name}}</option>
                                                                    </select>
                                                                </div>                                                                
                                                                <div class="row gx-4">
                                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                                        <a class="btn btn-info" style="width: 100%"
                                                                            (click)="CompanyInfoGroupsSave(element)"><i
                                                                                class="bi bi-sd-card"></i>
                                                                        </a>
                                                                    </div>
                                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                                        <a class="btn btn-danger" style="width: 100%"
                                                                            (click)="CompanyInfoGroupsDelete(element)"
                                                                            *ngIf="element.ID > 0"><i
                                                                                class="bi bi-trash"></i>
                                                                        </a>
                                                                    </div>
                                                                    <br />
                                                                    <br />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row *matHeaderRowDef="
                                                        CompanyInfoGroupsService.DisplayColumnsMobile
                                                      "></tr>
                                                    <tr mat-row *matRowDef="
                                                        let row;
                                                        columns: CompanyInfoGroupsService.DisplayColumnsMobile
                                                      "></tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #CompanyInfoGroupsPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loading *ngIf="CompanyInfoGroupsService.IsShowLoading"></app-loading>