<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-CompanyInfoDetail" data-bs-toggle="tab" href="#CompanyInfoDetail"
                role="tab" aria-controls="CompanyInfoDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Đơn vị Tổ chức</span>
            </a>
        </li>
        <!-- <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-CompanyLake" data-bs-toggle="tab" href="#CompanyLake" role="tab"
                aria-controls="CompanyLake" aria-selected="false">
                <span class="badge bg-warning" style="font-size: 16px;">Ao hồ</span>
            </a>
        </li> -->
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-ThanhVien" data-bs-toggle="tab" href="#ThanhVien" role="tab"
                aria-controls="ThanhVien" aria-selected="false">
                <span class="badge bg-primary" style="font-size: 16px;">Nhân viên/Nông hộ</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-CompanyInfoMap" data-bs-toggle="tab" href="#CompanyInfoMap" role="tab"
                aria-controls="CompanyInfoMap" aria-selected="false">
                <span class="badge bg-dark" style="font-size: 16px;">Bản đồ</span>
            </a>
        </li>        
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="CompanyInfoDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="CompanyInfoSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="CompanyInfoService.FormData.Active" />
                        <label class="form-check-label" for="Active">Kích hoạt</label>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-3 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu đề [{{CompanyInfoService.FormData.ID}}]</label>
                        <input name="Name" [(ngModel)]="CompanyInfoService.FormData.Name" placeholder="Tiêu đề"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đại diện pháp luật</label>
                        <input name="fullname" [(ngModel)]="CompanyInfoService.FormData.fullname"
                            placeholder="Đại diện pháp luật" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Chức danh</label>
                        <input name="role_name" [(ngModel)]="CompanyInfoService.FormData.role_name"
                            placeholder="Chức danh" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Định danh cá nhân</label>
                        <input name="identity_card" [(ngModel)]="CompanyInfoService.FormData.identity_card"
                            placeholder="Định danh cá nhân" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Điện thoại</label>
                        <input name="phone" [(ngModel)]="CompanyInfoService.FormData.phone" placeholder="Điện thoại"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Email</label>
                        <input name="email" [(ngModel)]="CompanyInfoService.FormData.email" placeholder="Email"
                            type="email" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Nơi cấp ĐKKD</label>
                        <input name="business_number_place"
                            [(ngModel)]="CompanyInfoService.FormData.business_number_place" placeholder="Nơi cấp ĐKKD"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số DKKD</label>
                        <input name="DKKD" [(ngModel)]="CompanyInfoService.FormData.DKKD" placeholder="Số DKKD"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số cấp</label>
                        <input name="DKKDSoCap" [(ngModel)]="CompanyInfoService.FormData.DKKDSoCap" placeholder="Số cấp"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày cấp</label>
                        <input [ngModel]="CompanyInfoService.FormData.DKKDNgayCap | date:'yyyy-MM-dd'"
                            (ngModelChange)="DateDKKDNgayCap($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                            type="date" class="form-control" autocomplete="off">
                    </div>
                    
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Quận huyện</label>
                        <select class="form-select" name="DistrictDataID"
                            [(ngModel)]="CompanyInfoService.FormData.DistrictDataID" (change)="WardDataSearch(CompanyInfoService.FormData.DistrictDataID)">
                            <option *ngFor="let item of DistrictDataService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Xã phường</label>
                        <select class="form-select" name="WardDataID"
                            [(ngModel)]="CompanyInfoService.FormData.WardDataID">
                            <option *ngFor="let item of WardDataService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ấp thôn</label>
                        <input name="hamlet" [(ngModel)]="CompanyInfoService.FormData.hamlet" placeholder="Ấp thôn"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Địa chỉ</label>
                        <input name="address" [(ngModel)]="CompanyInfoService.FormData.address" placeholder="Địa chỉ"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Công suất thiết kế (tấn/tháng)</label>
                        <input name="CongSuatThietKe" [(ngModel)]="CompanyInfoService.FormData.CongSuatThietKe"
                            placeholder="Công suất thiết kế (tấn/tháng)" type="number" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Sản lượng (tấn/tháng)</label>
                        <input name="SanLuong" [(ngModel)]="CompanyInfoService.FormData.SanLuong"
                            placeholder="Sản lượng (tấn/tháng)" type="number" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tổng diện tích (ha)</label>
                        <input name="DienTich" [(ngModel)]="CompanyInfoService.FormData.DienTich" placeholder="0"
                            type="number" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Diện tích nuôi trồng thủy sản (ha)</label>
                        <input name="CoSoNuoiDienTichNuoiTrong"
                            [(ngModel)]="CompanyInfoService.FormData.CoSoNuoiDienTichNuoiTrong" placeholder="0"
                            type="number" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tổng số ao nuôi</label>
                        <input name="CoSoNuoiSoLuongAo" [(ngModel)]="CompanyInfoService.FormData.CoSoNuoiSoLuongAo"
                            placeholder="0" type="number" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Lao động (người)</label>
                        <input name="SoLuongLaoDong" [(ngModel)]="CompanyInfoService.FormData.SoLuongLaoDong"
                            placeholder="Lao động (người)" type="number" class="form-control">
                    </div>
                  
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Website</label>
                        <input name="Website" [(ngModel)]="CompanyInfoService.FormData.Website" placeholder="Website" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Facebook</label>
                        <input name="Facebook" [(ngModel)]="CompanyInfoService.FormData.Facebook" placeholder="Facebook" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiktok</label>
                        <input name="Tiktok" [(ngModel)]="CompanyInfoService.FormData.Tiktok" placeholder="Tiktok" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Youtube</label>
                        <input name="Youtube" [(ngModel)]="CompanyInfoService.FormData.Youtube" placeholder="Youtube" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Zalo</label>
                        <input name="Zalo" [(ngModel)]="CompanyInfoService.FormData.Zalo" placeholder="Zalo" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">COOP.66</label>
                        <input name="COOP66" [(ngModel)]="CompanyInfoService.FormData.COOP66" placeholder="COOP.66" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">FBS</label>
                        <input name="FBS" [(ngModel)]="CompanyInfoService.FormData.FBS" placeholder="FBS" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">MS</label>
                        <input name="MS" [(ngModel)]="CompanyInfoService.FormData.MS" placeholder="MS" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi chú</label>
                        <input name="Note" [(ngModel)]="CompanyInfoService.FormData.Note" placeholder="Ghi chú"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi chú khác</label>
                        <input name="Description" [(ngModel)]="CompanyInfoService.FormData.Description"
                            placeholder="Ghi chú khác" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Quy trình</label>
                        <select class="form-select" name="PlanTypeID"
                            [(ngModel)]="CompanyInfoService.FormData.PlanTypeID">
                            <option *ngFor="let item of PlanTypeService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Phân loại</label>
                        <select class="form-select" name="ParentID" [(ngModel)]="CompanyInfoService.FormData.ParentID">
                            <option *ngFor="let item of DanhMucCompanyPhanLoaiService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Quy mô</label>
                        <select class="form-select" name="CompanyScopeID"
                            [(ngModel)]="CompanyInfoService.FormData.CompanyScopeID">
                            <option *ngFor="let item of CompanyScopeService.List;" [value]="item.ID">
                                {{item.Code}} - {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Nhóm</label>
                        <select class="form-select" name="CompanyGroupID"
                            [(ngModel)]="CompanyInfoService.FormData.CompanyGroupID">
                            <option *ngFor="let item of CompanyGroupService.List;" [value]="item.ID">
                                {{item.Code}} - {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tình trạng</label>
                        <select class="form-select" name="DanhMucCompanyTinhTrangID"
                            [(ngModel)]="CompanyInfoService.FormData.DanhMucCompanyTinhTrangID">
                            <option *ngFor="let item of DanhMucCompanyTinhTrangService.List;" [value]="item.ID">
                                {{item.Code}} - {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Loại hình</label>
                        <select class="form-select" name="CompanyFieldID"
                            [(ngModel)]="CompanyInfoService.FormData.CompanyFieldID">
                            <option *ngFor="let item of CompanyFieldsService.List;" [value]="item.ID">{{item.Name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Sản phẩm</label>
                        <select class="form-select" name="ProductGroupID"
                            [(ngModel)]="CompanyInfoService.FormData.ProductGroupID">
                            <option *ngFor="let item of ProductGroupService.List;" [value]="item.ID">{{item.Name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Thị trường tiêu thụ</label>
                        <select class="form-select" name="DanhMucThiTruongID"
                            [(ngModel)]="CompanyInfoService.FormData.DanhMucThiTruongID">
                            <option *ngFor="let item of DanhMucThiTruongService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Chương trình QLCL</label>
                        <select class="form-select" name="DanhMucChuongTrinhQuanLyChatLuongID"
                            [(ngModel)]="CompanyInfoService.FormData.DanhMucChuongTrinhQuanLyChatLuongID">
                            <option *ngFor="let item of DanhMucChuongTrinhQuanLyChatLuongService.List;"
                                [value]="item.ID">
                                {{item.Code}} - {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Hình thức nuôi</label>
                        <select class="form-select" name="DanhMucHinhThucNuoiID"
                            [(ngModel)]="CompanyInfoService.FormData.DanhMucHinhThucNuoiID">
                            <option *ngFor="let item of DanhMucHinhThucNuoiService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    
                </div>
            </div>
        </div>
        <div *ngIf="CompanyInfoService.FormData.ID>0" class="tab-pane fade" id="CompanyLake" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-10 col-sm-12 col-12">
                    <input [(ngModel)]="CompanyLakeService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="CompanyLakeSearch()">
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="CompanyLakeSearch()"><i
                            class="bi bi-search"></i></a>
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Thêm mới" (click)="CompanyLakeAdd(0)"><i
                            class="bi bi-plus"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-warning">
                <div class="card-header">
                    <h5 class="card-title text-white">Ao hồ
                        <span *ngIf="CompanyLakeService.List">({{CompanyLakeService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table mat-table matSort #CompanyLakeSort="matSort"
                                [dataSource]="CompanyLakeService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                        <div *ngIf="CompanyLakePaginator">
                                            {{CompanyLakePaginator.pageSize *
                                            CompanyLakePaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ID}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="species_name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Vật nuôi
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.species_name}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã ao</th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer;" title="{{element.Code}}" class="link-primary"
                                            (click)="CompanyLakeAdd(element.ID)"><b>{{element.Code}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer;" title="{{element.Name}}" class="link-primary"
                                            (click)="CompanyLakeAdd(element.ID)"><b>{{element.Name}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="acreage">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Diện tích (ha)
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="text-align: right;">
                                        {{element.acreage | number: "1.0-0"}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="btn btn-danger" (click)="CompanyLakeDelete(element)"
                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="CompanyLakeService.DisplayColumns002">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: CompanyLakeService.DisplayColumns002;">
                                </tr>
                            </table>
                            <mat-paginator #CompanyLakePaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="CompanyInfoService.FormData.ID>0" class="tab-pane fade" id="ThanhVien" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-11 col-sm-12 col-12">
                    <input [(ngModel)]="ThanhVienService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="ThanhVienSearch()">
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="ThanhVienSearch()"><i
                            class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                </div>
            </div>
            <div class="card mb-4 card-primary">
                <div class="card-header">
                    <h5 class="card-title text-white bg-primary">Nhân viên/Nông hộ
                        <span *ngIf="ThanhVienService.List">({{ThanhVienService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body bg-primary">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort #ThanhVienSort="matSort"
                                [dataSource]="ThanhVienService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                        <div *ngIf="ThanhVienPaginator">
                                            {{ThanhVienPaginator.pageSize *
                                            ThanhVienPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ID}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ParentID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Chức danh
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <select class="form-select" name="ParentID" [(ngModel)]="element.ParentID">
                                            <option *ngFor="let item of DanhMucThanhVienService.List;"
                                                [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nhân viên
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="text" placeholder="Nhân viên"
                                            name="Name{{element.ID}}" [(ngModel)]="element.Name">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Email">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Email
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="text" placeholder="Email"
                                            name="Email{{element.ID}}" [(ngModel)]="element.Email">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DienThoai">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="text" placeholder="Điện thoại"
                                            name="DienThoai{{element.ID}}" [(ngModel)]="element.DienThoai">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="btn btn-info" (click)="ThanhVienSave(element)"><i
                                                class="bi bi-sd-card"></i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-danger" (click)="ThanhVienDelete(element)"
                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="ThanhVienService.DisplayColumns004">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: ThanhVienService.DisplayColumns004;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile" #ThanhVienPaginator="matPaginator"
                                [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                [showFirstLastButtons]></mat-paginator>


                            <table *ngIf="NotificationService.IsMobile" mat-table matSort #ThanhVienSort="matSort"
                                [dataSource]="ThanhVienService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Chức danh</label>
                                                <select class="form-select" name="ParentID"
                                                    [(ngModel)]="element.ParentID">
                                                    <option *ngFor="let item of DanhMucThanhVienService.List;"
                                                        [value]="item.ID">
                                                        {{item.Name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Họ tên</label>
                                                <input class="form-control" type="text" placeholder="Họ tên"
                                                    name="Name{{ element.ID }}" [(ngModel)]="element.Name" />
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Email</label>
                                                <input class="form-control" type="text" placeholder="Email"
                                                    name="Email{{ element.ID }}" [(ngModel)]="element.Email" />
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Điện thoại</label>
                                                <input class="form-control" type="text" placeholder="Điện thoại"
                                                    name="DienThoai{{ element.ID }}" [(ngModel)]="element.DienThoai" />
                                            </div>
                                            <div class="row gx-4">
                                                <div class="col-lg-6 col-sm-6 col-6">
                                                    <a class="btn btn-info" style="width: 100%"
                                                        (click)="ThanhVienSave(element)"><i class="bi bi-sd-card"></i>
                                                    </a>
                                                </div>
                                                <div class="col-lg-6 col-sm-6 col-6">
                                                    <a class="btn btn-danger" style="width: 100%"
                                                        (click)="ThanhVienDelete(element)" *ngIf="element.ID > 0"><i
                                                            class="bi bi-trash"></i>
                                                    </a>
                                                </div>
                                                <br />
                                                <br />
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="
                                    ThanhVienService.DisplayColumnsMobile
                                  "></tr>
                                <tr mat-row *matRowDef="
                                    let row;
                                    columns: ThanhVienService.DisplayColumnsMobile
                                  "></tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile" #ThanhVienPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="CompanyInfoService.FormData.ID>0" class="tab-pane fade" id="CompanyInfoMap" role="tabpanel">
            <div class="row">
                <div class="col-lg-3 col-sm-12 col-12">
                    <label class="form-label">.</label>
                    <a style="width: 100%;" title="Xem bản đồ" (click)="MapLoad()" class="btn btn-success"><i
                            class="bi bi-pin-map"></i> Xem bản đồ</a>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <label class="form-label">.</label>
                    <a style="width: 100%;" title="Lấy tọa độ" (click)="GetPosition()" class="btn btn-success"><i
                            class="bi bi-pin-map"></i> Lấy tọa độ</a>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <label class="form-label">Kinh độ</label>
                    <input name="longitude" [(ngModel)]="CompanyInfoService.FormData.longitude" placeholder="Kinh độ"
                        type="number" class="form-control">
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <label class="form-label">Vĩ độ</label>
                    <input name="latitude" [(ngModel)]="CompanyInfoService.FormData.latitude" placeholder="Vĩ độ"
                        type="number" class="form-control">
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="map" #map style="height: 600px; width: 100%;">
                        <div style="z-index: 1000; position: absolute; top: 0;">
                            <img src="{{domainURL}}assets/image/vungtrong.png" />
                        </div>
                        <div
                            style="z-index: 1000; position: absolute; bottom: 0; width: 100%; height: 20px; background-color: #ffffff;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="CompanyInfoService.FormData.ID>0" class="tab-pane fade" id="ProductInfo" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-11 col-sm-12 col-12">
                    <input [(ngModel)]="ProductInfoService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="ProductInfoSearch()">
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="ProductInfoSearch()"><i
                            class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                </div>
                <!-- <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="ProductInfoAdd(0)"><i
                            class="bi bi-plus"></i></a>&nbsp;&nbsp;&nbsp;
                </div> -->
            </div>
            <div class="card mb-4 card-success">
                <div class="card-header">
                    <h5 class="card-title text-white">Sản phẩm
                        <span *ngIf="ProductInfoService.List">({{ProductInfoService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table mat-table matSort #ProductInfoSort="matSort"
                                [dataSource]="ProductInfoService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                        <div *ngIf="ProductInfoPaginator">
                                            {{ProductInfoPaginator.pageSize *
                                            ProductInfoPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.ID }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Code
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.Code }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Tiêu đề
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer" title="{{ element.Name }}" class="link-primary"
                                            (click)="ProductInfoAdd(element.ID)"><b>{{ element.Name }}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="congbo_date">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Công bố
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.congbo_date | date : "dd/MM/yyyy" }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="price_min">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Giá thấp nhất
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.price_min | number: "1.0-0" }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="price_max">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Giá cao nhất
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.price_max | number: "1.0-0" }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="btn btn-danger" (click)="ProductInfoDelete(element)"
                                            *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a *ngIf="element.FileName" class="btn btn-success" title="File công bố"
                                            href="{{element.FileName}}" target="_blank"><i
                                                class="bi bi-cloud-download"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="ProductInfoService.DisplayColumns002">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: ProductInfoService.DisplayColumns002;">
                                </tr>
                            </table>
                            <mat-paginator #ProductInfoPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="CompanyInfoService.FormData.ID>0" class="tab-pane fade" id="CompanyInfoStateAgency" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-11 col-sm-12 col-12">
                    <input [(ngModel)]="CompanyInfoStateAgencyService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="CompanyInfoStateAgencySearch()">
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm"
                        (click)="CompanyInfoStateAgencySearch()"><i class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                </div>
            </div>
            <div class="card mb-4 card-primary">
                <div class="card-header">
                    <h5 class="card-title text-white bg-primary">Danh sách
                        <span *ngIf="CompanyInfoStateAgencyService.List">({{CompanyInfoStateAgencyService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body bg-primary">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                #CompanyInfoStateAgencySort="matSort"
                                [dataSource]="CompanyInfoStateAgencyService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                        <div *ngIf="CompanyInfoStateAgencyPaginator">
                                            {{CompanyInfoStateAgencyPaginator.pageSize *
                                            CompanyInfoStateAgencyPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ID}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="StateAgencyID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Cơ quan quản lý
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <select class="form-select" name="StateAgencyID"
                                            [(ngModel)]="element.StateAgencyID">
                                            <option *ngFor="let item of StateAgencyService.List;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="btn btn-info" (click)="CompanyInfoStateAgencySave(element)"><i
                                                class="bi bi-sd-card"></i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-danger" (click)="CompanyInfoStateAgencyDelete(element)"
                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="CompanyInfoStateAgencyService.DisplayColumns001">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: CompanyInfoStateAgencyService.DisplayColumns001;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                #CompanyInfoStateAgencyPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="6" [showFirstLastButtons]></mat-paginator>


                            <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                #CompanyInfoStateAgencySort="matSort"
                                [dataSource]="TCompanyInfoStateAgencyService.DataSource"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Cơ quan quản lý</label>
                                                <select class="form-select" name="StateAgencyID"
                                                    [(ngModel)]="element.StateAgencyID">
                                                    <option *ngFor="let item of StateAgencyService.List;"
                                                        [value]="item.ID">
                                                        {{item.Name}}</option>
                                                </select>
                                            </div>
                                            <div class="row gx-4">
                                                <div class="col-lg-6 col-sm-6 col-6">
                                                    <a class="btn btn-info" style="width: 100%"
                                                        (click)="CompanyInfoStateAgencySave(element)"><i
                                                            class="bi bi-sd-card"></i>
                                                    </a>
                                                </div>
                                                <div class="col-lg-6 col-sm-6 col-6">
                                                    <a class="btn btn-danger" style="width: 100%"
                                                        (click)="CompanyInfoStateAgencyDelete(element)"
                                                        *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                    </a>
                                                </div>
                                                <br />
                                                <br />
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="
                                    CompanyInfoStateAgencyService.DisplayColumnsMobile
                                  "></tr>
                                <tr mat-row *matRowDef="
                                    let row;
                                    columns: CompanyInfoStateAgencyService.DisplayColumnsMobile
                                  "></tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile"
                                #CompanyInfoStateAgencyPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="CompanyInfoService.IsShowLoading"></app-loading>