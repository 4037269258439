<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-CompanyLakeDetail" data-bs-toggle="tab" href="#CompanyLakeDetail"
                role="tab" aria-controls="CompanyLakeDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Ao hồ</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="CompanyLakeDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="CompanyLakeSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="CompanyLakeService.FormData.Active" />
                        <label class="form-check-label" for="Active">Kích hoạt</label>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu đề</label>
                        <input name="Name" [(ngModel)]="CompanyLakeService.FormData.Name" placeholder="Tiêu đề"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã ao</label>
                        <input name="Code" [(ngModel)]="CompanyLakeService.FormData.Code" placeholder="Code" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Diện tích (ha)</label>
                        <input name="acreage" [(ngModel)]="CompanyLakeService.FormData.acreage" placeholder="0"
                            type="number" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Kinh độ</label>
                        <input name="longitude" [(ngModel)]="CompanyLakeService.FormData.longitude"
                            placeholder="Kinh độ" type="number" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Vĩ độ</label>
                        <input name="latitude" [(ngModel)]="CompanyLakeService.FormData.latitude" placeholder="Vĩ độ"
                            type="number" class="form-control">
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đơn vị Tổ chức</label>
                        <mat-select *ngIf="CompanyLakeService.FormData.ID==0" class="form-control"
                            name="CompanyLakeService.FormData.ParentID"
                            [(ngModel)]="CompanyLakeService.FormData.ParentID">
                            <input placeholder="Tìm..." class="form-control"
                                (keyup)="CompanyInfoFilter($event.target.value)">
                            <mat-option *ngFor="let item of CompanyInfoService.ListFilter" [value]="item.ID">
                                {{item.Name}} - {{item.address}}
                            </mat-option>
                        </mat-select>
                        <input *ngIf="CompanyLakeService.FormData.ID>0" name="TypeName"
                            [(ngModel)]="CompanyLakeService.FormData.TypeName" disabled placeholder="Đơn vị Tổ chức"
                            type="text" class="form-control" />
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Vật nuôi</label>
                        <select class="form-select" name="SpeciesID"
                            [(ngModel)]="CompanyLakeService.FormData.SpeciesID">
                            <option *ngFor="let item of SpeciesService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Quận huyện</label>
                        <select class="form-select" name="DistrictDataID"
                            [(ngModel)]="CompanyLakeService.FormData.DistrictDataID" (change)="WardDataSearch()">
                            <option *ngFor="let item of DistrictDataService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Xã phường</label>
                        <select class="form-select" name="WardDataID"
                            [(ngModel)]="CompanyLakeService.FormData.WardDataID">
                            <option *ngFor="let item of WardDataService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <!-- <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ấp thôn</label>
                        <input name="hamlet" [(ngModel)]="CompanyLakeService.FormData.hamlet" placeholder="Ấp thôn"
                            type="text" class="form-control">
                    </div> -->
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Địa chỉ</label>
                        <input name="address" [(ngModel)]="CompanyLakeService.FormData.address" placeholder="Địa chỉ"
                            type="text" class="form-control">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="CompanyLakeService.IsShowLoading"></app-loading>