<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-CompanyInfoThuMuaNongSanDetail" data-bs-toggle="tab"
                href="#CompanyInfoThuMuaNongSanDetail" role="tab" aria-controls="CompanyInfoThuMuaNongSanDetail"
                aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Bán ra nông sản
                    [{{CompanyInfoThuMuaNongSanService.FormData.ID}}]</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="CompanyInfoThuMuaNongSanDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="CompanyInfoThuMuaNongSanSave()"
                        class="btn btn-info"><i class="bi bi-sd-card"></i>
                        Lưu thay đổi</a>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="IsBenMua" disabled
                            [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.IsBenMua" />
                        <label class="form-check-label" for="IsBenMua">Bên mua xác
                            nhận</label>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="IsBenBan"
                            [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.IsBenBan" />
                        <label class="form-check-label" for="IsBenBan">Bên bán xác
                            nhận</label>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Bên mua</label>
                        <input name="DonViName" [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.DonViName"
                            placeholder="Bên mua" type="text" class="form-control" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã số thuế</label>
                        <input name="DonViMaSoThue" [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.DonViMaSoThue"
                            placeholder="Mã số thuế" type="text" class="form-control" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Điện thoại</label>
                        <input name="DonViDienThoai"
                            [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.DonViDienThoai"
                            placeholder="Điện thoại" type="text" class="form-control" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Email</label>
                        <input name="DonViEmail" [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.DonViEmail"
                            placeholder="Email" type="text" class="form-control" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Địa chỉ</label>
                        <input name="DonViDiaChi" [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.DonViDiaChi"
                            placeholder="Địa chỉ" type="text" class="form-control" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã đơn hàng (nếu có)</label>
                        <input name="Code" [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.Code"
                            placeholder="Mã đơn hàng (nếu có)" type="text" class="form-control" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi chú</label>
                        <input name="Note" [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.Note"
                            placeholder="Ghi chú" type="text" class="form-control" disabled>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Bên bán</label>
                        <input name="BenBanName" [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.BenBanName"
                            placeholder="Bên bán" type="text" class="form-control" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã số thuế</label>
                        <input name="BenBanMaSoThue" [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.BenBanMaSoThue"
                            placeholder="Mã số thuế" type="text" class="form-control" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Điện thoại</label>
                        <input name="BenBanDienThoai"
                            [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.BenBanDienThoai"
                            placeholder="Điện thoại" type="text" class="form-control" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Email</label>
                        <input name="BenBanEmail" [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.BenBanEmail"
                            placeholder="Email" type="text" class="form-control" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Địa chỉ</label>
                        <input name="BenBanDiaChi" [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.BenBanDiaChi"
                            placeholder="Địa chỉ" type="text" class="form-control" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã số vùng trồng</label>
                        <input name="MaSoVungTrong" [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.MaSoVungTrong"
                            placeholder="Mã số vùng trồng" type="text" class="form-control" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã số Cơ sở đóng gói</label>
                        <input name="MaSoDongGoi" [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.MaSoDongGoi"
                            placeholder="Mã số Cơ sở đóng gói" type="text" class="form-control" disabled>
                    </div>          
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi nhận</label>
                        <input [ngModel]="CompanyInfoThuMuaNongSanService.FormData.NgayGhiNhan | date:'yyyy-MM-dd'"
                            (ngModelChange)="DateNgayGhiNhan($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                            type="date" class="form-control" autocomplete="off" style="text-align: right;" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Sản phẩm</label>
                        <input name="SanPham" [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.SanPham"
                            placeholder="Sản phẩm" type="text" class="form-control" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Thị trường</label>
                        <input name="ThiTruong" [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.ThiTruong"
                            placeholder="Thị trường" type="text" class="form-control" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đơn vị tính</label>
                        <input name="DonViTinhName" [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.DonViTinhName"
                            placeholder="Đơn vị tính" type="text" class="form-control" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số lượng</label>
                        <input name="SoLuong" [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.SoLuong"
                            placeholder="Số lượng" type="number" class="form-control" style="text-align: right;" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đơn giá (đồng)</label>
                        <input name="DonGia" [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.DonGia"
                            placeholder="Đơn giá" type="number" class="form-control" style="text-align: right;" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Thành tiền (đồng)</label>
                        <input name="ThanhTien" [(ngModel)]="CompanyInfoThuMuaNongSanService.FormData.ThanhTien"
                            placeholder="Thành tiền" type="number" class="form-control"
                            style="text-align: right;" disabled>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<app-loading *ngIf="CompanyInfoThuMuaNongSanService.IsShowLoading"></app-loading>