<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-ThanhVienPhanQuyenChucNang" data-bs-toggle="tab"
                href="#ThanhVienPhanQuyenChucNang" role="tab" aria-controls="ThanhVienPhanQuyenChucNang"
                aria-selected="true">
                <span class="badge bg-success" style="font-size: 16px;">Phân quyền
                    | {{DanhMucChucNangService.FormData.Name}} [{{DanhMucChucNangService.FormData.ID}}]</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="ThanhVienPhanQuyenChucNang" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">                   
                    <div class="card mb-4 card-danger">
                        <div class="card-header">
                            <h5 class="card-title text-white">Thành viên
                                <span
                                    *ngIf="ThanhVienPhanQuyenChucNangService.List">({{ThanhVienPhanQuyenChucNangService.List.length}}
                                    items)</span>
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table mat-table matSort #ThanhVienPhanQuyenChucNangSort002="matSort"
                                        [dataSource]="ThanhVienPhanQuyenChucNangService.DataSource"
                                        class="table table-striped m-0">                                        
                                        <ng-container matColumnDef="DanhMucThanhVienID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Thành viên
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <select class="form-select" name="element.DanhMucThanhVienID"
                                                    [(ngModel)]="element.DanhMucThanhVienID"
                                                    (change)="ThanhVienPhanQuyenChucNangSave(element)">
                                                    <option *ngFor="let item of DanhMucThanhVienService.List;"
                                                        [value]="item.ID">
                                                        {{item.Name}}</option>
                                                </select>
                                            </td>
                                        </ng-container>                                        
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-danger"
                                                    (click)="ThanhVienPhanQuyenChucNangDelete(element)"
                                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="ThanhVienPhanQuyenChucNangService.DisplayColumns002">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: ThanhVienPhanQuyenChucNangService.DisplayColumns002;">
                                        </tr>
                                    </table>
                                    <mat-paginator #ThanhVienPhanQuyenChucNangPaginator002="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">                    
                    <div class="card mb-4 card-warning">
                        <div class="card-header">
                            <h5 class="card-title text-white">Cơ quan quản lý
                                <span
                                    *ngIf="ThanhVienPhanQuyenChucNangService.ListFilter">({{ThanhVienPhanQuyenChucNangService.ListFilter.length}}
                                    items)</span>
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table mat-table matSort #ThanhVienPhanQuyenChucNangSort001="matSort"
                                        [dataSource]="ThanhVienPhanQuyenChucNangService.DataSourceFilter"
                                        class="table table-striped m-0">                                        
                                        <ng-container matColumnDef="StateAgencyID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cơ quan quản lý
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <select class="form-select" name="element.StateAgencyID"
                                                    [(ngModel)]="element.StateAgencyID"
                                                    (change)="ThanhVienPhanQuyenChucNangSave(element)">
                                                    <option *ngFor="let item of StateAgencyService.List;"
                                                        [value]="item.ID">
                                                        {{item.Name}}</option>
                                                </select>
                                            </td>
                                        </ng-container>                                       
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-danger"
                                                    (click)="ThanhVienPhanQuyenChucNangDelete(element)"
                                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="ThanhVienPhanQuyenChucNangService.DisplayColumns003">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: ThanhVienPhanQuyenChucNangService.DisplayColumns003;">
                                        </tr>
                                    </table>
                                    <mat-paginator #ThanhVienPhanQuyenChucNangPaginator001="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="DanhMucChucNangService.IsShowLoading"></app-loading>