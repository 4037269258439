import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TapTinDinhKem } from 'src/app/shared/TapTinDinhKem.model';
import { BaseService } from './Base.service';
import { MatTableDataSource } from '@angular/material/table';

@Injectable({
    providedIn: 'root'
})
export class TapTinDinhKemService extends BaseService{

    


    ListChild: TapTinDinhKem[] | undefined;
    ListParent: TapTinDinhKem[] | undefined;

    List: TapTinDinhKem[] | undefined;
    ListFilter: TapTinDinhKem[] | undefined;
    FormData!: TapTinDinhKem;

    DisplayColumns001: string[] = ['STT', 'ParentID', 'Name', 'MaSo', 'NgayCap', 'NgayHetHan', 'NoiCap','Note', 'FileName', 'Save'];
    DisplayColumns002: string[] = ['STT', 'Name','Note', 'FileName','Display'];

    DataSource002: MatTableDataSource<any>;

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "TapTinDinhKem";
    }
}

