import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { CompanyInfoThuMuaNongSan } from 'src/app/shared/CompanyInfoThuMuaNongSan.model';
import { CompanyInfoThuMuaNongSanService } from 'src/app/shared/CompanyInfoThuMuaNongSan.service';

import { CompanyInfo } from 'src/app/shared/CompanyInfo.model';
import { CompanyInfoService } from 'src/app/shared/CompanyInfo.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

import { CompanyInfoDetailComponent } from '../company-info-detail/company-info-detail.component';
import { CompanyInfoThuMuaNongSanDetailComponent } from '../company-info-thu-mua-nong-san-detail/company-info-thu-mua-nong-san-detail.component';
import { ThanhVienDetailComponent } from '../thanh-vien-detail/thanh-vien-detail.component';

@Component({
  selector: 'app-co-so-company-info-thu-mua-nong-san',
  templateUrl: './co-so-company-info-thu-mua-nong-san.component.html',
  styleUrls: ['./co-so-company-info-thu-mua-nong-san.component.css']
})
export class CoSoCompanyInfoThuMuaNongSanComponent implements OnInit {

  @ViewChild('CompanyInfoThuMuaNongSanSort') CompanyInfoThuMuaNongSanSort: MatSort;
  @ViewChild('CompanyInfoThuMuaNongSanPaginator') CompanyInfoThuMuaNongSanPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,

    public CompanyInfoThuMuaNongSanService: CompanyInfoThuMuaNongSanService,

    public CompanyInfoService: CompanyInfoService,

    public ThanhVienService: ThanhVienService,
  ) { }

  ngOnInit(): void {
    this.ThanhVienService.GetLogin();
  }

  DateBatDau(value) {
    this.CompanyInfoThuMuaNongSanService.BaseParameter.BatDau = new Date(value);
  }
  DateKetThuc(value) {
    this.CompanyInfoThuMuaNongSanService.BaseParameter.KetThuc = new Date(value);
  }
  CompanyInfoThuMuaNongSanSearch() {
    if (this.CompanyInfoThuMuaNongSanService.BaseParameter.SearchString.length > 0) {
      this.CompanyInfoThuMuaNongSanService.BaseParameter.SearchString = this.CompanyInfoThuMuaNongSanService.BaseParameter.SearchString.trim();
      this.CompanyInfoThuMuaNongSanService.DataSource.filter = this.CompanyInfoThuMuaNongSanService.BaseParameter.SearchString.toLowerCase();
    }
    else {
      this.CompanyInfoThuMuaNongSanService.IsShowLoading = true;
      this.CompanyInfoThuMuaNongSanService.BaseParameter.ParentID = this.ThanhVienService.FormDataLogin.CompanyInfoID;
      this.CompanyInfoThuMuaNongSanService.GetByParentIDToListAsync().subscribe(
        res => {
          this.CompanyInfoThuMuaNongSanService.List = (res as CompanyInfoThuMuaNongSan[]).sort((a, b) => (a.SortOrder < b.SortOrder ? 1 : -1));

          this.CompanyInfoThuMuaNongSanService.DataSource = new MatTableDataSource(this.CompanyInfoThuMuaNongSanService.List);
          this.CompanyInfoThuMuaNongSanService.DataSource.sort = this.CompanyInfoThuMuaNongSanSort;
          this.CompanyInfoThuMuaNongSanService.DataSource.paginator = this.CompanyInfoThuMuaNongSanPaginator;

          this.CompanyInfoThuMuaNongSanService.IsShowLoading = false;
        },
        err => {
          this.CompanyInfoThuMuaNongSanService.IsShowLoading = false;
        }
      );
    }
  }
  CompanyInfoThuMuaNongSanDelete(element: CompanyInfoThuMuaNongSan) {
    this.CompanyInfoThuMuaNongSanService.IsShowLoading = true;
    this.CompanyInfoThuMuaNongSanService.BaseParameter.ID = element.ID;
    this.CompanyInfoThuMuaNongSanService.RemoveAsync().subscribe(
      res => {
        this.NotificationService.warn(environment.SaveSuccess);
        this.CompanyInfoThuMuaNongSanSearch();
        this.CompanyInfoThuMuaNongSanService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.CompanyInfoThuMuaNongSanService.IsShowLoading = false;
      }
    );
  }

  CompanyInfoThuMuaNongSanAdd(ID: number) {
    this.CompanyInfoThuMuaNongSanService.BaseParameter.ID = ID;
    this.CompanyInfoThuMuaNongSanService.GetByIDAsync().subscribe(
      res => {
        this.CompanyInfoThuMuaNongSanService.FormData = res as CompanyInfoThuMuaNongSan;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(CompanyInfoThuMuaNongSanDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.CompanyInfoThuMuaNongSanSearch();
        });
      },
      err => {
      }
    );
  }

  CompanyInfoAdd(ID: number) {
    this.CompanyInfoService.BaseParameter.ID = ID;
    this.CompanyInfoService.GetByIDAsync().subscribe(
      res => {
        this.CompanyInfoService.FormData = res as CompanyInfo;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(CompanyInfoDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
      },
      err => {
      }
    );
  }
  ThanhVienAdd(ID: number) {
    this.ThanhVienService.BaseParameter.ID = ID;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.FormData = res as ThanhVien;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThanhVienDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
      },
      err => {
      }
    );
  }
}