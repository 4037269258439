<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-Report" data-bs-toggle="tab" href="#Report"
                                    role="tab" aria-controls="Report" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">I. An toàn thực phẩm</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="Report" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Đơn vị Tổ chức
                                        </label>
                                        <mat-select class="form-control" name="ReportService.BaseParameter.CompanyInfoID"
                                            [(ngModel)]="ReportService.BaseParameter.CompanyInfoID" (selectionChange)="ReportSearch()">
                                            <input placeholder="Tìm..." class="form-control"
                                                (keyup)="CompanyInfoFilter($event.target.value)">
                                            <mat-option *ngFor="let item of CompanyInfoService.ListFilter"
                                                [value]="item.ID">
                                                <b>{{item.Name}}</b> - {{item.phone}} - {{item.DKKD}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-5 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input [(ngModel)]="ReportService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control" (keyup.enter)="ReportSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <div>
                                            <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                                (click)="ReportSearch()"><i class="bi bi-search"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span *ngIf="ReportService.List">({{ReportService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ReportSort="matSort" [dataSource]="ReportService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="ReportPaginator">
                                                                {{ReportPaginator.pageSize *
                                                                ReportPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="CompanyInfoID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Đơn vị
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;"
                                                                title="{{element.CompanyInfoName}}" class="link-primary"
                                                                (click)="CompanyInfoAdd(element.CompanyInfoID)"><b>{{element.CompanyInfoName}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="PlanThamDinhID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nội dung
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.Name}}"
                                                                class="link-primary"
                                                                (click)="PlanThamDinhAdd(element.PlanThamDinhID)"><b>{{element.Name}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hồ sơ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.MaSo}}"
                                                                class="link-primary"
                                                                (click)="PlanThamDinhDetailAdd(element.ID)"><b>{{element.MaSo}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayBatDau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element" style="text-align: right;">
                                                            {{element.NgayBatDau | date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucATTPXepLoaiName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Xếp loại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucATTPXepLoaiName}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.DisplayColumnsCompanyInfoI">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ReportService.DisplayColumnsCompanyInfoI;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ReportPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                                    [pageSize]="6" [showFirstLastButtons]></mat-paginator>

                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ReportSort="matSort" [dataSource]="ReportService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Đơn vị: </label>
                                                                    <br />
                                                                    <a style="cursor: pointer;"
                                                                        title="{{element.CompanyInfoName}}"
                                                                        class="link-primary"
                                                                        (click)="CompanyInfoAdd(element.CompanyInfoID)"><b>{{element.CompanyInfoName}}</b></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Nội dung: </label>
                                                                    <br />
                                                                    <a style="cursor: pointer;" title="{{element.Name}}"
                                                                        class="link-primary"
                                                                        (click)="PlanThamDinhAdd(element.PlanThamDinhID)"><b>{{element.Name}}</b></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Hồ sơ: </label>
                                                                    <br />
                                                                    <a style="cursor: pointer;" title="{{element.MaSo}}"
                                                                        class="link-primary"
                                                                        (click)="PlanThamDinhDetailAdd(element.PlanThamDinhCompaniesID)"><b>{{element.MaSo}}</b></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Ghi nhận: </label>
                                                                    <label class="form-label">{{element.NgayBatDau | date:'yyyy-MM-dd'}}</label>                                                          
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Xếp loại: </label>
                                                                    <label class="form-label">{{element.DanhMucATTPXepLoaiName}}</label>                                                          
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ReportService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ReportPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                                    [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ReportService.IsShowLoading"></app-loading>