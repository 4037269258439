import { Component, OnInit, Inject, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MailService } from 'src/app/shared/Mail.service';

import { CompanyInfo } from 'src/app/shared/CompanyInfo.model';
import { CompanyInfoService } from 'src/app/shared/CompanyInfo.service';

import { PlanThamDinh } from 'src/app/shared/PlanThamDinh.model';
import { PlanThamDinhService } from 'src/app/shared/PlanThamDinh.service';
import { PlanThamDinhCompanies } from 'src/app/shared/PlanThamDinhCompanies.model';
import { PlanThamDinhCompaniesService } from 'src/app/shared/PlanThamDinhCompanies.service';
import { PlanThamDinhCompanyDocument } from 'src/app/shared/PlanThamDinhCompanyDocument.model';
import { PlanThamDinhCompanyDocumentService } from 'src/app/shared/PlanThamDinhCompanyDocument.service';


import { CompanyInfoDetailComponent } from '../company-info-detail/company-info-detail.component';
import { CoSoMapDetailComponent } from '../co-so-map-detail/co-so-map-detail.component';
import { PlanThamDinhCompaniesDetailComponent } from '../plan-tham-dinh-companies-detail/plan-tham-dinh-companies-detail.component';
import { PlanThamDinhCompanyDocumentDetailComponent } from '../plan-tham-dinh-company-document-detail/plan-tham-dinh-company-document-detail.component';
import { element } from 'protractor';

@Component({
  selector: 'app-plan-tham-dinh-companies',
  templateUrl: './plan-tham-dinh-companies.component.html',
  styleUrls: ['./plan-tham-dinh-companies.component.css']
})
export class PlanThamDinhCompaniesComponent implements OnInit {

  @ViewChild('originalLink', { static: true }) originalLink!: ElementRef;
  // @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  @ViewChildren('fileInput') fileInputs!: QueryList<ElementRef>;
  @ViewChild('PlanThamDinhCompaniesSort') PlanThamDinhCompaniesSort: MatSort;
  @ViewChild('PlanThamDinhCompaniesPaginator') PlanThamDinhCompaniesPaginator: MatPaginator;

  @ViewChild('PlanThamDinhCompanyDocumentSort') PlanThamDinhCompanyDocumentSort: MatSort;
  @ViewChild('PlanThamDinhCompanyDocumentPaginator') PlanThamDinhCompanyDocumentPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<PlanThamDinhCompaniesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public MailService: MailService,

    public CompanyInfoService: CompanyInfoService,

    public PlanThamDinhService: PlanThamDinhService,
    public PlanThamDinhCompaniesService: PlanThamDinhCompaniesService,
    public PlanThamDinhCompanyDocumentService: PlanThamDinhCompanyDocumentService,
  ) { }

  SHOWRESULT :boolean = environment.ShowResult

  ngOnInit(): void {
    this.SetInitValue()
    this.PlanThamDinhCompanyDocumentSearch();
    this.PlanThamDinhCompaniesSearch();
  }

  SetInitValue(){
    this.PlanThamDinhCompaniesService.BaseParameter.SearchString = environment.InitializationString
    this.PlanThamDinhCompanyDocumentService.BaseParameter.SearchString = environment.InitializationString
  }

  CheckShowResult(element: PlanThamDinhCompanies): boolean {
    return this.listUploadFilesGobal.some(e => 
      e.DocumentTemplateID === 40 && e.PlanThamDinhID === element.ParentID
    );
  }
  isPDFFile(fileName: string): boolean {
    return /\.pdf$/i.test(fileName);
  }

  isWordOrExcelFile(fileName: string): boolean {
    return /\.(doc|docx|xlsx)$/i.test(fileName);
  }
  
  getViewerUrl(fileName: string): string {
    if (this.isPDFFile(fileName)) {
      return fileName;
    } else if (this.isWordOrExcelFile(fileName)) {
      return `https://docs.google.com/viewer?url=${encodeURIComponent(fileName)}&embedded=true`;
    }
    return fileName; // Mặc định
  }
  PlanThamDinhCompanyDocumentAdd(ID: number) {
    this.PlanThamDinhCompaniesService.IsShowLoading = true;
    this.PlanThamDinhCompanyDocumentService.BaseParameter.ID = ID;
    this.PlanThamDinhCompanyDocumentService.GetByIDAsync().subscribe(
      res => {
        this.PlanThamDinhCompanyDocumentService.FormData = res as PlanThamDinhCompanyDocument;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: this.PlanThamDinhCompaniesService.FormData.ID };
        const dialog = this.dialog.open(PlanThamDinhCompanyDocumentDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.PlanThamDinhCompanyDocumentSearch();
        });
        this.PlanThamDinhCompaniesService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhCompaniesService.IsShowLoading = false;
      }
    );
  }

  ProcessDocuments(listCut: PlanThamDinhCompanyDocument[]): PlanThamDinhCompanyDocument[] {
    for (let i = 0; i < listCut.length; i++) {
      const currentDoc = listCut[i];
      
      // Tìm tất cả các phần tử có cùng DocumentTemplateID
      const matchingDocs = listCut.filter(
        (doc) => doc.DocumentTemplateID === currentDoc.DocumentTemplateID
      );
  
      // Kiểm tra nếu có nhiều hơn một phần tử có cùng DocumentTemplateID
      if (matchingDocs.length > 1) {
        const docWithFileName = matchingDocs.find((doc) => doc.FileName !== null);
        const docWithoutFileName = matchingDocs.find((doc) => doc.FileName === null);
        
        if (docWithFileName && docWithoutFileName) {
          // Thay thế vị trí của docWithoutFileName bằng docWithFileName
          const indexWithoutFileName = listCut.indexOf(docWithoutFileName);
          const indexWithFileName = listCut.indexOf(docWithFileName);
  
          if (indexWithoutFileName !== -1 && indexWithFileName !== -1) {
            // Đổi vị trí trong danh sách
            listCut[indexWithoutFileName] = docWithFileName;
  
            // Xóa docWithFileName khỏi danh sách
            listCut.splice(indexWithFileName, 1);
          }
        }
      }
    }
    return listCut;
  }

  listCutGobal:PlanThamDinhCompanyDocument[]
  listUploadFilesGobal :PlanThamDinhCompanyDocument[]
  PlanThamDinhCompanyDocumentSearch() {
    if (this.PlanThamDinhCompanyDocumentService.BaseParameter.SearchString.length > 0) {
      this.PlanThamDinhCompanyDocumentService.BaseParameter.SearchString = this.PlanThamDinhCompanyDocumentService.BaseParameter.SearchString.trim();
      this.PlanThamDinhCompanyDocumentService.DataSource.filter = this.PlanThamDinhCompanyDocumentService.BaseParameter.SearchString.toLowerCase();
    }
    else {
      this.PlanThamDinhCompaniesService.IsShowLoading = true;
      this.PlanThamDinhCompanyDocumentService.BaseParameter.PlanThamDinhID = this.PlanThamDinhService.FormData.ID;      
      this.PlanThamDinhCompanyDocumentService.GetByPlanThamDinhIDToListAsync().subscribe(
        res => {
          let listPlanThamDinh = (res as PlanThamDinhCompanyDocument[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          let dataClone = [...listPlanThamDinh.filter(l=>l.DocumentTemplateID !== null)]
          dataClone = this.ProcessDocuments(dataClone);
          let dataClone1 = [...dataClone]
          this.PlanThamDinhCompanyDocumentService.ListPlanThamDinh = dataClone1;
          this.PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinh = new MatTableDataSource(this.PlanThamDinhCompanyDocumentService.ListPlanThamDinh.filter(p=>p.IsUploadConfirm!==true));
          this.PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinh.sort = this.PlanThamDinhCompanyDocumentSort;
          this.PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinh.paginator = this.PlanThamDinhCompanyDocumentPaginator;
          
          this.listCutGobal = dataClone.splice(0,6)
          this.listUploadFilesGobal = listPlanThamDinh.filter(d => d.IsUploadConfirm === true);
          this.listUploadFilesGobal.forEach(element => {
            this.listCutGobal.find(l => l.DocumentTemplateID === element.DocumentTemplateID).IsUploadConfirm = true
          });
          this.PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinhSlice = new MatTableDataSource(this.listCutGobal);
          this.PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinh.sort = this.PlanThamDinhCompanyDocumentSort;
          this.PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinh.paginator = this.PlanThamDinhCompanyDocumentPaginator;
          let dataEmpty : PlanThamDinhCompanyDocument[] = [];
          this.PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinhHS = new MatTableDataSource(dataEmpty);
          this.PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinh.sort = this.PlanThamDinhCompanyDocumentSort;
          this.PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinh.paginator = this.PlanThamDinhCompanyDocumentPaginator;

          this.PlanThamDinhCompaniesService.IsShowLoading = false;
        },
        err => {
          this.PlanThamDinhCompaniesService.IsShowLoading = false;
        }
      );
    }    
  }
  // ChangeFileName(element: PlanThamDinhCompanyDocument, files: FileList) {
  //   if (files) {
  //     this.PlanThamDinhCompanyDocumentService.FileToUpload = files;
  //     this.PlanThamDinhCompanyDocumentsSave(element)
  //   }
  // }
  
  ChangeFileName(element: PlanThamDinhCompanyDocument, files: FileList | null) {
    if (files && files.length > 0) {
      this.PlanThamDinhCompanyDocumentService.FileToUpload = files;
      this.PlanThamDinhCompanyDocumentsSave(element)
      // Thực hiện xử lý tên tệp
      // console.log(`Tên tệp mới của ${element.Name}: ${file.name}`);
    }
  }
  
  PlanThamDinhCompanyDocumentsSave(element: PlanThamDinhCompanyDocument) {
    this.PlanThamDinhCompanyDocumentService.IsShowLoading = true;
    element.PlanThamDinhID = this.PlanThamDinhService.FormData.ID;
    element.Code = this.PlanThamDinhCompanyDocumentService.FormData.Code;
    element.IsUploadConfirm = this.SHOWRESULT;
    this.PlanThamDinhCompanyDocumentService.FormData = element;
    this.PlanThamDinhCompanyDocumentService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.PlanThamDinhCompanyDocumentService.FormData = (res as PlanThamDinhCompanyDocument);
        element.FileName = this.PlanThamDinhCompanyDocumentService.FormData.FileName;
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhCompanyDocumentService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhCompanyDocumentService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhCompaniesSearch() {
    if (this.PlanThamDinhCompaniesService.BaseParameter.SearchString.length > 0) {
      this.PlanThamDinhCompaniesService.BaseParameter.SearchString = this.PlanThamDinhCompaniesService.BaseParameter.SearchString.trim();
      this.PlanThamDinhCompaniesService.DataSource.filter = this.PlanThamDinhCompaniesService.BaseParameter.SearchString.toLowerCase();
    }
    else {
      this.PlanThamDinhCompaniesService.IsShowLoading = true;
      this.PlanThamDinhCompaniesService.BaseParameter.ParentID = this.PlanThamDinhService.FormData.ID;
      this.PlanThamDinhCompaniesService.GetByParentIDToListAsync().subscribe(
        res => {
          this.PlanThamDinhCompaniesService.List = (res as PlanThamDinhCompanies[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));

          this.PlanThamDinhCompaniesService.DataSource = new MatTableDataSource(this.PlanThamDinhCompaniesService.List);
          this.PlanThamDinhCompaniesService.DataSource.sort = this.PlanThamDinhCompaniesSort;
          this.PlanThamDinhCompaniesService.DataSource.paginator = this.PlanThamDinhCompaniesPaginator;

          if (this.PlanThamDinhCompaniesService.List) {
            if (this.PlanThamDinhCompaniesService.List.length > 0) {
              for (let i = 0; i < this.PlanThamDinhCompaniesService.List.length; i++) {
                this.CompanyInfoService.BaseParameter.ID = this.PlanThamDinhCompaniesService.List[i].CompanyInfoID;
                this.CompanyInfoService.GetByIDAsync().subscribe(
                  res => {
                    this.PlanThamDinhCompaniesService.List[i].CompanyInfo = (res as CompanyInfo);
                  },
                  err => {
                  }
                );
              }
            }
          }

          this.PlanThamDinhCompaniesService.IsShowLoading = false;
        },
        err => {
          this.PlanThamDinhCompaniesService.IsShowLoading = false;
        }
      );
    }
  }

  CompanyInfoAdd(ID: number) {
    this.PlanThamDinhCompaniesService.IsShowLoading = true;
    this.CompanyInfoService.BaseParameter.ID = ID;
    this.CompanyInfoService.GetByIDAsync().subscribe(
      res => {
        this.CompanyInfoService.FormData = res as CompanyInfo;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(CompanyInfoDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
        this.PlanThamDinhCompaniesService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhCompaniesService.IsShowLoading = false;
      }
    );
  }
  CoSoMapDetailAdd(ID: number) {
    this.PlanThamDinhCompaniesService.IsShowLoading = true;
    this.CompanyInfoService.BaseParameter.ID = ID;
    this.CompanyInfoService.GetByIDAsync().subscribe(
      res => {
        this.CompanyInfoService.FormData = res as CompanyInfo;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(CoSoMapDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
        this.PlanThamDinhCompaniesService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhCompaniesService.IsShowLoading = false;
      }
    );
  }

  openFileInput(element: any) {
    const index = this.listCutGobal.indexOf(element); // Tìm index của element
    if (index !== -1) {
      const inputElement = this.fileInputs.toArray()[index];
      inputElement.nativeElement.click(); // Mở input của đúng element
    }
    // this.fileInputs.nativeElement.click();
  }

  PlanThamDinhCompaniesAdd(ID: number) {
    this.PlanThamDinhCompaniesService.IsShowLoading = true;
    this.PlanThamDinhCompaniesService.BaseParameter.ID = ID;
    this.PlanThamDinhCompaniesService.GetByIDAsync().subscribe(
      res => {
        this.PlanThamDinhCompaniesService.FormData = res as PlanThamDinhCompanies;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(PlanThamDinhCompaniesDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.PlanThamDinhCompaniesSearch();
          this.PlanThamDinhCompanyDocumentSearch();
        });
        this.PlanThamDinhCompaniesService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhCompaniesService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhCompaniesDelete(element: PlanThamDinhCompanies) {
    if (confirm(environment.DeleteConfirm)) {
      this.PlanThamDinhCompaniesService.IsShowLoading = true;
      element.Active = false;
      this.PlanThamDinhCompaniesService.FormData = element;
      this.PlanThamDinhCompaniesService.BaseParameter.ID = element.ID;
      this.PlanThamDinhCompaniesService.RemoveAsync().subscribe(
        res => {
          this.NotificationService.warn(environment.DeleteSuccess);
          this.PlanThamDinhCompaniesSearch();
          this.PlanThamDinhCompaniesService.IsShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
          this.PlanThamDinhCompaniesService.IsShowLoading = false;
        }
      );
    }
  }
  triggerOriginalLinkClick() {
    this.originalLink.nativeElement.click();
  }
  MailAdd(ID: number) {
    this.PlanThamDinhCompaniesService.IsShowLoading = true;
    this.MailService.BaseParameter.ID = ID;
    this.MailService.AnToanThucPhamThamDinhKetQuaByPlanThamDinhCompaniesIDAsync().subscribe(
      res => {
        this.PlanThamDinhCompaniesService.IsShowLoading = false;
        this.NotificationService.warn(environment.SentMailSuccess);
      },
      err => {
        this.PlanThamDinhCompaniesService.IsShowLoading = false;
        this.NotificationService.warn(environment.SentMailNotSuccess);
      }
    );
  }

  Close() {
    this.dialogRef.close();
  }
}
