<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-CauHoiATTPDetail" data-bs-toggle="tab" href="#CauHoiATTPDetail"
                role="tab" aria-controls="CauHoiATTPDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Thông tin</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="CauHoiATTPDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-5 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <a style="width: 100%;" title="Lưu thay đổi" (click)="CauHoiATTPSave()" class="btn btn-info"><i
                                class="bi bi-sd-card"></i> Lưu thay đổi</a>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Nhóm</label>
                        <select class="form-select" name="ParentID" [(ngModel)]="CauHoiATTPService.FormData.ParentID">
                            <option *ngFor="let item of CauHoiNhomService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu đề</label>
                        <input name="Name" [(ngModel)]="CauHoiATTPService.FormData.Name" placeholder="Tiêu đề"
                            type="text" class="form-control">
                    </div>                   
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Sắp xếp</label>
                        <input name="SortOrder" [(ngModel)]="CauHoiATTPService.FormData.SortOrder" placeholder="0"
                            type="number" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi chú</label>
                        <input name="Note" [(ngModel)]="CauHoiATTPService.FormData.Note" placeholder="Ghi chú"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <div class="form-check form-check-inline mb-6">
                            <input type="checkbox" class="form-check-input" name="Active"
                                [(ngModel)]="CauHoiATTPService.FormData.Active" />
                            <label class="form-check-label" for="Active">Kích hoạt</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 col-sm-12 col-12">
                    <div *ngIf="CauHoiATTPService.FormData.ID>0" class="card mb-4 card-info">
                        <div class="card-header">
                            <h5 class="card-title text-white">Đáp án
                                <span
                                    *ngIf="CauHoiATTPQuestionsService.List">({{CauHoiATTPQuestionsService.List.length}}
                                    items)</span>
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #CauHoiATTPQuestionsSort="matSort"
                                        [dataSource]="CauHoiATTPQuestionsService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="Name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Tiêu đề"
                                                    name="Name{{element.ID}}" [(ngModel)]="element.Name">
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="SortOrder">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="number" placeholder="0"
                                                    name="SortOrder{{element.ID}}" [(ngModel)]="element.SortOrder"
                                                    style="text-align: right;">
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="is_ans">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Đáp án
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox"
                                                        name="is_ans{{element.ID}}" [(ngModel)]="element.is_ans">
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-info" (click)="CauHoiATTPQuestionsSave(element)"><i
                                                        class="bi bi-sd-card"></i>
                                                </a>&nbsp;&nbsp;&nbsp;
                                                <a class="btn btn-danger" (click)="CauHoiATTPQuestionsDelete(element)"
                                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CauHoiATTPQuestionsService.DisplayColumns001">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CauHoiATTPQuestionsService.DisplayColumns001;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #CauHoiATTPQuestionsPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                        [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="CauHoiATTPService.IsShowLoading"></app-loading>