<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-RegisterHarvestDetail" data-bs-toggle="tab" href="#RegisterHarvestDetail"
                role="tab" aria-controls="RegisterHarvestDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Đăng ký thu hoạch
                    NT2MV</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="RegisterHarvestDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-3 col-sm-12 col-12">
                    <a style="width: 100%;" title="Hoàn thành và Gửi hồ sơ cho cơ quan quản lý"
                        (click)="RegisterHarvestSave()" class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu thay
                        đổi</a>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <a *ngIf="RegisterHarvestService.FormData.ID>0" style="width: 100%;" title="Tải về"
                        (click)="PlanThamDinhCompanyDocumentAdd(57)" class="btn btn-success"><i
                            class="bi bi-cloud-download"></i> Giấy đăng ký</a>
                </div>
                <!-- <div class="col-lg-3 col-sm-12 col-12">
                    <a *ngIf="RegisterHarvestService.FormData.ID>0" style="width: 100%;" title="Tải về"
                        (click)="PlanThamDinhCompanyDocumentAdd(58)" class="btn btn-success"><i
                        class="bi bi-cloud-download"></i> Phiếu kiểm soát</a>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <a *ngIf="RegisterHarvestService.FormData.ID>0" style="width: 100%;" title="Tải về"
                        (click)="PlanThamDinhCompanyDocumentAdd(59)" class="btn btn-success"><i
                        class="bi bi-cloud-download"></i> Giấy chứng nhận</a>
                </div> -->
            </div>
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4>Bước 01: Nhập Thông tin chung</h4>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">

                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Giống</label>
                        <select class="form-select" name="DanhMucLayMauID" disabled
                            [(ngModel)]="RegisterHarvestService.FormData.DanhMucLayMauID">
                            <option *ngFor="let item of DanhMucLayMauService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi chú</label>
                        <input name="Note" [(ngModel)]="RegisterHarvestService.FormData.Note" placeholder="Ghi chú"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-6 col-sm-12 col-12">
                        <div class="form-check form-check-inline mb-6">
                            <input type="checkbox" class="form-check-input" name="Active"
                                [(ngModel)]="RegisterHarvestService.FormData.Active" />
                            <label class="form-check-label" for="Active">Kích hoạt</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đơn vị Tổ chức</label>
                        <input name="CompanyInfoName" [(ngModel)]="RegisterHarvestService.FormData.CompanyInfoName"
                            disabled *ngIf="RegisterHarvestService.FormData.ID>0" placeholder="Đơn vị Tổ chức"
                            type="text" class="form-control">
                        <mat-select class="form-control" name="RegisterHarvestService.FormData.ParentID"
                            *ngIf="RegisterHarvestService.FormData.ID==0"
                            [(ngModel)]="RegisterHarvestService.FormData.ParentID">
                            <input placeholder="Tìm..." class="form-control"
                                (keyup)="CompanyInfoFilter($event.target.value)">
                            <mat-option *ngFor="let item of CompanyInfoService.ListFilter" [value]="item.ID">
                                {{item.Name}} - {{item.phone}} - {{item.DKKD}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày bắt đầu</label>
                        <input [ngModel]="RegisterHarvestService.FormData.NgayBatDau | date:'yyyy-MM-dd'"
                            (ngModelChange)="DateBatDau($event)" [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                            class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày kết thúc</label>
                        <input [ngModel]="RegisterHarvestService.FormData.NgayKetThuc | date:'yyyy-MM-dd'"
                            (ngModelChange)="DateKetThuc($event)" [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                            class="form-control" autocomplete="off">
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="card mb-4 card-success">
                    <div class="card-header">
                        <h5 class="card-title text-white">Bước 02: Nhập Thông tin thu hoạch</h5>
                    </div>
                    <div class="card-body">
                        <div class="table-outer">
                            <div class="table-responsive">
                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                    #RegisterHarvestItemsSort="matSort"
                                    [dataSource]="RegisterHarvestItemsService.DataSource"
                                    class="table table-striped m-0">
                                    <ng-container matColumnDef="NgayGhiNhan">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ngày thu hoạch<br />
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="text-align: right;">
                                            <input name="NgayGhiNhan{{element.ID}}"
                                                [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                (ngModelChange)="DateRegisterHarvestItemsNgayGhiNhan(element, $event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                autocomplete="off">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Địa chỉ thu hoạch
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <input type="text" placeholder="Địa chỉ thu hoạch" name="Name{{element.ID}}"
                                                [(ngModel)]="element.Name" class="form-control">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Description">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Cơ sở nhận hàng
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <input type="text" placeholder="Cơ sở nhận hàng"
                                                name="Description{{element.ID}}" [(ngModel)]="element.Description"
                                                class="form-control">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="HTMLContent">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Địa chỉ nhận hàng
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <input type="text" placeholder="Địa chỉ nhận hàng"
                                                name="HTMLContent{{element.ID}}" [(ngModel)]="element.HTMLContent"
                                                class="form-control">
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Code001">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phương tiện
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <input type="text" placeholder="Phương tiện" name="Code001{{element.ID}}"
                                                [(ngModel)]="element.Code001" class="form-control">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="SoLuong">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kế hoạch
                                            (kg)
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="text-align: right;">
                                            <input type="number" placeholder="0" name="SoLuong{{element.ID}}"
                                                [(ngModel)]="element.SoLuong" class="form-control" style="text-align: right;">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="SoLuong001">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kiểm soát
                                            (kg)
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="text-align: right;">
                                            <input type="number" placeholder="0" name="SoLuong001{{element.ID}}"
                                                [(ngModel)]="element.SoLuong001" class="form-control" style="text-align: right;">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="DanhMucATTPXepLoaiID">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Xếp loại
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="width: 200px;">
                                            <select class="form-select" name="DanhMucATTPXepLoaiID{{element.ID}}"
                                                [(ngModel)]="element.DanhMucATTPXepLoaiID">
                                                <option *ngFor="let item of DanhMucATTPXepLoaiService.List;"
                                                    [value]="item.ID">
                                                    {{item.Name}}</option>
                                            </select>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Note">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <input type="text" placeholder="Ghi chú" name="Note{{element.ID}}"
                                                [(ngModel)]="element.Note" class="form-control">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Save">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                        <td mat-cell *matCellDef="let element">
                                            <a class="btn btn-info" (click)="RegisterHarvestItemsSave(element)"><i
                                                    class="bi bi-sd-card"></i>
                                            </a>&nbsp;&nbsp;&nbsp;
                                            <a *ngIf="element.ID>0" title="Tải về"
                                                (click)="PlanThamDinhCompanyDocumentAdd001(58, element.ID)"
                                                class="btn btn-success"><i class="bi bi-cloud-download"></i> Phiếu kiểm
                                                soát</a>&nbsp;&nbsp;&nbsp;
                                            <a *ngIf="element.ID>0" title="Tải về"
                                                (click)="PlanThamDinhCompanyDocumentAdd001(59, element.ID)"
                                                class="btn btn-success"><i class="bi bi-cloud-download"></i> Giấy
                                                chứng nhận</a>&nbsp;&nbsp;&nbsp;
                                            <a class="btn btn-danger" (click)="RegisterHarvestItemsDelete(element)"
                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                            </a>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="RegisterHarvestItemsService.DisplayColumns004">
                                    </tr>
                                    <tr mat-row
                                        *matRowDef="let row;columns: RegisterHarvestItemsService.DisplayColumns004">
                                    </tr>
                                </table>
                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                    #RegisterHarvestItemsPaginator="matPaginator"
                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                    [showFirstLastButtons]></mat-paginator>


                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                    #RegisterHarvestItemsSort="matSort"
                                    [dataSource]="RegisterHarvestItemsService.DataSource"
                                    class="table table-striped m-0">
                                    <ng-container matColumnDef="STT">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Danh sách
                                        </th>
                                        <td mat-cell *matCellDef="let element; index as i">

                                        </td>
                                    </ng-container>
                                    <tr mat-header-row
                                        *matHeaderRowDef="RegisterHarvestItemsService.DisplayColumnsMobile"></tr>
                                    <tr mat-row
                                        *matRowDef="let row;columns: RegisterHarvestItemsService.DisplayColumnsMobile">
                                    </tr>
                                </table>
                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                    #RegisterHarvestItemsPaginator="matPaginator"
                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                    [showFirstLastButtons]></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
                <div class="card mb-4 card-primary">
                    <div class="card-header">
                        <h5 class="card-title text-white bg-primary">Bước 03 (Sau khi Gửi hồ sơ cho cơ quan quản lý):
                            Tải về
                            biểu mẫu và Tải lên biểu mẫu đã ký
                        </h5>
                    </div>
                    <div class="card-body bg-primary">
                        <div class="table-outer">
                            <div class="table-responsive">
                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                    #PlanThamDinhCompanyDocumentSort="matSort"
                                    [dataSource]="PlanThamDinhCompanyDocumentService.DataSource"
                                    class="table table-striped m-0">
                                    <ng-container matColumnDef="LastUpdatedDate">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Ngày Ghi nhận
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <b>{{element.LastUpdatedDate | date:'dd/MM/yyyy HH:mm:ss'}}</b>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="width: 600px;">
                                            <input class="form-control" type="text" placeholder="Tiêu đề"
                                                name="Name{{element.ID}}" [(ngModel)]="element.Name" />
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="FileName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải về hoặc
                                            tải lên
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <a *ngIf="element.ID > 0 && element.TypeName" class="link-primary"
                                                href="{{element.TypeName}}"><b>Tải về biểu mẫu</b></a>
                                            <input *ngIf="element.ID == 0" type="file"
                                                (change)="ChangeFileName(element, $event.target.files)">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Save">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <a class="btn btn-info"
                                                (click)="PlanThamDinhCompanyDocumentSave(element)"><i
                                                    class="bi bi-sd-card"></i> </a>&nbsp;&nbsp;&nbsp;
                                            <a class="btn btn-danger"
                                                (click)="PlanThamDinhCompanyDocumentDelete(element)"
                                                *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                            </a>&nbsp;&nbsp;&nbsp;
                                            <a *ngIf="element.ID > 0 && element.FileName" class="btn btn-success"
                                                title="Tải về" href="{{element.FileName}}" target="_blank"><i
                                                    class="bi bi-cloud-download"></i>
                                            </a>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row
                                        *matHeaderRowDef="PlanThamDinhCompanyDocumentService.DisplayColumns001">
                                    </tr>
                                    <tr mat-row
                                        *matRowDef="let row; columns: PlanThamDinhCompanyDocumentService.DisplayColumns001;">
                                    </tr>
                                </table>
                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                    #PlanThamDinhCompanyDocumentPaginator="matPaginator"
                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                    [showFirstLastButtons]></mat-paginator>

                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                    #PlanThamDinhCompanyDocumentSort="matSort"
                                    [dataSource]="PlanThamDinhCompanyDocumentService.DataSource"
                                    class="table table-striped m-0">
                                    <ng-container matColumnDef="STT">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Danh sách
                                        </th>
                                        <td mat-cell *matCellDef="let element; index as i;">
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row
                                        *matHeaderRowDef="PlanThamDinhCompanyDocumentService.DisplayColumnsMobile">
                                    </tr>
                                    <tr mat-row
                                        *matRowDef="let row; columns: PlanThamDinhCompanyDocumentService.DisplayColumnsMobile;">
                                    </tr>
                                </table>
                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                    #PlanThamDinhCompanyDocumentPaginator="matPaginator"
                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                    [showFirstLastButtons]></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="RegisterHarvestService.IsShowLoading"></app-loading>