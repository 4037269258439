<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-CompanyStaffExamDetail" data-bs-toggle="tab"
                href="#CompanyStaffExamDetail" role="tab" aria-controls="CompanyStaffExamDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Thông tin</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="CompanyStaffExamDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-info">
                        <div class="card-header">
                            <h5 class="card-title text-white">{{CompanyExaminationService.FormData.Name}}
                                <span
                                    *ngIf="CompanyExaminationQuestionsService.List">({{CompanyExaminationQuestionsService.List.length}}
                                    items)</span>
                                <span *ngIf="surveyed"> Đã khảo sát</span>

                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table mat-table matSort #CompanyExaminationQuestionsSort="matSort"
                                        [dataSource]="CompanyExaminationQuestionsService.DataSource"
                                        class="table table-striped m-0">                                        
                                        <ng-container matColumnDef="Name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Câu hỏi
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="white-space: normal;">
                                                <b>{{element.Name}}</b>
                                                <ul style="list-style-type: none; margin-left: -30px;">
                                                    <li
                                                        *ngFor="let itemCauHoiATTPQuestions of element.ListCauHoiATTPQuestions;">
                                                        <div class="form-check form-check-inline mb-6">
                                                            <input type="checkbox" class="form-check-input"
                                                                name="Active"
                                                                [ngClass]="{'disabled': surveyed, 'enabled': !surveyed}"
                                                                [(ngModel)]="itemCauHoiATTPQuestions.Active" 
                                                                (change)="CompanyStaffExamAnswersActiveChange(element, itemCauHoiATTPQuestions, element.ListCauHoiATTPQuestions)" />
                                                            <label class="form-check-label" for="Active">{{itemCauHoiATTPQuestions.Name}}</label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyExaminationQuestionsService.DisplayColumns003">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyExaminationQuestionsService.DisplayColumns003;">
                                        </tr>
                                    </table>
                                    
                                    <div class="card-footer">
                                        <a class="btn btn-info" [ngClass]="{'disabled': surveyed, 'enabled': !surveyed}" (click)="SaveSurvey()" style="position: absolute; bottom: 30px;"><i class="bi bi-sd-card"></i> Lưu khảo sát
                                        </a>
                                        <mat-paginator #CompanyExaminationQuestionsPaginator="matPaginator"
                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                            [showFirstLastButtons]>
                                        </mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="CompanyStaffExamService.IsShowLoading"></app-loading>