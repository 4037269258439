import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TemPhieuKhoiTao } from './TemPhieuKhoiTao.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class TemPhieuKhoiTaoService extends BaseService{

    DisplayColumns001: string[] = ['STT', 'ID', 'GhiNhan', 'ThanhVienName', 'ToChucName', 'Code', 'SoLuong', 'SuDung', 'ConLai', 'Active', 'IsThuHoi', 'Save'];
    DisplayColumns002: string[] = ['STT', 'ID', 'GhiNhan', 'ThanhVienName', 'Code', 'SoLuong', 'SuDung', 'ConLai', 'Active', 'IsThuHoi'];
        
    List: TemPhieuKhoiTao[] | undefined;
    ListFilter: TemPhieuKhoiTao[] | undefined;
    FormData!: TemPhieuKhoiTao;

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "TemPhieuKhoiTao";
    }

    GetByToChucIDToListAsync() {
        let url = this.APIURL + this.Controller + '/GetByToChucIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    GetSQLByToChucID_ActiveToListAsync() {
        let url = this.APIURL + this.Controller + '/GetSQLByToChucID_ActiveToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    GetBySearchString_DanhMucUngDungIDToListAsync() {
        let url = this.APIURL + this.Controller + '/GetBySearchString_DanhMucUngDungIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
}

