<h2 mat-dialog-title style="text-align: center;">Đơn đề nghị</h2>
<mat-dialog-actions>
    <button mat-button (click)="close()" style="margin-left: auto;">Đóng</button>
  </mat-dialog-actions>
<mat-dialog-content class="iframe-dialog-content">
  <iframe 
  [src]="safeUrl" 
  width="100%" 
  height="100%" 
  frameborder="0" 
  sandbox="allow-same-origin allow-scripts allow-forms allow-popups">
</iframe>
</mat-dialog-content>
