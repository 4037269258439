import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/TruyXuatNguonGoc/Download.service';

import { TruyXuatNguonGocToChuc } from 'src/app/shared/TruyXuatNguonGoc/ToChuc.model';
import { TruyXuatNguonGocToChucService } from 'src/app/shared/TruyXuatNguonGoc/ToChuc.service';

import { DanhMucUngDung } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.model';
import { DanhMucUngDungService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.service';

import { CompanyInfo } from 'src/app/shared/CompanyInfo.model';
import { CompanyInfoService } from 'src/app/shared/CompanyInfo.service';
import { CompanyInfoDetailComponent } from '../company-info-detail/company-info-detail.component';

@Component({
  selector: 'app-truy-xuat-nguon-goc-kho-tem',
  templateUrl: './truy-xuat-nguon-goc-kho-tem.component.html',
  styleUrls: ['./truy-xuat-nguon-goc-kho-tem.component.css']
})
export class TruyXuatNguonGocKhoTemComponent implements OnInit {

  @ViewChild('ToChucSort') ToChucSort: MatSort;
  @ViewChild('ToChucPaginator') ToChucPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public TruyXuatNguonGocToChucService: TruyXuatNguonGocToChucService,

    public DanhMucUngDungService: DanhMucUngDungService,

    public CompanyInfoService: CompanyInfoService,
  ) { }

  ngOnInit(): void {
    this.DanhMucUngDungService.GetLogin();
    this.ToChucSearch001();
  }
  ToChucSearch001() {
    this.TruyXuatNguonGocToChucService.BaseParameter.Active = true;    
    this.TruyXuatNguonGocToChucService.ComponentGetByActiveAndPageAndPageSizeAndIDToListAsync(this.TruyXuatNguonGocToChucService);
  }
  ToChucFilter(searchString: string) {
    this.TruyXuatNguonGocToChucService.FilterActive(searchString, this.TruyXuatNguonGocToChucService);
  }
  ToChucSearch() {
    this.TruyXuatNguonGocToChucService.IsShowLoading = true;
    this.TruyXuatNguonGocToChucService.BaseParameter.DanhMucUngDungID = Number(localStorage.getItem(environment.TruyXuatNguonGocDanhMucUngDungID));
    this.TruyXuatNguonGocToChucService.GetByDanhMucUngDungID_SearchStringToListAsync().subscribe(
      res => {
        this.TruyXuatNguonGocToChucService.ListFilter001 = (res as TruyXuatNguonGocToChuc[]).sort((a, b) => (a.TemPhieuSoLuong < b.TemPhieuSoLuong ? 1 : -1));
        this.TruyXuatNguonGocToChucService.List001 = [];
        for (let i = 0; i < this.TruyXuatNguonGocToChucService.ListFilter001.length; i++) {
          let TruyXuatNguonGocToChuc = this.TruyXuatNguonGocToChucService.ListFilter001[i];
          if (TruyXuatNguonGocToChuc.TemPhieuSoLuong > 0) {
            this.TruyXuatNguonGocToChucService.List001.push(TruyXuatNguonGocToChuc);
          }
        }
        this.TruyXuatNguonGocToChucService.DataSource = new MatTableDataSource(this.TruyXuatNguonGocToChucService.List001);
        this.TruyXuatNguonGocToChucService.DataSource.sort = this.ToChucSort;
        this.TruyXuatNguonGocToChucService.DataSource.paginator = this.ToChucPaginator;
        this.TruyXuatNguonGocToChucService.IsShowLoading = false;
      },
      err => {
        this.TruyXuatNguonGocToChucService.IsShowLoading = false;
      }
    );
  }
  ToChucAdd(ID: number) {
    this.TruyXuatNguonGocToChucService.IsShowLoading = true;
    this.TruyXuatNguonGocToChucService.BaseParameter.ID = ID;
    this.TruyXuatNguonGocToChucService.GetByIDAsync().subscribe(
      res => {
        this.TruyXuatNguonGocToChucService.FormData = res as TruyXuatNguonGocToChuc;


        this.CompanyInfoService.BaseParameter.ID = this.TruyXuatNguonGocToChucService.FormData.IDToken;
        this.CompanyInfoService.GetByIDAsync().subscribe(
          res => {
            this.CompanyInfoService.FormData = res as CompanyInfo;
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.width = environment.DialogConfigWidth;
            dialogConfig.data = { ID: ID };
            const dialog = this.dialog.open(CompanyInfoDetailComponent, dialogConfig);
            dialog.afterClosed().subscribe(() => {
            });
          },
          err => {
          }
        );


        this.TruyXuatNguonGocToChucService.IsShowLoading = false;
      },
      err => {
        this.TruyXuatNguonGocToChucService.IsShowLoading = false;
      }
    );
  }
}