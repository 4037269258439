import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-danh-muc-han-su-dung',
  templateUrl: './danh-muc-han-su-dung.component.html',
  styleUrls: ['./danh-muc-han-su-dung.component.css']
})
export class DanhMucHanSuDungComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
