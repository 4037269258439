import { Component, OnInit, Inject, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

import { CompanyInfo } from 'src/app/shared/CompanyInfo.model';
import { CompanyInfoService } from 'src/app/shared/CompanyInfo.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  private bodyClickListener?: () => void;
  MatKhauIsActive: boolean = true;
  constructor(
    private renderer: Renderer2,
    public router: Router,
    public NotificationService: NotificationService,
    public ThanhVienService: ThanhVienService,
    public CompanyInfoService: CompanyInfoService,
  ) { }

  ngOnInit(): void {
    this.GetByQueryString();
    document.getElementById("sidebar").style.display = "none";
    //document.getElementById("main-container").style.padding = "0";
    this.BodyListener();
  }
  
  BodyListener() {
    this.bodyClickListener = this.renderer.listen(
      document.body,
      'keyup',
      (event) => {
        if (event && event.keyCode === 13) {
          this.Submit();
        }
      }
    );
  }
  
  ngOnDestroy(): void {
    document.getElementById("sidebar").style.display = "block";
    //document.getElementById("main-container").style.padding = "0 0 0 300px";
    if (this.bodyClickListener) {
      this.bodyClickListener();
    }
  }

  MatKhauChangeType() {
    this.MatKhauIsActive = !this.MatKhauIsActive;
  }

  GetByQueryString() {
    this.ThanhVienService.IsShowLoading = true;
    this.ThanhVienService.BaseParameter.ID = environment.InitializationNumber;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.FormDataLogin = res as ThanhVien;
        this.ThanhVienService.IsShowLoading = false;
      },
      err => {
        this.ThanhVienService.IsShowLoading = false;
      }
    );
  }
  Submit() {
    this.ThanhVienService.IsShowLoading = true;
    let tokenFCM = localStorage.getItem(environment.TokenFCM);
    if (tokenFCM != null) {
      this.ThanhVienService.FormData.uid = tokenFCM;
    }
    this.ThanhVienService.SaveAsync().subscribe(
      res => {
        this.ThanhVienService.FormDataLogin = res as ThanhVien;
        this.NotificationService.warn(environment.SaveSuccess);
        this.ThanhVienService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.ThanhVienService.IsShowLoading = false;
      }
    );
  }
  Login() {
    this.router.navigate(['/' + environment.Login]);
  }
}